import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type DraftOptionsProps = {
  selectedOption: DraftOptionsEnum;
  setSelectedOption: (option: DraftOptionsEnum) => void;
  disabled: boolean;
};

export enum DraftOptionsEnum {
  auto = 'auto',
  partial = 'partial',
  manual = 'manual',
}

const DraftOptions = (props: DraftOptionsProps) => {
  return (
    <div className="my-4">
      <FormControl fullWidth>
        <InputLabel id="draft-options-select-label">
          Selected Draft Option
        </InputLabel>
        <Select
          labelId="draft-options-select-label"
          id="draft-options-select"
          value={props.selectedOption}
          onChange={(e) =>
            props.setSelectedOption(e.target.value as DraftOptionsEnum)
          }
          disabled={props.disabled}
          label="Selected Draft Option"
        >
          <MenuItem key={DraftOptionsEnum.auto} value={DraftOptionsEnum.auto}>
            Auto Pick
          </MenuItem>
          <MenuItem
            key={DraftOptionsEnum.partial}
            value={DraftOptionsEnum.partial}
          >
            Partial Draft
          </MenuItem>

          {/* Perform manual picks for the entire draft */}
          {/* <MenuItem
            key={DraftOptionsEnum.manual}
            value={DraftOptionsEnum.manual}
          >
            Manual Picks
          </MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
};

export default DraftOptions;
