import {
  Button,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  allTrophies,
  iChatMessage,
  iLeague,
  iTrophy,
  iUser,
  iUserTrophiesDB,
} from '@shared/shared-utils/models';
import { useContext, useEffect, useState } from 'react';
import Loading from '../../../components/ui/loading-animation';
import { LeagueContext } from '../../../providers/league-provider';
import ModalWrapper from '../../../components/modals/modal-wrapper/modal-wrapper';
import { Timestamp } from 'firebase/firestore';
import { OperationsContext } from '../operations-provider';
import { getTrophyById } from '../../../assets/trophies/trophy-icons';
import { NotificationService } from '../../../services/notification-service';

type OUserDetailsType = {
  user: iUser;
  toggleModal: () => void;
};

const OUserDetails = (props: OUserDetailsType) => {
  const { appConfig } = useContext(LeagueContext);
  const { getUsersLeagues, getAllChatsForUser, updateUserTrophies } =
    useContext(OperationsContext);
  const [userData, setUserData] = useState<iUser>(props.user);
  const [usersLeaguesList, setUsersLeaguesList] = useState<iLeague[]>();
  const [selectedSeason, setSelectedSeason] = useState<number>(
    appConfig.currentSeason,
  );
  const [userChats, setUserChats] = useState<iChatMessage[]>([]);
  const [chatLeagueIds, setChatLeagueIds] = useState<string[]>([]);
  const [mostRecentMessageDate, setMostRecentMessageDate] = useState<Date>();
  const [showAddUserTrophyDialog, setShowAddUserTrophyDialog] = useState(false);
  const [trophyButtonDisabled, setTrophyButtonDisabled] = useState(true);
  const [trophySwitchState, setTrophySwitchState] = useState<{
    [trophyType: string]: {
      toggleState: boolean;
    };
  }>({});

  const _notificationService = new NotificationService();

  useEffect(() => {
    selectedSeason && buildUserData();
  }, []);

  const buildUserData = async () => {
    const usersLeagues = await getUsersLeagues(userData, selectedSeason);
    setUsersLeaguesList(usersLeagues);

    const chats = await getAllChatsForUser(userData.id);
    if (chats) {
      setUserChats(chats);

      if (usersLeagues.length > 0 && chats.length > 0) {
        const chatLeagueIds: string[] = [];
        chats.forEach((userChat) => {
          chatLeagueIds.push(userChat.roomId);
        });

        const uniqueIds = [...new Set(chatLeagueIds)];
        setChatLeagueIds(uniqueIds);

        // Get most recent message sent date
        const lastSentDates: Date[] = [];
        usersLeagues.forEach((league: iLeague) => {
          userData.messageHistory?.[league.id] &&
            lastSentDates.push(userData.messageHistory?.[league.id]);
        });
        lastSentDates.sort((a, b) => b.valueOf() - a.valueOf());
        const lastTimeStamp = lastSentDates[0] as unknown as Timestamp;
        setMostRecentMessageDate(lastTimeStamp.toDate());
      }
    }

    setUpInitialTrophySwitchState();
  };

  // Set up the initial local state for the trophy switches based on the user's trophies
  const setUpInitialTrophySwitchState = () => {
    if (props.user.userTrophies) {
      const trophyState: {
        [trophyType: string]: { toggleState: boolean; progress: number };
      } = {};

      // Look through all trophy types to see if the user has any trophies
      Object.keys(allTrophies).forEach((trophyType) => {
        // This sets each switch to either true or false
        if (props.user.userTrophies?.[trophyType]) {
          trophyState[trophyType] = {
            ...trophyState[trophyType],
            toggleState: true,
          };
        } else {
          trophyState[trophyType] = {
            ...trophyState[trophyType],
            toggleState: false,
          };
        }

        // If the trophy type is a progress trophy...
        if (
          allTrophies[trophyType].progress &&
          props.user.userTrophies?.[trophyType]
        ) {
          const seasonsForTrophy = Object.keys(
            props.user.userTrophies[trophyType],
          );
          seasonsForTrophy.forEach((season) => {
            if (props.user.userTrophies?.[trophyType][+season]) {
              trophyState[trophyType] = {
                ...trophyState[trophyType],
              };
            }
          });
        }
      });

      setTrophySwitchState(trophyState);
    }
  };

  // TODO: calculate the progress when a trophy is turned on
  // When the switch is toggled, remove or add the trophy on the user object
  const handleTrophySwitchToggle = (
    switchState: boolean,
    trophyData: iTrophy,
  ) => {
    let newTrophies: {
      [trophyType: string]: {
        toggleState: boolean;
      };
    } = trophySwitchState;

    newTrophies = {
      ...newTrophies,
      [trophyData.trophyType]: {
        toggleState: switchState,
      },
    };

    // Update the local switch state
    setTrophySwitchState(newTrophies);
  };

  // Convert the switch state into the iUserTrophiesDB format then save the new trophies on the user
  const handleTrophySave = async () => {
    let newUsersTrophiesState: iUserTrophiesDB | undefined =
      props.user.userTrophies;
    let sendPushNotification: boolean = false;
    Object.keys(trophySwitchState).forEach((trophyType) => {
      // Check the toggle state of each trophy.
      // If toggled on, make sure user has that trophy.
      // If toggled off, make sure user doesn't have that trophy.
      if (trophySwitchState[trophyType].toggleState) {
        // If the user already has the trophy, do nothing
        if (newUsersTrophiesState?.[trophyType]) {
          return;
        } else {
          // If the user doesn't have the trophy, add it
          newUsersTrophiesState = {
            ...newUsersTrophiesState,
            [trophyType]: {
              [appConfig.currentSeason]: {
                trophyType: trophyType,
                awardDate: new Date(),
              },
            },
          };

          // Only send notifications on some manual add trophies
          if (allTrophies[trophyType].manualTrophyNotification) {
            sendPushNotification = true;
          }

          return;
        }
      } else {
        // If the user has the trophy, remove it
        if (newUsersTrophiesState?.[trophyType]) {
          delete newUsersTrophiesState[trophyType];
          return;
        } else {
          return;
        }
      }
    });

    if (newUsersTrophiesState) {
      const newUserDetails = await updateUserTrophies(
        userData,
        newUsersTrophiesState,
      );

      // Send a new trophy push notification to the user
      try {
        if (sendPushNotification && userData.playerId) {
          _notificationService.sendNewTrophyNotification(userData.playerId);
        }
      } catch (error) {
        const errorMessage =
          'Error sending new trophy push notification: ' + error;
        console.log(errorMessage);
      }

      // Update local user state to match new selections and show changes right away
      setUserData(newUserDetails);
      setShowAddUserTrophyDialog(false);
    }
  };

  return usersLeaguesList ? (
    <ModalWrapper
      title={`User Details: ${userData?.fullName}`}
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={props.toggleModal}
    >
      <div className="mt-4 pb-4 max-h-[90vh] overflow-scroll">
        <h3>ID: {userData?.id}</h3>
        <h3>Email: {userData?.email}</h3>
        <h3>Full Name: {userData?.fullName}</h3>
        <h3>Phone Number: {userData?.phoneNumber}</h3>
        <h3>PlayerID: {userData?.playerId ? userData?.playerId : 'None'}</h3>
        <h3>Discord Auth: {userData?.discordAuthTokens ? 'Exists' : 'None'}</h3>
        <h3>
          User Created Date:{' '}
          {userData.createdDate
            ? userData.createdDate.toLocaleDateString()
            : ''}
        </h3>
        <h3>
          User Trophies:{' '}
          {userData.userTrophies
            ? Object.keys(userData.userTrophies).join(', ')
            : 'None'}
        </h3>
        <div>
          <Button
            variant="contained"
            onClick={() => setShowAddUserTrophyDialog(true)}
          >
            Add User Trophies
          </Button>
        </div>
        <div>
          <div className="w-full flex flex-row justify-between items-center pt-4">
            <h2>User Leagues Data</h2>
            <Select
              value={selectedSeason}
              onChange={(e) => setSelectedSeason(+e.target.value)}
              // We don't have previous season chats right now
              disabled={true}
            >
              {appConfig.allSeasons?.map((season) => {
                return (
                  <MenuItem key={season} value={season}>
                    {season}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          {usersLeaguesList.length === 0 ? (
            <span>No leagues to show</span>
          ) : userChats && chatLeagueIds.length ? (
            <>
              <h3>Active League Chats: {chatLeagueIds.length}</h3>
              <h3>Total # Messages: {userChats.length}</h3>
              <h3>
                Last Message Sent Date:{' '}
                {mostRecentMessageDate
                  ? mostRecentMessageDate.toDateString()
                  : '--'}
              </h3>
              <div className="flex flex-1 flex-col">
                <TableContainer sx={{ maxHeight: 330, overflow: 'scroll' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>League Name</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Season</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell># Messages Sent</TableCell>
                        <TableCell>Last Sent Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersLeaguesList.map((league) => {
                        const lastCheckedDate = userData.messageHistory?.[
                          league.id
                        ]
                          ? new Date(
                              (
                                userData.messageHistory[
                                  league.id
                                ] as unknown as Timestamp
                              ).toDate(),
                            )
                          : null;
                        return (
                          <TableRow key={league.id}>
                            <TableCell>{league.title}</TableCell>
                            <TableCell>{league.id}</TableCell>
                            <TableCell>{league.season}</TableCell>
                            <TableCell>
                              {league.creator?.id === userData?.id
                                ? 'YES'
                                : 'NO'}
                            </TableCell>
                            <TableCell>
                              {
                                userChats.filter(
                                  (chat) => chat.roomId === league.id,
                                ).length
                              }
                            </TableCell>
                            <TableCell>
                              {lastCheckedDate
                                ? lastCheckedDate.toLocaleDateString()
                                : 'No chat history'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <span>Total Leagues: {usersLeaguesList.length}</span>
            </>
          ) : (
            <span>No chat data is available</span>
          )}
        </div>
      </div>
      <Dialog
        onClose={() => setShowAddUserTrophyDialog(false)}
        open={showAddUserTrophyDialog}
      >
        <DialogTitle>Add User Trophies</DialogTitle>
        <img
          src="/assets/images/cancel.png"
          className="w-4 h-4 cursor-pointer ml-auto absolute top-6 right-8"
          onClick={() => setShowAddUserTrophyDialog(false)}
          alt="cancel"
        />
        <div className="flex flex-col mx-4 mb-2">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Trophy ID</TableCell>
                  <TableCell>Toggle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(allTrophies).map((trophy) => {
                  const trophyData = getTrophyById(trophy);
                  return (
                    <TableRow key={trophyData.trophyType}>
                      <TableCell key={'id:' + trophyData.trophyType}>
                        {trophyData.trophyType}
                      </TableCell>
                      <TableCell key={'toggle:' + trophyData.trophyType}>
                        <Tooltip
                          title={
                            allTrophies[trophy].progress
                              ? 'Progress trophies cannot be awarded manually.'
                              : allTrophies[trophy].pending
                                ? 'Pending trophies cannot be awarded yet.'
                                : null
                          }
                        >
                          <div>
                            <Switch
                              checked={
                                trophySwitchState?.[trophy]?.toggleState ??
                                false
                              }
                              onChange={(e) => {
                                handleTrophySwitchToggle(
                                  e.target.checked,
                                  trophyData,
                                );
                                setTrophyButtonDisabled(false);
                              }}
                              // If this is progress trophy, do not allow toggling for now.
                              // TODO: add in the ability to manually modify progress
                              disabled={
                                allTrophies[trophy].progress ||
                                allTrophies[trophy].pending
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            onClick={() => handleTrophySave()}
            disabled={trophyButtonDisabled}
            variant="contained"
          >
            Save User Trophies
          </Button>
        </div>
      </Dialog>
    </ModalWrapper>
  ) : (
    <Loading></Loading>
  );
};

export default OUserDetails;
