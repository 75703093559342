import { ChangeEvent, useContext, useEffect, useState } from 'react';
import PasswordInput from '../../../components/ui/password-input/password-input';
import { AuthContext } from '../../../providers/auth-provider';
import { ToastContext } from '../../../providers/toast-provider';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthErrorCodes } from 'firebase/auth';
import Button from '@mui/material/Button';
import Loading from '../../../components/ui/loading-animation';
import AuthWrapper from '../../../components/ui/auth-wrapper/auth-wrapper';

const RequestPassword = ({ props }: any) => {
  const { showToast } = useContext(ToastContext);
  const { isLoggedIn, currentUser, loginWithEmailAndPasssword } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { mEmail } = state;
  const [password, setPassword] = useState<string | undefined>();
  const [loginLoading, setLoginLoading] = useState(false);

  const login = async () => {
    if (mEmail && password) {
      try {
        await loginWithEmailAndPasssword(mEmail, password);
      } catch (error: any) {
        if (AuthErrorCodes.INVALID_PASSWORD === error.code) {
          showToast({
            messageType: 'error',
            message: 'Incorrect password',
            autoHideDuration: 15000,
          });
        } else {
          showToast({ messageType: 'error', message: error.message });
        }
        setLoginLoading(false);
      }
    }
  };

  const navigateToCorrectPage = async () => {
    if (isLoggedIn) {
      const redirectUrl = localStorage.getItem('rTo');
      if (redirectUrl) {
        navigate(redirectUrl);
        return;
      }
      navigate('/home');
    }
    if (!mEmail) navigate('/login');
  };

  useEffect(() => {
    navigateToCorrectPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, currentUser]);

  return loginLoading ? (
    <Loading></Loading>
  ) : (
    <AuthWrapper>
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          if (password) {
            setLoginLoading(true);
            login();
          }
        }}
      >
        <div>
          <PasswordInput
            showIconToggler={true}
            label="Password"
            e2eTag="password-input"
            onChangeAction={(event: ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
          />
          <p className="my-4">
            <Link to="/forgot-password" state={{ userEmail: mEmail }}>
              Forgot password?
            </Link>
          </p>
        </div>
        <div className="flex justify-between">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          <Button
            disabled={!mEmail || !password}
            onClick={() => {
              setLoginLoading(true);
              login();
            }}
            data-e2e="login-button"
            variant="contained"
          >
            Login
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default RequestPassword;
