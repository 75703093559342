type TabHeaderItemType = {
  key: string;
  text: string;
  index: number;
  selected: boolean;
  customClass: string;
  onTabSelect: (index: number) => void;
};

const TabHeaderItem = (props: TabHeaderItemType) => {
  return (
    <li
      className={`inline-block px-4 py-2 font-semibold text-xs md:text-sm ${
        props.selected ? ' selected' : ''
      }${props.customClass ? ' ' + props.customClass : ''}`}
      onClick={() => {
        props.onTabSelect(props.index);
      }}
      data-e2e={props.text}
    >
      {props.text}
    </li>
  );
};

export default TabHeaderItem;
