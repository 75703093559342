import React, { useState, useMemo, useContext, useEffect } from 'react';
import { iDraft, iRoundPick, iTeam, iUser } from '@shared/shared-utils/models';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Image from '../../widgets/image';
import ModalWrapper from '../modal-wrapper/modal-wrapper';
import Loading from '../../ui/loading-animation';

import { LeagueContext } from '../../../providers/league-provider';
import { TeamsContext } from '../../../providers/teams-provider';
import { ToastContext } from '../../../providers/toast-provider';
import DraftPickCard from './components/draft-pick-card';
import { MenuItem, Select } from '@mui/material';

interface DraftOverviewProps {
  leagueId: string;
  users: iUser[];
  onCloseClick: () => void;
}

interface iDisplayedRoundPick extends iRoundPick {
  position: number;
}
interface iDisplayedDraft extends iDraft {
  picks: iDisplayedRoundPick[];
}

// Soft hex color codes for borders
const borderColors = [
  '#FFB6C1', // Light Pink
  '#FFDAB9', // Peach Puff
  '#E6E6FA', // Lavender
  '#B0E0E6', // Powder Blue
  '#F0E68C', // Khaki
  '#D8BFD8', // Thistle
  '#E0FFFF', // Light Cyan
  '#F5DEB3', // Wheat
  '#FFFACD', // Lemon Chiffon
  '#FFE4E1', // Misty Rose
  '#F5F5DC', // Beige
  '#E0FFFF', // Light Cyan
];

const DraftOverview: React.FC<DraftOverviewProps> = ({
  leagueId,
  users,
  onCloseClick,
}) => {
  const { getLeagueDraft } = useContext(LeagueContext);
  const { teamsData } = useContext(TeamsContext);
  const { showToast } = useContext(ToastContext);

  const [teams, setTeams] = useState<iTeam[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedRound, setSelectedRound] = useState<number | null>(null);
  const [draft, setDraft] = useState<iDisplayedDraft | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const analytics = getAnalytics();

  useEffect(() => {
    if (leagueId) {
      loadData();
    }
  }, [leagueId]);

  useEffect(() => {
    if (teamsData) {
      setTeams(teamsData);
    }
  }, [teamsData]);

  const loadData = async () => {
    logEvent(analytics, 'draft-overview-view', {
      content_type: 'viewed',
      content_id: leagueId,
    });
    setLoading(true);
    try {
      const draft = await getLeagueDraft(leagueId);

      let pickNumber = 1;
      const displayedDraft: iDisplayedDraft = {
        ...draft,
        picks: draft.picks.map((pick) => {
          const displayedPick: iDisplayedRoundPick = {
            ...pick,
            position: pickNumber,
          };
          pickNumber += 1;

          return displayedPick;
        }),
      };
      setDraft(displayedDraft);
    } catch (error) {
      console.log('Error loading', error);
      showToast({
        messageType: 'error',
        message: 'Something went wrong. Please try again or contact support.',
      });
      onCloseClick();
    }
    setLoading(false);
  };

  // Create maps for faster lookup
  const userMap = useMemo(
    () => new Map(users.map((user) => [user.id, user])),
    [users],
  );
  const teamMap = useMemo(
    () => new Map(teams.map((team) => ['team-' + team.id, team])),
    [teams],
  );

  const filteredPicks = useMemo(() => {
    if (!draft?.picks) return [];
    return draft.picks.filter((pick) => {
      const matchesUser = selectedUser ? pick.memberId === selectedUser : true;
      const matchesRound = selectedRound ? pick.round === selectedRound : true;
      return matchesUser && matchesRound;
    });
  }, [draft, selectedUser, selectedRound]);

  const uniqueRounds = useMemo(
    () =>
      draft ? Array.from(new Set(draft.picks.map((pick) => pick.round))) : [],
    [draft],
  );

  return (
    <ModalWrapper
      title={`Draft Recap`}
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={onCloseClick}
    >
      {loading ? (
        <Loading />
      ) : (
        <div className="p-4">
          <div className="flex flex-col mb-2">
            {/* User Avatars */}
            <div className="flex overflow-x-auto space-x-2 mb-2">
              {users.map((user, index) => (
                <div
                  key={user.id}
                  className={`cursor-pointer p-1 rounded-full ${
                    selectedUser === user.id ? 'border-4' : ''
                  }`}
                  style={
                    selectedUser === user.id ? { borderColor: 'green' } : {}
                  }
                  onClick={() =>
                    setSelectedUser(user.id === selectedUser ? null : user.id)
                  }
                >
                  <div className="w-12 h-12 rounded-full overflow-hidden">
                    <Image
                      imageUrl={user?.profileImage}
                      title={user?.fullName}
                      key={user?.id}
                      specialClass="mb-2"
                      size={12}
                    />
                  </div>
                </div>
              ))}
            </div>
            {/* Round Selection */}
            <div className="flex items-center mb-2">
              <label htmlFor="roundSelect" className="mr-2">
                Show:
              </label>

              <Select
                id="roundSelect"
                value={selectedRound ?? ''}
                onChange={(e) =>
                  setSelectedRound(
                    e.target.value ? parseInt(e.target.value as string) : null,
                  )
                }
                displayEmpty
                className="py-1 px-3 rounded border border-gray-300"
              >
                <MenuItem value="">All Rounds</MenuItem>
                {uniqueRounds.map((round) => (
                  <MenuItem key={round} value={round}>
                    Round {round}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* Display current filter status and clear button */}
            <div className="flex justify-between items-center">
              <div>
                {selectedUser
                  ? `Viewing picks by ${userMap.get(selectedUser)?.fullName}`
                  : 'Click on a user to filter their picks!'}{' '}
                {selectedRound ? `in Round ${selectedRound}` : ''}
              </div>
              {(selectedUser || selectedRound) && (
                <button
                  onClick={() => {
                    setSelectedUser(null);
                    setSelectedRound(null);
                  }}
                  className="text-sm text-blue-600 hover:underline"
                >
                  Clear Filter
                </button>
              )}
            </div>
          </div>

          {/* Draft Grid */}
          <div className="grid grid-cols-3 gap-4 mb-4">
            {filteredPicks.map((pick) => {
              const user = userMap.get(pick.memberId);
              const borderColor =
                user && users.findIndex((u) => u.id === user.id) !== -1
                  ? borderColors[
                      users.findIndex((u) => u.id === user.id) %
                        borderColors.length
                    ]
                  : '#D3D3D3'; // Light Gray as default
              const team = teamMap.get(pick.teamId);

              return (
                <DraftPickCard
                  key={`${pick.round}-${pick.teamId}`}
                  pick={pick}
                  user={user}
                  team={team}
                  borderColor={borderColor}
                />
              );
            })}
          </div>

          {/* Selected User's Picks */}
          {selectedUser && filteredPicks.length === 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-2">
                No picks found for {userMap.get(selectedUser)?.fullName}
              </h3>
            </div>
          )}
        </div>
      )}
    </ModalWrapper>
  );
};

export default DraftOverview;
