export interface iGameResults {
  [teamId: string]: iGameResult;
}

export interface iGameResult {
  conference: string;
  homeAway: string;
  id: number;
  points: number;
  school: string;
  season: number;
  week: number;
  stats: {
    category: string;
    stat: string;
  }[];
};

export interface iSeasonResults {
  [teamId: string]: iSeasonResult;
}

export interface iSeasonResult {
  id: number,
  season: number,
  points: number,
  stats: {
    [category: string]: {
      category: string,
      stat: number;
    };
  };
}

export enum statsGroup {
  'Passing' = 'Passing Points',
  'Rushing' = 'Rushing Points',
  'Defensive' = 'Defensive Points',
}

export const statsData = {
  netPassingYards: {
    name: 'Passing Yards',
  },
  passingTDs: {
    name: 'Passing TDs',
  },
  interceptions: {
    name: 'Interceptions Thrown',
  },
  rushingYards: {
    name: 'Rushing Yards',
  },
  rushingTDs: {
    name: 'Rushing TDs',
  },
  fumblesLost: {
    name: 'Fumbles Lost',
  },
  sacks: {
    name: 'Sacks',
  },
  passesIntercepted: {
    name: 'Interceptions',
  },
  fumblesRecovered: {
    name: 'Fumbles Recovered',
  },
  defensiveTDs: {
    name: 'Defensive TDs',
  },
  pointsScoredAgainst: {
    name: 'Points Scored Against'
  },
  // yardsGainedAgainst: {
  //   name: 'Yards Gained Against'
  // },
  kickReturnTDs: {
    name: 'Kick Return TDs'
  },
  puntReturnTDs: {
    name: 'Punt Return TDs'
  },
  kickingPoints: {
    name: 'Kicking Points'
  }
};
