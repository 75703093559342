import { useContext, useEffect, useState } from 'react';
import Header from '../../components/widgets/header/header';
import Redirect from '../unauthorized-pages/redirect/redirect';
import { AuthContext } from '../../providers/auth-provider';
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import ModalWrapper from '../../components/modals/modal-wrapper/modal-wrapper';
import { OperationsService } from './operations-service';
import { UserService } from '../../services/user-service';
import { Link } from 'react-router-dom';
import { OperationsContext } from './operations-provider';
import {
  iLeague,
  iLeagueDb,
  iUser,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import { LeagueContext } from '../../providers/league-provider';
import { ToastContext } from '../../providers/toast-provider';
import Table from '../../components/ui/table/table';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

const OperationsReports = () => {
  const {
    allUsers,
    allLeagues,
    getAllUserTransfers,
    getAllUserTrades,
    getAllUsersChats,
    getAllUsers,
  } = useContext(OperationsContext);
  const { superAdminStatus } = useContext(AuthContext);
  const { appConfig } = useContext(LeagueContext);
  const { showToast } = useContext(ToastContext);
  const operationsService = new OperationsService();
  const userService = new UserService();
  const [allUsersData, setAllUsersData] = useState<Map<any, any>>();
  const [reportName, setReportName] = useState<string>();
  const [showReportModal, setShowReportModal] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState<ColumnDef<any, any>[]>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [includeInternalLeagues, setIncludeInternalLeagues] = useState(false);

  const allUserMap = new Map();

  useEffect(() => {
    if (allUsers) {
      allUsers.forEach((user) => {
        allUserMap.set(user.id, user);
      });
      setAllUsersData(allUserMap);
    }
  }, []);

  // **** Table Builder **** //

  type HeaderData = {
    header: string;
    id: string;
  }[];

  const headerStyling = (props?: string) => {
    return <div className="text-center">{props}</div>;
  };

  const buildTable = (headerData: HeaderData) => {
    const columnHelper = createColumnHelper<any>();
    const columns = headerData.map((dataObj) => {
      return columnHelper.accessor(dataObj.id, {
        id: dataObj.id,
        header: () => headerStyling(dataObj.header),
      });
    });
    setTableColumns(columns);
  };

  // **** Build Reports **** //

  const buildNoLeaguesReport = async () => {
    setShowReportModal(true);
    setReportName('Users Without a League');
    setLoading(true);

    let usersWithoutALeague: iUser[] = [];
    if (allUsers && allLeagues) {
      const usersInLeaguesSet = new Set();
      allLeagues.forEach((league) => {
        const memberIdsArray = league.memberIds;

        if (memberIdsArray) {
          memberIdsArray.forEach((userId: string) =>
            usersInLeaguesSet.add(userId),
          );
        }
      });

      allUsers.forEach((user) => {
        if (!usersInLeaguesSet.has(user.id)) {
          usersWithoutALeague.push(user);
        }
      });
    } else {
      usersWithoutALeague = await operationsService.getUsersNotInALeague();
    }

    if (!usersWithoutALeague || usersWithoutALeague.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    setLoading(false);
    setNoDataAvailable(false);

    const headerData: HeaderData = [
      { header: 'Email', id: 'email' },
      { header: 'ID', id: 'id' },
    ];

    buildTable(headerData);
    setTableData(usersWithoutALeague);
  };

  const buildNoInvitesReport = async () => {
    setShowReportModal(true);
    setReportName('Leagues Without Invites');
    setLoading(true);
    const noInvitesLeagues = await operationsService.getLeaguesWithoutInvites(
      appConfig.currentSeason,
    );

    if (!noInvitesLeagues || noInvitesLeagues.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    const leagues = includeInternalLeagues
      ? noInvitesLeagues
      : noInvitesLeagues.filter((league) => !league.internalLeague);

    const dataForTable = await Promise.all(
      leagues.map(async (league) => {
        let leagueOwner: iUser;
        if (allUsersData) {
          leagueOwner = allUsersData.get(league.creator);
        } else {
          leagueOwner = await userService.getUserById(league.creator);
        }
        return {
          title: league.title,
          id: league.id,
          email: leagueOwner.email,
          creator: leagueOwner.id,
        };
      }),
    );
    setLoading(false);

    setNoDataAvailable(false);

    const headerData: HeaderData = [
      { header: 'League Name', id: 'title' },
      { header: 'League ID', id: 'id' },
      { header: 'Owner Email', id: 'email' },
      { header: 'Owner ID', id: 'creator' },
    ];

    buildTable(headerData);
    setTableData(dataForTable);
  };

  const buildLeaguesNotFilledReport = async () => {
    setShowReportModal(true);
    setReportName('Leagues Not Filled');
    setLoading(true);
    const leaguesNotFilled = await operationsService.getLeaguesNotFilled(
      appConfig.currentSeason,
    );

    if (!leaguesNotFilled || leaguesNotFilled.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    const leagues = includeInternalLeagues
      ? leaguesNotFilled
      : leaguesNotFilled.filter((league) => !league.internalLeague);

    const dataForTable = await Promise.all(
      leagues.map(async (league) => {
        let leagueOwner: iUser;
        if (allUsersData) {
          leagueOwner = allUsersData.get(league.creator);
        } else {
          leagueOwner = await userService.getUserById(league.creator);
        }

        return {
          title: league.title,
          id: league.id,
          email: leagueOwner ? leagueOwner.email : 'User deleted',
          creator: leagueOwner ? leagueOwner.id : 'User deleted',
        };
      }),
    );

    const headerData: HeaderData = [
      { header: 'League Name', id: 'title' },
      { header: 'League ID', id: 'id' },
      { header: 'Owner Email', id: 'email' },
      { header: 'Owner ID', id: 'creator' },
    ];

    setLoading(false);
    setNoDataAvailable(false);

    buildTable(headerData);
    setTableData(dataForTable);
  };

  const buildLeaguesNotDraftedReport = async () => {
    setShowReportModal(true);
    setReportName('Leagues Filled, Not Drafted');
    setLoading(true);
    const leaguesFilledNotDrafted =
      await operationsService.getLeaguesFilledNotDrafted(
        appConfig.currentSeason,
      );

    if (!leaguesFilledNotDrafted || leaguesFilledNotDrafted.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    const leagues = includeInternalLeagues
      ? leaguesFilledNotDrafted
      : leaguesFilledNotDrafted.filter((league) => !league.internalLeague);

    const dataForTable = await Promise.all(
      leagues.map(async (league) => {
        let leagueOwner: iUser;
        if (allUsersData) {
          leagueOwner = allUsersData.get(league.creator);
        } else {
          leagueOwner = await userService.getUserById(league.creator);
        }
        return {
          title: league.title,
          id: league.id,
          email: leagueOwner.email,
          creator: leagueOwner.id,
        };
      }),
    );

    const headerData: HeaderData = [
      { header: 'League Name', id: 'title' },
      { header: 'League ID', id: 'id' },
      { header: 'Owner Email', id: 'email' },
      { header: 'Owner ID', id: 'creator' },
    ];

    setLoading(false);
    setNoDataAvailable(false);

    buildTable(headerData);
    setTableData(dataForTable);
  };

  const buildLeaguesDraftCompletedReport = async () => {
    setShowReportModal(true);
    setReportName('Leagues with Completed Draft');
    setLoading(true);

    const leaguesDraftCompleted =
      await operationsService.getLeaguesDraftCompleted(appConfig.currentSeason);

    if (!leaguesDraftCompleted || leaguesDraftCompleted.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    const leagues = includeInternalLeagues
      ? leaguesDraftCompleted
      : leaguesDraftCompleted.filter((league) => !league.internalLeague);

    const dataForTable = await Promise.all(
      leagues.map(async (league) => {
        let leagueOwner: iUser;
        if (allUsersData) {
          leagueOwner = allUsersData.get(league.creator);
        } else {
          leagueOwner = await userService.getUserById(league.creator);
        }
        return {
          title: league.title,
          id: league.id,
          email: leagueOwner.email,
          creator: leagueOwner.id,
        };
      }),
    );

    const headerData: HeaderData = [
      { header: 'League Name', id: 'title' },
      { header: 'League ID', id: 'id' },
      { header: 'Owner Email', id: 'email' },
      { header: 'Owner ID', id: 'creator' },
    ];

    setLoading(false);
    setNoDataAvailable(false);

    buildTable(headerData);
    setTableData(dataForTable);
  };

  const buildActiveUsersReport = async () => {
    setShowReportModal(true);
    setReportName('Active Users');
    setLoading(true);

    interface ActiveUser extends iUser {
      numTransfers: number;
      numTrades: number;
      chatCount: number;
    }

    let users: iUser[];

    if (!allUsers || allUsers.length < 1) {
      users = await getAllUsers();
    } else {
      users = allUsers;
    }

    const leaguesDraftCompleted =
      await operationsService.getLeaguesDraftCompleted(appConfig.currentSeason);

    const activeUsersMap = new Map<string, string>();
    const userLeagueCountMap = new Map<string, number>();

    leaguesDraftCompleted.forEach((league) => {
      league.memberIds?.forEach((userId) => {
        activeUsersMap.set(userId, league.id);
        const currentUserLeagueCount = userLeagueCountMap.get(userId);
        userLeagueCountMap.set(
          userId,
          currentUserLeagueCount ? currentUserLeagueCount + 1 : 1,
        );
      });
    });

    const filteredUsers = users.filter((user) => {
      return activeUsersMap.has(user.id);
    });

    if (!filteredUsers || filteredUsers.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    const activeUsers: ActiveUser[] = await Promise.all(
      filteredUsers.map(async (user) => {
        // const transfers = await getAllUserTransfers(user.id);
        // const trades = await getAllUserTrades(user.id);
        // const chats = await getAllUsersChats(user.id);

        const transfers: any = [];
        const trades: any = [];
        const chats: any = [];

        if (!transfers || !trades || !chats) {
          const errorArr = [];
          !trades && errorArr.push('trades');
          !transfers && errorArr.push('transfers');
          !chats && errorArr.push('chats');

          showToast({
            messageType: 'error',
            message: `Error fetching data for ${errorArr.join(', ')}`,
          });
        }

        return {
          ...user,
          numTransfers: transfers ? transfers.length : 0,
          numTrades: trades ? trades.length : 0,
          chatCount: chats ? chats.length : 0,
        };
      }),
    );

    if (!activeUsers || activeUsers.length < 1) {
      setNoDataAvailable(true);
      return;
    }

    const usersData = activeUsers.map((user) => {
      const totalCounts = user.chatCount + user.numTransfers + user.numTrades;
      return {
        ...user,
        numLeagues: userLeagueCountMap.get(user.id) || 0,
        numTransfers: user.numTransfers,
        numTrades: user.numTrades,
        chatCount: user.chatCount,
        totalCounts: totalCounts,
      };
    });

    usersData.sort((a, b) => {
      return b.totalCounts - a.totalCounts;
    });

    const dataForTable = usersData.map((user) => {
      return {
        email: user.email,
        id: user.id,
        fullName: user.fullName,
        numLeagues: user.numLeagues,
        numTransfers: user.numTransfers,
        numTrades: user.numTrades,
        chatCount: user.chatCount,
        totalCounts: user.totalCounts,
      };
    });

    const headerData: HeaderData = [
      { header: 'Email', id: 'email' },
      { header: 'ID', id: 'id' },
      { header: 'Full Name', id: 'fullName' },
      { header: 'Num Leagues', id: 'numLeagues' },
      { header: 'Num Transfers', id: 'numTransfers' },
      { header: 'Num Trades', id: 'numTrades' },
      { header: 'Chat Count', id: 'chatCount' },
      { header: 'Total Activity', id: 'totalCounts' },
    ];

    setLoading(false);
    setNoDataAvailable(false);

    buildTable(headerData);
    setTableData(dataForTable);
  };

  return !superAdminStatus ? (
    <Redirect />
  ) : (
    <div className="flex flex-col football-bg">
      <Header
        title="Operations Portal"
        isHome={false}
        cancelLink="/operations"
        isOP={true}
      />
      <div className="pt-[9rem] flex flex-col space-y-1">
        <Button variant="contained" onClick={() => buildNoLeaguesReport()}>
          Users Without a League
        </Button>
        <Button variant="contained" onClick={() => buildNoInvitesReport()}>
          Leagues Without Invites
        </Button>
        <Button
          variant="contained"
          onClick={() => buildLeaguesNotFilledReport()}
        >
          Leagues Not Filled
        </Button>
        <Button
          variant="contained"
          onClick={() => buildLeaguesNotDraftedReport()}
        >
          Leagues Filled, Not Drafted
        </Button>
        <Button
          variant="contained"
          onClick={() => buildLeaguesDraftCompletedReport()}
        >
          Leagues with Completed Draft
        </Button>
        <Button variant="contained" onClick={() => buildActiveUsersReport()}>
          Active Users
        </Button>
        <FormGroup className="mt-4">
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setIncludeInternalLeagues(e.target.checked);
                }}
              />
            }
            label="Include Internal Leagues"
          />
        </FormGroup>
      </div>
      {showReportModal && (
        <ModalWrapper
          title={`${reportName}`}
          backEnabled={false}
          closeOnTapOutside={false}
          onCloseClicked={() => setShowReportModal(!showReportModal)}
        >
          {!noDataAvailable ? (
            !loading && tableColumns ? (
              <div className="flex flex-col mt-8 px-4">
                <div className="overflow-scroll max-h-96 db-list border-t-[1px] border-t-gray rounded-2xl">
                  <Table
                    data={noDataAvailable ? [] : tableData}
                    columns={tableColumns}
                    columnFilters={undefined}
                    globalFilter={undefined}
                    updateGlobalFilter={undefined}
                    updateColFilter={undefined}
                    rowSelection={undefined}
                    canSelect={0}
                    rowClick={undefined}
                  />
                </div>
                <span className="w-full text-right py-2">
                  {'Total: ' + tableData.length}
                </span>
              </div>
            ) : (
              'Loading report...'
            )
          ) : (
            'No Data'
          )}
        </ModalWrapper>
      )}
      <div className="w-full text-right pt-4">
        <Link
          to="/operations"
          style={{
            color: '#00BE2A',
          }}
        >
          Back
        </Link>
      </div>
    </div>
  );
};

export default OperationsReports;
