import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  User,
  UserCredential,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { Firestore } from 'firebase/firestore/lite';
import React, { ReactNode, useContext, useState } from 'react';
import { iDiscordAuthTokens, iUser } from '@shared/shared-utils/models';
import { DBFirebaseApp, DBFirebaseDB } from '../services/firebase-core';
import { UserService } from '../services/user-service';
import { DBUserRoles } from '@shared/shared-utils';
import { ToastContext } from './toast-provider';

type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextProps = {
  DBFirebaseApp: FirebaseApp;
  DBFirebaseDB: Firestore;
  DBAuth: Auth;
  isLoggedIn: boolean;
  logOut: () => Promise<void>;
  currentUser: User | null;
  isUserProfileComplete: (user: User) => Promise<boolean>;
  getUserLoginMethods: (loginEmail: string) => Promise<string[]>;
  signInWithGoogle: () => Promise<void>;
  signupWithEmailAndPasssword: (
    email: string,
    password: string,
  ) => Promise<UserCredential>;
  loginWithEmailAndPasssword: (
    email: string,
    password: string,
  ) => Promise<UserCredential>;
  sendResetPasswordEmail: (email: string) => Promise<void>;
  verifyResetPasswordCode: (code: string) => Promise<string>;
  resetPassword: (code: string, password: string) => Promise<void>;
  updateLoginStatus: (user: User) => Promise<void>;
  userDetails: iUser | null;
  setUserDetails: (userDetails: iUser) => void;
  getUserRole: () => void;
  deleteUserAccount: (password: string) => Promise<boolean>;
  superAdminStatus: boolean;
  adminStatus: boolean;
  authHasChecked: boolean;
  setDiscordAuthToken: (discordAuthTokens: iDiscordAuthTokens) => void;
  discordAuthToken?: iDiscordAuthTokens;
};

export const AuthContext = React.createContext({} as AuthContextProps);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const googleAuthProvider = new GoogleAuthProvider();
  const DBAuth = getAuth();
  const userService = new UserService();
  const { closeSnackBars } = useContext(ToastContext);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userDetails, setUserDetails] = useState<iUser | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authHasChecked, setAuthHasChecked] = useState(false);
  const [superAdminStatus, setSuperAdminStatus] = useState(false);
  const [adminStatus, setAdminStatus] = useState(false);
  const [discordAuthToken, setDiscordAuthToken] =
    useState<iDiscordAuthTokens>();

  const logOut = async () => {
    await signOut(DBAuth);
    setIsLoggedIn(false);
    setCurrentUser(null);
    setUserDetails(null);
    closeSnackBars();
  };

  const isUserProfileComplete = async (user: User): Promise<boolean> => {
    if (DBAuth?.currentUser && user?.email) {
      const userProfile = await userService.getUserByEmail(user.email);
      if (userProfile) {
        setUserDetails({ ...userProfile });
        return true;
      }
    }
    return false;
  };

  onAuthStateChanged(DBAuth, async (user) => {
    setAuthHasChecked(true);
    if (user && !authHasChecked) await updateLoginStatus(user);
  });

  //PERFORMANCE: running 7 times
  // onAuthStateChanged is the one calling it so often, this is provided by firebase
  const updateLoginStatus = async (user: User) => {
    console.log('updateLoginStatus');
    setCurrentUser(user);
    if (user?.email) {
      setIsLoggedIn(true);
      if (!userDetails) {
        const userProfile = await isUserProfileComplete(user);
        if (userProfile) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(true);
        // saveUserToken(userDetails);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  const getUserLoginMethods = async (loginEmail: string): Promise<string[]> => {
    if (loginEmail) {
      const loginMethods = await fetchSignInMethodsForEmail(DBAuth, loginEmail);
      return loginMethods;
    }
    return [];
  };

  const signInWithGoogle = async () => {
    // await signInWithPopup(DBAuth, googleAuthProvider);
    try {
      await signInWithRedirect(DBAuth, googleAuthProvider);
    } catch (error) {
      console.log('error in signInWithGoogle', error);
    }
  };

  const signupWithEmailAndPasssword = async (
    email: string,
    password: string,
  ) => {
    return await createUserWithEmailAndPassword(DBAuth, email, password);
  };

  const loginWithEmailAndPasssword = async (
    email: string,
    password: string,
  ) => {
    const signinCreds = await signInWithEmailAndPassword(
      DBAuth,
      email,
      password,
    );
    updateLoginStatus(signinCreds.user);
    return signinCreds;
  };

  const sendResetPasswordEmail = async (email: string) => {
    return await sendPasswordResetEmail(DBAuth, email);
  };

  const verifyResetPasswordCode = async (code: string) => {
    return await verifyPasswordResetCode(DBAuth, code);
  };

  const resetPassword = async (code: string, password: string) => {
    return await confirmPasswordReset(DBAuth, code, password);
  };

  const getUserRole = async () => {
    if (userDetails?.role) {
      switch (userDetails.role) {
        case DBUserRoles.superAdmin:
          setSuperAdminStatus(true);
          setAdminStatus(false);
          break;
        case DBUserRoles.admin:
          setSuperAdminStatus(false);
          setAdminStatus(true);
          break;
        case DBUserRoles.leagueMember:
          setSuperAdminStatus(false);
          setAdminStatus(false);
          break;
        default:
          setSuperAdminStatus(false);
          setAdminStatus(false);
      }
    }
  };

  const deleteUserAccount = async (password: string) => {
    if (DBAuth.currentUser?.email) {
      try {
        const credential = await EmailAuthProvider.credential(
          DBAuth.currentUser.email,
          password,
        );

        const result = await reauthenticateWithCredential(
          DBAuth.currentUser,
          credential,
        );

        // Pass result.user here
        await deleteUser(result.user);

        return true;
      } catch (error) {
        console.log('Error deleting user:', error);
        return false;
      }
    }

    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        DBFirebaseApp,
        DBFirebaseDB,
        DBAuth,
        isLoggedIn,
        logOut,
        currentUser,
        isUserProfileComplete,
        getUserLoginMethods,
        signInWithGoogle,
        signupWithEmailAndPasssword,
        loginWithEmailAndPasssword,
        sendResetPasswordEmail,
        verifyResetPasswordCode,
        resetPassword,
        updateLoginStatus,
        deleteUserAccount,
        userDetails,
        setUserDetails,
        getUserRole,
        superAdminStatus,
        adminStatus,
        authHasChecked,
        setDiscordAuthToken,
        discordAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
