import Table from '../../../components/ui/table/table';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { iLeague } from '@shared/shared-utils/models';

type OLeagueTableRow = {
  leagues: iLeague[];
  rowClick: (league?: iLeague) => void;
  getOwner: (league: iLeague) => void;
  globalFilter: string;
  updateGlobalFilter: any;
  columnFilters: ColumnFiltersState;
  updateColFilter: any;
  getLeagueRowCount: (numRows: number) => void;
};

const OLeaguesTable = (props: OLeagueTableRow) => {
  const {
    leagues,
    rowClick,
    getOwner,
    globalFilter,
    updateGlobalFilter,
    columnFilters,
    updateColFilter,
    getLeagueRowCount,
  } = props;
  const columnHelper = createColumnHelper<iLeague>();

  const headerStyling = (props?: string) => {
    return <div className="text-center">{props}</div>;
  };

  const defaultColumns = [
    columnHelper.accessor('title', {
      id: 'leagueName',
      header: () => headerStyling('League Name'),
    }),
    columnHelper.accessor('id', {
      id: 'leagueID',
      header: () => headerStyling('League ID'),
    }),
    columnHelper.accessor('season', {
      id: 'leagueSeason',
      header: () => headerStyling('Season'),
    }),
    columnHelper.accessor((row) => getOwner(row), {
      id: 'leagueCreator',
      header: () => headerStyling('Owner'),
    }),
    columnHelper.accessor((row) => `${row.memberIds?.length}`, {
      id: 'memberCount',
      header: () => headerStyling('Member Count'),
    }),
    columnHelper.accessor((row) => `${row.invites?.length}`, {
      id: 'inviteCount',
      header: () => headerStyling('Invite Count'),
    }),
    columnHelper.accessor('draftStatus', {
      id: 'draftStatus',
      header: () => headerStyling('Draft Status'),
    }),
    columnHelper.accessor((row) => row.isActive?.toString(), {
      id: 'activeStatus',
      header: () => headerStyling('Active?'),
    }),
    columnHelper.accessor(
      (row) => {
        if (row.createdAt) {
          return row.createdAt.toLocaleDateString();
        } else {
          return '';
        }
      },
      {
        id: 'createdAt',
        header: () => headerStyling('Create Date'),
      },
    ),
    columnHelper.accessor(
      (row) => (row.internalLeague ? 'Internal' : 'External'),
      {
        id: 'internalLeague',
        header: () => headerStyling('Internal League?'),
      },
    ),
  ];

  return (
    <Table
      data={leagues}
      columns={defaultColumns}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      rowSelection={''}
      updateGlobalFilter={updateGlobalFilter}
      updateColFilter={updateColFilter}
      canSelect={1}
      rowClick={rowClick}
      selectedRow={''}
      getRowCount={getLeagueRowCount}
    />
  );
};

export default OLeaguesTable;
