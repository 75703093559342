import React, { ReactNode, useContext, useState } from 'react';
import { OperationsService } from './operations-service';
import {
  iLeague,
  iLeagueMemberSquadDb,
  iUser,
  iTeam,
  iSmallGame,
  iAppConfig,
  iLeagueDb,
  iLeagueInvite,
  iTradeRequest,
  iTransferPortalRequest,
  iChatMessage,
  iUserTrophiesDB,
} from '@shared/shared-utils/models';
import { LeagueContext } from '../../providers/league-provider';
import { LeagueService } from '../../services/league-service';

type OperationsProviderProps = {
  children: ReactNode;
};

type OperationsContextProps = {
  initOperationsData: (dataType: 'all' | 'leagues' | 'users' | 'teams') => void;
  allLeagues?: iLeague[];
  allUsers?: iUser[];
  allTeams?: iTeam[];
  convertIdToEmail: (id: string) => string | undefined;
  getMemberSquad: (
    leagueId: string,
    memberId: string,
  ) => Promise<iLeagueMemberSquadDb | undefined>;
  reDraftLeague: (leagueId: string) => Promise<boolean>;
  deleteLeague: (leagueId: string) => Promise<boolean>;
  setTeamAsByeWeek: (team: iTeam) => Promise<iTeam>;
  setTeamAsNonByeWeek: (
    team: iTeam,
    season: number,
    week: number,
  ) => Promise<iTeam>;
  toggleTeamLockedStatus: (
    newLockedStatus: boolean,
    team: iTeam,
  ) => Promise<iTeam>;
  updateAppConfig: (
    season: number,
    week: number,
    appConfig: iAppConfig,
  ) => Promise<iAppConfig>;
  updateLeagueSeason: (newSeason: number, league: iLeague) => Promise<iLeague>;
  getUsersLeagues: (user: iUser, season?: number) => Promise<iLeague[]>;
  getAllUserTransfers: (
    userId: string,
  ) => Promise<iTransferPortalRequest[] | null>;
  getAllUserTrades: (userId: string) => Promise<iTradeRequest[] | null>;
  getAllUsersChats: (userId: string) => Promise<iChatMessage[] | null>;
  getAllChatsForUser: (userId: string) => Promise<iChatMessage[] | null>;
  updateUserTrophies: (
    user: iUser,
    newTrophies: iUserTrophiesDB,
  ) => Promise<iUser>;
};

export const OperationsContext = React.createContext(
  {} as OperationsContextProps,
);

const OperationsProvider = ({ children }: OperationsProviderProps) => {
  const _operationsService = new OperationsService();
  const _leagueService = new LeagueService();
  const { setAppConfig } = useContext(LeagueContext);
  const [allLeagues, setAllLeagues] = useState<iLeague[]>();
  const [allUsers, setAllUsers] = useState<iUser[]>();
  const [allTeams, setAllTeams] = useState<iTeam[]>();
  const [allInvites, setAllInvites] = useState<iLeagueInvite[]>();
  const [allSeasonRequests, setAllSeasonRequests] = useState<{
    trades: iTradeRequest[];
    transfers: iTransferPortalRequest[];
  }>();
  const [allSeasonTransfers, setAllSeasonTransfers] =
    useState<iTransferPortalRequest[]>();
  const [allSeasonTrades, setAllSeasonTrades] = useState<iTradeRequest[]>();
  const [allSeasonChats, setAllSeasonChats] = useState<iChatMessage[]>();

  const initOperationsData = async (
    dataType: 'all' | 'leagues' | 'users' | 'teams',
  ) => {
    switch (dataType) {
      case 'all':
        await getAllLeaguesWithInvites();
        setAllUsers(await _operationsService.getAllUsers());
        setAllTeams(await _operationsService.getAllTeams());
        break;
      case 'leagues':
        await getAllLeaguesWithInvites();
        break;
      case 'users':
        setAllUsers(await _operationsService.getAllUsers());
        break;
      case 'teams':
        setAllTeams(await _operationsService.getAllTeams());
        break;
      default:
        break;
    }
  };

  const getAllLeaguesWithInvites = async () => {
    const leagues = await _operationsService.getAllLeagues(2024);
    // const invites = await _operationsService.getAllInvites();
    // setAllInvites(invites);
    // const leaguesToPopulate: Promise<iLeague>[] = [];
    // leagues.forEach((league) => {
    //   leaguesToPopulate.push(_leagueService.populateLeagueDetails(league));
    // });

    // const populatedLeagues = await Promise.all(leaguesToPopulate);

    // setAllLeagues(populatedLeagues);
    // return populatedLeagues;
    setAllLeagues(leagues as any);
    return leagues as any;
  };

  const convertIdToEmail = (id: string) => {
    const userIDs = allUsers?.map((user) => user.id);
    const userIdIndex = userIDs?.indexOf(id);
    if (userIDs && userIdIndex && allUsers) {
      const userEmail = allUsers[userIdIndex].email;
      return userEmail;
    }
    return;
  };

  const getMemberSquad = async (leagueId: string, memberId: string) => {
    const memberSquad = await _operationsService.getLeagueMemberSquad(
      leagueId,
      memberId,
    );
    return memberSquad;
  };

  const reDraftLeague = async (leagueId: string) => {
    try {
      await _operationsService.reDraftLeague(leagueId);

      console.log('Draft reset');
      return true;
    } catch (error) {
      console.log('Error redrafting league', error);
      return false;
    }
  };
  const deleteLeague = async (leagueId: string) => {
    try {
      await _operationsService.deleteLeague(leagueId);
      console.log('League Deleted', leagueId);
      return true;
    } catch (error) {
      console.log('Error deleting league', error);
      return false;
    }
  };

  const setTeamAsByeWeek = async (team: iTeam) => {
    const teamWithByeWeek: iTeam = { ...team };
    delete teamWithByeWeek.nextGame;
    await _operationsService.overwriteTeamData(teamWithByeWeek);
    return teamWithByeWeek;
  };

  const setTeamAsNonByeWeek = async (
    team: iTeam,
    season: number,
    week: number,
  ) => {
    const nextGameData: iSmallGame = {
      id: 99999,
      season: season,
      week: week,
      start_date: '',
      start_time_tbd: '',
      home_id: team.id,
      home_team: team.school,
      home_points: 100,
      away_id: 9999,
      away_team: 'Test School',
      away_points: 100,
    };
    const teamWithNonByeWeek: iTeam = { ...team, nextGame: nextGameData };
    await _operationsService.overwriteTeamData(teamWithNonByeWeek);
    return teamWithNonByeWeek;
  };

  // This really only works for last season. If the DB is set to a week in a season > 1 year ago,
  // this will not work as expected.
  const toggleTeamLockedStatus = async (
    newLockedStatus: boolean,
    team: iTeam,
  ) => {
    const nextGame = team.nextGame;

    if (nextGame) {
      const newTeamData: iTeam = {
        ...team,
        teamLocked: newLockedStatus,
        nextGame: {
          ...nextGame,
          start_date: newLockedStatus
            ? new Date(Date.now() - 1000 * 60 * 60 * 24 * 7 * 52).toISOString() // 1 year in the past
            : new Date(Date.now() + 1000 * 60 * 60 * 24 * 7).toISOString(), // 1 week in the future
        },
      };

      await _operationsService.overwriteTeamData(newTeamData);
      return newTeamData;
    }

    return team;
  };

  const updateAppConfig = async (
    season: number,
    week: number,
    appConfig: iAppConfig,
  ) => {
    const newAppConfig: iAppConfig = {
      ...appConfig,
      currentSeason: season,
      currentWeek: week,
    };
    await _operationsService.updateAppConfig(newAppConfig);
    setAppConfig(newAppConfig);
    return newAppConfig;
  };

  const updateLeagueSeason = async (newSeason: number, league: iLeague) => {
    const newLeagueData: iLeagueDb = {
      id: league.id,
      title: league.title,
      isOpen: league.isOpen,
      creator: league.creator?.toString(),
      memberIds: league.memberIds || [''],
      season: newSeason,
    };
    const newLeague: iLeague = { ...league, season: newSeason };
    await _operationsService.overwriteLeagueData(newLeagueData);
    return newLeague;
  };

  const getUsersLeagues = async (user: iUser, season?: number) => {
    const userLeagues: iLeague[] =
      (await _leagueService.getLeagues(user.id)) || [];

    return userLeagues;
  };

  const getAllSeasonRequests = async () => {
    if (!allSeasonRequests) {
      const seasonRequests = await _operationsService.getAllSeasonRequests();
      if (seasonRequests) {
        setAllSeasonRequests(seasonRequests);
        return seasonRequests;
      } else {
        return null;
      }
    } else {
      return allSeasonRequests;
    }
  };

  const getAllSeasonTransfers = async () => {
    if (!allSeasonTransfers) {
      const seasonRequests = await getAllSeasonRequests();
      if (seasonRequests) {
        setAllSeasonTransfers(seasonRequests.transfers);
        return seasonRequests?.transfers;
      } else {
        return null;
      }
    } else {
      return allSeasonTransfers;
    }
  };

  const getAllUserTransfers = async (userId: string) => {
    const allTransfers = await getAllSeasonTransfers();
    if (allTransfers) {
      return allTransfers.filter(
        (transfer) => transfer.requesterDetails.id === userId,
      );
    } else {
      return null;
    }
  };

  const getAllSeasonTrades = async () => {
    if (!allSeasonTrades) {
      const seasonRequests = await getAllSeasonRequests();
      if (seasonRequests) {
        setAllSeasonTrades(seasonRequests.trades);
        return seasonRequests.trades;
      } else {
        return null;
      }
    } else {
      return allSeasonTrades;
    }
  };

  const getAllUserTrades = async (userId: string) => {
    const allTrades = await getAllSeasonTrades();
    if (allTrades) {
      return allTrades.filter((trade) => trade.requesterDetails.id === userId);
    } else {
      return null;
    }
  };

  const getAllSeasonChats = async () => {
    if (!allSeasonChats) {
      const seasonChats = await _operationsService.getAllSeasonChats();
      if (seasonChats) {
        setAllSeasonChats(seasonChats);
        return seasonChats;
      } else {
        return null;
      }
    } else {
      return allSeasonChats;
    }
  };

  const getAllUsersChats = async (userId: string) => {
    const allChats = await getAllSeasonChats();
    if (allChats) {
      const filtered = allChats.filter((chat) => chat.userId === userId);
      return filtered;
    } else {
      return null;
    }
  };

  const getAllChatsForUser = async (userId: string) => {
    // Checks if we have all chat data already pulled. If so, look there first
    let allChats = allSeasonChats;
    if (allChats && allChats.length > 0) {
      // Checks if the user chat data is in the allChats data
      const filtered = allChats.filter((chat) => chat.userId === userId);
      if (filtered.length > 0) {
        return filtered;
      } // If user's chat data is not in all chat data, pull it specifically
      else {
        const userChats = await _operationsService.getAllChatsForUser(userId);
        return userChats;
      }
      // If allSeasonChats is undefined, pull just this user's data
    } else {
      const userChats = await _operationsService.getAllChatsForUser(userId);
      return userChats;
    }
  };

  // TODO: need to be able to handle progress changes
  // TODO: need to be able to handle deleting a trophy for just one type or season
  const updateUserTrophies = async (
    user: iUser,
    newTrophies: iUserTrophiesDB,
  ) => {
    let newUserData: iUser;

    newUserData = {
      ...user,
      userTrophies: {
        ...newTrophies,
      },
    };

    await _operationsService.overwriteUserData(newUserData, user.id);
    return newUserData;
  };

  return (
    <OperationsContext.Provider
      value={{
        initOperationsData,
        allLeagues,
        allUsers,
        allTeams,
        convertIdToEmail,
        getMemberSquad,
        reDraftLeague,
        deleteLeague,
        setTeamAsByeWeek,
        setTeamAsNonByeWeek,
        toggleTeamLockedStatus,
        updateAppConfig,
        updateLeagueSeason,
        getUsersLeagues,
        getAllUserTransfers,
        getAllUserTrades,
        getAllUsersChats,
        getAllChatsForUser,
        updateUserTrophies,
      }}
    >
      {children}
    </OperationsContext.Provider>
  );
};

export default OperationsProvider;
