import { useEffect, useState } from 'react';
import '../input.scss';
import { TextField } from '@mui/material';

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  inputStyle,
  placeholder,
  overrideStyle,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  placeholder?: string;
  inputStyle?: string;
  overrideStyle?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className={overrideStyle ? inputStyle : `input-holder ${inputStyle}`}>
      <input
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default DebouncedInput;
