import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../components/ui/button/button';
import { UpcomingGame } from '../../../../components/ui/UpcomingGame/upcoming-game';
import {
  iLeague,
  iLeagueMemberSquadDb,
  iTeam,
  iTeamOwnerMap,
  iUser,
} from '@shared/shared-utils/models';
import { useContext, useState } from 'react';
import SelectTeamModal from '../../../../components/modals/select-team/select-team-modal';
import Loading from '../../../../components/ui/loading-animation';
import { LeagueContext } from '../../../../providers/league-provider';
import { TradingContext } from '../../../../providers/trading-provider';
import { TeamsContext } from '../../../../providers/teams-provider';

type TradeTeamPropType = {
  myTeams: iTeam[];
  usersLeague: iLeague;
  userDetails: iUser;
  onTradeRequestClick: () => void;
};

export const TradeTeam = ({
  myTeams,
  usersLeague,
  userDetails,
  onTradeRequestClick,
}: TradeTeamPropType) => {
  const { getMemberSquad, appConfig } = useContext(LeagueContext);
  const { sendTradeRequest } = useContext(TradingContext);
  const {} = useContext(TeamsContext);

  const [showSelectTeamModal, setShowSelectTeamModal] =
    useState<boolean>(false);
  const [slotType, setSlotType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectableTeamIds, setSelectableTeamIds] = useState<string[]>([]);
  const [squadOwnerList, setSquadOwnerList] = useState<iTeamOwnerMap[]>([]);
  const [otherLeagueMemberDetails, setOtherLeagueMemberDetails] = useState<
    iLeagueMemberSquadDb[]
  >([]);
  const [tradeFromTeam, setTradeFromTeam] = useState<iTeam>();
  const [tradeToTeam, setTradeToTeam] = useState<iTeam>();

  const handleSendTradeRequest = async () => {
    if (
      usersLeague?.id &&
      usersLeague?.members &&
      userDetails?.id &&
      tradeFromTeam &&
      tradeToTeam
    ) {
      try {
        sendTradeRequest(
          {
            otherLeagueMemberDetails: otherLeagueMemberDetails,
            league: usersLeague,
            tradeToTeam: tradeToTeam,
            tradeFromTeam: tradeFromTeam,
            userDetails: userDetails,
            season: usersLeague.season,
            currentWeek: appConfig.currentWeek,
          },
          () => {
            // show success
            setShowSelectTeamModal(false);
            onTradeRequestClick();
          },
        );
      } catch (error) {
        // show error
      }
    } else {
      // show generic error
    }
  };

  const updateSelectableTeams = async (teamFilter: string) => {
    switch (teamFilter) {
      case 'tradeFromTeam':
        setSelectableTeamIds(myTeams.map((team) => 'team-' + team.id));
        break;
      case 'tradeToTeam':
        setLoading(true);
        if (usersLeague?.memberIds && usersLeague?.id) {
          const squadOwnerMap: iTeamOwnerMap[] = [];
          const leagueMemberTeams: any[] = [];
          const membersIdsOtherThanMe = usersLeague.memberIds.filter(
            (memberId) => memberId !== userDetails?.id,
          );
          const otherMemberDetails: iLeagueMemberSquadDb[] = [];
          for (let i = 0; i < membersIdsOtherThanMe.length; i++) {
            const memberDetails = await getMemberSquad(
              usersLeague.id,
              membersIdsOtherThanMe[i],
            );
            if (memberDetails) {
              otherMemberDetails.push(memberDetails);
            }
            if (memberDetails && memberDetails.teams) {
              memberDetails.teams.forEach((teamId) => {
                const teamOwnerDetails = usersLeague.members?.find(
                  (member) => member.id === membersIdsOtherThanMe[i],
                );
                if (teamOwnerDetails) {
                  console.log('teamOwnerDetails', teamOwnerDetails);
                  squadOwnerMap.push({
                    teamId: Number(teamId.split('-')[1]),
                    ownerDetails: teamOwnerDetails,
                  });
                }
                leagueMemberTeams.push({
                  id: teamId.split('-')[1],
                });
              });
            }
          }
          setSquadOwnerList(squadOwnerMap);
          if (otherMemberDetails) {
            setOtherLeagueMemberDetails(otherMemberDetails);
          }
          setSelectableTeamIds(
            leagueMemberTeams.map((team) => 'team-' + team.id),
          );
        }
        setLoading(false);
        break;
      default:
        break;
    }
  };

  return loading ? (
    <Loading></Loading>
  ) : (
    <div className="flex flex-col justify-between flex-1 mt-4">
      <div>
        <div className="my-2 text-sm">
          Trade teams with the other members of your League!
        </div>
        <div
          className="flex justify-between border-b-[1px] border-b-gray p-4 cursor-pointer"
          onClick={() => {
            setSlotType('tradeFromTeam');
            updateSelectableTeams('tradeFromTeam');
            setShowSelectTeamModal(true);
          }}
          data-e2e="tradeFromTeam"
        >
          {tradeFromTeam && (
            <div className="flex items-center justify-between">
              <div
                style={{
                  backgroundImage: `url(${tradeFromTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={tradeFromTeam.school}
              />
              <div className="flex flex-col">
                <p className="text-base">{tradeFromTeam.school}</p>
                <div>
                  <UpcomingGame
                    teamID={tradeFromTeam.id}
                    game={tradeFromTeam.nextGame}
                  />
                </div>
              </div>
            </div>
          )}
          {!tradeFromTeam && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-base">Team you want to trade</p>
              </div>
            </div>
          )}
          <FontAwesomeIcon icon={faChevronDown} className="text-white fa-lg" />
        </div>
        <div
          className="flex justify-between border-b-[1px] border-b-gray p-4 cursor-pointer mt-4"
          onClick={() => {
            setSlotType('tradeToTeam');
            updateSelectableTeams('tradeToTeam');
            setShowSelectTeamModal(true);
          }}
          data-e2e="tradeToTeam"
        >
          {tradeToTeam && (
            <div className="flex items-center justify-between">
              <div
                style={{
                  backgroundImage: `url(${tradeToTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={tradeToTeam.school}
              />
              <div className="flex flex-col">
                <p className="text-base">{tradeToTeam.school}</p>
                <div>
                  <UpcomingGame
                    teamID={tradeToTeam.id}
                    game={tradeToTeam.nextGame}
                  />
                </div>
              </div>
            </div>
          )}
          {!tradeToTeam && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-base">Team you want to pick</p>
              </div>
            </div>
          )}
          <FontAwesomeIcon icon={faChevronDown} className="text-white fa-lg" />
        </div>
      </div>
      <div className="flex justify-end mt-12">
        <Button
          disabled={!tradeFromTeam || !tradeToTeam}
          label={'Request'}
          onClick={() => {
            handleSendTradeRequest();
          }}
          data-e2e="request_trade_btn"
        />
      </div>
      {showSelectTeamModal && (
        <SelectTeamModal
          title="Select Team"
          league={usersLeague}
          includedTeams={selectableTeamIds}
          onTeamSelect={(team) => {
            if (slotType === 'tradeFromTeam') {
              setTradeFromTeam(team);
            } else {
              setTradeToTeam(team);
            }
          }}
          teamOwnerMap={squadOwnerList}
          handleClose={() => setShowSelectTeamModal(false)}
          defaultSeason={appConfig.currentSeason}
          slotType={slotType}
          isLockedTeamSelectable={true}
        />
      )}
    </div>
  );
};
