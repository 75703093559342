import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Header from '../../../components/widgets/header/header';
import { LeagueContext } from '../../../providers/league-provider';
import Moment from 'react-moment';
import './league.scss';
import Button from '@mui/material/Button';
import Image from '../../../components/widgets/image';
import { iLeague, leagueDraftStatus } from '@shared/shared-utils/models';
import Loading from '../../../components/ui/loading-animation';
import { ToastContext } from '../../../providers/toast-provider';
import { TrophyContext } from '../../../providers/trophy-provider';
import { AuthContext } from '../../../providers/auth-provider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function JoinLeague() {
  const params: any = useParams();
  const navigate = useNavigate();
  const {
    getLeagueDetails,
    amILeagueMember,
    amIInvitedToLeague,
    joinLeague,
    appConfig,
  } = useContext(LeagueContext);
  const { showToast } = useContext(ToastContext);
  const { assignTrophy } = useContext(TrophyContext);
  const { userDetails } = useContext(AuthContext);
  const [currentLeague, setCurrentLeague] = useState<iLeague>();
  const [showJoin, setShowJoin] = useState<boolean>(false);
  const [justJoinedLeague, setJustJoinedLeague] = useState<boolean>(false);
  const [leagueInviteChecked, setLeagueInviteChecked] =
    useState<boolean>(false);
  const [openJoinDialog, setOpenJoinDialog] = useState(false);

  const handleClickOpenJoinDialog = () => {
    setOpenJoinDialog(true);
  };

  const handleCloseJoinDialog = () => {
    setOpenJoinDialog(false);
  };

  const initLeagueDetails = async () => {
    if (params) {
      try {
        const leagueDetails = await getLeagueDetails(params['leagueId'], true);
        setCurrentLeague(leagueDetails);
      } catch (error) {
        console.log('Error getting league', error);
        showToast({
          messageType: 'warning',
          message: 'League no longer exists.',
        });
        navigate('/');
      }
    }
  };

  useEffect(() => {
    initLeagueDetails();
  }, []);

  useEffect(() => {
    if (currentLeague?.id) {
      const amIMember = amILeagueMember();
      if (amIMember) {
        if (!justJoinedLeague) {
          showToast({
            messageType: 'success',
            message: 'Navigating to league.',
          });
        }

        if (currentLeague?.draftStatus === leagueDraftStatus.done) {
          navigate('/league/' + params['leagueId']);
        } else {
          navigate('/draft/' + params['leagueId']);
        }
      }

      // If the league is full, show message to contact league owner
      if (
        currentLeague?.settings.numOfMembers ===
          currentLeague?.memberIds?.length &&
        !justJoinedLeague
      ) {
        showToast({
          messageType: 'warning',
          message:
            'League is full. Contact support if you think this is wrong.',
        });
      } else {
        // If the league is open, show join button
        if (currentLeague?.isOpen) {
          setShowJoin(true);
        } else {
          if (amIInvitedToLeague() && !leagueInviteChecked) {
            setLeagueInviteChecked(true);
            setShowJoin(true);
          }
        }
      }
    }
  }, [currentLeague]);

  const handleJoinLeagueClick = async () => {
    await joinLeague();
    userDetails &&
      (await assignTrophy(
        userDetails,
        appConfig.currentSeason,
        'joinedRandomLeague',
        "'No friends of my own' trophy awarded!",
      ));
    setJustJoinedLeague(true);
  };

  return !currentLeague ? (
    <Loading></Loading>
  ) : (
    <div id="db-league-details" className="relative flex flex-col football-bg">
      <Header
        title={'LEAGUE: ' + currentLeague.title}
        isHome={false}
        cancelLink="/home"
        subContent={
          <div className="relative mt-2">
            {currentLeague?.settings ? (
              <>
                <div className="text-xs">Draft date</div>
                <div className="mb-2">
                  <Moment format="MMMM DD, YYYY h:mm A">
                    {currentLeague?.settings?.draftDate?.toDate()}
                  </Moment>
                </div>
              </>
            ) : (
              <div className="py-6"></div>
            )}
            {showJoin && (
              <div>
                <div className="float-right">
                  <Button
                    className="floating-join-btn"
                    variant="contained"
                    onClick={handleClickOpenJoinDialog}
                  >
                    Join
                  </Button>
                </div>
                <Dialog open={openJoinDialog} onClose={handleCloseJoinDialog}>
                  <DialogTitle>{'Join league?'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Click "Join" to become a member of{' '}
                      <strong>{currentLeague.title}</strong>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleCloseJoinDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleJoinLeagueClick}
                      autoFocus
                    >
                      Join
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </div>
        }
      />
      <div className="flex flex-1 pt-[6.3rem] flex-col pb-12">
        <div className="w-full max-h-[560px] overflow-hidden image relative">
          <img
            src={currentLeague.bannerImage}
            className="w-full h-auto"
            alt={currentLeague.title}
          />
          <div className="absolute z-10 flex bottom-4 left-4">
            <Image
              imageUrl={currentLeague.creator?.profileImage}
              title={currentLeague.creator?.fullName}
              size={14}
            />
            <div className="pt-2 pl-4">
              <span className="text-xs leading-3">Creator</span>
              <div className="text-lg leading-3">
                {currentLeague.creator?.fullName}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="mb-4">{currentLeague.description}</div>
          <div className="mt-4">
            <div className="mb-2 font-bold">Members</div>
            <div
              className="flex mt-2 overflow-x-auto whitespace-nowrap"
              style={{
                msOverflowStyle: 'none' /* for Internet Explorer, Edge */,
                scrollbarWidth: 'none' /* for Firefox */,
              }}
            >
              {currentLeague?.members?.length &&
                currentLeague?.members?.map((member) => (
                  <Image
                    key={member.id}
                    imageUrl={member?.profileImage}
                    title={member?.fullName}
                    size={12}
                  />
                ))}
            </div>
          </div>
          {currentLeague?.settings && (
            <div className="mt-4">
              <div className="mb-2 font-bold">League Details</div>
              <div className="mt-2">
                <p>
                  {currentLeague?.settings?.numOfTeamsPerMember} teams per
                  member.
                </p>
                <p>{currentLeague?.settings?.numOfMembers} league members.</p>
                <p>
                  Trading{' '}
                  {currentLeague?.settings?.tradeEnabled
                    ? 'enabled.'
                    : 'disabled.'}
                </p>
                <p>
                  Free agent{' '}
                  {currentLeague?.settings?.freeAgentEnabled
                    ? 'enabled.'
                    : 'disabled.'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JoinLeague;
