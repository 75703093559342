import { ReactNode, useEffect, useState } from 'react';
import { getTrophyById } from '../../../assets/trophies/trophy-icons';
import ListItem from '../../../components/widgets/list-widget/list-item';
import {
  iUserTrophy,
  allTrophies,
  iUserTrophiesUI,
} from '@shared/shared-utils/models';
import ListWidget from '../../../components/widgets/list-widget/list-widget';
import TrophyZoom from '../../../components/widgets/trophy-zoom';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, List, Badge } from '@mui/material';

type TrophyCaseProps = {
  userTrophies?: iUserTrophiesUI;
};

const TrophyCase = (props: TrophyCaseProps) => {
  const [userTrophyItems, setUserTrophyItems] = useState<ReactNode[]>();
  const [showTrophyZoom, setShowTrophyZoom] = useState(false);
  const [selectedTrophy, setSelectedTrophy] = useState<iUserTrophy>();
  const [showTrophyHelpDialog, setShowTrophyHelpDialog] = useState(false);

  // List of all trophies that a user can earn
  const trophyList = Object.values(allTrophies).filter(
    (trophy) => trophy.trophyType !== 'adminSB' && trophy.archived !== true,
  );

  // Sort trophy display by title
  trophyList.sort((a, b) => a.title.localeCompare(b.title));

  const buildUserTrophyCase = () => {
    // If the user has trophies,
    const userTrophyTypes = props.userTrophies
      ? Object.keys(props.userTrophies)
      : [];

    const trophyItems =
      userTrophyTypes.length > 0
        ? userTrophyTypes.map((trophyType) => {
            const userTrophy = props.userTrophies?.[trophyType];
            const trophyData = getTrophyById(trophyType, userTrophy?.progress);
            return (
              <ListItem
                itemClick={() => handleTrophyClick(userTrophy)}
                key={trophyType}
              >
                <div className="flex flex-row justify-between items-center my-2 ml-2">
                  <div className="flex flex-row items-center">
                    <div className="bg-white rounded-full w-8 h-8 mr-4">
                      <Badge
                        badgeContent={
                          userTrophy?.progress ? userTrophy?.progress : null
                        }
                        invisible={!userTrophy?.progress}
                        color="primary"
                      >
                        <img
                          src={trophyData.path}
                          alt="trophy icon"
                          className="w-8 h-8 mr-2"
                        />
                      </Badge>
                    </div>
                    <span className="text-white">{trophyData?.title}</span>
                  </div>
                </div>
              </ListItem>
            );
          })
        : [<div className="ml-4 mt-4">No trophies yet!</div>];
    setUserTrophyItems(trophyItems);
  };

  const handleTrophyClick = (trophy?: iUserTrophy) => {
    if (trophy) {
      setShowTrophyZoom(true);
      setSelectedTrophy(trophy);
    }
  };

  useEffect(() => {
    buildUserTrophyCase();
  }, []);

  const showAllImages = (
    trophyType: string,
    progress?: {
      [key: number]: {
        path: string;
      };
    },
  ) => {
    if (progress) {
      const allProgressKeys = Object.keys(progress);
      const sortedKeys = allProgressKeys.sort((a, b) => +a - +b);
      const allPaths = sortedKeys.map((key) => {
        return allTrophies[trophyType].progress?.[+key].path;
      });

      return (
        <div className="flex flex-row">
          {allPaths.map((path, index) => {
            return (
              <img
                src={path}
                className={`w-20 mr-4 ${index > 0 ? `ml-[-4rem]` : ''}`}
                style={{
                  zIndex: 99 - index,
                }}
                key={path}
              />
            );
          })}
        </div>
      );
    } else {
      return '';
    }
  };

  return (
    <>
      {userTrophyItems && (
        <div className="my-4">
          <ListWidget
            label="Trophy Case"
            loading={!userTrophyItems}
            items={userTrophyItems}
            infoText={
              <div>
                These trophies represent the achievements you've earned! To see
                all the trophy options and how you can achieve them, click{' '}
                {
                  <Link to="" onClick={() => setShowTrophyHelpDialog(true)}>
                    here
                  </Link>
                }
                !
              </div>
            }
          />
        </div>
      )}
      {selectedTrophy && (
        <TrophyZoom
          trophy={getTrophyById(
            selectedTrophy.trophyType,
            selectedTrophy.progress,
          )}
          onCloseClicked={() => setShowTrophyZoom(false)}
          trophyData={selectedTrophy}
          open={showTrophyZoom}
        />
      )}
      {/* When user clicks info icon on trophy case header */}
      {showTrophyHelpDialog && (
        <Dialog
          open={showTrophyHelpDialog}
          onClose={() => setShowTrophyHelpDialog(false)}
        >
          <DialogTitle>User Trophies</DialogTitle>
          <img
            src="/assets/images/cancel.png"
            className="w-4 h-4 cursor-pointer ml-auto absolute top-6 right-8"
            onClick={() => setShowTrophyHelpDialog(false)}
            alt="cancel"
            data-e2e="trophy_zoom_close_button"
          />
          <div className="flex flex-col mx-4 overflow-scroll max-h-96">
            <span>
              User trophies are special achievements that you can earn by
              playing Squad Blitz. They are displayed in your trophy case for
              everyone to see!
            </span>
            <List>
              {trophyList.map((trophy) => {
                const trophyIcon = getTrophyById(trophy.trophyType);
                return (
                  <div key={trophyIcon.trophyType}>
                    <hr className="my-2" />
                    <div className="relative flex flex-col mx-2">
                      <div
                        className={`flex flex-row items-center ${
                          trophy.pending && 'opacity-25'
                        }`}
                      >
                        {allTrophies[trophyIcon.trophyType]?.progress ? (
                          <div>
                            {showAllImages(
                              trophyIcon.trophyType,
                              allTrophies[trophyIcon.trophyType].progress,
                            )}
                          </div>
                        ) : (
                          <img src={trophyIcon.path} className="w-20 mr-4" />
                        )}
                        <span>{trophyIcon.title}</span>
                      </div>
                      <span
                        className={`text-sm ${trophy.pending && 'opacity-25'}`}
                      >
                        {trophyIcon.description}
                      </span>
                      {trophy.pending && (
                        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                          <div className="text-white p-4 text-3xl font-medium">
                            Coming Soon in 2024!
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </List>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default TrophyCase;
