import axios from 'axios';
import { currentDBProject } from './firebase-core';
import { iDiscordAuthTokens, iUser } from '@shared/shared-utils/models';
import { UserService } from './user-service';

export class DiscordService {
  private userService = new UserService();

  public async postDiscordMessage(message: string, webhookUrl: string) {
    try {
      //Comment this out to test
      if (currentDBProject !== 'squadblitz-dev') {
        const response = await axios.post(
          webhookUrl,
          {
            content: message,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Discord webhook response:', response.data);
      }
    } catch (error) {
      console.error('Error posting to Discord webhook:', error);
    }
  }

  //Saves the Discord auth tokens on the db.
  public async saveDiscordAuthToUser(
    currentUserDetails: iUser,
    discordAuthTokens: iDiscordAuthTokens
  ) {
    if (currentUserDetails.id) {
      const updatedUserDetails = {
        ...currentUserDetails,
        discordAuthTokens: discordAuthTokens,
      };

      await this.userService.updateUserProfile(
        updatedUserDetails,
        currentUserDetails?.id
      );
    }
  }

  public async getUserIdFromDiscord(accessToken: string) {
    try {
      const response = await fetch('https://discord.com/api/v10/users/@me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        const userId = userData.id;

        return userId;
      } else {
        console.error(
          'Error retrieving user data. Bad response:',
          response.status
        );
        console.log(response);
      }
    } catch (error) {
      console.error('Error accessing the Discord API:', error);
    }
  }
}
