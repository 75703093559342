import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { environment } from '../../environments/environment.local.dev';
import { AuthContext } from '../../providers/auth-provider';
import { getAnalytics, setUserId } from 'firebase/analytics';

function MobileAppLayout() {
  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    // Make sure we are on production
    if (environment.addAnalytics) {
      if (userDetails) {
        const analytics = getAnalytics();
        setUserId(analytics, userDetails.id);
      }
    }
  }, [userDetails]);

  return (
    <div className="relative">
      <div id="db-app">
        <Outlet />
        <span className="flex flex-col text-center text-xs my-2">
          <span>
            Need help? Text us at <a href="tel:850-816-0398">(850) 816-0398</a>{' '}
          </span>
          <span>(This number only accepts texts)</span>
        </span>
      </div>
    </div>
  );
}

export default MobileAppLayout;
