import { ChangeEvent, useContext, useEffect, useState } from 'react';
import Header from '../../components/widgets/header/header';
import { AuthContext } from '../../providers/auth-provider';
import Redirect from '../unauthorized-pages/redirect/redirect';
import { useParams } from 'react-router-dom';
import { LeagueService } from '../../services/league-service';
import {
  iDraft,
  iDraftPickOrder,
  iLeague,
  iRoundPick,
  iUser,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import TextField from '@mui/material/TextField/TextField';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ToastContext } from '../../providers/toast-provider';
import { OperationsContext } from './operations-provider';
import { DraftOptionsEnum } from './draft-league/options';

import DraftDetails from './draft-league/details';
import DraftData from './draft-league/draft-data';
import { LeagueContext } from '../../providers/league-provider';
import Loading from '../../components/ui/loading-animation';
import { areDraftsEqual } from './helpers';

export enum ConfirmDialog {
  clear = 'clear',
  redraft = 'redraft',
}

const LeagueDraft = () => {
  const { superAdminStatus } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { reDraftLeague, getLeagueDraft } = useContext(OperationsContext);
  const { appConfig, completeLeagueSetup } = useContext(LeagueContext);

  const params: any = useParams();

  const _leagueService = new LeagueService();

  const [loading, setLoading] = useState(false);
  const [currentLeague, setCurrentLeague] = useState<iLeague | null>(null);
  const [leagueId, setLeagueId] = useState<string>();
  const [leagueDraft, setLeagueDraft] = useState<iDraft>(); // What is read off the db
  const [currentDraft, setCurrentDraft] = useState<iDraft | null>(null); // What has been completed here
  const [showConfirmationDialogType, setShowConfirmationDialogType] =
    useState<ConfirmDialog | null>(null);
  const [selectedDraftOption, setSelectedDraftOption] =
    useState<DraftOptionsEnum>(DraftOptionsEnum.auto);
  const [optionsDisabled, setOptionsDisabled] = useState(false);
  const [expandedDetails, setExpandedDetails] = useState(true);
  const [newDraftOrder, setNewDraftOrder] = useState<iUser[]>();
  const [selectedRound, setSelectedRound] = useState<number>(1);
  const [editDraft, setEditDraft] = useState(false);
  const [draftEditDuplicates, setDraftEditDuplicates] = useState<Set<{
    memberId: string;
    teamId: string;
  }> | null>(null);

  const initLeague = async (leagueId: string) => {
    try {
      setLoading(true);
      const leagueDetails = await _leagueService.getLeagueById(leagueId);
      setCurrentLeague(leagueDetails);
      const draft = await getLeagueDraft(leagueId);
      setLeagueDraft(draft);
      setCurrentDraft(draft);
    } catch (error) {
      console.log(error);
      showToast({
        messageType: 'error',
        message: 'Failed to get league details. Check the ID and try again.',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params['leagueId']) {
      initLeague(params['leagueId']);
    }
  }, []);

  const handleConfirmRedraftLeagueClick = async () => {
    if (currentLeague) {
      const redrafted = await reDraftLeague(currentLeague.id);

      if (redrafted) {
        setCurrentDraft(null);
        setOptionsDisabled(false);
        setExpandedDetails(true);
        showToast({
          messageType: 'success',
          message: 'League redrafted successfully',
        });
      } else {
        showToast({
          messageType: 'error',
          message: 'Failed to redraft league',
        });
      }
    }
    setShowConfirmationDialogType(null);
  };

  const handleRedraftLeagueClick = () => {
    setShowConfirmationDialogType(ConfirmDialog.redraft);
  };

  const handleConfirmClick = () => {
    if (showConfirmationDialogType === ConfirmDialog.clear) {
      handleConfirmClearLeagueClick();
    }

    if (showConfirmationDialogType === ConfirmDialog.redraft) {
      handleConfirmRedraftLeagueClick();
    }
    setShowConfirmationDialogType(null);
    setEditDraft(false);
  };

  const handleConfirmClearLeagueClick = () => {
    setCurrentLeague(null);
    setShowConfirmationDialogType(ConfirmDialog.clear);
  };

  const beginDraftClick = () => {
    setOptionsDisabled(true);
    setExpandedDetails(false);
    performLeagueDraft();
  };

  // Performs the draft according to the selected settings and options
  // Returns a draft object
  // Sets the current draft as the local state so it can be reverted if needed?
  const performLeagueDraft = () => {
    const defaultRankings = appConfig.defaultTeamRankings.split(',');

    const snakeDraft = (players: string[], rounds: number) => {
      let draftOrder: { player: string; round: number }[] = [];
      let reverse = false;

      for (let i = 0; i < rounds; i++) {
        const pickers = players.map((player) => {
          return { player: player, round: i + 1 };
        });

        if (reverse) {
          draftOrder = draftOrder.concat(pickers.slice().reverse());
        } else {
          draftOrder = draftOrder.concat(pickers);
        }
        reverse = !reverse;
      }

      return draftOrder;
    };

    // If the user has selected a partial draft, draft up to the selected round
    const numRounds =
      selectedDraftOption === DraftOptionsEnum.partial
        ? selectedRound
        : (currentLeague?.settings?.numOfTeamsPerMember ?? 1);

    const draftOrder = snakeDraft(
      newDraftOrder?.map((user) => user.id) ?? [],
      numRounds,
    );

    const picks: iRoundPick[] = [];

    draftOrder.forEach((picker, index) =>
      picks.push({
        memberId: picker.player,
        teamId: defaultRankings[index],
        slot: index,
        round: picker.round,
        pickBy: picker.player,
        pickAt: new Date(),
      }),
    );

    const pickOrder = {} as iDraftPickOrder;
    draftOrder.forEach((picker) => {
      if (!pickOrder[picker.round]) {
        pickOrder[picker.round] = [picker.player];
      }
      pickOrder[picker.round].push(picker.player);
    });

    const newLeagueDraft: iDraft = {
      status: leagueDraftStatus.done,
      leagueId: currentLeague?.id ?? '',
      numberOfRounds: numRounds,
      round: numRounds,
      members: currentDraft?.members ?? [],
      pickOrder: pickOrder,
      currentTurn: draftOrder[draftOrder.length - 1].player,
      nextPickBy: new Date(),
      pendingPicksInRound: 0,
      picks: picks,
      startedOn: new Date(),
      endedOn: new Date(),
      membersList: newDraftOrder,
    };
    setCurrentDraft(newLeagueDraft);
  };

  const handleResetDraftClick = () => {
    setOptionsDisabled(false);
    setExpandedDetails(true);
    setCurrentDraft(null);
  };

  const handleSaveDraftClick = async () => {
    if (draftEditDuplicates) {
      // TODO: list out duplicates in the toast
      showToast({
        messageType: 'warning',
        message: `Duplicate picks detected in the draft edits. Fix those in order to proceed.`,
      });
      return;
    }

    if (currentDraft && leagueDraft) {
      const equalDrafts = areDraftsEqual(leagueDraft, currentDraft);
      if (equalDrafts) {
        showToast({
          messageType: 'info',
          message: 'No changes detected to save',
        });
        return;
      }
    }

    setLoading(true);
    try {
      // Save the draft to the db
      if (currentDraft && currentLeague) {
        await _leagueService.updateDraft(currentDraft);
        await completeLeagueSetup(currentLeague);
        showToast({
          messageType: 'success',
          message: 'Draft saved successfully',
        });
      }
    } catch (error) {
      console.log(error);
      showToast({
        messageType: 'error',
        message: `Failed to save draft: ${error}`,
      });
    }
    setLoading(false);
  };

  const toggleEditDraft = () => {
    setEditDraft(!editDraft);
  };

  const handleDraftEdit = (updatedPicks: iRoundPick[]) => {
    if (currentDraft) {
      const hasDuplicates = () => {
        const duplicateSet = new Set<{ memberId: string; teamId: string }>();
        const teamIdSet = new Set<string>();
        for (const pick of updatedPicks) {
          if (teamIdSet.has(pick.teamId)) {
            duplicateSet.add({
              memberId: pick.memberId,
              teamId: pick.teamId,
            });
            // Duplicate found
          }
          teamIdSet.add(pick.teamId);
        }
        return duplicateSet; // No duplicates
      };

      const duplicates = hasDuplicates();

      if (duplicates) {
        // show alert
        setDraftEditDuplicates(duplicates);
      } else {
        // proceed with updating the draft
        setDraftEditDuplicates(null);
        setCurrentDraft({ ...currentDraft, picks: updatedPicks });
      }
    }
  };

  return !superAdminStatus ? (
    <Redirect />
  ) : (
    <div className="flex flex-col football-bg">
      <Header
        title="Operations Portal"
        isHome={false}
        cancelLink="/operations"
        isOP={true}
      />
      <div style={{ marginTop: '116px' }} className="px-2">
        {loading ? (
          <Loading />
        ) : currentLeague ? (
          <>
            <DraftDetails
              setShowConfirmationDialogType={setShowConfirmationDialogType}
              currentLeague={currentLeague}
              optionsDisabled={optionsDisabled}
              handleRedraftLeagueClick={handleRedraftLeagueClick}
              selectedDraftOption={selectedDraftOption}
              setSelectedDraftOption={setSelectedDraftOption}
              beginDraftClick={beginDraftClick}
              expanded={expandedDetails}
              setNewDraftOrder={setNewDraftOrder}
              onExpand={() => setExpandedDetails(!expandedDetails)}
              setSelectedRound={setSelectedRound}
              selectedRound={selectedRound}
            />
            {currentDraft && (
              <div className="mt-4">
                <div className="flex justify-between">
                  <div className="flex">
                    <Button color="error" onClick={handleResetDraftClick}>
                      Reset Draft
                    </Button>
                    <Button color="primary" onClick={toggleEditDraft}>
                      Edit Draft
                    </Button>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSaveDraftClick}
                    disabled={!currentDraft}
                  >
                    Save Draft
                  </Button>
                </div>
                <DraftData
                  draft={currentDraft}
                  members={currentLeague.members}
                  editDraft={editDraft}
                  onDraftChange={handleDraftEdit}
                />
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center mt-2 space-y-3">
            <TextField
              fullWidth
              value={leagueId ?? ''}
              label="League ID"
              placeholder="Enter League ID"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setLeagueId(event.target.value);
              }}
              required={true}
            />
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={async () => leagueId && (await initLeague(leagueId))}
              disabled={!leagueId}
            >
              Get League
            </Button>
          </div>
        )}
      </div>
      <Dialog
        open={showConfirmationDialogType !== null}
        onClose={() => setShowConfirmationDialogType(null)}
      >
        <DialogTitle>
          {showConfirmationDialogType === ConfirmDialog.clear
            ? "Are you sure you want to clear this league? This will reset any progress you've made."
            : "Are you sure you want to redraft the league? This can't be undone."}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationDialogType(null)}
            color="error"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeagueDraft;
