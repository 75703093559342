import Checkbox from "../checkbox/checkbox";
import { OptionType } from "../select/select-input";
import "./multiselect.scss";

type MultiSelectType = {
    primaryContainerOptions: OptionType[];
    secondaryContainerOptions?: OptionType[];
    //TODO: change value type to string?
    onPrimaryOptionChange: (value: any) => void;
    onSecondaryOptionChange?: (value: any) => void;
    primarySelected: any[];
    secondarySelected?: any[];
    dropDownBtn: any;
    selectAllOptions: boolean;
};

const MultiSelectDropdown = (props: MultiSelectType) => {
    const {
        primaryContainerOptions,
        secondaryContainerOptions,
        primarySelected,
        secondarySelected,
        onPrimaryOptionChange,
        onSecondaryOptionChange,
        dropDownBtn,
        selectAllOptions
    } = props;

    return (
        <div className="c-multi-select-dropdown">
            <div className="c-multi-select-dropdown__selected">
                {dropDownBtn}
            </div>
            <ul className="c-multi-select-dropdown__options">
                {primaryContainerOptions.map(option => {
                    const isSelected = primarySelected.includes(option.value);

                    return (
                        <li key={option.value} className="c-multi-select-dropdown__option">
                            <Checkbox
                                label={option.label}
                                checked={selectAllOptions === true ? true : isSelected}
                                className="c-multi-select-dropdown__option-checkbox"
                                onChange={() => {
                                  onPrimaryOptionChange({ value: option.value })
                                }}
                             />
                        </li>
                    )
                })}

                {/* This is a second 'container' within the filter options. It's used to separate from the 'Select All' options
                *   This is only optional in case we decide to use this component elsewhere and only one container is needed
                */}
                {secondaryContainerOptions &&
                    <>
                        <hr></hr>
                        {secondaryContainerOptions.map(option => {
                            const isSelected = secondarySelected && secondarySelected.includes(option.value);

                            return (
                                <li key={option.value} className="c-multi-select-dropdown__option">
                                    <Checkbox
                                        label={option.label}
                                        checked={isSelected}
                                        className="c-multi-select-dropdown__option-checkbox"
                                        onChange={() => {
                                          onSecondaryOptionChange && onSecondaryOptionChange({ value: option.value })
                                        }}
                                     />
                                </li>
                            )
                        })}
                    </>
                }
            </ul>
        </div>
    );
};

export default MultiSelectDropdown;
