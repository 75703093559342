import { useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/auth-provider';
import { useNavigate } from 'react-router-dom';
import { DiscordService } from '../../services/discord-service';
import { iDiscordAuthTokens } from '@shared/shared-utils/models';
import { discordConfig } from '../../environments/environment.local.dev';
import { ToastContext } from '../../providers/toast-provider';

const DiscordRedirectPage = () => {
  const { setDiscordAuthToken } = useContext(AuthContext);
  const { clientId, clientSecret, redirectUri, scope } = discordConfig;
  const navigate = useNavigate();
  const _discordService = new DiscordService();
  const { showToast } = useContext(ToastContext);

  //Runs only once when this component is mounted
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');

    //Authorization code for the Discord OAuth2 flow is used to get the user's access token
    if (authorizationCode) {
      const fetchAccessToken = async () => {
        try {
          const response = await fetch('https://discord.com/api/oauth2/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              client_id: clientId,
              client_secret: clientSecret,
              grant_type: 'authorization_code',
              code: authorizationCode,
              redirect_uri: redirectUri,
              scope: scope,
            }).toString(),
          });

          if (response.ok) {
            const data = await response.json();

            const accessToken = data.access_token;
            const refreshToken = data.refresh_token;

            //Get the Discord User ID of this user
            const discordUserId =
              await _discordService.getUserIdFromDiscord(accessToken);

            if (discordUserId) {
              const discordTokens: iDiscordAuthTokens = {
                discordUserId: discordUserId,
                accessToken: accessToken,
                refreshToken: refreshToken,
              };

              // Save the access token in the current state. Will be added to the user object on the profile page
              setDiscordAuthToken(discordTokens);

              //Everything else will be handled on the home page.
              navigate('/home?fromDiscordRedirect=true');
            } else {
              console.log('Trouble receiving the Discord User ID.');
              showToast({
                messageType: 'error',
                message:
                  'Something went wrong. Please try to sign in to Discord again.',
              });
            }
          } else {
            // Handle the error case
            console.error('Failed to fetch access token');
            console.log('response status: ' + response.status);
            console.log('response body: ' + (await response.text()));
            showToast({
              messageType: 'error',
              message:
                'Something went wrong. Please try to sign in to Discord again.',
            });
          }
        } catch (error) {
          console.error('Error:', error);
          showToast({
            messageType: 'error',
            message:
              'Something went wrong. Please try to sign in to Discord again.',
          });
        }
      };

      fetchAccessToken();
    } else {
      showToast({
        messageType: 'error',
        message:
          'Something went wrong. Please try to sign in to Discord again.',
      });
    }

    navigate('/home?fromDiscordRedirect=true');
  }, []);

  return <></>;
};

export default DiscordRedirectPage;
