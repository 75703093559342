import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import '../input.scss';

type EmailInputProps = {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  disabled?: boolean;
  helperText?: string;
  e2eTag?: string;
  sxProps?: any;
};

export function EmailInput(props: EmailInputProps) {
  return (
    <TextField
      fullWidth
      type="email"
      label={props.label}
      value={props.value}
      data-e2e={props.e2eTag}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(event);
      }}
      disabled={props.disabled}
      helperText={props.helperText}
      sx={props.sxProps}
    />
  );
}

export default EmailInput;
