import Select, {
  OptionType,
} from '../../../../components/ui/select/select-input';

import { useContext, useEffect, useState } from 'react';
import { TeamsContext } from '../../../../providers/teams-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsStaggered,
  faChevronDown,
  faChevronRight,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../components/ui/button/button';
import {
  iCategoryStatsItem,
  iTeam,
  iAutoPickList,
  iLeague,
  leagueDraftStatus,
  iSeasonResults,
} from '@shared/shared-utils/models';
import { AuthContext } from '../../../../providers/auth-provider';
import {
  defensiveScoreStats,
  passingScoreStats,
  rushingScoreStats,
} from '@shared/shared-utils';
import { LeagueContext } from '../../../../providers/league-provider';
import { ToastContext } from '../../../../providers/toast-provider';
import { Tooltip } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Reorder = require('react-reorder');

const DEFAULT_SORT_OPTIONS = [
  { label: 'SquadBlitz Recommended', value: 1 },
  { label: 'Most Wins', value: 2 },
  { label: 'Rushing Stats', value: 3 },
  { label: 'Passing Stats', value: 4 },
  { label: 'Defensive Stats', value: 5 },
];

type AutopickType = {
  league: iLeague;
  updatedAutopickList?: iAutoPickList;
  setPickForDraft?: (team: iTeam) => void;
};

export function AutoPickList(props: AutopickType) {
  const { teamsData, getAllTeamsData, getSeasonResultTotals } =
    useContext(TeamsContext);
  const { showToast } = useContext(ToastContext);
  const { saveAutopickListOfUser, appConfig } = useContext(LeagueContext);
  const { userDetails } = useContext(AuthContext);
  const [sortedList, setSortedList] = useState(teamsData);
  const [mySavedAutopickList, setMySavedAutopickList] = useState<
    iAutoPickList | undefined
  >(props.updatedAutopickList);
  const [enableReordering, setEnableReordering] = useState<boolean>(false);
  const [autopickSortOptions, setAutopickSortOptions] =
    useState<OptionType[]>(DEFAULT_SORT_OPTIONS);
  const [selectedSortOption, setSelectedSortOption] = useState<
    OptionType | undefined
  >(autopickSortOptions[0]);
  const [expandAutopickList, setExpandAutopickList] = useState(
    props.league.draftStatus === leagueDraftStatus.inProgress ? false : true,
  );
  const [isDragging, setIsDragging] = useState(false);
  const [teamsStats, setTeamsStats] = useState<iSeasonResults>({});
  const getCategoryScore = (
    categoryStats: iCategoryStatsItem[],
    team: iTeam,
  ) => {
    let statScoreValue = 0;
    const categoryStatsKeys: string[] = categoryStats.map((r) => r.key);

    const teamStats = teamsStats[team.id]?.stats;
    if (teamStats) {
      Object.keys(teamStats).forEach((key) => {
        if (categoryStatsKeys.includes(teamStats[key].category)) {
          statScoreValue += teamStats[key].stat;
        }
      });
    }

    return statScoreValue;
  };

  const orderTeamList = (a: iTeam, b: iTeam, sortOption: number) => {
    if (sortOption === 2) {
      if (b.sortingStats && a.sortingStats) {
        return b.sortingStats?.wins - a.sortingStats?.wins;
      }
      return b.gameSeasonTotals?.wins - a.gameSeasonTotals?.wins;
    }

    if (sortOption === 3) {
      if (b.sortingStats && a.sortingStats) {
        return b.sortingStats.rushingStats - a.sortingStats.rushingStats;
      }
      return (
        getCategoryScore(rushingScoreStats, b) -
        getCategoryScore(rushingScoreStats, a)
      );
    }

    if (sortOption === 4) {
      if (b.sortingStats && a.sortingStats) {
        return b.sortingStats.passingStats - a.sortingStats.passingStats;
      }
      return (
        getCategoryScore(passingScoreStats, b) -
        getCategoryScore(passingScoreStats, a)
      );
    }

    if (sortOption === 5) {
      if (b.sortingStats && a.sortingStats) {
        return b.sortingStats.defensiveStats - a.sortingStats.defensiveStats;
      }
      return (
        getCategoryScore(defensiveScoreStats, b) -
        getCategoryScore(defensiveScoreStats, a)
      );
    }

    return b.gameSeasonTotals?.wins - a.gameSeasonTotals?.wins;
  };

  const getTeamsByID = (teamIds: string[], mappedTeams: iTeam[]) => {
    const tempList: iTeam[] = [];
    teamIds.forEach((teamId: string) => {
      const foundTeam = mappedTeams.find(
        (team) => 'team-' + team.id === teamId,
      );
      if (foundTeam && !tempList.includes(foundTeam)) {
        tempList.push(foundTeam);
      }
    });
    return tempList;
  };

  const handleSortChange = (sortOption: OptionType) => {
    let tempList: iTeam[] = [];
    if (sortOption.value !== 6 || sortOption.value !== 1) {
      tempList = [...sortedList];
      tempList.sort((a: iTeam, b: iTeam) => {
        return orderTeamList(a, b, sortOption.value);
      });
    }

    if (sortOption.value === 6) {
      if (mySavedAutopickList) {
        tempList = getTeamsByID(mySavedAutopickList.teamOrder, sortedList);
      }
    }

    if (sortOption.value === 1) {
      const recommendedSquadList = appConfig.defaultTeamRankings.split(',');
      tempList = [...getTeamsByID(recommendedSquadList, sortedList)];
    }
    setSortedList(tempList);
  };

  const exchangeItems = (currPos: number, newPos: number) => {
    const tempArr = [...sortedList];
    if (newPos >= tempArr.length) {
      let k = newPos - tempArr.length + 1;
      while (k--) {
        tempArr.push();
      }
    }
    tempArr.splice(newPos, 0, tempArr.splice(currPos, 1)[0]);
    setSortedList(tempArr);
  };

  const initAutoList = async () => {
    const myAutopickList = props.updatedAutopickList;
    if (myAutopickList) {
      const tempAutopickList = [
        { label: 'My Ranking', value: 6 },
        ...DEFAULT_SORT_OPTIONS,
      ];

      setAutopickSortOptions(tempAutopickList);
      setMySavedAutopickList(myAutopickList);
    }

    const teamsData = await getAllTeamsData();
    const teamsStats = await getSeasonResultTotals(appConfig.currentSeason);

    setTeamsStats(teamsStats);
    if (teamsData) {
      const mappedTeamsData = teamsData.map((team) => {
        const tempTeam = {
          ...team,
          sortingStats: {
            wins: team.gameSeasonTotals?.wins,
            rushingStats: getCategoryScore(rushingScoreStats, team),
            passingStats: getCategoryScore(passingScoreStats, team),
            defensiveStats: getCategoryScore(defensiveScoreStats, team),
          },
        };
        return tempTeam;
      });
      if (myAutopickList) {
        setSortedList(getTeamsByID(myAutopickList.teamOrder, mappedTeamsData));
      } else {
        mappedTeamsData.sort((a: iTeam, b: iTeam) => {
          return orderTeamList(a, b, 1);
        });
        setSortedList(mappedTeamsData);
      }
    }
  };

  const saveAutopickList = async () => {
    try {
      const savedList: string[] = sortedList.map((l) => 'team-' + l.id);
      await saveAutopickListOfUser(savedList, props.league.id);
      if (userDetails?.id) {
        setMySavedAutopickList({
          ownerId: userDetails?.id,
          teamOrder: savedList,
        });
      }
      showToast({
        messageType: 'info',
        message: 'Autopick list has been saved.',
      });
    } catch (e: any) {
      showToast({ messageType: 'error', message: e });
    }
  };

  useEffect(() => {
    if (selectedSortOption) {
      handleSortChange(selectedSortOption);
    }
  }, [selectedSortOption]);

  //Pulls the league data on render and when there have been draft picks
  useEffect(() => {
    initAutoList();
  }, [props.updatedAutopickList]);

  //When draft starts, collapse list
  useEffect(() => {
    props.league.draftStatus === leagueDraftStatus.inProgress &&
      setExpandAutopickList(false);
  }, [props.league.draftStatus]);

  //Once the list is sorted after reorder, enables clicking again
  useEffect(() => {
    setIsDragging(false);
  }, [sortedList]);

  return (
    <div className="my-6 px-4">
      {/* Only show these filter options prior to draft start */}
      {props.league.draftStatus === leagueDraftStatus.pending && (
        <>
          <p className="font-bold">Rank teams for autopicking</p>
          <Select
            additionalClasses="bg-db-background-color mt-4"
            showLabelAlways
            options={autopickSortOptions}
            onChange={(sortOption) => {
              setEnableReordering(false);
              setSelectedSortOption(undefined);
              setSelectedSortOption(sortOption);
            }}
            placeholder="Select Sort order"
            initialValue={6 || 1}
          />
        </>
      )}
      <div className="mt-4 db-list">
        <div
          className={`flex items-center justify-between w-full header ${
            !expandAutopickList && 'rounded-b-xl'
          }`}
        >
          <div className="flex flex-row items-center">
            {/* Collapsable only during draft*/}
            {props.league.draftStatus === leagueDraftStatus.inProgress && (
              <FontAwesomeIcon
                icon={expandAutopickList ? faChevronDown : faChevronRight}
                className="mr-2 cursor-pointer"
                onClick={() => setExpandAutopickList(!expandAutopickList)}
              />
            )}
            <h3>Autopick List</h3>
          </div>
          <div className="flex flex-row space-x-3">
            <Button
              label={enableReordering ? 'Save' : 'Customize'}
              className={
                'p-0 text-accent border-b-[1px] rounded-none border-b-accent mb-2'
              }
              onClick={() => {
                //If "Save" is shown
                if (enableReordering) {
                  saveAutopickList();
                  //User CANNOT edit list order
                  setEnableReordering(false);

                  //If "Customize" is shown
                } else if (!enableReordering) {
                  //User can edit list order
                  setEnableReordering(true);
                  //Prior to draft start, list is always expanded
                  //During draft, expand list if collapsed
                  props.league.draftStatus === leagueDraftStatus.inProgress &&
                    setExpandAutopickList(true);
                }
              }}
            />
            <Tooltip
              title="If you don't pick before the time expires, the league owner can trigger an autopick for you according to this list. Rank these teams in the order that you want that to happen."
              enterTouchDelay={0}
            >
              <div>
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>
            </Tooltip>
          </div>
        </div>
        {expandAutopickList && (
          <>
            <div className="db-list-items-wrapper">
              {!enableReordering && (
                <div className="db-list-item-wrapper">
                  <div className="relative">
                    {sortedList.map((team, idx) => {
                      return (
                        <div
                          key={idx}
                          className={`flex-item px-4 py-2 border-b-[1px] border-b-gray flex justify-between ${
                            props.setPickForDraft && 'cursor-pointer'
                          }`}
                          onClick={() =>
                            props.setPickForDraft && props.setPickForDraft(team)
                          }
                        >
                          <div className="flex flex-row items-center">
                            <div
                              style={{
                                backgroundImage: `url(${team?.logos[0].replace(
                                  /http:/g,
                                  'https:',
                                )})`,
                              }}
                              className="bg-no-repeat bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image bg-[length:26px_26px] mr-3"
                              title={team.school}
                            />
                            <p>{team.school}</p>
                          </div>
                          <div className="flex justify-center">
                            <p className="mr-2">
                              {selectedSortOption?.value === 2 &&
                                `Wins : ` + team.sortingStats?.wins}
                              {selectedSortOption?.value === 3 &&
                                `RS : ` + team.sortingStats?.rushingStats}
                              {selectedSortOption?.value === 4 &&
                                `PS : ` + team.sortingStats?.passingStats}
                              {selectedSortOption?.value === 5 &&
                                `DS : ` + team.sortingStats?.defensiveStats}
                            </p>
                            <FontAwesomeIcon
                              icon={faBarsStaggered}
                              className="fa-lg opacity-30"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {enableReordering && (
                <div className="db-list-item-wrapper">
                  <div>
                    <Reorder
                      reorderId="autolist-id"
                      lock="horizontal"
                      holdTime={300}
                      onReorder={(e: any, currentPos: any, newPos: any) => {
                        setIsDragging(true);
                        exchangeItems(currentPos, newPos);
                      }}
                    >
                      {sortedList.map((team, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`flex-item px-4 py-2 border-b-[1px] border-b-gray flex justify-between ${
                              props.setPickForDraft && 'cursor-pointer'
                            }`}
                            onClick={() =>
                              !isDragging &&
                              props.setPickForDraft &&
                              props.setPickForDraft(team)
                            }
                          >
                            <div className="flex flex-row items-center">
                              <div
                                style={{
                                  backgroundImage: `url(${team?.logos[0].replace(
                                    /http:/g,
                                    'https:',
                                  )})`,
                                }}
                                className="bg-no-repeat bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image bg-[length:26px_26px] mr-3"
                                title={team.school}
                              />
                              <p>{team.school}</p>
                            </div>
                            <div className="flex justify-center">
                              <p className="mr-2">
                                {selectedSortOption?.value === 2 &&
                                  `Wins : ` + team.sortingStats?.wins}
                                {selectedSortOption?.value === 3 &&
                                  `RS : ` + team.sortingStats?.rushingStats}
                                {selectedSortOption?.value === 4 &&
                                  `PS : ` + team.sortingStats?.passingStats}
                                {selectedSortOption?.value === 5 &&
                                  `DS : ` + team.sortingStats?.defensiveStats}
                              </p>
                              <FontAwesomeIcon
                                icon={faBarsStaggered}
                                className="fa-lg"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Reorder>
                  </div>
                </div>
              )}
            </div>
            <Button
              label={'Confirm & Save'}
              className={'mt-4 w-full bg-accent'}
              onClick={() => {
                setEnableReordering(false);
                saveAutopickList();
                if (props.league.draftStatus !== leagueDraftStatus.pending) {
                  setExpandAutopickList(!expandAutopickList);
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default AutoPickList;
