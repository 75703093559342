import { Unsubscribe } from 'firebase/firestore';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
  iAppConfig,
  iDraft,
  iRoundPick,
  iLeague,
  iLeagueDb,
  iLeagueInvite,
  iLeagueMatchups,
  iLeagueMemberSquadDb,
  iLeagueSettings,
  iMatchupSquadItem,
  iMatchupSquadItemDB,
  leagueDraftStatus,
  iMatchupSquadDB,
  iLeagueMemberRanking,
  iMatchup,
  iMyMatchup,
  iAutoPickList,
  iTradeRequest,
  iTeam,
  defaultLeagueSettings,
  iOrdinalRankingConst,
} from '@shared/shared-utils/models';
import { LeagueService } from '../services/league-service';
import { NotificationService } from '../services/notification-service';
import { UserService } from '../services/user-service';
import { generateLeagueMatchups, ordinalRanking } from '@shared/shared-utils';
import { AuthContext } from './auth-provider';
import { DiscordService } from '../services/discord-service';
import { DiscordWebhooks } from '@shared/shared-utils';
import { ChatContext } from './chat-provider';

type LeagueProviderProps = {
  children: ReactNode;
};

type LeagueContextProps = {
  createLeague: (
    leagueName: string,
    leagueDescription: string,
    leagueBanner: File,
    season: number,
    isOpen: boolean,
    internalLeague?: boolean,
  ) => Promise<iLeague | void>;
  currentLeague: iLeague | undefined;
  setCurrentLeague: (league: iLeague) => void;
  userLeaguesList: iLeague[] | undefined;
  getUserLeagues: (
    userId: string,
    fetchForUserDetails?: boolean,
  ) => Promise<iLeague[]>;
  getOpenLeagues: (year: number) => Promise<iLeague[]>;
  getLeagueDetails: (
    leagueId: string,
    isCurrentLeague?: boolean,
  ) => Promise<iLeague>;
  amICurrentLeagueOwner: () => boolean;
  inviteLeagueMember: (
    leagueId: string,
    leagueTitle: string,
    inviteeEmail: string,
    invitorName: string,
  ) => Promise<void>;
  getLeagueInvites: (currentLeague: iLeague) => Promise<iLeagueInvite[]>;
  getUserInvites: (email: string) => Promise<iLeagueInvite[]>;
  deleteLeagueMember: (
    memberId: string,
    leagueId: string,
    inviteId?: string,
  ) => Promise<boolean>;
  deleteMemberInvite: (inviteId: string) => Promise<boolean>;
  resendMemberInvite: (inviteId: string) => Promise<boolean>;
  updateLeague: (
    leagueDetails: iLeague,
    leagueImage?: File | undefined,
  ) => Promise<iLeague | undefined>;
  duplicateLeague: (leagueDetails: iLeagueDb) => Promise<iLeagueDb>;
  saveLeagueSettings: (
    leagueId: string,
    leagueSetting: iLeagueSettings,
  ) => Promise<iLeague | undefined>;
  amILeagueMember: () => boolean;
  amIInvitedToLeague: () => boolean;
  joinLeague: () => Promise<iLeague | undefined>;
  leaveLeague: (leagueId: string) => Promise<void>;
  startLeagueDraft: (league: iLeague, leagueDraft: iDraft) => Promise<iDraft>;
  listenToLeagueDraft: (
    leagueId: string,
    callbackFun: (draft: iDraft) => void,
  ) => Unsubscribe;
  getLeagueDraft: (leagueId: string) => Promise<iDraft>;
  addLeaguePick: (draft: iDraft, leaguePick: iRoundPick) => Promise<boolean>;
  myCurrentLeagueSquad: iLeagueMemberSquadDb | undefined;
  getMyLeagueSquad: (
    leagueId: string,
  ) => Promise<iLeagueMemberSquadDb | undefined>;
  toggleShowMatchupModal: () => void;
  showMatchupModal: boolean;
  saveMatchupSquad: (
    leagueId: string,
    userId: string,
    userTeams: iTeam[],
    matchUpSquad: iMatchupSquadItem,
  ) => void;
  appConfig: iAppConfig;
  setAppConfig: (appConfig: iAppConfig) => void;
  saveAutopickListOfUser: (
    sortedTeamList: string[],
    leagueId: string,
  ) => Promise<any>;
  getAppConfig: () => void;
  getMemberSquad: (
    leagueId: string,
    memberId: string,
  ) => Promise<iLeagueMemberSquadDb | undefined>;
  getLeagueRequests: (leagueId: string) => Promise<iTradeRequest[]>;
  getProcessingLeagueRequests: (leagueId: string) => Promise<iTradeRequest[]>;
  getUserPendingRequests: (leagueId: string) => Promise<iTradeRequest[]>;
  getLeaderBoardDataForLeague: (
    leagueId: string,
  ) => Promise<iLeagueMemberRanking | undefined>;
  weekMatchupsData: iMatchup[] | undefined;
  matchupResultsIndex: number | undefined;
  handleHomeMatchupResultClick: (
    matchupWeek: number,
    resultsMatchup: iMyMatchup,
    league: iLeague,
  ) => void;
  homeMatchupClicked: boolean;
  toggleHomeMatchupClicked: () => void;
  matchupWeek: number | undefined;
  resultsMatchup: iMatchup | undefined;
  leaguePickTimer: number;
  setLeaguePickTimer: (value: number) => void;
  leaguePickTimerUnit: string;
  setLeaguePickTimerUnit: (unit: string) => void;
  updateSquadName: (
    squadName: string,
    leagueId: string,
    userId: string,
  ) => void;
  userRank:
    | {
        alpha: string;
        numeric: {
          number: number;
          suffix: string;
        };
      }
    | null
    | undefined;
  reactivateOldLeague: (leagueId: string) => Promise<iLeague | undefined>;
};

const defaultAppConfig: iAppConfig = {
  allSeasons: [2022, 2023, 2024],
  seasonActive: true,
  currentSeason: 2024,
  currentWeek: 1,
  resetDayIndex: 7,
  isProduction: true,
  defaultTeamRankings:
    'team-61,team-130,team-194,team-333,team-2633,team-2628,team-251,team-213,team-2306,team-254,team-2483,team-30,team-228,team-99,team-145,team-135,team-356,team-264,team-201,team-344,team-26,team-204,team-52,team-2132,team-96,team-2294,team-2579,team-2655,team-8,team-239,team-197,team-120,team-6,team-57,team-2116,team-87,team-265,team-245,team-97,team-221,team-2641,team-275,team-154,team-142,team-2653,team-153,team-2509,team-2636,team-152,team-235,team-256,team-68,team-66,team-2,team-150,team-2567,team-248,team-2005,team-278,team-276,team-2305,team-183,team-277,team-309,team-2026,team-98,team-127,team-151,team-324,team-5,team-158,team-252,team-249,team-349,team-2335,team-2390,team-25,team-12,team-2247,team-2572,team-2649,team-9,team-23,team-21,team-195,team-238,team-202,team-290,team-2393,team-2199,team-2226,team-84,team-24,team-2426,team-258,team-164,team-2084,team-259,team-2638,team-2751,team-326,team-59,team-2439,team-193,team-218,team-2032,team-295,team-2309,team-328,team-77,team-2117,team-41,team-103,team-2050,team-242,team-166,team-2433,team-2429,team-58,team-2711,team-2348,team-189,team-2459,team-38,team-36,team-2440,team-2006,team-62,team-2229,team-167,team-113',
};

export const LeagueContext = React.createContext({} as LeagueContextProps);

const LeagueProvider = ({ children }: LeagueProviderProps) => {
  const { userDetails, isLoggedIn } = useContext(AuthContext);
  const { checkForUnreadMessages } = useContext(ChatContext);

  const _leagueService = new LeagueService();
  const _userService = new UserService();
  const _notificationService = new NotificationService();
  const _discordService = new DiscordService();

  const [showMatchupModal, setShowMatchupModal] = useState<boolean>(false);
  const [currentLeague, setCurrentLeague] = useState<iLeague>();
  const [userLeaguesList, setUserLeaguesList] = useState<iLeague[]>();
  const [myCurrentLeagueSquad, setMyCurrentLeagueSquad] =
    useState<iLeagueMemberSquadDb>();
  const [appConfig, setAppConfig] = useState<iAppConfig>(defaultAppConfig);
  const [weekMatchupsData, setWeekMatchupsData] = useState<iMatchup[]>();
  const [matchupResultsIndex, setMatchupResultsIndex] = useState<number>();
  const [homeMatchupClicked, setHomeMatchupClicked] = useState<boolean>(false);
  const [matchupWeek, setMatchupWeek] = useState<number>();
  const [resultsMatchup, setResultsMatchup] = useState<iMatchup>();
  const [leaguePickTimer, setLeaguePickTimer] = useState(5);
  const [leaguePickTimerUnit, setLeaguePickTimerUnit] = useState('minutes');
  const [leagueLeaderboard, setLeagueLeaderboard] =
    useState<iLeagueMemberRanking>();
  const [userRank, setUserRank] = useState<
    iOrdinalRankingConst[number] | null
  >();

  const toggleShowMatchupModal = () => {
    setShowMatchupModal(!showMatchupModal);
  };

  const createLeague = async (
    leagueTitle: string,
    leagueDescription: string,
    leagueBanner: File,
    season: number,
    isOpen: boolean,
    internalLeague?: boolean,
  ) => {
    if (userDetails?.id) {
      let bannerImageLink = '';
      if (leagueBanner) {
        bannerImageLink = await _leagueService.uploadBannerImage(
          leagueBanner,
          userDetails,
        );
      }
      const leagueDetails: Partial<iLeagueDb> = {
        title: leagueTitle,
        description: leagueDescription,
        bannerImage: bannerImageLink,
        creator: userDetails.id,
        memberIds: [userDetails.id],
        isActive: true,
        draftStatus: leagueDraftStatus.pending,
        createdAt: new Date(),
        updatedAt: new Date(),
        season: season,
        expectedNumMembers: 1,
        internalLeague: internalLeague,
        isOpen: isOpen,
      };

      const createdLeagueDetails =
        await _leagueService.createLeague(leagueDetails);

      // create settings
      await saveLeagueSettings(createdLeagueDetails.id, defaultLeagueSettings);

      _discordService.postDiscordMessage(
        `New league created by ${createdLeagueDetails.creator.fullName}: ${
          createdLeagueDetails.title
        } - ${
          createdLeagueDetails.creator.phoneNumber
            ? createdLeagueDetails.creator.phoneNumber
            : createdLeagueDetails.creator.email
        }`,
        DiscordWebhooks.activityChannel,
      );

      // Update the leagues list
      getUserLeagues(userDetails.id, false, true);
      return createdLeagueDetails;
    }
    return;
  };

  const duplicateLeague = async (leagueDetails: iLeagueDb) => {
    try {
      const season = appConfig?.currentSeason ? appConfig.currentSeason : 2023;
      await _leagueService.duplicateLeague(leagueDetails, season);
    } catch (error) {
      console.log('Error duplicating league', error);
    }

    // TODO: Need to fix this
    return leagueDetails;
  };

  const updateLeague = async (leagueDetails: iLeague, leagueImage?: File) => {
    if (userDetails) {
      if (leagueDetails) {
        let bannerImageLink = leagueDetails.bannerImage;
        if (leagueImage) {
          bannerImageLink = await _leagueService.uploadBannerImage(
            leagueImage,
            userDetails,
          );
        }
        const updatedLeagueDetails: Partial<iLeagueDb> = {
          id: leagueDetails.id,
          title: leagueDetails.title,
          description: leagueDetails.description,
          bannerImage: bannerImageLink,
          isOpen: leagueDetails.isOpen,
          updatedAt: new Date(),
          expectedNumMembers: leagueDetails.expectedNumMembers ?? 1,
          lastMessageSent: leagueDetails.lastMessageSent,
        };

        const updatedLeague =
          await _leagueService.updateLeague(updatedLeagueDetails);

        setCurrentLeague(updatedLeague);
        return updatedLeague;
      }
    }
    return;
  };

  const joinLeague = async () => {
    if (userDetails?.id) {
      if (currentLeague?.members && currentLeague?.memberIds) {
        const leagueMembersIDs = [...currentLeague.memberIds];
        const alreadyMember = leagueMembersIDs.includes(userDetails.id);
        if (!alreadyMember) {
          leagueMembersIDs.push(userDetails.id);
          const leagueDetails: Partial<iLeagueDb> = {
            id: currentLeague.id,
            title: currentLeague.title,
            memberIds: leagueMembersIDs,
            updatedAt: new Date(),
          };
          const updatedLeague =
            await _leagueService.updateLeague(leagueDetails);
          setCurrentLeague(updatedLeague);

          try {
            // Send notification to league owner that member has joined
            if (
              updatedLeague.creator.playerId &&
              updatedLeague.settings.numOfMembers
            ) {
              _notificationService.memberJoinedLeagueNotification(
                updatedLeague.creator.playerId,
                userDetails.fullName,
                currentLeague.title,
                `/redirect?to=draft/${updatedLeague.id}`,
                updatedLeague.settings.numOfMembers - leagueMembersIDs.length,
                updatedLeague.settings.numOfMembers === leagueMembersIDs.length,
              );
            }
          } catch (error) {
            console.log('Error sending notification to league owner.', error);
          }

          _discordService.postDiscordMessage(
            `${userDetails?.fullName} just joined ${currentLeague?.title} : ${
              userDetails.phoneNumber
                ? userDetails.phoneNumber
                : userDetails.email
            }`,
            DiscordWebhooks.activityChannel,
          );
          // Update the leagues list
          getUserLeagues(userDetails.id, false, true);
          return updatedLeague;
        }
      }
    }
    return;
  };

  const leaveLeague = async (leagueId: string) => {
    if (userDetails?.id) {
      // get the league
      const leagueDetails = await _leagueService.getLeagueById(leagueId);

      // remove user from members array
      const leagueMembersIDs = [...leagueDetails.memberIds];
      leagueMembersIDs.splice(leagueMembersIDs.indexOf(userDetails.id), 1);

      // save updated league
      const updatedLeague = await _leagueService.updateLeague({
        id: leagueId,
        memberIds: leagueMembersIDs,
      });
      setCurrentLeague(updatedLeague);
      // Update the leagues list
      getUserLeagues(userDetails.id, false, true);
    }
  };

  const saveLeagueSettings = async (
    leagueId: string,
    leagueSettings: iLeagueSettings,
  ) => {
    if (leagueId) {
      const updatedLeague = await _leagueService.saveLeagueSetting(leagueId, {
        ...leagueSettings,
        leagueId: leagueId,
      });
      setCurrentLeague(updatedLeague);
      userDetails && (await getUserLeagues(userDetails.id, false, true));
      return updatedLeague;
    }
    return;
  };

  const getUserLeagues = async (
    userId: string,
    fetchForUserDetails?: boolean,
    forceUpdate?: boolean,
  ): Promise<iLeague[]> => {
    if (
      !userLeaguesList ||
      userLeaguesList?.length === 0 ||
      fetchForUserDetails ||
      forceUpdate
    ) {
      try {
        const userLeaguesListResponse = await _leagueService.getLeagues(userId);

        const userLeagues = userLeaguesListResponse?.length
          ? userLeaguesListResponse
          : [];

        if (!fetchForUserDetails) {
          // We don't want to set it throughout the app if its for user details
          setUserLeaguesList(userLeagues);
        }

        return userLeagues;
      } catch (error) {
        console.log('ERROR populating league list', error);
        setUserLeaguesList([]);
        return [];
      }
    }

    return userLeaguesList;
  };

  const getOpenLeagues = async (year: number): Promise<iLeague[]> => {
    try {
      const openLeaguesListResponse = await _leagueService.getOpenLeagues(year);

      const openLeagues = openLeaguesListResponse?.length
        ? openLeaguesListResponse
        : [];

      // Make sure the user isn't already in the league and that the league isn't full
      return openLeagues.filter((league) => {
        if (league.settings?.numOfMembers === league.memberIds.length) {
          return false;
        } else {
          return !league.memberIds?.find(
            (memberId: string) => memberId === userDetails?.id,
          );
        }
      });
    } catch (error) {
      console.log('ERROR populating open league list', error);
      return [];
    }
  };

  const getLeagueDetails = async (
    leagueId: string,
    isCurrentLeague = false,
  ): Promise<iLeague> => {
    const leagueDetails = await _leagueService.getLeagueById(leagueId);
    if (isCurrentLeague) {
      const league: iLeague = {
        ...leagueDetails,
        unreadMessages: leagueDetails.lastMessageSent
          ? checkForUnreadMessages(leagueDetails)
          : false,
      };
      setCurrentLeague(league);
    }
    await getAppConfig();
    return leagueDetails;
  };

  const inviteLeagueMember = async (
    leagueId: string,
    leagueTitle: string,
    inviteeEmail: string,
    inviterName: string,
  ): Promise<void> => {
    const inviteDetails = await _leagueService.inviteMember(
      inviteeEmail,
      leagueTitle,
      leagueId,
      inviterName,
    );

    // Send push notification if possible

    // See if the user exists
    const user = await _userService.getUserByEmail(inviteeEmail);

    // Does the user exist and have notifications set up?
    if (user?.playerId) {
      // send the notification
      _notificationService.leagueInvite(user.playerId, leagueTitle);
    }

    if (!user?.id) {
      _discordService.postDiscordMessage(
        `New user invited: ${inviteeEmail} to ${leagueTitle}`,
        DiscordWebhooks.activityChannel,
      );
    }

    return inviteDetails;
  };

  const deleteMemberInvite = async (inviteId: string): Promise<boolean> => {
    const inviteDeleted = await _leagueService.deleteMemberInvite(inviteId);
    return inviteDeleted;
  };

  const deleteLeagueMember = async (
    memberId: string,
    leagueId: string,
    inviteId?: string,
  ): Promise<boolean> => {
    try {
      const updatedLeague = await _leagueService.updateLeague({
        id: leagueId,
        memberIds: currentLeague?.memberIds.filter((id) => id !== memberId),
      });

      setCurrentLeague(updatedLeague);

      if (inviteId) {
        await deleteMemberInvite(inviteId);
      }

      return true;
    } catch (error) {
      console.log('ERROR deleting league member', error);
      return false;
    }
  };

  const resendMemberInvite = async (inviteId: string): Promise<boolean> => {
    const inviteDeleted = await _leagueService.resendMemberInvite(inviteId);
    return inviteDeleted;
  };

  const amICurrentLeagueOwner = (): boolean => {
    return currentLeague?.creator?.id === userDetails?.id;
  };

  const amILeagueMember = (): boolean => {
    if (currentLeague?.memberIds && userDetails?.id) {
      return currentLeague?.memberIds?.indexOf(userDetails?.id) >= 0;
    }
    return false;
  };

  const amIInvitedToLeague = (): boolean => {
    if (currentLeague?.invites?.length) {
      const myInvite = currentLeague?.invites.find(
        (invite: iLeagueInvite) =>
          invite.to[0].toLowerCase() === userDetails?.email.toLowerCase(),
      );
      return !!myInvite;
    }
    return false;
  };

  const getLeagueInvites = async (
    currentLeague: iLeague,
  ): Promise<iLeagueInvite[]> => {
    const invites = await _leagueService.getLeagueInvitesById(currentLeague.id);

    if (invites.length + 1 !== currentLeague?.expectedNumMembers) {
      const updatedLeague: iLeague = {
        ...currentLeague,
        expectedNumMembers: invites.length + 1,
      };
      await updateLeague(updatedLeague);
    }
    return invites;
  };

  const getUserInvites = async (email: string): Promise<iLeagueInvite[]> => {
    return await _leagueService.getLeagueRequestsForUser(email);
  };

  const startLeagueDraft = async (league: iLeague, leagueDraft: iDraft) => {
    if (league?.id) {
      const mDraft = await _leagueService.startLeagueDraft(
        league.id,
        leagueDraft,
      );

      const updatedLeagueDetails: Partial<iLeagueDb> = {
        id: league.id,
        title: league.title,
        draftStatus: leagueDraftStatus.inProgress,
      };

      const updatedLeague =
        await _leagueService.updateLeague(updatedLeagueDetails);
      setCurrentLeague(updatedLeague);
      return mDraft;
    }
    return {} as iDraft;
  };

  const listenToLeagueDraft = (
    leagueId: string,
    callbackFun: (draft: iDraft) => void,
  ): Unsubscribe => {
    return _leagueService.listenToDraftChanges(leagueId, callbackFun);
  };

  const getLeagueDraft = async (leagueId: string) => {
    return await _leagueService.getLeagueDraft(leagueId);
  };

  const getMyLeagueSquad = async (leagueId: string) => {
    if (userDetails?.id) {
      const leagueSquad = await getMemberSquad(leagueId, userDetails.id);
      leagueSquad &&
        setMyCurrentLeagueSquad({ ...myCurrentLeagueSquad, ...leagueSquad });
      return leagueSquad as iLeagueMemberSquadDb;
    }
    return;
  };

  // TODO: Seems like we should just pass the entire object in here
  const saveMatchupSquad = async (
    leagueId: string,
    userId: string,
    userTeams: iTeam[],
    matchUpSquad: iMatchupSquadItem,
  ) => {
    const teamIds = userTeams.map((team: iTeam) => 'team-' + team.id);
    const sanitizedMatchupSquad: iMatchupSquadItemDB = {};
    Object.keys(matchUpSquad).forEach((weekKey) => {
      const sanitizedWeekSquad: iMatchupSquadDB = {} as iMatchupSquadDB;
      Object.keys(matchUpSquad[Number(weekKey)]).forEach((slotKey) => {
        const slotMap: { [key: string]: iTeam } = {
          ps1: matchUpSquad[Number(weekKey)].ps1,
          ps2: matchUpSquad[Number(weekKey)].ps2,
          rs1: matchUpSquad[Number(weekKey)].rs1,
          rs2: matchUpSquad[Number(weekKey)].rs2,
          ds1: matchUpSquad[Number(weekKey)].ds1,
          ds2: matchUpSquad[Number(weekKey)].ds2,
        };
        switch (slotKey) {
          case 'ps1':
            sanitizedWeekSquad.ps1 = 'team-' + slotMap[slotKey].id;
            break;
          case 'ps2':
            sanitizedWeekSquad.ps2 = 'team-' + slotMap[slotKey].id;
            break;
          case 'rs1':
            sanitizedWeekSquad.rs1 = 'team-' + slotMap[slotKey].id;
            break;
          case 'rs2':
            sanitizedWeekSquad.rs2 = 'team-' + slotMap[slotKey].id;
            break;
          case 'ds1':
            sanitizedWeekSquad.ds1 = 'team-' + slotMap[slotKey].id;
            break;
          case 'ds2':
            sanitizedWeekSquad.ds2 = 'team-' + slotMap[slotKey].id;
            break;
        }
      });
      const benchedTeams = userTeams
        .filter((team: iTeam) => {
          return !Object.values(matchUpSquad[Number(weekKey)])
            .map((mTeam: iTeam) => mTeam.id)
            .includes(team.id);
        })
        .map((team: iTeam) => 'team-' + team.id);
      sanitizedWeekSquad.benchedTeams = benchedTeams;
      sanitizedMatchupSquad[Number(weekKey)] = sanitizedWeekSquad;
    });
    await _leagueService.addOrUpdateLeagueMemberSquad(
      leagueId,
      userId,
      false,
      teamIds,
      sanitizedMatchupSquad,
    );
  };

  const updateSquadName = async (
    squadName: string,
    leagueId: string,
    userId: string,
  ) => {
    const updatedSquadName = await _leagueService.updateSquadName(
      squadName,
      leagueId,
      userId,
    );
    return updatedSquadName;
  };

  const addLeaguePick = async (draft: iDraft, leaguePick: iRoundPick) => {
    //Current round constants
    const currentRoundIndex = draft.round - 1;
    const nextRoundIndex = draft.round;
    const currentTurnIndex = draft.pickOrder[currentRoundIndex].indexOf(
      draft.currentTurn,
    );
    const nextTurnIndex =
      currentTurnIndex + 1 < draft.members.length ? currentTurnIndex + 1 : 0;

    //Setup draft for next turn
    const updatedDraft = { ...draft };
    updatedDraft.picks = [...draft.picks, leaguePick];
    updatedDraft.pendingPicksInRound =
      nextTurnIndex === 0 ? 0 : draft.pendingPicksInRound - 1;

    //Not the last round
    if (nextRoundIndex < draft.numberOfRounds) {
      //If next turn does not mark the beginning of a new round...
      if (nextTurnIndex !== 0) {
        //Set next picker
        updatedDraft.currentTurn =
          draft.pickOrder[currentRoundIndex][nextTurnIndex];

        //If next turn marks the beginning of a new round
      } else if (nextTurnIndex === 0) {
        //Set next picker
        updatedDraft.currentTurn =
          draft.pickOrder[nextRoundIndex][nextTurnIndex];

        updatedDraft.round = draft.round + 1;
      }

      //Last round
    } else if (nextRoundIndex === draft.numberOfRounds) {
      //Not last pick of last round
      if (nextTurnIndex !== 0) {
        //Set next picker
        updatedDraft.currentTurn =
          draft.pickOrder[currentRoundIndex][nextTurnIndex];

        //After last pick of last round, complete draft
      } else if (nextTurnIndex === 0) {
        updatedDraft.status = leagueDraftStatus.done;
        updatedDraft.endedOn = new Date();

        _discordService.postDiscordMessage(
          `Draft completed for ${currentLeague?.title}`,
          DiscordWebhooks.activityChannel,
        );

        //Iterate through each league member
        for (let i = 0; i < draft.members.length; i++) {
          const memberId = draft.members[i];

          //Get all of the member's picks
          let allUserPicks: iRoundPick[] = updatedDraft.picks.filter(
            (pick) => pick.memberId === memberId,
          );
          allUserPicks = allUserPicks.sort((a, b) =>
            a.slot < b.slot ? -1 : 1,
          );

          //Add picks to slots and save it
          const week1match = setWeekOne(allUserPicks);

          // Look at what week we are on and set lineups accordingly
          const lineups: { [key: number]: iMatchupSquadDB } = {};
          const results = {
            wins: 0,
            losses: 0,
            ties: 0,
          };
          for (let i = 1; i <= appConfig.currentWeek; i++) {
            lineups[i] = week1match;
            if (i !== appConfig.currentWeek) {
              results.ties++;
            }
          }

          const memberMySquad =
            await _leagueService.addOrUpdateLeagueMemberSquad(
              updatedDraft.leagueId,
              memberId,
              true,
              allUserPicks.map((pick) => pick.teamId),
              lineups,
              results,
            );
          setMyCurrentLeagueSquad(memberMySquad);
        }

        if (currentLeague) {
          await completeLeagueSetup(currentLeague);
        }
      }
    }

    // Make sure the draft is still in progress and that we didn't just end it.
    if (updatedDraft.status === leagueDraftStatus.inProgress) {
      // Send notification for next user pick
      const nextUser = await _userService.getUserById(updatedDraft.currentTurn);

      // Not all users will have accepted notifications.
      if (nextUser.playerId && currentLeague) {
        _notificationService.draftTurnUpdate(
          nextUser.playerId,
          currentLeague?.title,
        );
      }
    }

    delete updatedDraft.membersList;
    delete updatedDraft.currentRoundPickOrderList;
    return await _leagueService.updateDraft(updatedDraft);
  };

  const getAppConfig = async () => {
    try {
      const appConfig = await _leagueService.getAppConfig('web');
      if (appConfig) {
        setAppConfig(appConfig);
        return;
      }
      setAppConfig(defaultAppConfig);
    } catch (error) {
      setAppConfig(defaultAppConfig);
      console.log('Error getting appConfig.', error);
    }
  };

  const getMemberSquad = async (leagueId: string, memberId: string) => {
    const memberSquad = await _leagueService.getLeagueMemberSquad(
      leagueId,
      memberId,
    );
    return memberSquad;
  };

  const getLeagueRequests = async (leagueId: string) => {
    const leagueRequests = await _leagueService.getLeagueRequests(leagueId);
    return leagueRequests as iTradeRequest[];
  };

  const getProcessingLeagueRequests = async (leagueId: string) => {
    const leagueRequests =
      await _leagueService.getProcessingLeagueRequests(leagueId);
    return leagueRequests as iTradeRequest[];
  };

  const getUserPendingRequests = async (userId: string) => {
    const leagueRequests = await _leagueService.getUserPendingRequests(userId);
    return leagueRequests as iTradeRequest[];
  };

  useEffect(() => {
    getAppConfig();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      setUserLeaguesList(undefined);
    }
  }, [isLoggedIn]);

  const handleHomeMatchupResultClick = async (
    matchupWeek: number,
    resultsMatchup: iMyMatchup,
    league: iLeague,
  ) => {
    setShowMatchupModal(true);
    setHomeMatchupClicked(true);
    setMatchupWeek(matchupWeek);

    const resultMatchup: iMatchup = resultsMatchup;
    setResultsMatchup(resultMatchup);
  };

  const toggleHomeMatchupClicked = () => {
    setHomeMatchupClicked(false);
  };

  const saveAutopickListOfUser = async (
    sortedTeamList: string[],
    leagueId: string,
  ): Promise<any> => {
    try {
      if (leagueId === currentLeague?.id && userDetails?.id) {
        const autopickLists: iAutoPickList[] = currentLeague.autopickLists
          ? [...currentLeague.autopickLists]
          : [];
        const filteredAutopickList = autopickLists.filter(
          (apList: iAutoPickList) => {
            return apList.ownerId !== userDetails.id;
          },
        );
        const newAutopickList: iAutoPickList = {
          ownerId: userDetails?.id,
          teamOrder: sortedTeamList,
        };
        filteredAutopickList.push(newAutopickList);
        await _leagueService.saveAutopickList(
          currentLeague.id,
          filteredAutopickList,
        );
        const updatedLeague = {
          ...currentLeague,
          autopickLists: [newAutopickList, ...filteredAutopickList],
        };
        setCurrentLeague(updatedLeague);
      }
    } catch (error) {
      return error;
    }
  };

  // cleanup: Not exported. Maybe a different file to clean this up?
  // Runs after the draft is finished
  const completeLeagueSetup = async (currentLeague: iLeague) => {
    const updatedLeagueDetails: Partial<iLeagueDb> = {
      id: currentLeague.id,
      title: currentLeague.title,
      draftStatus: leagueDraftStatus.done,
    };

    const updatedLeague =
      await _leagueService.updateLeague(updatedLeagueDetails);
    setCurrentLeague(updatedLeague);

    if (currentLeague.memberIds && currentLeague.id) {
      const matchups = generateLeagueMatchups(currentLeague.memberIds);
      const leagueMatchups: iLeagueMatchups = {
        leagueId: currentLeague.id,
        matchups: matchups,
        weeklyReports: {},
      };

      const savedLeagueMatchups =
        await _leagueService.addOrUpdateLeagueMatchups(
          currentLeague.id,
          leagueMatchups,
        );

      const updatedLeagueWithMatchups = { ...currentLeague };
      updatedLeagueWithMatchups.matchups = savedLeagueMatchups.matchups;
      setCurrentLeague(updatedLeagueWithMatchups);

      // TODO: Need to get this working
      await _leagueService.createInitialLeagueLeaderBoard(currentLeague);
    }
  };

  const setWeekOne = (teams: iRoundPick[]) => {
    const slots = ['ps1', 'ps2', 'rs1', 'rs2', 'ds1', 'ds2'];
    const benchedTeams: string[] = [];

    const positions: { [key: string]: string } = {};

    for (let i = 0; i < slots.length; i++) {
      const team = teams[i];
      if (team) {
        positions[slots[i]] = team.teamId;
      } else {
        break;
      }
    }

    if (teams.length > slots.length) {
      for (let i = slots.length; i < teams.length; i++) {
        benchedTeams.push(teams[i].teamId);
      }
    }

    return {
      ...positions,
      benchedTeams,
    } as iMatchupSquadDB;
  };

  const getLeaderBoardDataForLeague = async (leagueId: string) => {
    let leaderBoardData;
    try {
      leaderBoardData = await _leagueService.getLeagueLeaderBoardData(leagueId);
      setLeagueLeaderboard(leaderBoardData);
      return leaderBoardData;
    } catch (error) {
      return leaderBoardData;
    }
  };

  useEffect(() => {
    if (currentLeague) {
      getLeaderBoardDataForLeague(currentLeague?.id);
    }
  }, [currentLeague]);

  //Converts the users rank (number) to ordinal rank (e.g. 1st and first)
  const determinePlace = async (memberId: string) => {
    if (leagueLeaderboard?.[memberId].rank) {
      const memberRank = leagueLeaderboard[memberId].rank;
      Object.keys(ordinalRanking).forEach((key) => {
        setUserRank(ordinalRanking[memberRank]);
      });
    } else {
      setUserRank(null);
    }
  };

  useEffect(() => {
    if (userDetails) {
      determinePlace(userDetails.id);
    }
  }, [userDetails, leagueLeaderboard]);

  const reactivateOldLeague = async (leagueId: string) => {
    try {
      const oldLeague = userLeaguesList?.find(
        (league) => league.id === leagueId,
      );

      // Perform initial league setup
      const newLeagueDetails: Partial<iLeagueDb> = {
        title: oldLeague?.title,
        description: oldLeague?.description,
        bannerImage: oldLeague?.bannerImage,
        creator: oldLeague?.creator.id,
        memberIds: oldLeague?.memberIds,
        isActive: true,
        draftStatus: leagueDraftStatus.pending,
        isPrivate: oldLeague?.isPrivate,
        createdAt: new Date(),
        updatedAt: new Date(),
        season: appConfig.currentSeason,
        expectedNumMembers: oldLeague?.expectedNumMembers,
        internalLeague: oldLeague?.internalLeague,
      };

      const reactivatedLeagueDetails =
        await _leagueService.createLeague(newLeagueDetails);

      let finalizedLeague;

      // Apply league settings
      if (oldLeague?.settings) {
        const modifiedSettings: iLeagueSettings = {
          ...oldLeague.settings,
          draftDate: new Date(),
        };
        finalizedLeague = await saveLeagueSettings(
          reactivatedLeagueDetails.id,
          modifiedSettings,
        );
      }

      _discordService.postDiscordMessage(
        `New league Recreated by ${
          reactivatedLeagueDetails.creator.fullName
        }: ${reactivatedLeagueDetails.title} : ${
          reactivatedLeagueDetails.creator.phoneNumber
            ? reactivatedLeagueDetails.creator.phoneNumber
            : reactivatedLeagueDetails.creator.email
        }`,
        DiscordWebhooks.activityChannel,
      );

      return finalizedLeague;
    } catch (error) {
      console.log('Error reactivating old league', error);
      //TODO: send to rollbar
    }
    return;
  };

  return (
    <LeagueContext.Provider
      value={{
        createLeague,
        currentLeague,
        setCurrentLeague,
        userLeaguesList,
        getUserLeagues,
        getOpenLeagues,
        getLeagueDetails,
        amICurrentLeagueOwner,
        inviteLeagueMember,
        deleteLeagueMember,
        deleteMemberInvite,
        resendMemberInvite,
        getLeagueInvites,
        getUserInvites,
        updateLeague,
        duplicateLeague,
        saveLeagueSettings,
        amILeagueMember,
        amIInvitedToLeague,
        joinLeague,
        leaveLeague,
        startLeagueDraft,
        listenToLeagueDraft,
        getLeagueDraft,
        addLeaguePick,
        myCurrentLeagueSquad,
        getMyLeagueSquad,
        showMatchupModal,
        toggleShowMatchupModal,
        saveMatchupSquad,
        saveAutopickListOfUser,
        appConfig,
        setAppConfig,
        getAppConfig,
        getMemberSquad,
        getLeagueRequests,
        getProcessingLeagueRequests,
        getUserPendingRequests,
        weekMatchupsData,
        matchupResultsIndex,
        handleHomeMatchupResultClick,
        getLeaderBoardDataForLeague,
        homeMatchupClicked,
        toggleHomeMatchupClicked,
        matchupWeek,
        resultsMatchup,
        leaguePickTimer,
        setLeaguePickTimer,
        leaguePickTimerUnit,
        setLeaguePickTimerUnit,
        updateSquadName,
        userRank,
        reactivateOldLeague,
      }}
    >
      {children}
    </LeagueContext.Provider>
  );
};

export default LeagueProvider;
