import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../components/ui/button/button';
import { UpcomingGame } from '../../../../components/ui/UpcomingGame/upcoming-game';
import { iTeam } from '@shared/shared-utils/models';

type TradeTeamPropType = {
  setSlotType: (slot: string) => void;
  tradeByTeam: iTeam | undefined;
  tradeToTeam: iTeam | undefined;
  onTradeRequestClick: () => void;
};

export const TradeTeam = ({
  setSlotType,
  tradeByTeam,
  tradeToTeam,
  onTradeRequestClick,
}: TradeTeamPropType) => {
  return (
    <div className="flex flex-col justify-between flex-1 mt-4">
      <div>
        <div className="my-2 text-sm">
          Trade teams with the other members of your league!
        </div>
        <div
          className="flex justify-between border-b-[1px] border-b-gray p-4 cursor-pointer"
          onClick={() => {
            setSlotType('tradeByTeam');
          }}
          data-e2e="tradeByTeam"
        >
          {tradeByTeam && (
            <div className="flex items-center justify-between">
              <div
                style={{
                  backgroundImage: `url(${tradeByTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={tradeByTeam.school}
              />
              <div className="flex flex-col">
                <p className="text-base">{tradeByTeam.school}</p>
                <div>
                  <UpcomingGame
                    teamID={tradeByTeam.id}
                    game={tradeByTeam.nextGame}
                  />
                </div>
              </div>
            </div>
          )}
          {!tradeByTeam && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-base">Team you want to trade</p>
              </div>
            </div>
          )}
          <FontAwesomeIcon icon={faChevronDown} className="text-white fa-lg" />
        </div>
        <div
          className="flex justify-between border-b-[1px] border-b-gray p-4 cursor-pointer mt-4"
          onClick={() => {
            setSlotType('tradeToTeam');
          }}
          data-e2e="tradeToTeam"
        >
          {tradeToTeam && (
            <div className="flex items-center justify-between">
              <div
                style={{
                  backgroundImage: `url(${tradeToTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={tradeToTeam.school}
              />
              <div className="flex flex-col">
                <p className="text-base">{tradeToTeam.school}</p>
                <div>
                  <UpcomingGame
                    teamID={tradeToTeam.id}
                    game={tradeToTeam.nextGame}
                  />
                </div>
              </div>
            </div>
          )}
          {!tradeToTeam && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-base">Team you want to pick</p>
              </div>
            </div>
          )}
          <FontAwesomeIcon icon={faChevronDown} className="text-white fa-lg" />
        </div>
      </div>
      <div className="flex justify-end mt-12">
        <Button
          disabled={!tradeByTeam || !tradeToTeam}
          label={'Request'}
          onClick={() => {
            onTradeRequestClick();
          }}
          data-e2e="request_trade_btn"
        />
      </div>
    </div>
  );
};
