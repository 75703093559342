import { ReactNode, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../providers/auth-provider';
import Image from '../image';
import logo from '../../../assets/images/cropped-logo.png';
import smallLogo from '../../../assets/images/small-logo.png';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faEllipsisVertical,
  faNoteSticky,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContext } from '../../../providers/toast-provider';

type HeaderType = {
  title: string;
  isHome: boolean;
  subContent?: ReactNode;
  cancelLink?: string;
  isOP?: boolean;
};

const Header = (props: HeaderType) => {
  const { title, isHome, subContent, isOP } = props;
  const navigate = useNavigate();
  const { userDetails, logOut } = useContext(AuthContext);
  const { currentSnackbar, closeSnackBars } = useContext(ToastContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOperationsPortal] = useState<boolean>(isOP ? isOP : false);
  const [operationsMenuType, setOperationsMenuType] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.id === 'opsMenu') {
      setOperationsMenuType(true);
      setAnchorEl(event.currentTarget);
    } else {
      setOperationsMenuType(false);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOperationsMenuType(false);
  };

  return (
   <header id="mainHeader" className="p-4 pb-0 border-b-accent fixed w-full max-w-[560px] top-0 left-0 right-0 m-auto z-50">
      {(isHome || isOperationsPortal) && (
        <div className="flex justify-between w-full mb-3">
          <div className="flex flex-col items-center justify-center">
            <div
              className="w-14 m-1 border-[1px] rounded-full border-gray h-14"
              onClick={(event) => handleClick(event)}
              data-e2e="profile-image"
            >
              <Image
                imageUrl={userDetails?.profileImage}
                title={userDetails?.fullName}
                specialImageClass={'min-h-[56px]'}
                size="full"
                clickable={true}
              />
            </div>
            <span className="p-1 text-xs">Profile</span>
          </div>
          <div className="flex justify-center items-center">
            <img src={logo} alt="Logo" className="h-16" />
          </div>
          <div className="flex items-center">
            {isHome && (
              <div className="flex flex-col items-center justify-center">
                <div className="p-1 w-14 ">
                  <Link to="/notifications" data-e2e="notification_btn_header">
                    <img
                      src="/assets/images/notification-button.png"
                      alt="add button"
                      className="w-[46px]"
                    />
                  </Link>
                </div>
                <span className="p-1 text-xs">Notifications</span>
              </div>
            )}
            {isOperationsPortal && (
              <div>
                <IconButton
                  aria-label="more"
                  id="opsMenu"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      )}
      {!isHome && !isOperationsPortal && (
        <div className="flex flex-col justify-between w-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex ml-4">
              <img src={smallLogo} alt="Logo" className="h-auto w-10 mx-auto" />
            </div>
            <div className="ml-4">
              <h2 className="text-xl font-bold tracking-wider uppercase">{title}</h2>
            </div>
            <img
              src="/assets/images/cancel.png"
              className="w-4 h-4 cursor-pointer ml-auto"
              onClick={() => {
                if (props.cancelLink) {
                  navigate(props.cancelLink);
                } else {
                  navigate(-1);
                }
              }}
              alt="cancel"
              data-e2e="header_close_button"
            />
          </div>
          {subContent && <div>{subContent}</div>}
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {operationsMenuType
          ? [
              <Link
                className="no-underline"
                style={{ color: 'white' }}
                to="/operations/reports"
                key={'reports'}
              >
                <MenuItem key={'reports'}>Reports</MenuItem>
              </Link>,
              <Divider key="divider" />,
              <Link
                className="no-underline"
                style={{ color: 'white' }}
                to="/"
                key={'escape'}
              >
                <MenuItem>Back to app</MenuItem>
              </Link>,
            ]
          : [
              <Link
                className="no-underline"
                style={{ color: 'white' }}
                to="/profile"
                key="profile"
                onClick={() => closeSnackBars(currentSnackbar?.current)}
              >
                <MenuItem>
                  <div className="flex flex-row items-center space-x-2">
                    <Image
                      imageUrl={userDetails?.profileImage}
                      title={userDetails?.fullName}
                      size={10}
                      clickable={true}
                    />{' '}
                    <span>Profile</span>
                  </div>
                </MenuItem>
              </Link>,
              <Divider key="divider" />,
              <Link
                className="no-underline"
                style={{ color: 'white' }}
                to="/about"
                key={'about'}
              >
                <MenuItem onClick={handleClose}>
                  <div className="flex flex-row items-center space-x-2">
                    <FontAwesomeIcon
                      icon={faNoteSticky}
                      style={{ minWidth: '16px' }}
                    />
                    <span>About Us</span>
                  </div>
                </MenuItem>
              </Link>,
              <MenuItem
                onClick={async () => {
                  await logOut();
                  navigate('/');
                }}
                data-e2e="logout-menu-option"
                key={'logout'}
              >
                <div className="flex flex-row items-center space-x-2">
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    style={{ minWidth: '16px' }}
                  />
                  <span>Logout</span>
                </div>
              </MenuItem>,
            ]}
      </Menu>
    </header>
  );
};

export default Header;
