import { iBasicTeamDetails } from './team';
import { iUser } from './user';

export enum RequestType {
  trade = 'trade',
  freeAgent = 'freeAgent',
}

export enum RequestStatus {
  pending = 'pending',
  accepted = 'accepted',
  expired = 'expired',
  rejected = 'rejected',
  cancelled = 'cancelled',
  processing = 'processing'
}

export interface iTradeRequest {
  id: string;
  leagueId: string;
  leagueTitle: string;
  requesterDetails: iUser;
  approvers: string[];
  status: RequestStatus;
  type: RequestType;
  expiryDate: any;
  createdDate: any;
  requestedTeamOwner: iUser;
  requestedTeam: iBasicTeamDetails;
  tradedTeam: iBasicTeamDetails;
}
export interface iTransferPortalRequest {
  id: string;
  leagueId: string;
  leagueTitle: string;
  requesterDetails: iUser;
  status: RequestStatus;
  type: RequestType;
  expiryDate: any;
  createdDate: any;
  requestedTeam: iBasicTeamDetails;
  tradedTeam: iBasicTeamDetails;
}
