import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { iLeague } from '@shared/shared-utils/models';
import { useState } from 'react';

type ReactivateLeagueDialogProps = {
  openReactivateLeagueDialog: boolean;
  onClose: () => void;
  reactivatableLeagues: iLeague[];
  handleReactivateLeagueClick: (leagueId: string) => void;
};

const ReactivateLeagueDialog = (props: ReactivateLeagueDialogProps) => {
  const [selectedLeague, setSelectedLeague] = useState<string>();
  const [disableReactivateButton, setDisableReactivateButton] = useState(true);
  const [working, setWorking] = useState(false);

  const handleLeagueClick = (leagueId: string) => {
    setSelectedLeague(leagueId);
    setDisableReactivateButton(false);
  };

  return (
    <Dialog
      open={props.openReactivateLeagueDialog}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-e2e="reactivate-league-dialog"
    >
      <DialogContent>
        <div className="pb-6">
          Select the league that you would like to reactivate.
        </div>
        <List dense={true}>
          {props.reactivatableLeagues.map((league) => (
            <ListItem
              key={league.id}
              data-e2e={`reactivate_league_${league.id}`}
            >
              <ListItemButton
                onClick={() => handleLeagueClick(league.id)}
                sx={
                  selectedLeague === league.id
                    ? {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                      }
                    : {}
                }
              >
                <ListItemAvatar>
                  <Avatar src={league.bannerImage} />
                </ListItemAvatar>
                <ListItemText
                  primary={league.title}
                  secondary={league.description}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <div className=" z-10 my-4 w-full flex justify-center max-w-[560px] mx-auto">
          <div className="w-1/3 mr-2">
            <Button
              children="Cancel"
              onClick={() => {
                setSelectedLeague(undefined);
                setDisableReactivateButton(true);
                props.onClose();
              }}
              size="large"
              fullWidth
              color="warning"
              variant="contained"
              data-e2e="cancel_reactivate_league_btn"
            />
          </div>
          <div className="w-2/3">
            <Tooltip
              title={
                disableReactivateButton && 'Select a league to reactivate!'
              }
              enterTouchDelay={0}
            >
              <div>
                {working ? (
                  <Button
                    children={
                      <>
                        Reactivate
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="ml-2 text-sm"
                          spin
                        />
                      </>
                    }
                    disabled={disableReactivateButton}
                    data-e2e="reactivate_league_working_btn"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  />
                ) : (
                  <Button
                    children="Reactivate"
                    disabled={disableReactivateButton}
                    data-e2e="reactivate_league_btn"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      if (selectedLeague) {
                        setWorking(true);
                        props.handleReactivateLeagueClick(selectedLeague);
                      }
                    }}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ReactivateLeagueDialog;
