import { useContext, useEffect, useState } from 'react';
import Header from '../../components/widgets/header/header';
import Redirect from '../unauthorized-pages/redirect/redirect';
import { AuthContext } from '../../providers/auth-provider';

import { Button } from '@mui/material';
import { LeagueContext } from '../../providers/league-provider';
import BasicDetails from './league-builder/basic-details';
import Settings from './league-builder/settings';
import {
  defaultLeagueSettings,
  iLeagueDb,
  iLeagueSettings,
  iUser,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import { OperationsContext } from './operations-provider';
import { ToastContext } from '../../providers/toast-provider';
import AddMembers from './league-builder/add-members';
import InvitesModal from './league-builder/invites-modal';
import { useNavigate } from 'react-router-dom';

const LeagueBuilder = () => {
  const navigate = useNavigate();

  const { superAdminStatus } = useContext(AuthContext);
  const { appConfig, inviteLeagueMember } = useContext(LeagueContext);
  const { allUsers, getUserByEmail, createLeague } =
    useContext(OperationsContext);
  const { showToast } = useContext(ToastContext);

  const [currentTab, setCurrentTab] = useState(0);
  const [newLeague, setNewLeague] = useState<Partial<iLeagueDb>>();
  const [leagueBasicDetails, setLeagueBasicDetails] = useState<{
    title: string;
    leagueDescription: string;
    leagueBanner?: File;
    season: number;
    isOpen: boolean;
    internalLeague: boolean;
    creator: string;
    draftStatus: leagueDraftStatus;
  }>();
  const [leagueSettings, setLeagueSettings] = useState<iLeagueSettings>(
    defaultLeagueSettings,
  );
  const [leagueCreator, setLeagueCreator] = useState<iUser>();
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
  const [leagueUsers, setLeagueUsers] = useState<iUser[]>();
  const [pendingInvites, setPendingInvites] = useState<string[]>([]); // string of email addresses
  const [showInvitesModal, setShowInvitesModal] = useState(false);

  useEffect(() => {
    setNewLeague({ ...newLeague, ...leagueBasicDetails, ...leagueSettings });
  }, [leagueBasicDetails, leagueSettings]);

  const handleNextClick = async () => {
    // League basic details tab
    if (currentTab === 0) {
      try {
        // If all necessary information is already present, proceed to next tab
        if (newLeague?.creator === '' || newLeague?.title === '') {
          showToast({
            messageType: 'error',
            message: 'Must have a valid creator email and league title.',
          });
          return;
        }

        if (!leagueBasicDetails?.creator) {
          showToast({
            messageType: 'error',
            message: 'Cannot proceed without valid creator email.',
          });
          return;
        }

        const user =
          allUsers?.find(
            (user) => user.email === leagueBasicDetails?.creator,
          ) ?? (await getUserByEmail(leagueBasicDetails?.creator));

        // If userId successfully retrieved, proceed with navigation
        if (user) {
          setLeagueCreator(user);
          setNewLeague({
            ...newLeague,
            creator: user.id,
            memberIds: newLeague?.memberIds?.includes(user.id)
              ? [...newLeague?.memberIds]
              : newLeague?.memberIds
                ? [user.id, ...newLeague?.memberIds]
                : [user.id],
          });
          setCurrentTab(currentTab + 1);
        } else {
          showToast({
            messageType: 'error',
            message: 'User not found. Please use a created user.',
          });
        }
      } catch (error) {
        console.log('Error processing league basic details:', error);
        showToast({
          messageType: 'error',
          message:
            'Something went wrong while getting the user. Please enter a valid email address and try again.',
        });
      }
    }

    // General league, draft, and scoring settings
    if (currentTab === 1) {
      setNewLeague({ ...newLeague, ...leagueSettings });
      setCurrentTab(currentTab + 1);
    }
  };

  const updateLeagueMembers = async (memberEmails: string[]) => {
    const leagueMembers = await Promise.all(
      memberEmails.map(async (memberEmail) => {
        // First, check in allUsers if available
        let user = allUsers?.find((user) => user.email === memberEmail) ?? null;

        // If not found, try fetching from the DB
        if (!user) {
          try {
            user = await getUserByEmail(memberEmail);
          } catch (error) {
            console.error(
              `Error fetching user for email ${memberEmail}:`,
              error,
            );
          }
        }

        return user || memberEmail; // Return user object if found, otherwise return email
      }),
    );

    const usersInLeague: iUser[] = leagueMembers.filter(
      (member) => typeof member !== 'string',
    ) as iUser[];
    leagueCreator && usersInLeague.push(leagueCreator);
    const membersNeedingInvites: string[] = leagueMembers.filter(
      (member) => typeof member === 'string',
    ) as string[];

    setPendingInvites(membersNeedingInvites);
    const newLeagueDetails = {
      ...newLeague,
      memberIds: usersInLeague.map((user) => user.id),
    };
    setNewLeague(newLeagueDetails);
    setLeagueUsers(usersInLeague);

    // If the number of members currently in the league + pending invites do not match the expected amount
    if (
      !newLeagueDetails?.memberIds?.length ||
      newLeagueDetails?.memberIds?.length + pendingInvites.length !==
        leagueSettings.numOfMembers
    ) {
      setCreateButtonDisabled(false);
    } else {
      setCreateButtonDisabled(false);
    }

    return membersNeedingInvites;
  };

  const handleCreateLeagueClick = async () => {
    // If this path, cannot start a draft until that is complete. Make sure they are accounted for in expected number of members
    if (pendingInvites.length > 0) {
      setShowInvitesModal(true);
      return;
    }

    // If the number of members currently in the league + pending invites do not match the expected amount
    if (
      !newLeague?.memberIds?.length ||
      newLeague?.memberIds?.length + pendingInvites.length !==
        leagueSettings.numOfMembers
    ) {
      showToast({
        messageType: 'error',
        message:
          'The number of members and invites do not match the number of members expected for the league.',
      });
      return;
    }
    await createNewLeague();
  };

  const sendInvites = async (invites: string[]) => {
    // In case there were some changes to the invite email address in the modal, update the pending invites one last time
    const stillPendingInvites = await updateLeagueMembers(invites);

    if (newLeague?.id && newLeague.title && leagueCreator?.fullName) {
      for (const invite of stillPendingInvites) {
        await inviteLeagueMember(
          newLeague.id,
          newLeague?.title,
          invite,
          leagueCreator?.fullName,
        );
      }
      setShowInvitesModal(false);
      await createNewLeague();
    }
  };

  const createNewLeague = async () => {
    if (newLeague?.title && leagueCreator) {
      const league: Partial<iLeagueDb> = {
        title: newLeague.title,
        creator: leagueCreator.id,
        memberIds: newLeague.memberIds ?? [],
        season: newLeague.season ?? appConfig.currentSeason,
        isOpen: newLeague.isOpen ?? false,
        expectedNumMembers: leagueSettings.numOfMembers,
        createdAt: new Date(),
        updatedAt: new Date(),
        internalLeague: newLeague.internalLeague ?? false,
        draftStatus: newLeague.draftStatus,
      };

      await createLeague(league, leagueSettings);
      showToast({
        messageType: 'success',
        message: 'League created successfully.',
      });

      if (
        newLeague.draftStatus !== leagueDraftStatus.pending &&
        pendingInvites.length === 0
      ) {
        navigate(`/operations/league-draft/${newLeague.id}`);
      } else {
        setCreateButtonDisabled(false);
      }
    }
  };

  return !superAdminStatus ? (
    <Redirect />
  ) : (
    <div className="flex flex-col football-bg">
      <Header
        title="Operations Portal"
        isHome={false}
        cancelLink="/operations"
        isOP={true}
      />
      <div style={{ marginTop: '116px' }}>
        <form
          className="flex flex-col justify-between flex-1 p-4"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div>
            {currentTab === 0 && (
              <BasicDetails
                updateDetails={setLeagueBasicDetails}
                league={leagueBasicDetails}
              />
            )}
            {currentTab === 1 && (
              <Settings
                currentLeagueSettings={leagueSettings}
                updateSettings={setLeagueSettings}
              />
            )}
            {currentTab === 2 && leagueCreator && (
              <AddMembers
                currentMembers={leagueUsers?.map((user) => user.email) ?? []}
                creator={leagueCreator}
                updateLeagueMembers={updateLeagueMembers}
              />
            )}

            {
              <>
                <div>
                  {currentTab > 0 && (
                    <div className="z-10 mt-8 mb-4 w-full flex justify-center max-w-[560px] px-4 right-0">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setCurrentTab((current) => current - 1)}
                      >
                        Back
                      </Button>
                    </div>
                  )}
                  {currentTab !== 2 && (
                    <div className="z-10 mt-8 mb-4 w-full flex justify-center max-w-[560px] px-4 right-0">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
                {currentTab === 2 && (
                  <div className="z-10 mt-8 mb-4 w-full flex justify-center max-w-[560px] px-4 right-0">
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={createButtonDisabled}
                      onClick={handleCreateLeagueClick}
                    >
                      Create League
                    </Button>
                  </div>
                )}
              </>
            }
          </div>
        </form>
      </div>
      {showInvitesModal && (
        <InvitesModal
          pendingInvites={pendingInvites}
          onCloseClicked={() => setShowInvitesModal(false)}
          onSendClicked={sendInvites}
        />
      )}
    </div>
  );
};

export default LeagueBuilder;
