import fullLoadingAnimation from '../../assets/full-loading-animation.json';
import Lottie from 'lottie-react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Lottie
        animationData={fullLoadingAnimation}
        loop={true}
        autoplay={true}
        style={{ width: '400px', height: '400px' }}
      />
    </div>
  );
};

export default Loading;
