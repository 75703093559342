import { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import ListItem from '../../../../components/widgets/list-widget/list-item';
import ListWidget from '../../../../components/widgets/list-widget/list-widget';
import {
  iLeague,
  iLeagueInvite,
  iMatchup,
  iTradeRequest,
} from '@shared/shared-utils/models';
import { AuthContext } from '../../../../providers/auth-provider';
import { LeagueContext } from '../../../../providers/league-provider';
import { Button } from '@mui/material';

// TODO Add invites, only show my matchUps and trade requests, maybe draft complete
interface iUserActivity
  extends Partial<iMatchup>,
    Partial<iTradeRequest>,
    Partial<iLeagueInvite> {}

const UserActivity = () => {
  const { getUserInvites, getUserPendingRequests, getUserLeagues } =
    useContext(LeagueContext);
  const { userDetails } = useContext(AuthContext);

  const [leagueActivityData, setLeagueActivityData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    prepareLeagueActivityData();
  }, []);

  const getSortKey = (item: iUserActivity) => {
    switch (true) {
      case 'requesterDetails' in item:
        return item.createdDate.toDate();
      case 'resultTime' in item:
        return item.resultTime;
      case 'delivery' in item:
        return item.delivery?.startTime;
    }
  };

  const handleClick = (item: iUserActivity) => {
    switch (true) {
      case 'requesterDetails' in item:
        navigate('/notifications/');
        break;
      case 'resultTime' in item:
        return item.resultTime;
      case 'delivery' in item:
        navigate('/join/' + item.leagueId);
    }
  };

  const prepareLeagueActivityData = async () => {
    if (userDetails?.id) {
      // TODO Typings we wouldn't be here if it wasn't defined
      const requests: iTradeRequest[] = userDetails?.id
        ? await getUserPendingRequests(userDetails?.id)
        : [];
      // Filter out expired requests

      let invites: iLeagueInvite[] = userDetails?.email
        ? await getUserInvites(userDetails?.email)
        : [];

      const userLeagues: iLeague[] = await getUserLeagues(userDetails?.id);

      // filter out joined leagues
      invites = invites.filter(
        (invite) =>
          !userLeagues.find((userLeague) => userLeague.id === invite.leagueId),
      );

      const resultsWithDate: any[] = [];
      const combinedData: iUserActivity[] = [
        ...requests,
        ...resultsWithDate,
        ...invites,
      ];

      combinedData.sort((a: iUserActivity, b: iUserActivity) => {
        const aDate = getSortKey(a);
        const bDate = getSortKey(b);
        return +bDate - +aDate;
      });

      createList(combinedData);
      setIsLoading(false);
    }
  };

  const getListItemByActivityType = (item: iUserActivity) => {
    switch (true) {
      case 'requesterDetails' in item: {
        const requester = <b>{item.requesterDetails?.fullName}</b>;
        const tradedTeam = <b>{item.tradedTeam?.school}</b>;
        const requestedTeam = <b>{item.requestedTeam?.school}</b>;
        return (
          <div>
            <div className="text-sm">
              {item.requestedTeamOwner ? (
                <div data-e2e="trade_request_notification">
                  {requester} wants to trade {tradedTeam} for{' '}
                  <b>{item.requestedTeamOwner.fullName}</b>'s {requestedTeam}
                </div>
              ) : (
                <>
                  {requester} dropped {tradedTeam} and added {requestedTeam}
                </>
              )}
            </div>
            <Moment format="MMMM DD, YYYY h:mm A">
              {item.createdDate.toDate()}
            </Moment>
          </div>
        );
      }
      case 'delivery' in item: {
        return (
          <div className="flex justify-between w-full">
            <div>
              <div className="text-sm">{item?.activityMessage}</div>
              <Moment format="MMMM DD, YYYY h:mm A">
                {new Date(item.delivery?.startTime?.seconds * 1000)}
              </Moment>
            </div>
            <Button>JOIN</Button>
          </div>
        );
      }

      default:
        return null;
    }
  };

  // TODO add click event
  const createList = (combinedData: iUserActivity[]) => {
    const listView = combinedData.map((item: iUserActivity, index: number) => {
      const listItem = getListItemByActivityType(item);
      return (
        <ListItem
          specialClass="cursor-default"
          key={index}
          children={
            <div
              onClick={() => handleClick(item)}
              className="flex items-center justify-between cursor-pointer w-full"
            >
              <div className="flex flex-row items-center w-full">
                {listItem}
              </div>
            </div>
          }
        />
      );
    });

    setLeagueActivityData(listView);
  };

  return (
    <ListWidget
      label="User Activity"
      items={leagueActivityData}
      loading={isLoading}
      height="max-h-36"
    />
  );
};

export default UserActivity;
