import { LeagueContext } from '../../../providers/league-provider';
import { useContext, useEffect, useState } from 'react';
import {
  iLeague,
  iUser,
  iLeagueMemberSquadDb,
  iMatchupSquadDB,
  iLeagueScoring,
  lineupSpots,
  iStatScoreItem,
  iTeamWithScore,
  iSmallGame,
} from '@shared/shared-utils/models';
import {
  defensiveScoreStats,
  passingScoreStats,
  rushingScoreStats,
  getScores,
  getSquadName,
} from '@shared/shared-utils';
import { TeamsContext } from '../../../providers/teams-provider';

import './matchup.scss';
import { UpcomingGame } from '../../ui/UpcomingGame/upcoming-game';
import Image from '../../widgets/image';
import Loading from '../../ui/loading-animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faCaretDown } from '@fortawesome/free-solid-svg-icons';

type MatchupDetailsPropsType = {
  memberA: string;
  memberB: string;
  round: number;
  league: iLeague;
  showUpcoming: boolean;
};

const MatchupDetails = ({
  memberA,
  memberB,
  round,
  league,
  showUpcoming,
}: MatchupDetailsPropsType) => {
  const { getMemberSquad, appConfig } = useContext(LeagueContext);
  const { getTeamById, getResults } = useContext(TeamsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [memberADetails, setMemberADetails] = useState<iUser>();
  const [memberBDetails, setMemberBDetails] = useState<iUser>();
  const [memberATotal, setMemberATotal] = useState<number>();
  const [memberBTotal, setMemberBTotal] = useState<number>();
  const [memberASquadName, setMemberASquadName] = useState<string>();
  const [memberBSquadName, setMemberBSquadName] = useState<string>();
  const [squadsList, setSquadsList] =
    useState<Array<{ aTeam: iTeamWithScore; bTeam: iTeamWithScore }>>();
  const [expandedRowIndex, setExpandedRowIndex] = useState<number>(0);

  const getMemberDetailsFromLeague = (memberId: string) => {
    if (league) {
      const memberDetails = league.members?.find(
        (member) => member.id === memberId,
      );
      return memberDetails;
    }
    return;
  };

  const expandStatRow = (rowIndex: number) => {
    if (rowIndex === expandedRowIndex) {
      // If they click on the row again we should close it
      setExpandedRowIndex(-1);
      return;
    }
    setExpandedRowIndex(rowIndex);
  };

  const getScoresForOtherSlots = (
    results: any,
    teamId: string,
    leagueScoring: iLeagueScoring,
  ) => {
    const statsSlotArray = [
      { name: 'PS', stats: passingScoreStats },
      { name: 'RS', stats: rushingScoreStats },
      { name: 'DS', stats: defensiveScoreStats },
    ];

    const otherSlots: any = [];
    statsSlotArray.forEach((slot) => {
      otherSlots.push({
        name: slot.name,
        scoreResult: getScores(results, teamId, leagueScoring, slot.name),
      });
    });

    return otherSlots;
  };

  const getScoreData = (memberSq: iLeagueMemberSquadDb, results: any) => {
    const memberScoreObj: { [key: string]: any } = {};
    const memberTotalScore = lineupSpots.reduce((acc, lineupSpot: string) => {
      const roundMatchup = memberSq.matchupSquad[round];
      const spotScore = getScores(
        results,
        roundMatchup[lineupSpot as keyof iMatchupSquadDB] as string,
        league.settings.scoring,
        lineupSpot,
      );

      memberScoreObj[lineupSpot] = spotScore;
      return acc + spotScore.total;
    }, 0);

    return { memberScoreObj, memberTotalScore };
  };

  const getMatchupData = async () => {
    if (league?.id) {
      const memberASq = await getMemberSquad(league.id, memberA);
      const memberBSq = await getMemberSquad(league.id, memberB);

      const memberADet = getMemberDetailsFromLeague(memberA);
      const memberBDet = getMemberDetailsFromLeague(memberB);
      setMemberBDetails(memberBDet);
      setMemberADetails(memberADet);

      if (!memberADet || !memberBDet) return;
      setMemberASquadName(getSquadName(memberADet, league.id));
      setMemberBSquadName(getSquadName(memberBDet, league.id));
      const teamsList: any[] = [];

      let results: any;
      if (appConfig) {
        results = await getResults(round, league.season);
      }

      if (memberASq && memberBSq && results && league.settings?.scoring) {
        if (memberASq.matchupSquad[round] && memberBSq.matchupSquad[round]) {
          // TODO: Look into getMatchupScores helper

          const {
            memberScoreObj: memberAScoreObj,
            memberTotalScore: memberATotalScore,
          } = getScoreData(memberASq, results);

          setMemberATotal(
            Number((Math.round(memberATotalScore * 10) / 10).toFixed(1)),
          );

          const {
            memberScoreObj: memberBScoreObj,
            memberTotalScore: memberBTotalScore,
          } = getScoreData(memberBSq, results);

          setMemberBTotal(
            Number((Math.round(memberBTotalScore * 10) / 10).toFixed(1)),
          );

          await Promise.all(
            lineupSpots.map(async (lineupSpot) => {
              teamsList.push({
                aTeam: {
                  ...(await getTeamById(
                    memberASq.matchupSquad[round][
                      lineupSpot as keyof iMatchupSquadDB
                    ] as string,
                  )),
                  selectedSlot: lineupSpot,
                  score: memberAScoreObj[lineupSpot],
                  otherSlots: getScoresForOtherSlots(
                    results,
                    memberASq.matchupSquad[round][
                      lineupSpot as keyof iMatchupSquadDB
                    ] as string,
                    league.settings?.scoring,
                  ),
                },
                bTeam: {
                  ...(await getTeamById(
                    memberBSq.matchupSquad[round][
                      lineupSpot as keyof iMatchupSquadDB
                    ] as string,
                  )),
                  selectedSlot: lineupSpot,
                  score: memberBScoreObj[lineupSpot],
                  otherSlots: getScoresForOtherSlots(
                    results,
                    memberBSq.matchupSquad[round][
                      lineupSpot as keyof iMatchupSquadDB
                    ] as string,
                    league.settings?.scoring,
                  ),
                },
              });
            }),
          );

          const otherATeams = memberASq.matchupSquad[round].benchedTeams;
          const otherBTeams = memberBSq.matchupSquad[round].benchedTeams;

          for (let i = 0; i < otherATeams.length; i++) {
            // We need to show the rest of the possible scores here.
            // We want to show this like:
            // PS: Passing Total | RS: Rushing Total | DS: Defensive Total

            const aTeamData = await getTeamById(otherATeams[i]);
            // TODO: I really already have this data but this might be easier?
            const aTeamScore = getScores(
              results,
              otherATeams[i],
              league.settings.scoring,
            );

            const bTeamData = await getTeamById(otherBTeams[i]);
            const bTeamScore = getScores(
              results,
              otherBTeams[i],
              league.settings.scoring,
            );

            teamsList.push({
              aTeam: {
                ...aTeamData,
                score: aTeamScore,
                otherSlots: getScoresForOtherSlots(
                  results,
                  otherATeams[i],
                  league.settings.scoring,
                ),
              },
              bTeam: {
                ...bTeamData,
                score: bTeamScore,
                otherSlots: getScoresForOtherSlots(
                  results,
                  otherBTeams[i],
                  league.settings.scoring,
                ),
              },
            });
          }
        } else {
          // During matchup week but teams aren't set yet
          for (let i = 0; i < memberASq.teams.length; i++) {
            const aTeam = await getTeamById(memberASq.teams[i]);
            const bTeam = await getTeamById(memberBSq.teams[i]);
            teamsList.push({
              aTeam,
              bTeam,
            });
          }
        }
      } else {
        if (memberASq && memberBSq && league.settings?.numOfTeamsPerMember) {
          // Matchup is in the future
          for (let i = 0; i < league.settings?.numOfTeamsPerMember; i++) {
            const aTeam = await getTeamById(memberASq.teams[i]);
            const bTeam = await getTeamById(memberBSq.teams[i]);
            teamsList.push({
              aTeam,
              bTeam,
            });
          }
        }
      }
      setSquadsList(teamsList as any);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getMatchupData();
  }, [memberA, memberB, round, league]);

  return loading ? (
    <Loading></Loading>
  ) : (
    <div className="mt-2 overflow-y-scroll max-h-[90vh] bg-db-header-background-color">
      <div className="matchup-header flex border-b-[1px] border-b-dashed border-b-gray">
        <div className="flex items-start justify-between w-1/2 py-4 pl-4">
          <div>
            <Image
              imageUrl={memberADetails?.profileImage}
              title={memberADetails?.fullName}
              key={memberADetails?.id}
              specialClass="mb-2"
              size={12}
            />
            {memberASquadName ? memberASquadName : memberADetails?.fullName}
          </div>
          {memberATotal !== undefined && memberBTotal !== undefined ? (
            <div
              className={`pr-4 text-2xl ${
                memberATotal > memberBTotal ? 'font-bold win-l' : 'loss-l'
              }`}
            >
              {memberATotal}
            </div>
          ) : (
            <div className="pr-4 text-2xl tie-l">{0}</div>
          )}
        </div>
        <div className="flex justify-between items-start w-1/2 border-l-[1px] border-l-dashed border-l-gray py-4 pr-4">
          {memberATotal !== undefined && memberBTotal !== undefined ? (
            <div
              className={`pl-4 text-2xl ${
                memberBTotal > memberATotal ? 'font-bold win-r' : 'loss-r'
              }`}
            >
              {memberBTotal}
            </div>
          ) : (
            <div className="pl-4 text-2xl tie-r">{0}</div>
          )}
          <div className="flex flex-col items-end justify-end text-right">
            <Image
              imageUrl={memberBDetails?.profileImage}
              title={memberBDetails?.fullName}
              key={memberBDetails?.id}
              specialClass="mb-2"
              size={12}
            />
            {memberBSquadName ? memberBSquadName : memberBDetails?.fullName}
          </div>
        </div>
      </div>
      {/* TODO: this name does not make sense. Is this really a matchup? */}
      {squadsList?.map((squad, index: number) => {
        return (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => {
              expandStatRow(index);
            }}
          >
            <div className="flex  border-b-[1px] border-b-dashed border-b-gray">
              <div
                className={`flex flex-1 items-center ${
                  squad.aTeam?.selectedSlot ? 'w-[45%]' : 'w-1/2'
                } py-2 pl-4 text-left  border-r-[1px] border-r-dashed border-r-gray justify-between`}
              >
                {/* Make sure the team is there */}
                {squad.aTeam && (
                  <div className="flex">
                    <div
                      key={squad.aTeam.id}
                      style={{
                        backgroundImage: `url(${
                          squad.aTeam?.logos
                            ? squad.aTeam?.logos[0].replace(/http:/g, 'https:')
                            : ''
                        })`,
                      }}
                      className="bg-no-repeat bg-cover bg-primary mb-2 rounded-full h-8 w-8 min-h-[2rem]"
                      title={squad.aTeam.school}
                    />
                    {showUpcoming ? (
                      <div className="flex flex-col pl-2 flex-1">
                        <div className="text-sm">{squad.aTeam.school}</div>
                        <UpcomingGame
                          teamID={squad.aTeam.id}
                          game={
                            squad.aTeam[
                              `${appConfig.currentSeason}-schedule` as keyof typeof squad.aTeam
                            ]
                              ? (
                                  squad.aTeam[
                                    `${appConfig.currentSeason}-schedule` as keyof typeof squad.aTeam
                                  ] as any
                                )[round]
                              : undefined
                          }
                          expanded={expandedRowIndex === index}
                        />
                      </div>
                    ) : (
                      <div className="w-24 pl-4 text-sm">
                        {squad.aTeam.school}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`pr-4 ${
                    squad.aTeam.score?.total > squad.bTeam.score?.total
                      ? 'font-bold'
                      : ''
                  }`}
                >
                  {squad.aTeam.selectedSlot ? (
                    squad.aTeam.score?.total
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} className="fa-sm" />
                  )}
                </div>
              </div>
              {squad.aTeam.selectedSlot && (
                <div className="w-[10%] text-center flex justify-around items-center uppercase">
                  {squad.aTeam.selectedSlot}
                </div>
              )}
              <div
                className={`flex items-center flex-1 ${
                  squad.bTeam.selectedSlot ? 'w-[45%]' : 'w-1/2'
                } text-right border-l-[1px] border-l-dashed border-l-gray py-2 pr-4 justify-between`}
              >
                <div
                  className={`pl-4 ${
                    squad.bTeam.score?.total > squad.aTeam.score?.total
                      ? 'font-bold'
                      : ''
                  }`}
                >
                  {squad.bTeam.selectedSlot ? (
                    squad.bTeam.score?.total
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} className="fa-sm" />
                  )}
                </div>
                {/* Make sure the team is set */}
                {squad.bTeam && (
                  <div className="flex justify-end">
                    {showUpcoming ? (
                      <div className="flex flex-col pr-2 flex-1">
                        <div className="text-sm">{squad.bTeam.school}</div>
                        <UpcomingGame
                          teamID={squad.bTeam.id}
                          game={
                            squad.bTeam[
                              `${appConfig.currentSeason}-schedule` as keyof typeof squad.bTeam
                            ]
                              ? (
                                  squad.bTeam[
                                    `${appConfig.currentSeason}-schedule` as keyof typeof squad.bTeam
                                  ] as any
                                )[round]
                              : undefined
                          }
                          expanded={expandedRowIndex === index}
                        />
                      </div>
                    ) : (
                      <div className="w-24 pr-4 text-sm ">
                        {squad.bTeam.school}
                      </div>
                    )}

                    <div
                      key={squad.bTeam.id}
                      style={{
                        backgroundImage: `url(${
                          squad.bTeam?.logos
                            ? squad.bTeam?.logos[0].replace(/http:/g, 'https:')
                            : ''
                        })`,
                      }}
                      className="bg-no-repeat bg-cover bg-primary mb-2 rounded-full h-8 w-8 min-h-[2rem]"
                      title={squad.bTeam.school}
                    />
                  </div>
                )}
              </div>
            </div>
            {expandedRowIndex === index &&
              (squad.aTeam.score || squad.bTeam.score) && (
                <div>
                  {squad.aTeam.score.categoryStatScores.map(
                    (statScore: iStatScoreItem, index: number) => {
                      return (
                        <div
                          key={index}
                          className="flex border-b-[1px] border-dashed border-b-gray"
                        >
                          <div
                            className={`flex flex-1 items-center w-[35%] py-2 pl-4 text-left  border-r-[1px] border-dashed border-r-gray justify-start`}
                          >
                            <div
                              className={`pr-4 ${
                                squad.aTeam.score.categoryStatScores[index]
                                  .score >
                                squad.bTeam.score.categoryStatScores[index]
                                  ?.score
                                  ? 'font-bold'
                                  : ''
                              }`}
                            >
                              {
                                squad.aTeam.score.categoryStatScores[index]
                                  .score
                              }{' '}
                              (
                              {
                                squad.aTeam.score.categoryStatScores[index]
                                  .statValue
                              }
                              )
                            </div>
                          </div>
                          <div className="w-[30%] text-center flex justify-around items-center uppercase text-sm py-2 bg-db-background-color">
                            {statScore.stat.name}
                          </div>
                          <div
                            className={`flex items-center flex-1 w-[35%] text-right border-l-[1px] border-dashed border-l-gray py-2 pr-4 justify-end`}
                          >
                            <div
                              className={`pl-4 ${
                                squad.bTeam.score.categoryStatScores[index]
                                  ?.score >
                                squad.aTeam.score.categoryStatScores[index]
                                  .score
                                  ? 'font-bold'
                                  : ''
                              }`}
                            >
                              {
                                squad.bTeam.score.categoryStatScores[index]
                                  ?.score
                              }{' '}
                              (
                              {
                                squad.bTeam.score.categoryStatScores[index]
                                  ?.statValue
                              }
                              )
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}
                  {squad.aTeam.otherSlots && (
                    <div className="flex border-b-[1px] border-dashed border-b-gray">
                      <div
                        className={`flex items-center w-[50%] py-2 text-left  border-r-[1px] border-dashed border-r-gray justify-around`}
                      >
                        {squad.aTeam.otherSlots?.map(
                          (otherSlots, index: number) => {
                            return (
                              <div key={index}>
                                {' '}
                                {otherSlots.name} |{' '}
                                {otherSlots.scoreResult.total}{' '}
                              </div>
                            );
                          },
                        )}
                      </div>
                      <div
                        className={`flex items-center w-[50%] py-2 text-left  border-r-[1px] border-dashed border-r-gray justify-around`}
                      >
                        {squad.bTeam.otherSlots?.map(
                          (otherSlots, index: number) => {
                            return (
                              <div key={index}>
                                {' '}
                                {otherSlots.name} |{' '}
                                {otherSlots.scoreResult.total}{' '}
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        );
      })}
      <div className="w-full text-center text-sm pt-2">
        Stats will populate after the game is concluded!
      </div>
    </div>
  );
};

export default MatchupDetails;
