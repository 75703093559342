import { iSmallGame } from '@shared/shared-utils/models';

type UpcomingGameProps = {
  teamID: number;
  game?: iSmallGame;
  expanded?: boolean;
};

export const UpcomingGame = (props: UpcomingGameProps) => {
  const windowWidth = window.innerWidth;

  let awayTeam = props.game?.away_team || '';
  let homeTeam = props.game?.home_team || '';

  // Truncate the long team names on smaller screens. Don't truncate when the row is expanded.
  if (awayTeam?.length > 10 && windowWidth <= 768 && !props.expanded) {
    awayTeam = awayTeam.substring(0, 7) + '...';
  }
  if (homeTeam?.length > 10 && windowWidth <= 768 && !props.expanded) {
    homeTeam = homeTeam.substring(0, 7) + '...';
  }

  return (
    <div className="text-xs">
      (
      {props.game?.id
        ? props.teamID === props.game?.home_id
          ? ' vs ' + awayTeam
          : ' at ' + homeTeam
        : 'bye'}
      )
    </div>
  );
};
