import MemberList from './member-list';
import {
  iLeague,
  iLeagueInvite,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import { ChangeEvent, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faFootball,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { LeagueContext } from '../../../../providers/league-provider';
import { AuthContext } from '../../../../providers/auth-provider';
import { Button, Chip } from '@mui/material';
import { ToastContext } from '../../../../providers/toast-provider';

type InviteMembersType = {
  onFinish: () => void;
  league: iLeague;
  showMembers: boolean;
  isOwner: boolean;
  isLeagueActive?: boolean;
  readonly?: boolean;
  getExpectedNumLeagueMembers?: (numMembers?: number) => void;
  updateLeagueState: (league: iLeague) => void;
  reactivatedLeague?: boolean;
};

const InviteMembers = (props: InviteMembersType) => {
  const { userDetails } = useContext(AuthContext);
  const {
    inviteLeagueMember,
    getLeagueInvites,
    deleteMemberInvite,
    deleteLeagueMember,
    resendMemberInvite,
  } = useContext(LeagueContext);
  const { showToast } = useContext(ToastContext);
  const [currentLeague, setCurrentLeague] = useState<iLeague>(props.league);

  const [isSending, setIsSending] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [invites, setInvites] = useState<iLeagueInvite[]>(
    currentLeague.invites ? currentLeague.invites : [],
  );
  const [pendingInvites, setPendingInvites] = useState<any[]>([]);

  const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValidEmail = (email: string) => EMAIL_REGEXP.test(email);

  const handleEmailDelete = (inviteToDelete: any) => () => {
    setPendingInvites((invites) =>
      invites.filter((invite) => invite !== inviteToDelete),
    );
  };

  // Checks whether we've added this email already.
  const pendingChipExists = (email: string) => pendingInvites.includes(email);
  const inviteExists = (email: string) =>
    invites?.some((invite) => invite.to[0].toLocaleLowerCase() === email);
  const memberExists = (email: string) =>
    currentLeague.members?.some(
      (member) => member.email.toLocaleLowerCase() === email,
    );

  const inviteExceedsMemberCount = () => {
    // We will let 8 be the default for now
    const maxMembers = currentLeague.settings?.numOfMembers
      ? currentLeague.settings?.numOfMembers
      : 8;
    if (!currentLeague.members?.length) {
      return false;
      // Takes into account number of pending invites, sent invites, and league owner
    } else if (
      invites.length + 1 + pendingInvites.length >= maxMembers ||
      currentLeague.members.length >= maxMembers
    ) {
      return true;
    }

    return false;
  };
  // Add an email to the list, if it's valid and isn't already there.
  const addEmails = (emailsToAdd: string[]) => {
    const validatedEmails = emailsToAdd
      .map((e: string) => e.trim().toLocaleLowerCase())
      .filter((email: string) => {
        if (!isValidEmail(email)) {
          showToast({
            messageType: 'error',
            message: 'Invalid email.',
            dataTag: 'invalid-email',
          });
          return false;
        } else if (memberExists(email)) {
          showToast({
            messageType: 'warning',
            message: 'User is already in league.',
            dataTag: 'member-already-added',
          });
          return false;
        } else if (pendingChipExists(email)) {
          showToast({
            messageType: 'warning',
            message: 'Email already pending.',
            dataTag: 'pending-email-chip',
          });
          return false;
        } else if (inviteExists(email)) {
          showToast({
            messageType: 'warning',
            message: 'Email already pending.',
            dataTag: 'pending-email-invite',
          });
          return false;
        } else if (inviteExceedsMemberCount()) {
          showToast({
            messageType: 'warning',
            message: 'Member count reached.',
            dataTag: 'member-count-reached',
          });
          return false;
        } else {
          return true;
        }
      });

    const newEmails: string[] = [...pendingInvites, ...validatedEmails];

    setPendingInvites(newEmails);
    setInputValue('');
  };

  // Validate and add the email if we press tab, enter or comma.
  const handleKeyDown = (event: any) => {
    if (['Enter', 'Tab', ','].includes(event.key)) {
      event.preventDefault();

      addEmails([inputValue]);
    }
  };

  const handlePaste = (event: any) => {
    event.preventDefault();

    const pastedData = event.clipboardData.getData('text');
    const pastedEmails = pastedData.split(',');
    addEmails(pastedEmails);
  };

  const sendInvites = async () => {
    setIsSending(true);
    if (userDetails)
      await Promise.all(
        pendingInvites.map(async (invite) => {
          await inviteLeagueMember(
            currentLeague.id,
            currentLeague.title,
            invite,
            userDetails.fullName,
          );
        }),
      );

    const leagueInvites = await getLeagueInvites(currentLeague);
    setInvites(leagueInvites);

    const updatedLeague: iLeague = {
      ...currentLeague,
      expectedNumMembers: currentLeague.expectedNumMembers
        ? currentLeague.expectedNumMembers + 1
        : leagueInvites.length + 1,
    };
    setCurrentLeague(updatedLeague);
    props.updateLeagueState(updatedLeague);

    setPendingInvites([]);

    showToast({
      messageType: 'success',
      message: 'Invite sent successfully',
      dataTag: 'invite_sent_toast',
    });

    setIsSending(false);
    props.getExpectedNumLeagueMembers &&
      props.getExpectedNumLeagueMembers(leagueInvites.length + 1);
  };

  const handleDeleteMember = async (memberId: string, email: string) => {
    // check if the member is also an invite
    const invite = invites.find((invite) => invite.to[0] === email);

    const memberDeleted = await deleteLeagueMember(
      memberId,
      currentLeague.id,
      invite?.id,
    );

    if (memberDeleted) {
      showToast({
        messageType: 'success',
        message: 'Member deleted successfully',
        dataTag: 'member_delete_toast',
      });

      const updatedLeague: iLeague = {
        ...currentLeague,
        expectedNumMembers: currentLeague.expectedNumMembers
          ? currentLeague.expectedNumMembers - 1
          : invites.length + 1,
        members: currentLeague.members.filter((m) => m.id !== memberId),
      };
      setCurrentLeague(updatedLeague);
      props.updateLeagueState(updatedLeague);

      // check if there is an invite that was deleted and remove it
      if (invite) {
        setInvites(invites.filter((invite) => invite.to[0] !== email));
      }
    } else {
      showToast({
        messageType: 'error',
        message: 'Problem deleting invite.',
      });
    }
  };

  const handleInviteDelete = async (inviteId: string) => {
    const inviteDeleted = await deleteMemberInvite(inviteId);

    if (inviteDeleted) {
      showToast({
        messageType: 'success',
        message: 'Invite canceled successfully',
        dataTag: 'invite_delete_toast',
      });

      const leagueInvites = await getLeagueInvites(currentLeague);
      setInvites(leagueInvites);

      const updatedLeague: iLeague = {
        ...currentLeague,
        expectedNumMembers: currentLeague.expectedNumMembers
          ? currentLeague.expectedNumMembers - 1
          : leagueInvites.length + 1,
      };
      setCurrentLeague(updatedLeague);
      props.updateLeagueState(updatedLeague);

      props.getExpectedNumLeagueMembers &&
        props.getExpectedNumLeagueMembers(leagueInvites.length + 1);
    } else {
      showToast({
        messageType: 'error',
        message: 'Problem deleting invite.',
      });
    }
  };

  const handleInviteResend = async (inviteId: string) => {
    const inviteResent = await resendMemberInvite(inviteId);

    if (inviteResent) {
      showToast({
        messageType: 'success',
        message: 'Invite resent successfully',
      });
    } else {
      showToast({ messageType: 'error', message: 'Problem resending invite.' });
    }
  };

  return (
    <div
      className="flex-1 flex-col justify-between p-4 overflow-hidden"
      data-e2e="invite_members_page"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div>
          <div>
            {pendingInvites.length
              ? pendingInvites.map((invite, index) => {
                  return (
                    <Chip
                      label={invite}
                      key={index}
                      variant="outlined"
                      color="primary"
                      onDelete={handleEmailDelete(invite)}
                      icon={<FontAwesomeIcon icon={faFootball} />}
                      data-e2e={`chip_${invite}`}
                    />
                  );
                })
              : ''}
          </div>
          {!props.readonly && (
            <div>
              <div className="flex flex-1">
                <div className="flex-1 input-holder">
                  <input
                    type="email"
                    placeholder="Email address"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputValue(e.target.value);
                    }}
                    onPaste={handlePaste}
                    onKeyDown={handleKeyDown}
                    disabled={!props.isLeagueActive || props.readonly}
                    value={inputValue}
                  />
                </div>
                <button
                  className="h-10 py-2 pl-4 pr-4 mt-4 ml-4 border-[1px] rounded-none text-primary border-primary"
                  onClick={() => {
                    addEmails([inputValue]);
                  }}
                  disabled={!props.isLeagueActive || props.readonly}
                  data-e2e="add_email_invites_btn"
                >
                  <span>Add Email</span>
                </button>
              </div>
              {/* TODO: change button */}
              <button
                className="h-10 py-2 pl-4 pr-4 mt-4 border-[1px] rounded-none text-primary border-primary"
                onClick={() => {
                  sendInvites();
                }}
                disabled={!pendingInvites.length || isSending}
                data-e2e="send_invites_btn"
              >
                {isSending ? (
                  <>
                    <span>Sending</span>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="ml-2 text-sm"
                      spin
                    />
                  </>
                ) : (
                  <>
                    <span>Send</span>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="ml-2 text-sm"
                    />
                  </>
                )}
              </button>
            </div>
          )}
          {!props.readonly && (
            <div className="mt-8">
              <strong>Required number of members:</strong> {currentLeague.settings.numOfMembers}<br />
              <small><em>Required number of members can be edited in "Settings"</em></small>
            </div>
          )}
          {invites && (
            <div className="mt-6 mb-4">
              <MemberList
                loading={isSending}
                members={currentLeague.members}
                invites={invites}
                onDelete={handleInviteDelete}
                onResend={handleInviteResend}
                onDeleteMember={handleDeleteMember}
                leagueOwner={currentLeague.creator}
                isOwner={props.isOwner}
                leagueIsOpen={currentLeague.isOpen}
                reactivatedLeague={props.reactivatedLeague}
                leagueHasDrafted={
                  currentLeague.draftStatus !== leagueDraftStatus.pending
                    ? true
                    : false
                }
              />
            </div>
          )}
        </div>
        <div className="text-right">
          {!props.readonly && (
            <Button
              variant="contained"
              onClick={() => {
                props.onFinish();
              }}
              data-e2e="finish_invites_btn"
            >
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default InviteMembers;
