import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
const storage = getStorage();

export class DocumentService {
  public async deleteFile(filePath: string) {
    if (filePath) {
      const fileRef = ref(storage, filePath);
      return await deleteObject(fileRef);
    }
    return null;
  }

  public async uploadFile(
    file: File,
    directory: string,
    fileName?: string
  ): Promise<string> {
    const fileRef = ref(
      storage,
      directory + '/' + (fileName ? fileName : file.name.replace(' ', '-'))
    );
    const metadata = {
      contentType: file.type,
    };
    await uploadBytes(fileRef, file, metadata);
    const imageLink = await getDownloadURL(fileRef);
    return imageLink;
  }
}

export default DocumentService;
