import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { iTeamDetailsTableData, statsData } from '@shared/shared-utils/models';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';

type TeamDetailsTabletype = {
  tableData: iTeamDetailsTableData[];
};

const TeamDetailsTable = (props: TeamDetailsTabletype) => {
  const [tableHeaders, setTableHeaders] = useState<string[]>();

  const buildTable = async () => {
    interface iColumn {
      id: string;
      title: string;
    }

    const columns: iColumn[] = [
      {
        id: 'opponent',
        title: 'Week - Opponent',
      },
    ];

    //Build column headers from stats categories
    Object.entries(statsData).forEach((val) => {
      columns.push({
        id: val[0],
        title: val[1].name,
      });
    });

    setTableHeaders(
      Object.values(columns).map((val) => {
        return val.title;
      }),
    );
  };

  useEffect(() => {
    buildTable();
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: 'gray',
        borderStyle: 'solid',
        borderWidth: 'thin',
        marginTop: '0.5rem',
        paddingBottom: '0',
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders?.map((col, index) => {
              return (
                <TableCell
                  sx={{
                    textAlign: 'center',
                    minWidth: `${index === 0 ? '150px' : '50px'}`,
                    position: `${index === 0 ? 'sticky' : 'relative'}`,
                    left: 0,
                    zIndex: `${index === 0 ? '99' : '0'}`,
                    backgroundColor: 'black',
                  }}
                  key={col + index}
                >
                  {col}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.map((row, index) => {
            return (
              <TableRow
                key={row.opponentName + index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  key={row.opponentName + index}
                  sx={{
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'black',
                    zIndex: '99',
                  }}
                >
                  {row.week}
                  {' - '}
                  {row.opponentName}
                  <div className="text-sx">
                    <Moment format="M/D/YY">{row.gameDate}</Moment>
                  </div>
                </TableCell>
                {Object.keys(statsData).map((key, index) => {
                  const stats = row.stats
                    ? row.stats.find((stat) => stat.category === key)
                    : { category: key, stat: '--' };
                  return (
                    <TableCell sx={{ textAlign: 'center' }} key={index}>
                      {stats?.stat || '0'}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamDetailsTable;
