import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  orderBy,
  limit,
} from 'firebase/firestore';
import { DBCollections } from '@shared/shared-utils';
import { DBFirebaseDB } from './firebase-core';

export class ChatService {
  private _DBChatCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueChats,
  );

  public async getGroupChats(
    roomId: string,
    lastMessageTime: Date,
    limitCount: number,
  ) {
    //console.log('read - getGroupChats');
    const chatByGroupId = query(
      collection(DBFirebaseDB, DBCollections.leagueChats),
      where('roomId', '==', roomId),
      where('timestamp', '<', lastMessageTime),
      orderBy('timestamp', 'desc'),
      limit(limitCount),
    );

    const querySnapshot = await getDocs(chatByGroupId);
    let groupChat = new Array();

    querySnapshot.forEach((doc) => {
      groupChat.push(doc.data());
    });

    return groupChat;
  }

  public async sendMessage(
    roomId: string,
    roomTitle: string,
    userId: string,
    userName: string,
    profileImage: string,
    message: string,
  ) {
    const now = new Date();

    const newItem = {
      roomId: roomId, // Assign a unique ID to the new item
      roomTitle: roomTitle,
      userId: userId,
      userName: userName,
      profileImage: profileImage,
      message: message,
      timestamp: now,
    };
    const documentRef = doc(this._DBChatCollection);
    await setDoc(documentRef, newItem);

    return documentRef.id;
  }
}
