import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';

type Platform = 'android' | 'ios';

interface MobileAppMessageProps {
  platform: Platform;
}

const MobileAppMessage: React.FC<MobileAppMessageProps> = ({
  platform = 'android',
}) => {
  const [showMessage, setShowMessage] = useState(true);

  const dismissMessage = () => {
    setShowMessage(false);
  };

  let appStoreLink = '';
  if (platform === 'android') {
    appStoreLink =
      'https://play.google.com/store/apps/details?id=com.squadblitz';
  } else if (platform === 'ios') {
    appStoreLink = 'https://apps.apple.com/us/app/squad-blitz/id1669667588';
  }

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 bg-info text-primary p-4 flex justify-between items-center z-50 ${
        showMessage ? '' : 'hidden'
      }`}
    >
      <div className="flex items-center h-full">
        <div className="mr-2 text-center">
          For the best experience, please use our mobile app.
        </div>
        <Button
          variant="contained"
          href={appStoreLink}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 text-center"
        >
          Get the app
        </Button>
      </div>
      <IconButton onClick={dismissMessage} className="text-primary-contrast">
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
    </div>
  );
};

export default MobileAppMessage;
