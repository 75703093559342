import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { environment } from '../environments/environment.local.dev';
import { Firestore, initializeFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

export const DBFirebaseApp: FirebaseApp = initializeApp(
  environment.firebaseConfig,
);

if (environment.addAnalytics) {
  console.log('Analytics added');
  getAnalytics(DBFirebaseApp);
} else {
  console.log('Not running on host. No analytics.', environment.hostUrl);
}
export const DBFirebaseDB: Firestore = initializeFirestore(DBFirebaseApp, {
  ignoreUndefinedProperties: true,
});

export const currentDBProject = DBFirebaseApp.options.projectId;

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(DBFirebaseApp);
