import { Button } from '@mui/material';
import Linkify from 'linkify-react';
import moment from 'moment';
import {
  iMessage,
  iUser,
} from '@shared/shared-utils/models';
import { useCallback, useEffect, useState } from 'react';

type ChatListProps = {
  loadMoreMessages: boolean;
  onLoadMoreClick: () => void;
  chatMessages: iMessage[];
  currentUserDetails: iUser;
  memberImages: {
    memberId: string;
    memberImage: JSX.Element;
  }[];
};

const ChatList = (props: ChatListProps) => {
  const [parentHeight, setParentHeight] = useState<number>();
  const [scrollingRef, setScrollingRef] = useState<HTMLDivElement>();

  const parentRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setParentHeight(node.clientHeight);
      }
    },
    [props.chatMessages],
  );

  const scrollRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        setScrollingRef(node);
      }
    },
    [props.chatMessages, parentHeight],
  );

  useEffect(() => {
    setTimeout(() => {
      scrollingRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 500);
  }, [props.chatMessages.length, parentHeight]);

  return (
    <div
      className="flex flex-col mt-5 mb-10 overflow-scroll max-h-[80vh]"
      ref={parentRef}
      id="parent_container"
    >
      {props.loadMoreMessages ? (
        <div className={`flex justify-center`}>
          <div className={`py-3 px-4 w-full `}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => props.onLoadMoreClick()}
            >
              Load More
            </Button>
          </div>
        </div>
      ) : (
        ''
      )}
      {props.chatMessages.length > 0 ? (
        props.chatMessages.map((item: iMessage, index) => {
          return (
            <div
              className={`flex ${
                props.currentUserDetails.id === item.userId
                  ? 'justify-end'
                  : 'justify-start'
              }`}
              key={index}
            >
              <div className="py-3 px-4 ml-2">
                <div className="relative w-48 mb-2">
                  <div className="text-black bg-white p-3 rounded break-words">
                    {item.message.includes('giphy.com/media/') ||
                    item.message.includes('giphy.com/gifs/') ? (
                      <img
                        src={item.message}
                        alt=""
                        className="min-h-[94.5px]"
                      />
                    ) : (
                      !item.message.includes('giphy.com/media/') && (
                        <Linkify as="div">{item.message}</Linkify>
                      )
                    )}
                  </div>
                  <div
                    className={`flex flex-col relative text-xs ${
                      props.currentUserDetails.id === item.userId
                        ? 'float-right'
                        : ''
                    }`}
                  >
                    <span>
                      {moment(
                        item.datetime,
                        'ddd MMM DD YYYY h:mm:ss A',
                      ).format('ddd MMM DD h:mm A')}
                    </span>
                    <span>{item.userName}</span>
                  </div>
                  <div
                    className={`absolute ${
                      props.currentUserDetails?.id === item.userId
                        ? 'left-[-2rem] bottom-[-1.5rem]'
                        : 'right-[-2rem] bottom-[0.5rem]'
                    }`}
                  >
                    {
                      props.memberImages.find(
                        (memberObj) => memberObj.memberId === item.userId,
                      )?.memberImage
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className={`flex justify-center`}>
          <div className={`py-3 px-4 ml-2 `}>
            <div className={`text-black bg-white p-3 rounded`}>
              Send a message to get the conversation started!
            </div>
          </div>
        </div>
      )}
      <div className=" pb-20" ref={scrollRef} id="scroll_to" />
    </div>
  );
};

export default ChatList;
