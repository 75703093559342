import { ReactNode, useEffect, useState } from 'react';
import {
  iLeague,
  iMatchupSquad,
  iMatchupSquadObj,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import LDSquadPosition from '../../../../../components/modals/squad-positions/league-details-squad-positions';
import ListItem from '../../../../../components/widgets/list-widget/list-item';
import ListWidget from '../../../../../components/widgets/list-widget/list-widget';
import Button from '../../../../../components/ui/button/button';
import { useHorizontalScroll } from '../../../../../hooks/use-hoz-scroll';

type WeeklyMatchupSquadsProps = {
  navigateToUpdateSquad: () => void;
  matchupSquad: iMatchupSquad;
  leagueDetails: iLeague;
};

export const WeeklyMatchupSquads = (props: WeeklyMatchupSquadsProps) => {
  const [squadModal, setSquadModal] = useState<ReactNode>();
  const hozScrollRef = useHorizontalScroll();

  const createSquadModal = () => {
    const posArray = ['Passing Squads', 'Rushing Squads', 'Defensive Squads'];
    const matchupSquadsObj: iMatchupSquadObj[] = [
      {
        matchupSquadTeam: props.matchupSquad.ps1,
        posTitle: 'Passing Squads',
        posTeamNum: 1,
        slot: 'ps1',
      },
      {
        matchupSquadTeam: props.matchupSquad.ps2,
        posTitle: 'Passing Squads',
        posTeamNum: 2,
        slot: 'ps2',
      },
      {
        matchupSquadTeam: props.matchupSquad.rs1,
        posTitle: 'Rushing Squads',
        posTeamNum: 1,
        slot: 'rs1',
      },
      {
        matchupSquadTeam: props.matchupSquad.rs2,
        posTitle: 'Rushing Squads',
        posTeamNum: 2,
        slot: 'rs2',
      },
      {
        matchupSquadTeam: props.matchupSquad.ds1,
        posTitle: 'Defensive Squads',
        posTeamNum: 1,
        slot: 'ds1',
      },
      {
        matchupSquadTeam: props.matchupSquad.ds2,
        posTitle: 'Defensive Squads',
        posTeamNum: 2,
        slot: 'ds2',
      },
    ];

    const createListItem = (pos: string) => {
      return matchupSquadsObj.map((squad, index: number) => {
        if (squad.posTitle === pos) {
          return [
            <ListItem
              key={index}
              children={
                <LDSquadPosition
                  rowClicked={() => props.navigateToUpdateSquad()}
                  teamNum={squad.posTeamNum}
                  team={squad.matchupSquadTeam}
                  slot={squad.slot}
                />
              }
              specialClass={
                squad.matchupSquadTeam?.nextGame?.id ? '' : 'animate-pulse'
              }
            />,
          ];
        } else {
          return null;
        }
      });
    };

    const listWidget = () => {
      return posArray.map((pos, index: number) => {
        return (
          <div key={index} className="flex-shrink-0 w-3/4 mr-6">
            <ListWidget
              items={createListItem(pos).filter((item) => item != null)}
              label={pos}
              loading={false}
            />
          </div>
        );
      });
    };

    const modal = (
      <div
        className={`flex w-full px-4 overflow-x-scroll
          ${
            props.leagueDetails.draftStatus !== leagueDraftStatus.done
              ? ' disabled'
              : ''
          }`}
        ref={hozScrollRef}
      >
        {listWidget()}
      </div>
    );

    setSquadModal(modal);
  };

  useEffect(() => {
    createSquadModal();
  }, [props.leagueDetails, props.matchupSquad]);

  return (
    <>
      <div className="flex items-center justify-between m-4">
        <p className="font-bold">Weekly Matchup Squads</p>
        <div>
          <Button
            label="Update"
            onClick={() => {
              props.navigateToUpdateSquad();
            }}
            data-e2e="updateSquadButton"
          />
        </div>
      </div>
      {squadModal}
    </>
  );
};

export default WeeklyMatchupSquads;
