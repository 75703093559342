import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import DateInput from '../../../components/ui/date-input/date-input';
import { iLeagueScoring, iLeagueSettings } from '@shared/shared-utils/models';
import { ChangeEvent, useEffect, useState } from 'react';
import LeagueScoring from '../../authorized-pages/league/widgets/league-scoring/league-scoring';

type SettingsProps = {
  currentLeagueSettings: iLeagueSettings;
  updateSettings: (newSettings: iLeagueSettings) => void;
};

const Settings = (props: SettingsProps) => {
  const [leagueDraftDate, setLeagueDraftDate] = useState<Date>();
  const [leagueNumberOfTeamsPerUser, setLeagueNumberOfTeamsPerUser] =
    useState<number>(8);
  const [leagueNumberOfMembers, setLeagueNumberOfMembers] = useState<number>(
    props.currentLeagueSettings?.numOfMembers ?? 8,
  );
  const [timerValue, setTimerValue] = useState(
    props.currentLeagueSettings.pickTimer,
  );
  const [timerUnit, setTimerUnit] = useState(
    props.currentLeagueSettings.pickTimerUnit,
  );
  const [currentScoring, setCurrentScoring] = useState<iLeagueScoring>(
    props.currentLeagueSettings.scoring,
  );

  useEffect(() => {
    props.updateSettings({
      draftDate: leagueDraftDate,
      numOfMembers: leagueNumberOfMembers,
      numOfTeamsPerMember: leagueNumberOfTeamsPerUser,
      pickTimer: timerValue,
      pickTimerUnit: timerUnit,
      scoring: currentScoring,
    });
  }, [
    leagueDraftDate,
    leagueNumberOfMembers,
    leagueNumberOfTeamsPerUser,
    timerValue,
    timerUnit,
    currentScoring,
  ]);

  return (
    <div
      className="flex flex-col justify-between flex-1 p-4"
      data-e2e="create_league_settings"
    >
      <div>
        {/* <h3 className="border-b-[1px] border-b-accent pb-2 mt-6 flex justify-between">
          League Settings
        </h3> */}

        <div>
          {/* Checkbox to enable/disable dynasties
            <FormControl fullWidth>
              <h3 className="pb-2 mt-6">Dynasty Mode</h3>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leagueEnableDynasty}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setLeagueEnableDynasty(event.target.checked);
                    }}
                    // Disabled until edit button is clicked
                    disabled={!editLeagueSettings}
                  />
                }
                label="Enable Dynasty Mode"
              />
              <FormHelperText>
                Keep your league for years to come with Dynasty Mode!
              </FormHelperText>
            </FormControl>
            {leagueEnableDynasty && (
              <div className="my-4">
                <FormControl fullWidth>
                  <InputLabel id="num-dynasty-teams-label">
                    Num of Teams to Keep
                  </InputLabel>
                  <Select
                    labelId="num-dynasty-teams-label"
                    id="num-dynasty-teams-select"
                    value={numOfTeamsDynasty}
                    label="Num of Teams"
                    onChange={(event: SelectChangeEvent<number>) => {
                      if (event.target.value) {
                        //If the selected number of teams > number of teams per squad, show error
                        if (leagueNumberOfTeamsPerUser > +event.target.value) {
                          setNumOfTeamsDynasty(+event.target.value);
                        } else {
                          showToast({
                            messageType: 'error',
                            message:
                              'You cannot change this to be more than the number of teams per squad.',
                            autoHideDuration: 4000,
                            dataTag: 'num_dynasty_teams',
                          });
                        }
                      }
                    }}
                    // Disabled until edit button is clicked
                    disabled={!editLeagueSettings}
                    data-e2e="ls_draft_num_members"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )} */}
          {/* <FormControl>
          <h3 className="pb-2 mt-6">League Type</h3>
          <RadioGroup
            onChange={(e) => {
              if (e.target.value === 'open') {
                setLeagueIsOpen(true);
              } else {
                setLeagueIsOpen(false);
              }
            }}
          >
            <FormControlLabel
              value="private"
              control={
                <Radio
                  disabled={!editLeagueSettings}
                  checked={!leagueIsOpen}
                />
              }
              label="Private"
            />
            <FormHelperText>
              Private leagues require invitations to add members
            </FormHelperText>
            <FormControlLabel
              value="open"
              control={
                <Radio
                  disabled={!editLeagueSettings}
                  checked={leagueIsOpen}
                />
              }
              label="Open"
            />
            <FormHelperText>
              Open leagues allow anyone to join your league
            </FormHelperText>
          </RadioGroup>
        </FormControl> */}
        </div>
      </div>
      <div>
        <h3 className="border-b-[1px] border-b-accent pb-2 mt-6 flex justify-between">
          Draft Settings
          {/* shows an edit icon for this section */}
        </h3>
        <DateInput
          showTime
          initialValue={leagueDraftDate}
          placeholder="Draft Date"
          onChange={(val) => {
            if (val) setLeagueDraftDate(val);
          }}
        />
        <div className="my-4">
          <FormControl fullWidth>
            <InputLabel id="num-members-label">Num of Members</InputLabel>
            <Select
              labelId="num-members-label"
              id="num-members-select"
              value={leagueNumberOfMembers}
              label="Num of Members"
              onChange={(event: SelectChangeEvent<number>) => {
                setLeagueNumberOfMembers(+event.target.value);
              }}
            >
              <MenuItem value={2} data-e2e="ls_draft_num_members_2">
                2
              </MenuItem>
              <MenuItem value={4} data-e2e="ls_draft_num_members_4">
                4
              </MenuItem>
              <MenuItem value={6} data-e2e="ls_draft_num_members_6">
                6
              </MenuItem>
              <MenuItem value={8} data-e2e="ls_draft_num_members_8">
                8
              </MenuItem>
              <MenuItem value={10} data-e2e="ls_draft_num_members_10">
                10
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="my-4">
          <FormControl fullWidth>
            <InputLabel id="num-teams-label">Num of Teams</InputLabel>
            <Select
              labelId="num-teams-label"
              id="num-teams-select"
              value={leagueNumberOfTeamsPerUser}
              label="Num of Teams"
              onChange={(event: any) => {
                if (event.target.value)
                  setLeagueNumberOfTeamsPerUser(event.target.value);
              }}
            >
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </FormControl>
        </div>
        <InputLabel id="pick-timer">Pick Timer</InputLabel>
        <div className="flex max-w-fit">
          <TextField
            id="timer-value"
            value={timerValue}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setTimerValue(Number(event.target.value));
            }}
            required
          />
          <FormControl fullWidth>
            <Select
              labelId="pick-timer"
              id="timer-unit"
              value={timerUnit}
              onChange={(event: SelectChangeEvent<string>) => {
                setTimerUnit(event.target.value);
              }}
            >
              <MenuItem value={'seconds'}>seconds</MenuItem>
              <MenuItem value={'minutes'}>minutes</MenuItem>
            </Select>
          </FormControl>
        </div>
        <LeagueScoring
          readOnly={false}
          isAdmin
          leagueScoring={currentScoring}
          setLeagueScoringInSettings={setCurrentScoring}
        />
      </div>
    </div>
  );
};

export default Settings;
