import { useContext, useEffect, useState, ReactNode } from 'react';
import {
  iLeague,
  iUser,
  iUserTrophiesUI,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import { LeagueContext } from '../../../providers/league-provider';
import Loading from '../../../components/ui/loading-animation';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from '../../../services/user-service';
import ListWidget from '../../../components/widgets/list-widget/list-widget';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { TeamsContext } from '../../../providers/teams-provider';
import { ordinalRanking } from '@shared/shared-utils';
import TrophyCase from './trophy-case';
import { TrophyContext } from '../../../providers/trophy-provider';
import Header from '../../../components/widgets/header/header';

const UserDetails = () => {
  const params: any = useParams();
  const _userService = new UserService();
  const navigate = useNavigate();
  const { getUserLeagues, getMemberSquad, currentLeague, appConfig } =
    useContext(LeagueContext);
  const { getLocalTeamById } = useContext(TeamsContext);
  const { getAllUsersTrophies } = useContext(TrophyContext);
  const [user, setUser] = useState<iUser>();
  const [userLeagueItems, setUserLeagueItems] = useState<ReactNode[]>();
  const [currentLeagueDisplay, setCurrentLeagueDisplay] = useState<ReactNode>();
  const [userTrophies, setUserTrophies] = useState<iUserTrophiesUI>();

  const initUserDetails = async () => {
    if (params) {
      try {
        const userData = await _userService.getUserById(params['userId']);
        setUser(userData);
        const userLeagues = await getUserLeagues(userData.id, true);

        const currentSeasonLeagues = userLeagues.filter((league) => {
          return league.season === appConfig.currentSeason;
        });

        buildLeaguesList(currentSeasonLeagues, userData.id);

        const userTrophies = getAllUsersTrophies(userData);
        setUserTrophies(userTrophies ? userTrophies : undefined);
      } catch (error) {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    initUserDetails();
  }, [appConfig]);

  const buildLeaguesList = async (userLeagues: iLeague[], userId: string) => {
    //If user is navigating from another league, filter that league out and show as context
    const filteredLeagues = currentLeague
      ? userLeagues.filter((league) => league.id !== currentLeague.id)
      : userLeagues;
    const leagueItems =
      filteredLeagues.length > 0
        ? await Promise.all(
            //TODO: Sort the user's leagues based on ?? @joshuaPSmith
            filteredLeagues.map(async (league) => {
              const squad = await getMemberSquad(league.id, userId);
              return (
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    >
                      <div
                        className={`w-full flex flex-row items-center pr-4 ${
                          league.bannerImage ? 'justify-between' : 'justify-end'
                        }`}
                      >
                        {league.bannerImage && (
                          <div
                            className="bg-no-repeat bg-cover bg-primary rounded-full h-12 w-12 min-h-[2rem] team-image"
                            style={{
                              backgroundImage: `url(${league.bannerImage.replace(
                                /http:/g,
                                'https:',
                              )})`,
                            }}
                          />
                        )}
                        <div>{league.title}</div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {league.draftStatus !== leagueDraftStatus.done ? (
                        <span className="italic">
                          This league has not drafted yet
                        </span>
                      ) : (
                        <div className="flex flex-col bg-[#1E1E1E]">
                          <div className="flex flex-row justify-around mb-3">
                            {league.leaderBoard[userId] && (
                              <>
                                <span>
                                  {league.leaderBoard[userId].rank
                                    ? 'Rank: ' +
                                      league.leaderBoard[userId].rank +
                                      ordinalRanking[
                                        league.leaderBoard[userId].rank
                                      ].numeric.suffix
                                    : 'Rank: --'}
                                </span>
                                <span>
                                  {league.leaderBoard[userId].wins >= 0 &&
                                  league.leaderBoard[userId].losses >= 0
                                    ? league.leaderBoard[userId].wins +
                                      ' | ' +
                                      league.leaderBoard[userId].losses
                                    : '0 | 0'}
                                </span>
                              </>
                            )}
                          </div>
                          {squad ? (
                            <div className="flex flex-row justify-between items-center overflow-x-scroll">
                              <span className="flex items-center mr-6 sticky left-0 h-12 z-10 px-4 py-2 bg-[#1E1E1E]">
                                SQUAD
                              </span>
                              <div className="flex flex-row -ml-6">
                                {squad?.teams.map((team) => {
                                  const teamData = getLocalTeamById(team);
                                  return (
                                    <div
                                      className="bg-no-repeat bg-cover bg-primary rounded-full h-12 w-12 min-h-[2rem] team-image"
                                      style={{
                                        backgroundImage: `url(${teamData?.logos[0].replace(
                                          /http:/g,
                                          'https:',
                                        )})`,
                                      }}
                                      title={teamData?.school}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <span className="italic">No squad chosen yet</span>
                          )}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            }),
          )
        : ['This user is not a part of any other leagues yet.'];
    setUserLeagueItems(leagueItems);
  };

  //This shows the data about the current league
  const buildLeagueContextDisplay = async () => {
    if (user && currentLeague?.leaderBoard[user.id]) {
      const squad = await getMemberSquad(currentLeague.id, user.id);
      const leagueItems = [
        <div className="flex flex-col bg-[#1E1E1E]">
          <div className="flex flex-row justify-around my-3">
            <span>
              {currentLeague.leaderBoard[user.id].rank
                ? 'Rank: ' +
                  currentLeague.leaderBoard[user.id].rank +
                  ordinalRanking[currentLeague.leaderBoard[user.id].rank]
                    .numeric.suffix
                : 'Rank: --'}
            </span>
            <span>
              {currentLeague.leaderBoard[user.id].wins >= 0 &&
              currentLeague.leaderBoard[user.id].losses >= 0
                ? currentLeague.leaderBoard[user.id].wins +
                  ' | ' +
                  currentLeague.leaderBoard[user.id].losses
                : '0 | 0'}
            </span>
          </div>
          {squad ? (
            <div className="flex flex-row justify-between items-center overflow-x-scroll">
              <span className="flex items-center mr-6 sticky left-0 h-12 z-10 px-8 py-2 bg-[#1E1E1E]">
                SQUAD
              </span>
              <div className="flex flex-row -ml-6">
                {squad?.teams.map((team) => {
                  //Pulls team data from the cache
                  const teamData = getLocalTeamById(team);
                  return (
                    <div
                      className="bg-no-repeat bg-cover bg-primary rounded-full h-12 w-12 min-h-[2rem] team-image -z-1"
                      style={{
                        backgroundImage: `url(${teamData?.logos[0].replace(
                          /http:/g,
                          'https:',
                        )})`,
                      }}
                      title={teamData?.school}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <span className="italic">No squad chosen yet</span>
          )}
        </div>,
      ];
      const leagueDisplay = (
        <div className="my-4">
          <ListWidget
            label={`Current League: ${currentLeague.title}`}
            loading={!currentLeague}
            items={leagueItems}
          />
        </div>
      );
      setCurrentLeagueDisplay(leagueDisplay);
    }
  };

  useEffect(() => {
    buildLeagueContextDisplay();
  }, [currentLeague, user]);

  return !user || !userLeagueItems ? (
    <Loading></Loading>
  ) : (
    <div id="user-profile" className="flex flex-col">
      <Header
        title={'User Profile'}
        isHome={false}
      />
      <div className="flex-1 pt-[7rem] pl-4 pr-4">
        <div className="flex flex-col items-center">
        {user.profileImage && (
          <div className="rounded-full w-40 h-40 overflow-hidden">
            <img
              src={user.profileImage}
              alt="profile pic"
              className="object-cover w-full h-full"
            />
          </div>
          )}
          <h2 className="py-2">{user.fullName}</h2>
        </div>
        <TrophyCase userTrophies={userTrophies} />
        {currentLeagueDisplay ?? <div></div>}
        {/* TODO: decide how much user data to show here. Depends on where all it can be accessed from. Eventually, follow users/add as friend? */}
        {userLeagueItems ? (
          <ListWidget
            label="Other Active Leagues"
            loading={!userLeagueItems}
            items={userLeagueItems}
          />
        ) : (
          //TODO: invite user to your league button here?
          <div>This user is not a part of any leagues yet.</div>
        )}
        <div className="flex flex-row justify-end mt-6">
          <Button
            variant="text"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
