import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
  allTrophies,
  iUser,
  iUserTrophiesUI,
} from '@shared/shared-utils/models';
import { AuthContext } from './auth-provider';
import { UserService } from '../services/user-service';
import { ToastContext } from './toast-provider';
import { Timestamp } from 'firebase/firestore';

type TrophyProviderProps = {
  children: ReactNode;
};

type TrophyContextProps = {
  currentUsersTrophies: iUserTrophiesUI | undefined;
  assignTrophy: (
    user: iUser,
    season: number,
    trophyType: string,
    notificationMessage: string,
  ) => Promise<void>;
  getAllUsersTrophies: (passedInUser?: iUser) => iUserTrophiesUI | null;
};

export const TrophyContext = React.createContext({} as TrophyContextProps);

const TrophyProvider = ({ children }: TrophyProviderProps) => {
  const { userDetails } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const userService = new UserService();

  // This type is used to display the user's trophies
  const [currentUsersTrophies, setCurrentUsersTrophies] = useState<
    iUserTrophiesUI | undefined
  >();

  /**
   * Gets all trophies, past and present, so that the trophy
   * progress is displayed as cumulative for the user's career
   */
  const getAllUsersTrophies = (passedInUser?: iUser) => {
    // if we pass in a user, use that
    const allUsersTrophies = passedInUser
      ? passedInUser.userTrophies
      : userDetails?.userTrophies;
    // Make sure user has trophies. Other wise, return null
    if (allUsersTrophies) {
      let allDisplayedTrophies: iUserTrophiesUI | undefined;

      // Iterate through all trophy constants to:
      // 1. Determine if the trophy is on the user
      // 2. Determine if the trophy is progress or not
      Object.keys(allTrophies).forEach((trophyType) => {
        // If this is a progress trophy and the user has it...
        if (allTrophies[trophyType].progress && allUsersTrophies[trophyType]) {
          let awardDate: Date = new Date('01/01/2001');
          // Iterate through each season that this trophy was awarded.
          const userProgressTrophies = allUsersTrophies[trophyType];

          Object.keys(userProgressTrophies).forEach((season) => {
            // If the property is not a season, return
            if (season === 'totalProgress') {
              return;
            }

            // Get the most recent award date.
            const oldDate = (
              userProgressTrophies[+season].awardDate as unknown as Timestamp
            ).toDate();
            if (oldDate > awardDate) {
              awardDate = oldDate;
            }
          });

          // Add the trophy, most recent award date, and total progress
          allDisplayedTrophies = {
            ...allDisplayedTrophies,
            [trophyType]: {
              trophyType: trophyType,
              awardDate: awardDate,
              progress: userProgressTrophies.totalProgress,
            },
          };

          // If this is NOT a progress trophy and the user has it...
        } else if (
          !allTrophies[trophyType].progress &&
          allUsersTrophies[trophyType]
        ) {
          // Iterate through each trophy and build the correct type
          const userNonProgressTrophies = allUsersTrophies[trophyType];
          Object.keys(userNonProgressTrophies).forEach((season) => {
            const trophy = userNonProgressTrophies[+season];
            allDisplayedTrophies = {
              ...allDisplayedTrophies,
              [trophyType]: {
                trophyType: trophyType,
                awardDate: trophy?.awardDate,
              },
            };
          });

          // If user does have this trophy type, move on to the next one.
        } else {
          return;
        }
      });

      if (allDisplayedTrophies) {
        // setCurrentUsersTrophies(allDisplayedTrophies);
        return allDisplayedTrophies;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  // Initialize user trophies state (AKA gets user's trophies)
  useEffect(() => {
    if (userDetails) {
      const usersTrophies = getAllUsersTrophies();
      if (usersTrophies) {
        setCurrentUsersTrophies(usersTrophies);
      }
    }
  }, [userDetails]);

  /**
   * Assigns a trophy to a user. Specify the trophy type as a string and include
   * a message for the toast saying what trophy was awarded.
   */
  const assignTrophy = async (
    user: iUser,
    season: number,
    trophyType: string,
    notificationMessage: string,
  ) => {
    const usersTrophies = { ...user.userTrophies } ?? {};

    const isProgress = allTrophies[trophyType].progress ? true : false;

    // Handle updating the progress if the trophy already exists
    if (user.userTrophies?.[trophyType]) {
      // If this is not a progress trophy and the user already has it then return
      if (!isProgress) {
        return;
      }

      // const usersTrophies = { ...user.userTrophies };
      const oldTotalProgress = usersTrophies[trophyType].totalProgress ?? 0;
      const newTotalProgress = oldTotalProgress + 1;

      // Get the old progress for this season if it exists
      const oldSeasonProgress =
        usersTrophies[trophyType][season]?.progress ?? 0;
      const newSeasonProgress = oldSeasonProgress + 1;

      // Update the total progress and progress per season
      usersTrophies[trophyType] = {
        ...usersTrophies[trophyType], // Include all of the existing data for this trophy
        [season]: {
          trophyType: trophyType,
          awardDate: usersTrophies[trophyType][season]
            ? usersTrophies[trophyType][season].awardDate
            : new Date(), // If a trophy for this season exists, keep the existing award date
          progress: newSeasonProgress,
        },
        totalProgress: newTotalProgress,
      };

      // If the trophy does not already exist, create it
    } else {
      usersTrophies[trophyType] = isProgress
        ? {
            [season]: {
              trophyType: trophyType,
              awardDate: new Date(),
              progress: 1,
            },
            totalProgress: 1,
          }
        : {
            [season]: {
              trophyType: trophyType,
              awardDate: new Date(),
            },
          };
    }

    const updatedUserDetails: iUser = {
      ...user,
      userTrophies: usersTrophies,
    };

    await userService.updateUserProfile(updatedUserDetails, user.id);
    showToast({
      messageType: 'success',
      message: notificationMessage,
    });

    return;
  };

  return (
    <TrophyContext.Provider
      value={{
        currentUsersTrophies,
        getAllUsersTrophies,
        assignTrophy,
      }}
    >
      {children}
    </TrophyContext.Provider>
  );
};

export default TrophyProvider;
