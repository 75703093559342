import { collection, doc, getDoc } from 'firebase/firestore';
import { iScoreboardResults } from '@shared/shared-utils/models';
import { DBCollections } from '@shared/shared-utils';
import { DBFirebaseDB } from './firebase-core';

export class ScoreboardService {
  private _DBScoreboardCollection = collection(
    DBFirebaseDB,
    DBCollections.weeklyScoreboard,
  );

  public async getScoreboardResults(): Promise<{
    results: iScoreboardResults[] | [];
  }> {
    try {
      //console.log('read - getScoreboardResults()');
      const scoreboardRef = doc(this._DBScoreboardCollection, 'scoreboard');
      const scoreboardSnapshot = await getDoc(scoreboardRef);
      return scoreboardSnapshot.data() as { results: iScoreboardResults[] };
    } catch (e) {
      console.log(e);
    }
    return { results: [] };
  }
}
