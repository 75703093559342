import ListItem from '../../../../components/widgets/list-widget/list-item';
import ListWidget from '../../../../components/widgets/list-widget/list-widget';
import { iLeague, iUser } from '@shared/shared-utils/models';
import { useEffect, useState } from 'react';
import Image from '../../../../components/widgets/image';
import useUserDetailsNavigation from '../../../../hooks/useUserDetailsNavigation';
import { getSquadName } from '@shared/shared-utils';

type LeaderBoardProps = {
  league: iLeague;
};

const LeaderBoard = (props: LeaderBoardProps) => {
  const { league } = props;
  const [leaderBoardData, setLeaderBoardData] = useState<any[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const { userNavigation } = useUserDetailsNavigation();

  const getLeaderBoard = async () => {
    if (league.memberIds) {
      const leaderBoard = league.memberIds?.sort((a, b) => {
        if (league.leaderBoard[a] && league.leaderBoard[b]) {
          return league.leaderBoard[a].rank - league.leaderBoard[b].rank;
        }

        return 0;
      });

      const listView = leaderBoard.map((element: any, index) => {
        // let profileImage = '';
        // let fullName = '';
        // let userId = '';

        let member = league.members?.find((member: iUser) => {
          return member.id === element;
        });

        if (!member) {
          return null;
        }

        // league?.members?.forEach((member: iUser) => {
        //   if (member?.id === element) {
        //     profileImage = member.profileImage;
        //     fullName = member.fullName;
        //     userId = member.id;
        //   }
        // });
        return (
          <ListItem
            children={
              <div
                className="flex items-center justify-between"
                onClick={() => {
                  userNavigation(member?.id ? member.id : '');
                }}
              >
                <div className="flex items-center flex-row ">
                  <div className="flex flex-col ">
                    <p className="text-base pr-2">
                      {league.leaderBoard[element]?.rank}
                    </p>
                  </div>
                  <Image
                    key={element}
                    imageUrl={member?.profileImage}
                    title={member?.fullName}
                    size={12}
                    specialClass="mr-2"
                    clickable={true}
                    clickAction={() =>
                      userNavigation(member?.id ? member.id : '')
                    }
                  />
                  <div className="flex flex-col">
                    <p className="text-base">
                      {getSquadName(member, league.id)}
                    </p>
                    <div className="flex flex-col">
                      <p>{`${Math.round(
                        league.leaderBoard[element]?.pointsScored,
                      )} - Total`}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-base">{`${league.leaderBoard[element]?.wins} | ${league.leaderBoard[element]?.losses}`}</div>
                  <div className="text-xs">W | L </div>
                </div>
              </div>
            }
          />
        );
      });
      setLeaderBoardData(listView);
    }
  };

  useEffect(() => {
    if (league?.id) {
      getLeaderBoard();
    }
  }, [league]);

  // Listen for when the leader board is ready
  useEffect(() => {
    if (leaderBoardData.length) {
      setLoadingData(false);
    }
  }, [leaderBoardData]);

  return (
    <ListWidget
      items={leaderBoardData}
      label={'Leaderboard'}
      loading={loadingData}
    />
  );
};

export default LeaderBoard;
