import React, { ReactNode, useContext, useState } from 'react';
import {
  RequestType,
  iLeague,
  iLeagueMemberSquadDb,
  iTeam,
  iUser,
} from '@shared/shared-utils/models';
import { TeamsService } from '../services/teams-service';
import { ToastContext } from './toast-provider';
import { NotificationService } from '../services/notification-service';

type TradingProviderProps = {
  children: ReactNode;
};

type TradingContextProps = {
  processingTradeRequest: boolean;
  setProcessingTradeRequest: React.Dispatch<React.SetStateAction<boolean>>;
  tradeToTeam: iTeam | undefined;
  setTradeToTeam: React.Dispatch<React.SetStateAction<iTeam | undefined>>;
  tradeByTeam: iTeam | undefined;
  setTradeByTeam: React.Dispatch<React.SetStateAction<iTeam | undefined>>;
  sendTradeRequest: (
    props: {
      otherLeagueMemberDetails: iLeagueMemberSquadDb[] | undefined;
      league: iLeague;
      tradeToTeam: iTeam;
      tradeByTeam: iTeam;
      userDetails: iUser;
      season: number;
      currentWeek: number;
    },
    successCallback: () => void,
  ) => Promise<void>;
};

export const TradingContext = React.createContext({} as TradingContextProps);

const TradingProvider = ({ children }: TradingProviderProps) => {
  const _teamsService = new TeamsService();
  const _notificationService = new NotificationService();
  const { showToast } = useContext(ToastContext);

  const [processingTradeRequest, setProcessingTradeRequest] = useState(false);
  const [tradeByTeam, setTradeByTeam] = useState<iTeam>();
  const [tradeToTeam, setTradeToTeam] = useState<iTeam>();

  const sendTradeRequest = async (
    props: {
      otherLeagueMemberDetails: iLeagueMemberSquadDb[] | undefined;
      league: iLeague;
      tradeToTeam: iTeam;
      tradeByTeam: iTeam;
      userDetails: iUser;
      season: number;
      currentWeek: number;
    },
    successCallback: () => void,
  ) => {
    setProcessingTradeRequest(true);
    const teamOwnerSquad = props.otherLeagueMemberDetails?.find(
      (leagueMemberSquad: iLeagueMemberSquadDb) => {
        return leagueMemberSquad.teams.includes('team-' + props.tradeToTeam.id);
      },
    );

    if (teamOwnerSquad?.memberId) {
      const requestedTeamOwner = props.league.members.find((member: iUser) => {
        if (member.id) {
          return member.id === teamOwnerSquad?.memberId;
        }
        return false;
      });

      if (requestedTeamOwner) {
        try {
          // TODO Send the year in here
          await _teamsService.saveRequest(
            props.league.id,
            props.league.title,
            props.userDetails,
            [requestedTeamOwner.id, props.userDetails.id],
            {
              id: props.tradeToTeam.id,
              school: props.tradeToTeam.school,
              logos: props.tradeToTeam.logos,
              nextGame: props.tradeToTeam.nextGame,
            },
            {
              id: props.tradeByTeam.id,
              school: props.tradeByTeam.school,
              logos: props.tradeByTeam.logos,
              nextGame: props.tradeByTeam.nextGame,
            },
            RequestType.trade,
            requestedTeamOwner,
            props.season,
            props.currentWeek,
          );
          showToast({
            messageType: 'info',
            message: `Trade request sent to ${requestedTeamOwner.fullName}`,
            dataTag: 'trade_request',
          });

          if (requestedTeamOwner.playerId) {
            // Send notification
            _notificationService.tradeRequestSent(
              requestedTeamOwner.playerId,
              props.league.title,
            );
          }
          successCallback();
        } catch (e: any) {
          showToast({ messageType: 'error', message: e });
        }
      }
    }
    setProcessingTradeRequest(false);
  };

  return (
    <TradingContext.Provider
      value={{
        processingTradeRequest,
        setProcessingTradeRequest,
        tradeToTeam,
        setTradeToTeam,
        tradeByTeam,
        setTradeByTeam,
        sendTradeRequest,
      }}
    >
      {children}
    </TradingContext.Provider>
  );
};

export default TradingProvider;
