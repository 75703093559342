import { iLeagueMemberSquadDb } from '@shared/shared-utils/models';

type LeagueRecordProps = {
  stylingClasses?: {
    titleWLT?: string;
    record?: string;
  };
  results: {
    wins: number;
    losses: number;
  };
};

const LeagueRecord = (props: LeagueRecordProps) => {
  return (
    <div className="flex flex-col" data-e2e="user_record_in_league">
      <p className={`${props.stylingClasses?.titleWLT}`}>Wins | Losses </p>
      <p className={`${props.stylingClasses?.record}`}>
        {props?.results.wins ? props?.results.wins : 0} |{' '}
        {props.results.losses ? props.results.losses : 0}
      </p>
    </div>
  );
};

export default LeagueRecord;
