import { Grid, SearchContext } from '@giphy/react-components';
import { useContext, useEffect, useRef, useState } from 'react';

type GiphyGifsProps = {
  onGifClick: (url: string) => void;
};

const GiphyGifs = (props: GiphyGifsProps) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState<number>();

  // Gets the width of the parent div and sets <Grid/> width to match
  useEffect(() => {
    setParentWidth(parentRef.current?.clientWidth);
  }, []);

  return (
    <div ref={parentRef}>
      <Grid
        key={searchKey}
        columns={2}
        width={parentWidth ?? 300}
        fetchGifs={() => fetchGifs(0)}
        gutter={6}
        onGifClick={(gif, e) => {
          e.preventDefault();
          props.onGifClick(gif.images.original.url);
        }}
      />
    </div>
  );
};

export default GiphyGifs;
