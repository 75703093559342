import {
  iTradeRequest,
  RequestStatus,
  RequestType,
} from '@shared/shared-utils/models';
import { AuthContext } from '../../../../providers/auth-provider';
import { TeamsContext } from '../../../../providers/teams-provider';
import { ReactNode, useContext, useEffect, useState } from 'react';
import ListItem from '../../../../components/widgets/list-widget/list-item';
import ListWidget from '../../../../components/widgets/list-widget/list-widget';
import { requestsFilterOptions } from '../notifications-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RequestDetails } from '../../../../components/modals/request-details/request-details';
import { ToastContext } from '../../../../providers/toast-provider';
import Moment from 'react-moment';
import Image from '../../../../components/widgets/image';
import { LeagueContext } from '../../../../providers/league-provider';

const ManageRequests = () => {
  const { getRequestsForUser, acceptRequest, rejectRequest, cancelRequest } =
    useContext(TeamsContext);
  const { userDetails } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { appConfig } = useContext(LeagueContext);

  const [filteredRequestListItems, setFilteredRequestListItems] = useState<
    ReactNode[]
  >([]);
  const [allUserRequests, setAllUserRequests] = useState<iTradeRequest[]>();
  const [selectedRequestStatus, setSelectedRequestStatus] = useState<string>();
  const [selectedRequest, setSelectedRequest] = useState<iTradeRequest>();
  const [showRequestPopup, setShowRequestPopup] = useState<boolean>(false);

  const getMyRequests = async () => {
    if (userDetails?.id) {
      const requests = await getRequestsForUser(userDetails.id);
      setAllUserRequests(requests);
    }
  };

  const filterRequests = async (requestList: iTradeRequest[]) => {
    const requestItems = requestList
      .filter(
        (request) =>
          request.status === selectedRequestStatus &&
          request.type === RequestType.trade,
      )
      .map((request: iTradeRequest) => createRequestItem(request));
    if (requestItems.length === 0) {
      requestItems.push(
        <ListItem
          children={<div>No {selectedRequestStatus} requests found.</div>}
        />,
      );
    }
    console.log('ITEMS', requestItems);
    setFilteredRequestListItems(requestItems);
  };

  const createRequestItem = (request: iTradeRequest) => {
    return (
      <ListItem
        children={
          <div
            className={`flex items-center justify-between ${
              request.status === RequestStatus.pending &&
              new Date() < request.expiryDate.toDate()
                ? 'cursor-pointer'
                : ''
            }`}
            onClick={() => {
              if (
                request.status === RequestStatus.pending &&
                new Date() < request.expiryDate.toDate()
              ) {
                setSelectedRequest(request);
                setShowRequestPopup(true);
              }
            }}
          >
            <div className="flex items-center justify-start">
              <Image
                key={request.requesterDetails.id}
                imageUrl={request.requesterDetails.profileImage}
                title={request.requesterDetails.fullName}
                size={12}
                specialClass="mr-2"
              />
              <div>
                <div className="text-base">
                  {request.type === RequestType.trade ? (
                    <div data-e2e="notifications_trade">
                      {request.requesterDetails.fullName} wants to trade{' '}
                      <b>{request.tradedTeam.school}</b> for{' '}
                      {request.requestedTeamOwner?.fullName}
                      {"'s "}
                      <b>{request.requestedTeam.school}</b>
                    </div>
                  ) : (
                    <div>
                      {request.requesterDetails.fullName} wants to pick{' '}
                      {request.requestedTeam.school} for{' '}
                      {request.tradedTeam.school}
                    </div>
                  )}
                </div>
                <div>
                  {request.status === RequestStatus.pending ? (
                    <span>
                      Expires on{' '}
                      <Moment format="MMMM DD, YYYY h:mm A">
                        {request.expiryDate.toDate()}
                      </Moment>
                    </span>
                  ) : (
                    <div className="capitalize">{selectedRequestStatus}</div>
                  )}
                </div>
              </div>
            </div>
            {request.status === RequestStatus.pending &&
              new Date() < request.expiryDate.toDate() && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="text-white fa-xl"
                />
              )}
          </div>
        }
      />
    );
  };

  const acceptSelectedRequest = async () => {
    if (selectedRequest) {
      try {
        await acceptRequest(selectedRequest, appConfig.currentWeek);
        setShowRequestPopup(false);
        // Show different toast if it is processing
        showToast({
          messageType: 'info',
          message: 'Request accepted successfully.',
          dataTag: 'accepted_request',
        });
        getMyRequests();
      } catch (e: any) {
        setShowRequestPopup(false);
        showToast({ messageType: 'error', message: e, autoHideDuration: 5000 });
        getMyRequests();
      }
    }
  };

  const rejectSelectedRequest = async () => {
    if (selectedRequest) {
      try {
        await rejectRequest(selectedRequest);
        setShowRequestPopup(false);
        showToast({
          messageType: 'info',
          message: 'Request rejected successfully.',
          dataTag: 'rejected_request',
        });
        getMyRequests();
      } catch (e: any) {
        setShowRequestPopup(false);
        showToast({ messageType: 'error', message: e });
        getMyRequests();
      }
    }
  };

  const cancelSelectedRequest = async () => {
    if (selectedRequest) {
      try {
        await cancelRequest(selectedRequest);
        setShowRequestPopup(false);
        showToast({
          messageType: 'info',
          message: 'Request cancelled successfully.',
        });
        getMyRequests();
      } catch (e: any) {
        setShowRequestPopup(false);
        showToast({ messageType: 'error', message: e });
        getMyRequests();
      }
    }
  };

  useEffect(() => {
    if (selectedRequestStatus && allUserRequests) {
      filterRequests(allUserRequests);
    }
  }, [allUserRequests, selectedRequestStatus]);

  useEffect(() => {
    getMyRequests();
  }, []);

  return (
    <div className="my-5">
      <ListWidget
        items={filteredRequestListItems}
        label={'Requests'}
        filterOptions={requestsFilterOptions}
        filterInitialValue={RequestStatus.pending}
        filterPlaceholder={RequestStatus.pending}
        onFilterChange={(optionSelected) => {
          if (
            optionSelected &&
            optionSelected.value &&
            selectedRequestStatus !== optionSelected.value
          ) {
            setSelectedRequestStatus(optionSelected.value);
          }
        }}
        loading={!allUserRequests}
      />
      {selectedRequest && showRequestPopup && (
        <RequestDetails
          request={selectedRequest}
          onCloseClick={() => {
            setShowRequestPopup(false);
          }}
          onAcceptClick={() => {
            acceptSelectedRequest();
          }}
          onRejectClick={() => {
            rejectSelectedRequest();
          }}
          onCancelRequestClick={() => {
            cancelSelectedRequest();
          }}
        />
      )}
    </div>
  );
};

export default ManageRequests;
