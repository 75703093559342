import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import {
  iUser,
  iLeagueMemberSquadDb,
  iMatchupSquadDB,
  lineupSpots,
  iTradeRequest,
  iTransferPortalRequest,
  RequestStatus,
  RequestType,
  iBasicTeamDetails,
  iTeam,
  iGameResults,
  iSeasonResults,
  iSmallGame,
} from '@shared/shared-utils/models';
import { DBCollections } from '@shared/shared-utils';
import { hasGameStarted } from '../utils/game-utils';
import { DBFirebaseDB } from './firebase-core';
import { LeagueService } from './league-service';

export class TeamsService {
  private _DBTeamsCollection = collection(
    DBFirebaseDB,
    DBCollections.teamsMetaData,
  );
  private _DBRequestsCollection = collection(
    DBFirebaseDB,
    DBCollections.requests,
  );
  private _DBResultsCollection = collection(
    DBFirebaseDB,
    DBCollections.results,
  );
  private _DBTeamData = collection(DBFirebaseDB, DBCollections.teamData);

  private _leagueService = new LeagueService();

  private gameResultsCache: { week: number; cachedResults: iGameResults[] } = {
    week: 0,
    cachedResults: [],
  };

  private seasonResultsCache: iSeasonResults = {};

  public getAllTeams = async () => {
    try {
      // pull data from new collection and create expected teamsMetaData
      const metaDataRef = doc(this._DBTeamData, 'AllTeamsMetaData');
      const metaDataSnapshot = await getDoc(metaDataRef);

      const teamMetaData = metaDataSnapshot.data() as { [key: string]: iTeam };
      // get the meta data first

      // get schedules for 2022, 2023, 2024
      const schedule22Ref = doc(this._DBTeamData, '2022-schedule');
      const schedule22Snapshot = await getDoc(schedule22Ref);

      const schedule22Data = schedule22Snapshot.data() as {
        [key: number]: { [key: string]: iSmallGame };
      };
      const schedule23Ref = doc(this._DBTeamData, '2023-schedule');
      const schedule23Snapshot = await getDoc(schedule23Ref);

      const schedule23Data = schedule23Snapshot.data() as {
        [key: number]: { [key: string]: iSmallGame };
      };
      const schedule24Ref = doc(this._DBTeamData, '2024-schedule');
      const schedule24Snapshot = await getDoc(schedule24Ref);

      const schedule24Data = schedule24Snapshot.data() as {
        [key: number]: { [key: string]: iSmallGame };
      };

      // iterate through the teamMetaData object and add the schedules
      const allTeams: iTeam[] = [];
      Object.keys(teamMetaData).forEach((key) => {
        const team = teamMetaData[key];
        team['2022-schedule'] = schedule22Data[team.id];
        team['2023-schedule'] = schedule23Data[team.id];
        team['2024-schedule'] = schedule24Data[team.id];

        if (!team.logos) {
          console.log('no logo', team);
        } else {
          allTeams.push(teamMetaData[team.id]);
        }
      });

      return allTeams;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  /**
   * Gets game results totals for the whole season
   */
  public getGameResultsBySeason = async (season: number) => {
    if (Object.values(this.seasonResultsCache).length > 0) {
      return this.seasonResultsCache;
    } else {
      const seasonTotalsDoc = doc(this._DBResultsCollection, `${season}-total`);
      const seasonResultsSnap = await getDoc(seasonTotalsDoc);
      const results = seasonResultsSnap.data() as iSeasonResults;
      return results;
    }
  };

  /**
   * Gets game results totals for a given week in a given season
   */
  public getGameResults = async (week: number, season: number) => {
    if (
      this.gameResultsCache?.week === week &&
      this.gameResultsCache.cachedResults.length
    ) {
      //console.log('read from cache - getGameResults()');
      return this.gameResultsCache.cachedResults;
    }

    //console.log('read - getGameResults()');
    const gameResultRef = doc(this._DBResultsCollection, season + '-' + week);

    const gameResultSnapshot = await getDoc(gameResultRef);
    return gameResultSnapshot.data() as iGameResults[];
  };

  /**
   * Used to get the results only for an array of weeks in a season.
   * Used to pull only the results for the weeks in which a given team played.
   */
  public getResultsBySeasonWeekIds = async (seasonWeeks: string[]) => {
    // Query below only allows the array to be max of 10 items
    // Break array into chunks of 10
    const chunks = [];
    for (let i = 0; i < seasonWeeks.length; i += 10) {
      chunks.push(seasonWeeks.slice(i, i + 10));
    }

    const promises = chunks.map((chunk) =>
      query(this._DBResultsCollection, where(documentId(), 'in', chunk)),
    );

    const allResults: iGameResults[] = [];

    await Promise.all(
      promises.map(async (promise) => {
        const snap = await getDocs(promise);
        snap.forEach((doc) => allResults.push(doc.data()));
      }),
    );
    return allResults;
  };

  public saveRequest = async (
    leagueId: string,
    leagueTitle: string,
    requester: iUser,
    approverIds: string[],
    requestedTeamDetails: iBasicTeamDetails,
    tradedTeamDetails: iBasicTeamDetails,
    requestType: RequestType.trade,
    requestedTeamOwner: iUser,
    season: number,
    cuurentWeek: number,
  ) => {
    const newDocRef = doc(this._DBRequestsCollection);
    const now = new Date();
    const expiryDate = new Date();
    const expiryDays = 48 / 24;
    expiryDate.setDate(expiryDate.getDate() + expiryDays);
    const request: iTradeRequest = {
      id: newDocRef.id,
      requesterDetails: requester,
      approvers: requestType === RequestType.trade ? approverIds : [],
      createdDate: now,
      expiryDate: expiryDate,
      leagueId: leagueId,
      leagueTitle: leagueTitle,
      requestedTeamOwner: requestedTeamOwner,
      requestedTeam: requestedTeamDetails,
      tradedTeam: tradedTeamDetails,
      status: RequestStatus.pending,
      type: requestType,
    };

    await setDoc(newDocRef, request);
  };

  public processTransferPortalRequest = async (
    leagueId: string,
    leagueTitle: string,
    requester: iUser,
    requestedTeamDetails: iBasicTeamDetails,
    tradedTeamDetails: iBasicTeamDetails,
    requestType: RequestType.freeAgent,
    currentWeek: number,
  ) => {
    const newDocRef = doc(this._DBRequestsCollection);
    const now = new Date();
    const expiryDate = new Date();
    const expiryDays = 48 / 24;
    expiryDate.setDate(expiryDate.getDate() + expiryDays);

    // TODO: Clean this up to make sense for transfer portal requests
    const request: iTransferPortalRequest = {
      id: newDocRef.id,
      requesterDetails: requester,
      createdDate: now,
      expiryDate: expiryDate,
      leagueId: leagueId,
      leagueTitle: leagueTitle,
      requestedTeam: requestedTeamDetails,
      tradedTeam: tradedTeamDetails,
      status: RequestStatus.accepted,
      type: requestType,
    };

    // TODO: Is this needed? It seems like its just getting the membersSquad
    const leagueDetails = await this._leagueService.getLeagueById(
      request.leagueId,
    );

    if (leagueDetails.memberIds && leagueDetails.id) {
      const leagueMemberTeams: any[] = [];
      for (let i = 0; i < leagueDetails.memberIds.length; i++) {
        const memberDetails = await this._leagueService.getLeagueMemberSquad(
          leagueDetails.id,
          leagueDetails.memberIds[i],
        );
        if (memberDetails?.teams) {
          memberDetails.teams.forEach((teamId) => {
            leagueMemberTeams.push({ id: teamId.split('-')[1] });
          });
        }
      }

      const itemFound = leagueMemberTeams.find((team: iTeam) => {
        return Number(team.id) === Number(request.requestedTeam.id);
      });
      if (itemFound) {
        return Promise.reject(
          'Requested team is already on another squad. Transfer cancelled.',
        );
      }
    }

    const userSquad = await this._leagueService.getLeagueMemberSquad(
      request.leagueId,
      request.requesterDetails.id,
    );

    const teamLeavingRoster = request.tradedTeam;
    const teamGoingToRoster = request.requestedTeam;

    const teamLeavingIndex = userSquad.teams.indexOf(
      'team-' + teamLeavingRoster.id,
    );

    // Make sure the team is still on your squad that you are trying to drop
    if (
      teamLeavingIndex === -1 ||
      isTeamPartOfAnotherTrade(userSquad, teamLeavingRoster.id)
    ) {
      return Promise.reject(
        'Team not found or invalid. Request has been cancelled!',
      );
    }

    // Check for locked teams
    if (
      hasGameStarted(teamLeavingRoster.nextGame?.start_date) ||
      hasGameStarted(teamGoingToRoster.nextGame?.start_date)
    ) {
      return Promise.reject(
        'One or both teams has already played. Request has been cancelled!',
      );
    }

    // Replace the team on the roster
    const updatedUserTeams = [...userSquad.teams];

    updatedUserTeams[teamLeavingIndex] = 'team-' + teamGoingToRoster.id;

    // Create the request in the DB
    await setDoc(newDocRef, request);

    return await this.processTradeRequest(
      request.leagueId,
      request.requesterDetails.id,
      currentWeek,
      userSquad,
      updatedUserTeams,
      teamLeavingRoster,
      teamGoingToRoster,
    );
  };

  public rejectRequest = async (request: iTradeRequest) => {
    const dbRequest = await this.getRequestById(request.id);

    if (dbRequest.status !== RequestStatus.pending) {
      return Promise.reject(
        `Invalid action, request is already ${dbRequest.status}.`,
      );
    }

    const requesterUserSquad = await this._leagueService.getLeagueMemberSquad(
      request.leagueId,
      request.requesterDetails.id,
    );
    const updatedRequestedUserTeams = [...requesterUserSquad.teams];
    const teamFoundIndex = updatedRequestedUserTeams.indexOf(
      'team-' + request.tradedTeam.id,
    );

    if (teamFoundIndex !== -1) {
      updatedRequestedUserTeams[teamFoundIndex] =
        'team-' + request.requestedTeam.id;
    } else {
      await this.updateRequestStatus(request.id, RequestStatus.expired);
      return Promise.reject(
        'Traded team not found or Invalid. Hence, request has been expired!',
      );
    }

    return await this.updateRequestStatus(request.id, RequestStatus.rejected);
  };

  public cancelRequest = async (request: iTradeRequest) => {
    const dbRequest = await this.getRequestById(request.id);

    if (dbRequest.status !== RequestStatus.pending) {
      return Promise.reject(
        `Invalid action, request is already ${dbRequest.status}.`,
      );
    }

    const requesterUserSquad = await this._leagueService.getLeagueMemberSquad(
      request.leagueId,
      request.requesterDetails.id,
    );
    const updatedRequestedUserTeams = [...requesterUserSquad.teams];
    const teamFoundIndex = updatedRequestedUserTeams.indexOf(
      'team-' + request.tradedTeam.id,
    );

    if (teamFoundIndex !== -1) {
      updatedRequestedUserTeams[teamFoundIndex] =
        'team-' + request.requestedTeam.id;
    } else {
      await this.updateRequestStatus(request.id, RequestStatus.expired);
      return Promise.reject(
        'Traded not found or Invalid. Hence, request has been expired!',
      );
    }

    return await this.updateRequestStatus(request.id, RequestStatus.cancelled);
  };

  /*
  requester - the person who sent the request
  requestedTeam - the team that the requester is trying to get (requestedTeamOwner currently has them)
  tradedTeam - The team the requester is losing
  requestedTeamOwner - The person receiving the trade
  */
  public acceptRequest = async (
    request: iTradeRequest,
    currentWeek: number,
  ) => {
    // Confirm the trade request is valid
    // If not, throw an error and set the request to denied
    const dbRequest = await this.getRequestById(request.id);

    if (dbRequest.status !== RequestStatus.pending) {
      return Promise.reject(
        `Invalid action, request is already ${dbRequest.status}.`,
      );
    }

    const teamLeavingRequester = request.tradedTeam;
    const teamGoingToRequester = request.requestedTeam;

    // Pull both users teams and make sure the desired teams are still rostered
    // If either doesn't exist, throw an error and set the request to denied

    const callsToMake: [
      Promise<iLeagueMemberSquadDb>,
      Promise<iLeagueMemberSquadDb>,
    ] = [
      this._leagueService.getLeagueMemberSquad(
        request.leagueId,
        request.requestedTeamOwner.id,
      ),
      this._leagueService.getLeagueMemberSquad(
        request.leagueId,
        request.requesterDetails.id,
      ),
    ];

    const [requestedUserSquad, requesterUserSquad] =
      await Promise.all(callsToMake);

    const teamLeavingRequesterIndex = requesterUserSquad.teams.indexOf(
      'team-' + teamLeavingRequester.id,
    );

    const teamGoingToRequesterIndex = requestedUserSquad.teams.indexOf(
      'team-' + teamGoingToRequester.id,
    );

    // check if either team is missing. Cancel if either are
    if (teamLeavingRequesterIndex === -1 || teamGoingToRequesterIndex === -1) {
      await this.updateRequestStatus(request.id, RequestStatus.cancelled);
      return Promise.reject(
        'Traded team not found or Invalid. Request has been cancelled!',
      );
    }

    // Check if the teams have already been traded
    if (
      isTeamPartOfAnotherTrade(requestedUserSquad, teamGoingToRequester.id) ||
      isTeamPartOfAnotherTrade(requesterUserSquad, teamLeavingRequester.id)
    ) {
      await this.updateRequestStatus(request.id, RequestStatus.cancelled);
      return Promise.reject(
        'Traded team part of another trade. Request has been cancelled!',
      );
    }

    // Replace each team in the roster
    const updatedRequestedUserTeams = [...requestedUserSquad.teams];
    const updatedRequesterUserTeams = [...requesterUserSquad.teams];

    updatedRequesterUserTeams[teamLeavingRequesterIndex] =
      'team-' + teamGoingToRequester.id;

    updatedRequestedUserTeams[teamGoingToRequesterIndex] =
      'team-' + teamLeavingRequester.id;

    const requesterCurrentWeek = requesterUserSquad.matchupSquad[currentWeek];
    const requestedCurrentWeek = requestedUserSquad.matchupSquad[currentWeek];

    // Check if at least one of the teams has played and that they aren't both on the bench
    if (
      (hasGameStarted(teamLeavingRequester.nextGame?.start_date) ||
        hasGameStarted(teamGoingToRequester.nextGame?.start_date)) &&
      !(
        isTeamOnBench(requesterCurrentWeek, teamLeavingRequester.id) &&
        isTeamOnBench(requestedCurrentWeek, teamGoingToRequester.id)
      )
    ) {
      const callsToMake = [
        this._leagueService.addTradedTeamsToSquad(
          request.leagueId,
          request.requestedTeamOwner.id,
          'team-' + teamLeavingRequester.id,
          'team-' + teamGoingToRequester.id,
          requestedUserSquad,
        ),
        this._leagueService.addTradedTeamsToSquad(
          request.leagueId,
          request.requesterDetails.id,
          'team-' + teamGoingToRequester.id,
          'team-' + teamLeavingRequester.id,
          requesterUserSquad,
        ),
      ];

      // Set them as traded on the DB
      await Promise.all(callsToMake);

      // Change the request to "processing" and save the request
      // We will handle this when we progress the week
      return await this.updateRequestStatus(
        request.id,
        RequestStatus.processing,
      );
    }

    // Teams are unlocked and ready to be set

    const requestsToMake = [
      this.processTradeRequest(
        request.leagueId,
        request.requesterDetails.id,
        currentWeek,
        requesterUserSquad,
        updatedRequesterUserTeams,
        teamLeavingRequester,
        teamGoingToRequester,
      ),
      this.processTradeRequest(
        request.leagueId,
        request.requestedTeamOwner.id,
        currentWeek,
        requestedUserSquad,
        updatedRequestedUserTeams,
        teamGoingToRequester,
        teamLeavingRequester,
      ),
    ];

    // TODO the return of this can be verified by our tests
    await Promise.all(requestsToMake);
    return await this.updateRequestStatus(request.id, RequestStatus.accepted);
  };

  public updateRequestStatus = async (
    requestId: string,
    requestStatus: string,
  ) => {
    const requestRef = doc(this._DBRequestsCollection, requestId);
    return await setDoc(requestRef, { status: requestStatus }, { merge: true });
  };

  public getUserRequests = async (userId: string) => {
    //console.log('read - getUserRequests()');
    const userRequestsQuery = query(
      this._DBRequestsCollection,
      where('approvers', 'array-contains', userId),
    );
    const userRequestsSnapshotList = await getDocs(userRequestsQuery);

    if (userRequestsSnapshotList.empty) {
      return [];
    }

    const userRequests: iTradeRequest[] = [];
    userRequestsSnapshotList.forEach((doc) => {
      if (doc.exists()) {
        userRequests.push(doc.data() as iTradeRequest);
      }
    });

    const allUserRequests = userRequests.map((request) => {
      if (request.status === RequestStatus.pending) {
        if (request.expiryDate.toDate() < new Date()) {
          // trade expired so update status
          this.updateRequestStatus(request.id, RequestStatus.expired);
          request.status = RequestStatus.expired;
          return request;
        }
      }
      return request;
    });

    return allUserRequests;
  };

  public getRequestById = async (requestId: string) => {
    //console.log('read - getRequestById()');
    const userRequestRef = doc(this._DBRequestsCollection, requestId);
    const userRequest = await getDoc(userRequestRef);
    return userRequest.data() as iTradeRequest;
  };

  private processTradeRequest = async (
    leagueId: string,
    userId: string,
    currentWeek: number,
    userSquad: iLeagueMemberSquadDb,
    updatedUserTeams: string[],
    teamLeaving: iBasicTeamDetails,
    incomingTeam: iBasicTeamDetails,
  ) => {
    // Set up our variables
    const newMatchupSquad = { ...userSquad.matchupSquad };

    const currentWeekSquad = userSquad.matchupSquad[currentWeek];

    newMatchupSquad[currentWeek] = replaceTradedTeam(
      currentWeekSquad,
      'team-' + incomingTeam.id,
      'team-' + teamLeaving.id,
    );

    // Requested Team
    return await this._leagueService.addOrUpdateLeagueMemberSquad(
      leagueId,
      userId,
      true,
      updatedUserTeams,
      newMatchupSquad,
    );
  };
}

const replaceTradedTeam = (
  squad: iMatchupSquadDB,
  incomingTeam: string,
  leavingTeam: string,
) => {
  const updatedSquad = { ...squad };
  // look at the lineup first
  lineupSpots.forEach((spot) => {
    if ((squad[spot as keyof iMatchupSquadDB] as string) === leavingTeam) {
      // replace to the same spot as the leaving
      (updatedSquad[spot as keyof iMatchupSquadDB] as string) = incomingTeam;
    }
  });

  // Look through bench array
  const index = squad.benchedTeams.indexOf(leavingTeam);
  if (index > -1) {
    // Found it, remove leaving and add incoming
    updatedSquad.benchedTeams.splice(index, 1);
    updatedSquad.benchedTeams.push(incomingTeam);
  }

  return updatedSquad;
};

const isTeamOnBench = (squad: iMatchupSquadDB, teamId: number) => {
  const index = squad.benchedTeams.indexOf('team-' + teamId);

  return index !== -1 ? true : false;
};

const isTeamPartOfAnotherTrade = (
  squad: iLeagueMemberSquadDb,
  teamId: number,
) => {
  if (!squad.tradedTeams?.teamsLeaving) {
    return false;
  } else {
    return squad.tradedTeams.teamsLeaving.indexOf('team-' + teamId) > -1;
  }
};
