import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from '@mui/material';
import { iLeague, iUser, leagueDraftStatus } from '@shared/shared-utils/models';
import DraftOptions, { DraftOptionsEnum } from './options';
import DraftOrder from './order';
import RoundSelect from './round-select';
import { ConfirmDialog } from '../operations-draft-league';

type DraftDetailsProps = {
  setShowConfirmationDialogType: (type: ConfirmDialog) => void;
  currentLeague: iLeague;
  optionsDisabled: boolean;
  handleRedraftLeagueClick: () => void;
  selectedDraftOption: DraftOptionsEnum;
  setSelectedDraftOption: (option: DraftOptionsEnum) => void;
  beginDraftClick: () => void;
  expanded: boolean;
  setNewDraftOrder: (order: iUser[]) => void;
  onExpand: () => void;
  setSelectedRound: (round: number) => void;
  selectedRound: number;
};

const DraftDetails = (props: DraftDetailsProps) => {
  return (
    <Accordion expanded={props.expanded} onChange={props.onExpand}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
        aria-controls="draft-details-content"
        id="draft-details-header"
      >
        <Typography component="span">Draft Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col mt-2">
          <div className="flex justify-start my-4">
            <Button
              onClick={() =>
                props.setShowConfirmationDialogType(ConfirmDialog.clear)
              }
              color="error"
            >
              Clear Team
            </Button>
          </div>
          <div className="flex justify-between items-center w-full">
            <div>
              <span>Current Draft Status: </span>
              <span>{props.currentLeague.draftStatus}</span>
            </div>
            <Button
              variant="contained"
              size="small"
              disabled={
                props.currentLeague.draftStatus !== leagueDraftStatus.done
              }
              onClick={props.handleRedraftLeagueClick}
            >
              Redraft League
            </Button>
          </div>
          <DraftOrder
            leagueMembers={props.currentLeague.members}
            disabled={props.optionsDisabled}
            onReorder={props.setNewDraftOrder}
          />

          <DraftOptions
            selectedOption={props.selectedDraftOption}
            setSelectedOption={props.setSelectedDraftOption}
            disabled={props.optionsDisabled}
          />
          {props.selectedDraftOption === DraftOptionsEnum.partial && (
            <RoundSelect
              disabled={props.optionsDisabled}
              draftSettings={props.currentLeague.settings}
              onSelectRound={props.setSelectedRound}
              selectedRound={props.selectedRound}
            />
          )}
          <div className="w-full mt-2 flex justify-center">
            <Button
              color="primary"
              onClick={props.beginDraftClick}
              variant="contained"
              // Disabled if number of members is 0 or odd
              disabled={
                props.currentLeague.members.length === 0 ||
                props.currentLeague.members.length % 2 !== 0
              }
            >
              Begin Draft
            </Button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default DraftDetails;
