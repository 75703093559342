import { allTrophies, iTrophy, iTrophyConst } from '@shared/shared-utils/models';

export const getTrophyById = (trophyType: string, userProgress?: number) => {
  let trophy;
  switch (trophyType) {
    case allTrophies['adminSB'].trophyType:
      trophy = allTrophies['adminSB'];
      break;
    case allTrophies['allConferencePatreon1'].trophyType:
      trophy = allTrophies['allConferencePatreon1'];
      break;
    case allTrophies['leagueWinner2023'].trophyType:
      trophy = allTrophies['leagueWinner2023'];
      break;
    case allTrophies['leagueWinner2024'].trophyType:
      trophy = allTrophies['leagueWinner2024'];
      break;
    case allTrophies['allAmericanPatreon2'].trophyType:
      trophy = allTrophies['allAmericanPatreon2'];
      break;
    case allTrophies['joinedRandomLeague'].trophyType:
      trophy = getPathFromProgressStatus(allTrophies['joinedRandomLeague'], userProgress) as iTrophy;
      // trophy = allTrophies['joinedRandomLeague'];
      break;
    case allTrophies['giveawayWinner'].trophyType:
      trophy = allTrophies['giveawayWinner'];
      break;
    case allTrophies['matchupBlowout'].trophyType:
      trophy = allTrophies['matchupBlowout'];
      break;
    case allTrophies['matchupCupcakes'].trophyType:
      trophy = getPathFromProgressStatus(allTrophies['matchupCupcakes'], userProgress) as iTrophy;
      break;
    case allTrophies['leagueSecond2023'].trophyType:
      trophy = allTrophies['leagueSecond2023'];
      break;
    case allTrophies['leagueSecond2024'].trophyType:
      trophy = allTrophies['leagueSecond2024'];
      break;
    case allTrophies['leagueThird2023'].trophyType:
      trophy = allTrophies['leagueThird2023'];
      break;
    case allTrophies['leagueThird2024'].trophyType:
      trophy = allTrophies['leagueThird2024'];
      break;
    case allTrophies['gameChanger'].trophyType:
      trophy = allTrophies['gameChanger'];
      break;
    case allTrophies['flagOnThePlay'].trophyType:
      trophy = allTrophies['flagOnThePlay'];
      break;
    case allTrophies['publicHero'].trophyType:
      trophy = getPathFromProgressStatus(allTrophies['publicHero'], userProgress) as iTrophy;
      break;
    default: trophy = allTrophies['adminSB'];
  }
  return trophy as iTrophy;
};

// Get the icon path from the trophy const based on the userProgress
const getPathFromProgressStatus = (trophy: iTrophyConst, userProgress?: number) => {

  // This should never be an issue since this is only called on progress trophies
  if (trophy.progress) {
    const progressLevel = userProgress && userProgress > 1 ? getLevelFromProgress(userProgress, trophy.progress) : 1;
    const trophyWithPath: iTrophy = {
      trophyType: trophy.trophyType,
      title: trophy.title,
      description: trophy.description,
      path: trophy.progress[progressLevel].path,
      pending: trophy.pending || false
    };
    return trophyWithPath;
    // It should never reach this path since we are only using this on the progress trophies in the switch statement
  } else {
    return;
  }
};

// Only called with a userProgress > 1
const getLevelFromProgress = (userProgress: number, trophyProgress: {
  [key: number]: {
    path: string;
  };
}) => {
  const progressLevels = Object.keys(trophyProgress);
  let level = 1;
  // Goes through each progress level to determine which level the userProgress is
  progressLevels.forEach((progressLevel) => {
    if (userProgress >= +progressLevel && userProgress >= level) {
      level = +progressLevel;
    }
  });
  return level;
};

// TODO: make sure this works with new seasons
export const getTrophyFromRank = (userRank: string) => {
  // Make sure the rank is usable for this
  if (userRank === 'first' || userRank === 'second' || userRank === 'third') {
    let trophyToShow = null;
    switch (userRank) {
      case 'first':
        trophyToShow = getTrophyById('leagueWinner2023');
        break;
      case 'second':
        trophyToShow = getTrophyById('leagueSecond2023');
        break;
      case 'third':
        trophyToShow = getTrophyById('leagueThird2023');
        break;
      default:
        break;
    }
    return trophyToShow;
  } else {
    return null;
  }
};
