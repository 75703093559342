import { useNavigate } from 'react-router-dom';

const useUserDetailsNavigation = () => {
  const navigate = useNavigate();

  const userNavigation = (userId: string) => {
    navigate(`/user-details/${userId}`);
  };

  return { userNavigation };
};

export default useUserDetailsNavigation;
