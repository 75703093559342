import { useState } from 'react';
import TabHeader from '../../../components/ui/tabs/tab-header';
import Header from '../../../components/widgets/header/header';
import ManageRequests from './widgets/requests';

export function Notifications() {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  return (
    <div id="db-notifications" className="football-bg">
      <Header
        title={'Notifications'}
        isHome={false}
        subContent={
          <TabHeader
            selectedTabIndex={selectedTabIndex}
            tabItems={[
              { key: 'clRequests', text: 'Trade Requests', customClass: '' },
            ]}
            onTabChange={(selectedIndex) => {
              setSelectedTabIndex(selectedIndex);
            }}
          />
        }
      />
      <div className="flex flex-1 p-4 flex-col pt-[7.8rem]">
        {selectedTabIndex === 0 && <ManageRequests />}
      </div>
    </div>
  );
}

export default Notifications;
