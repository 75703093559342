import { iTeamOwnerMap } from "@shared/shared-utils/models";

export const getTeamOwnerName = (teamId: number, teamOwnerMap: iTeamOwnerMap[]) => {
    if (teamOwnerMap) {
        const teamFound = teamOwnerMap.find(
            (teamOwnerMapItem) => teamOwnerMapItem.teamId === teamId,
        );

        if (teamFound) {
            return teamFound.ownerDetails.fullName;
        } else {
            return '--';
        }
    }
    return '--';
};