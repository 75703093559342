import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../providers/auth-provider';

export function RedirectPage() {
  const { isLoggedIn, superAdminStatus, userDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    const redirectUrl = params.get('to');
    if (!isLoggedIn) {
      if (redirectUrl) {
        localStorage.setItem('rTo', '/' + redirectUrl);
      }
      navigate('/');
    } else {
      navigate('/' + redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (!superAdminStatus) {
      navigate('/')
    }
  }, [userDetails])

  return <div>Redirecting...</div>;
}

export default RedirectPage;
