import { faArrowsRotate, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpcomingGame } from '../../ui/UpcomingGame/upcoming-game';
import { iTeam, matchupSlotTypes } from '@shared/shared-utils/models';
import { DateTime } from 'luxon';
import { Tooltip } from '@mui/material';

type UpdateSquadPositionsType = {
  team?: iTeam;
  matchupSlotType?: matchupSlotTypes;
  teamNum?: number;
  slot?: string;
  iconClicked: (slot?: string, team?: iTeam) => void;
};

const UpdateSquadPositions = (props: UpdateSquadPositionsType) => {
  return (
    <div
      className={`flex items-center justify-between ${
        props.team?.teamLocked ? 'grayscale' : 'cursor-pointer'
      }`}
      data-e2e={props.slot && props.slot}
    >
      {props.team ? (
        <Tooltip
          enterTouchDelay={0}
          title={
            props.team?.teamLocked ? "Can't change a team that has played" : ''
          }
        >
          <>
            <div className="flex items-center justify-between">
              <div
                key={props.team.id}
                style={{
                  backgroundImage: `url(${props.team.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={props.team.school}
              />
              <div className="flex flex-col">
                <p className="text-base" data-e2e="UD_positioned_team">
                  {props.team.school}
                </p>
                <div className="flex text-xs">
                  <UpcomingGame
                    teamID={props.team.id}
                    game={props.team.nextGame}
                  />
                  <div className="ml-0.5">
                    {props.team.nextGame?.start_date
                      ? DateTime.fromISO(
                          props.team.nextGame?.start_date,
                        ).toLocaleString({
                          month: 'numeric',
                          day: 'numeric',
                        })
                      : ''}{' '}
                  </div>
                </div>
                <div className="text-xs">
                  {props.team?.nextBettingLines &&
                  props.team?.nextBettingLines[0] ? (
                    <div>{props.team?.nextBettingLines[0].formattedSpread}</div>
                  ) : (
                    <div>No betting lines available</div>
                  )}
                </div>
              </div>
            </div>
            <FontAwesomeIcon
              icon={props.team?.teamLocked ? faLock : faArrowsRotate}
              className="text-white fa-xl"
              onClick={(event) => {
                if (!props.team?.teamLocked) {
                  event.stopPropagation();
                  props.iconClicked(props.slot, props.team);
                }
              }}
              data-e2e={
                props.team?.teamLocked ? 'team_locked' : 'reset_team_selection'
              }
            />
          </>
        </Tooltip>
      ) : (
        <div className="flex items-center justify-between">
          <img
            src="/assets/images/add-button.png"
            alt="add button"
            className="w-7"
          />
          <p className="ml-4 text-base" data-e2e="empty_team_slot">
            Select team {props.teamNum}
          </p>
        </div>
      )}
    </div>
  );
};

export default UpdateSquadPositions;
