import {
  iMatchupSquad,
  iMatchupSquadObj,
  matchupSlotTypes,
} from '@shared/shared-utils/models';
import UpdateSquadPositions from '../../../../../components/modals/squad-positions/update-squad-positions';
import ListItem from '../../../../../components/widgets/list-widget/list-item';
import ListWidget from '../../../../../components/widgets/list-widget/list-widget';

type UpdateSquadModalProps = {
  matchupSquad: iMatchupSquad;
  handleTeamSlotClick: (squad: iMatchupSquadObj) => void;
  handleResetSelectionClick: (slot: string, team: any) => void;
};

const UpdateSquadModal = (props: UpdateSquadModalProps) => {
  const posArray = ['Passing Squad', 'Rushing Squad', 'Defensive Squad'];
  const matchupSquadsObj: iMatchupSquadObj[] = [
    {
      matchupSquadTeam: props.matchupSquad?.ps1,
      posTitle: 'Passing Squad',
      posTeamNum: 1,
      matchupSlotType: matchupSlotTypes.ps1,
      slot: 'ps1',
    },
    {
      matchupSquadTeam: props.matchupSquad?.ps2,
      posTitle: 'Passing Squad',
      posTeamNum: 2,
      matchupSlotType: matchupSlotTypes.ps2,
      slot: 'ps2',
    },
    {
      matchupSquadTeam: props.matchupSquad?.rs1,
      posTitle: 'Rushing Squad',
      posTeamNum: 1,
      matchupSlotType: matchupSlotTypes.rs1,
      slot: 'rs1',
    },
    {
      matchupSquadTeam: props.matchupSquad?.rs2,
      posTitle: 'Rushing Squad',
      posTeamNum: 2,
      matchupSlotType: matchupSlotTypes.rs2,
      slot: 'rs2',
    },
    {
      matchupSquadTeam: props.matchupSquad?.ds1,
      posTitle: 'Defensive Squad',
      posTeamNum: 1,
      matchupSlotType: matchupSlotTypes.ds1,
      slot: 'ds1',
    },
    {
      matchupSquadTeam: props.matchupSquad?.ds2,
      posTitle: 'Defensive Squad',
      posTeamNum: 2,
      matchupSlotType: matchupSlotTypes.ds2,
      slot: 'ds2',
    },
  ];

  const createListItems = (pos: string) => {
    return matchupSquadsObj.map((squad, index: number) => {
      if (squad.posTitle === pos) {
        return [
          <ListItem
            key={index}
            children={
              <UpdateSquadPositions
                team={squad.matchupSquadTeam}
                matchupSlotType={squad.matchupSlotType}
                teamNum={squad.posTeamNum}
                slot={squad.slot}
                iconClicked={() =>
                  props.handleResetSelectionClick(
                    squad?.slot as string,
                    squad.matchupSquadTeam,
                  )
                }
              />
            }
            specialClass={
              squad.matchupSquadTeam?.nextGame?.id ? '' : 'animate-pulse'
            }
            itemClick={() => {
              props.handleTeamSlotClick(squad);
            }}
          />,
        ];
      } else {
        return null;
      }
    });
  };

  return posArray.map((pos, index: number) => (
    <div key={index} className="mt-6 mb-6">
      <ListWidget
        items={createListItems(pos).filter((item) => item != null)}
        label={`${pos}s`}
        loading={false}
      />
    </div>
  ));
};

export default UpdateSquadModal;
