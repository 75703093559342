import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { iLeagueSettings } from '@shared/shared-utils/models';
import { useEffect, useState } from 'react';

type RoundSelectProps = {
  draftSettings: iLeagueSettings;
  disabled: boolean;
  onSelectRound: (round: number) => void;
  selectedRound: number;
};

const RoundSelect = (props: RoundSelectProps) => {
  const [numDraftRounds, setNumDraftRounds] = useState<number[]>([1]);

  useEffect(() => {
    const rounds = [];
    for (let i = 0; i < (props.draftSettings.numOfTeamsPerMember ?? 8); i++) {
      rounds.push(i + 1);
    }
    setNumDraftRounds(rounds);
  }, [props.draftSettings]);

  return (
    <FormControl fullWidth>
      <InputLabel id="draft-round-select-label">
        Selected Draft Round
      </InputLabel>
      <Select
        labelId="draft-round-select-label"
        id="draft-round-select"
        value={props.selectedRound}
        onChange={(e) => props.onSelectRound(+e.target.value)}
        disabled={props.disabled}
        label="Selected Draft Round"
      >
        {numDraftRounds.map((round) => (
          <MenuItem key={round} value={round}>
            {round}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoundSelect;
