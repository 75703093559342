import {
  iDraft,
  iLeague,
  iUser,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import Image from '../../../../components/widgets/image';
import { Tooltip, Button } from '@mui/material';
import DraftCountdownTimer from '../../../../components/widgets/draft-countdown-timer';
import DraftPickTimer from '../../../../components/widgets/draft-pick-timer/draft-pick-timer';
import {
  draftDisplayTimer,
  secondsBetweenPicks,
} from '../../../../components/widgets/draft-pick-timer/helpers/seconds-between-picks';

type leagueDraftSettingsType = {
  league: iLeague;
  isCreator: boolean;
  isMember?: boolean;
  onStartDraft?: () => void;
  onAutoPickClicked?: () => void;
  draft?: iDraft;
  currentTurnUser: iUser | undefined;
  allMembersOnboarded: boolean;
  pickOrderDisplay?:
    | (JSX.Element | (JSX.Element | undefined)[])[][]
    | undefined;
};

export function LeagueDraftSettings({
  league,
  isCreator,
  onStartDraft,
  draft,
  onAutoPickClicked,
  currentTurnUser,
  allMembersOnboarded,
  pickOrderDisplay,
}: leagueDraftSettingsType) {
  const [isPickTimerExpired, setIsPickTimerExpired] = useState(false);
  const [resetPickTimer, setResetPickTimer] = useState(false);
  const [initalDisplayTimer, setInitialDisplayTimer] = useState<string>('-:--');

  //Sets the initial timer display value
  useEffect(() => {
    if (draft) {
      const secondsLeft = secondsBetweenPicks(draft.nextPickBy);
      const displaytimer = draftDisplayTimer(secondsLeft);
      setInitialDisplayTimer(displaytimer);
    }
  }, []);

  return (
    <div>
      {league?.draftStatus === leagueDraftStatus.pending && !draft && (
        <div className="mb-4">
          <div>
            <div>
              <DraftCountdownTimer
                futureDate={new Date(league?.settings?.draftDate?.toDate())}
                isLeagueOwner={isCreator}
              />
              <div className="mt-2 text-center">
                <small>
                  <em>
                    Scheduled for{' '}
                    <Moment format="MMMM DD, YYYY h:mm A">
                      {league?.settings?.draftDate?.toDate()}
                    </Moment>
                  </em>
                </small>
              </div>
            </div>
            <div>
              {isCreator && (
                <Tooltip
                  title={
                    !allMembersOnboarded &&
                    (league.settings?.numOfMembers && league.memberIds?.length
                      ? `Your league expects ${league.settings
                          ?.numOfMembers} members but only ${
                          league.memberIds?.length > 1
                            ? league.memberIds?.length + ' have'
                            : league.memberIds?.length + ' has'
                        } signed up so far. Change the settings to reflect the number of members in your league or invite more members.`
                      : 'You need to finish setting up your league before you can start the draft!')
                  }
                  enterTouchDelay={0}
                >
                  <div className="mx-4 mt-2">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: allMembersOnboarded
                          ? 'bg-accent'
                          : '#3e3e3e',
                        borderRadius: '9999px',
                        '&:hover': {
                          backgroundColor: allMembersOnboarded
                            ? 'bg-accent'
                            : '#3e3e3e',
                        },
                      }}
                      className="w-full"
                      onClick={() => {
                        if (onStartDraft && allMembersOnboarded) {
                          onStartDraft();
                        }
                      }}
                    >
                      Start Drafting Now
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      )}
      {draft?.status === leagueDraftStatus.inProgress && (
        <div className="p-4">
          <div className="flex justify-between">
            <div>
              <div className="text-xs">Draft Status</div>
              <div>In Progress</div>
            </div>
            <div className="flex">
              <div className="flex flex-col mr-3 items-center">
                <div className="text-xs">Round</div>
                <div className="text-2xl font-bold text-right">
                  {draft.round}
                </div>
              </div>
              <DraftPickTimer
                draftDetails={draft}
                getPickTimerStatus={setIsPickTimerExpired}
                pickTimerReset={resetPickTimer}
                initalDisplayTimer={initalDisplayTimer}
              />
            </div>
          </div>
          <div className="mt-2">
            {currentTurnUser && (
              <div>
                <div className="text-xs">Draft Sequence</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-start mt-2 w-full">
                    <div className="inline-flex items-center justify-start rounded-full bg-gray max-w-[70%]">
                      <div className="m-2">
                        <Image
                          key={currentTurnUser.id}
                          imageUrl={currentTurnUser?.profileImage}
                          title={currentTurnUser.fullName}
                          size={12}
                        />
                      </div>
                      <div className="mr-6">
                        {currentTurnUser.fullName}'s turn.
                      </div>
                    </div>
                    <div className="flex ml-2 overflow-x-scroll draft-sequence members-list items-center">
                      {pickOrderDisplay}
                    </div>
                  </div>
                </div>
                {isCreator && (
                  <div className="mt-4 mb-2">
                    <div>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: '9999px',
                        }}
                        className="w-full"
                        onClick={() => {
                          if (!isPickTimerExpired) {
                            if (
                              window.confirm(
                                `Are you sure you want to autopick for ${currentTurnUser.fullName} before the time has expired?`,
                              )
                            ) {
                              if (onAutoPickClicked) {
                                setResetPickTimer(true);
                                setIsPickTimerExpired(false);
                                onAutoPickClicked();
                              }
                            }
                          } else if (isPickTimerExpired && onAutoPickClicked) {
                            setResetPickTimer(true);
                            setIsPickTimerExpired(false);
                            onAutoPickClicked();
                          }
                        }}
                      >{`Autopick for ${currentTurnUser.fullName}`}</Button>
                    </div>
                    {!isPickTimerExpired && (
                      <div className="text-main text-center">
                        Timer is not up for user
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LeagueDraftSettings;
