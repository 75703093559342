import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from '../components/widgets/require-auth/require-auth';
import AuthProvider from '../providers/auth-provider';
import LeagueProvider from '../providers/league-provider';
import TeamsProvider from '../providers/teams-provider';
import ToastProvider from '../providers/toast-provider';
import Home from './authorized-pages/home/home';
import CreateLeague from './authorized-pages/league/create-league';
import SelectOpenLeague from './authorized-pages/league/select-open-league';
import JoinLeague from './authorized-pages/league/join-league';
import LeagueDetails from './authorized-pages/league/LeagueDetails/league-details';
import UpdateSquad from './authorized-pages/league/UpdateSquad/update-squad';
import Notifications from './authorized-pages/notifications/notifications';
import Profile from './authorized-pages/profile/profile';
import MobileAppLayout from './layouts/mobile-app-layout';
import OLeagueProvider from './operations-portal/operations-provider';
import OperationsPortal from './operations-portal/operations-portal';
import Splash from './splash/splash';
import ForgotPassword from './unauthorized-pages/forgot-password/forgot-password';
import Login from './unauthorized-pages/login/login';
import RedirectPage from './unauthorized-pages/redirect/redirect';
import RequestPassword from './unauthorized-pages/request-password/request-password';
import ResetPassword from './unauthorized-pages/reset-password/reset-password';
import Signup from './unauthorized-pages/signup/signup';
import GameDayGuide from './authorized-pages/gameday-guide/gameday-guide';
import ScoreboardProvider from '../providers/scoreboard-povider';
import DraftLeague from './authorized-pages/league/draft-league';
import DiscordRedirectPage from '../components/discord/discord-redirect';
import MobileAppMessage from '../components/ui/mobile-app-message/mobile-app-message';
import { useState } from 'react';
import { isAndroid, isIOS, isIPhone13, isMobile } from 'react-device-detect';
import UserDetails from './authorized-pages/profile/user-details';
import AboutUsPage from './authorized-pages/home/about-us';
import ChatProvider from '../providers/chat-provider';
import OperationsReports from './operations-portal/operations-reports';
import TrophyProvider from '../providers/trophy-provider';
import TradingProvider from '../providers/trading-provider';
import TransfersProvider from '../providers/transfer-provider';
import AppRedirectPage from "../components/redirect/redirect";

function Main() {
  const [showMobileAppMessage, setShowMobileAppMessage] =
    useState<boolean>(false);
  const [platformForAppMessage, setPlatformForAppMessage] = useState<
    'ios' | 'android'
  >('ios');

  setTimeout(() => {
    // need to give the app a chance to set the variable
    if (
      isMobile &&
      (isAndroid || isIOS) &&
      !(window as any).isRunningInSquadBlitzApp
    ) {
      // They are using our mobile website. Encourage them to go to our app
      setShowMobileAppMessage(true);
      isAndroid
        ? setPlatformForAppMessage('android')
        : setPlatformForAppMessage('ios');
    }
  }, 10000);

  return (
    <ToastProvider>
      <AuthProvider>
        <ChatProvider>
          <LeagueProvider>
            <TeamsProvider>
              <OLeagueProvider>
                <ScoreboardProvider>
                  <TrophyProvider>
                    <TradingProvider>
                      <TransfersProvider>
                        <Routes>
                          <Route path="/" element={<MobileAppLayout />}>
                            <Route index element={<Splash />} />
                            <Route path="login" element={<Login />} />
                            <Route path="redirect" element={<RedirectPage />} />

                            {/* TODO: require auth here?? It automatically redirects to the profile where auth is required. I'm afraid it would never get to this page if we do */}
                            <Route
                              path="discord-redirect"
                              element={<DiscordRedirectPage />}
                            />
                            <Route
                              path="password"
                              element={<RequestPassword />}
                            />
                            <Route
                              path="forgot-password"
                              element={<ForgotPassword />}
                            />
                            <Route
                              path="reset-password"
                              element={<ResetPassword />}
                            />
                            <Route path="signup" element={<Signup />} />

                            <Route
                              path="redirect/join/:leagueId"
                              element={
                                <AppRedirectPage/>
                              }
                            />
                            <Route
                              path="home"
                              element={<RequireAuth children={<Home />} />}
                            />
                            <Route
                              path="profile"
                              element={<RequireAuth children={<Profile />} />}
                            />
                            <Route
                              path="about"
                              element={
                                <RequireAuth children={<AboutUsPage />} />
                              }
                            />
                            <Route
                              path="notifications"
                              element={
                                <RequireAuth children={<Notifications />} />
                              }
                            />
                            <Route
                              path="league/create"
                              element={
                                <RequireAuth children={<CreateLeague />} />
                              }
                            />
                            <Route
                              path="league/selectOpenLeague"
                              element={
                                <RequireAuth children={<SelectOpenLeague />} />
                              }
                            />
                            <Route
                              path="draft/:leagueId"
                              element={
                                <RequireAuth children={<DraftLeague />} />
                              }
                            />
                            <Route
                              path="league/:leagueId"
                              element={
                                <RequireAuth children={<LeagueDetails />} />
                              }
                            />
                            <Route
                              path="league/:leagueId/update-squad"
                              element={
                                <RequireAuth children={<UpdateSquad />} />
                              }
                            />
                            <Route
                              path="league/:leagueId/gameday-guide"
                              element={
                                <RequireAuth children={<GameDayGuide />} />
                              }
                            />
                            <Route
                              path="join/:leagueId"
                              element={
                                <RequireAuth children={<JoinLeague />} />
                              }
                            />
                            <Route
                              path="operations"
                              element={
                                <RequireAuth children={<OperationsPortal />} />
                              }
                            />
                            <Route
                              path="operations/reports"
                              element={
                                <RequireAuth children={<OperationsReports />} />
                              }
                            />
                            <Route
                              path="user-details/:userId"
                              element={
                                <RequireAuth children={<UserDetails />} />
                              }
                            />
                          </Route>

                        </Routes>
                        {showMobileAppMessage && (
                          <MobileAppMessage platform={platformForAppMessage} />
                        )}
                      </TransfersProvider>
                    </TradingProvider>
                  </TrophyProvider>
                </ScoreboardProvider>
              </OLeagueProvider>
            </TeamsProvider>
          </LeagueProvider>
        </ChatProvider>
      </AuthProvider>
    </ToastProvider>
  );
}

export default Main;
