import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Tooltip,
} from '@mui/material';
import { iTeam } from '@shared/shared-utils/models';
import { useContext, useState } from 'react';
import { OperationsContext } from '../operations-provider';
import { iAppConfig } from '@shared/shared-utils/models';
import ModalWrapper from '../../../components/modals/modal-wrapper/modal-wrapper';
import { environment } from '../../../environments/environment.local.dev';

type OTeamDetailsType = {
  team: iTeam;
  appConfig: iAppConfig;
  toggleModal: () => void;
  superAdmin: boolean;
};

const OTeamDetails = (props: OTeamDetailsType) => {
  const { setTeamAsByeWeek, setTeamAsNonByeWeek, toggleTeamLockedStatus } =
    useContext(OperationsContext);
  const [teamDetails, setTeamDetails] = useState<iTeam>(props.team);
  const [isByeWeek, setIsByeWeek] = useState(
    teamDetails.nextGame ? false : true,
  );
  const [isTeamLocked, setIsTeamLocked] = useState(
    teamDetails.teamLocked ? true : false,
  );

  const toggleByeWeekStatus = async (switchStatus: boolean) => {
    if (switchStatus) {
      const teamWithBye = await setTeamAsByeWeek(teamDetails);
      setTeamDetails(teamWithBye);
    } else {
      const teamWithoutBye = await setTeamAsNonByeWeek(
        teamDetails,
        props.appConfig.currentSeason,
        props.appConfig.currentWeek,
      );
      setTeamDetails(teamWithoutBye);
    }

    setIsByeWeek(!isByeWeek);
    localStorage.clear();
  };

  const toggleTeamLocked = async (switchStatus: boolean) => {
    const newTeamDetails = await toggleTeamLockedStatus(
      switchStatus,
      teamDetails,
    );
    setTeamDetails(newTeamDetails);
    setIsTeamLocked(!isTeamLocked);
    localStorage.clear();
  };

  return (
    <ModalWrapper
      title={`Team Details: ${teamDetails.school} - ${teamDetails.id}`}
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={props.toggleModal}
    >
      <div className="my-4 mx-4 overflow-y-scroll max-h-[90vh] bg-db-header-background-color flex flex-col items-center">
        <h2>Team Testing</h2>
        <TableContainer
          component={Paper}
          sx={{
            border: 'gray',
            borderStyle: 'solid',
            borderWidth: 'thin',
            marginTop: '0.5rem',
            paddingBottom: '0',
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Function</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'byeWeek'}>
                <TableCell component="th" scope="row">
                  Set team on bye week
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      environment.hostingProd
                        ? 'Cannot perform that function on PROD'
                        : !props.superAdmin &&
                          'Must be a super admin to perform that action.'
                    }
                  >
                    <div>
                      <Switch
                        checked={isByeWeek}
                        onChange={(e) => toggleByeWeekStatus(e.target.checked)}
                        disabled={environment.hostingProd || !props.superAdmin}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
              <TableRow key={'teamLocked'}>
                <TableCell component="th" scope="row">
                  Set team as locked
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={
                      environment.hostingProd
                        ? 'Cannot perform that function on PROD'
                        : !props.superAdmin &&
                          'Must be a super admin to perform that action.'
                    }
                  >
                    <div>
                      <Switch
                        checked={isTeamLocked}
                        onChange={(e) => toggleTeamLocked(e.target.checked)}
                        disabled={environment.hostingProd || !props.superAdmin}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ModalWrapper>
  );
};

export default OTeamDetails;
