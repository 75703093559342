import { ReactNode } from 'react';

type ListItemType = {
  children: ReactNode,
  specialClass?: string,
  isSelected?: boolean,
  selectedStyle?: string,
  itemClick?: () => void,
};

const ListItem = (props: ListItemType) => {
  return (
    <div
    className={`cursor-pointer db-list-item ${props.specialClass}`}
    onClick={props.itemClick}
    style={{backgroundColor: props.isSelected ? props.selectedStyle : ''}}
    >
      {props.children}
    </div>
  )
};

export default ListItem;
