import { ChangeEvent, useEffect, useState } from 'react';
import '../input.scss';
import PasswordInput from './password-input';

type ConfirmPasswordInputProps = {
  doPasswordsMatch: (passwordMatch: boolean, password?: string) => void;
  firstPlaceholderText?: string;
  secondPlaceholderText?: string;
};

export function ConfirmPasswordInput(props: ConfirmPasswordInputProps) {
  const { doPasswordsMatch, firstPlaceholderText, secondPlaceholderText } =
    props;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const handleShowPasswordToggleSync = (passwordState: boolean) => {
    setShowPassword(passwordState);
  };

  useEffect(() => {
    if (password && password?.length > 5 && password === confirmPassword) {
      doPasswordsMatch(true, password);
    } else {
      doPasswordsMatch(false);
    }
  }, [password, confirmPassword]);

  return (
    <>
      <div className="mt-2 mb-4">
        <PasswordInput
          showIconToggler={true}
          syncShowPasswordState={handleShowPasswordToggleSync}
          passwordState={showPassword}
          label={firstPlaceholderText ?? 'Password*'}
          onChangeAction={(event: ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
          inputId="primary"
        />
      </div>
      <div>
        <PasswordInput
          showIconToggler={true}
          syncShowPasswordState={handleShowPasswordToggleSync}
          passwordState={showPassword}
          label={secondPlaceholderText ?? 'Confirm Password*'}
          onChangeAction={(event: ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(event.target.value);
          }}
          inputId="confirm"
        />
      </div>
      {password !== confirmPassword && (
        <div className="text-sm text-[red]">*Passwords do not match</div>
      )}
    </>
  );
}

export default ConfirmPasswordInput;
