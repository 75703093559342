import { iLeagueInvite, iUser } from '@shared/shared-utils/models';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Menu, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faUserLarge,
} from '@fortawesome/free-solid-svg-icons';
import Image from '../../../../components/widgets/image';
import { ToastContext } from '../../../../providers/toast-provider';
import useUserDetailsNavigation from '../../../../hooks/useUserDetailsNavigation';

type MemberListType = {
  invites: iLeagueInvite[];
  members: iUser[];
  loading: boolean;
  leagueOwner: iUser; //TODO this is because everything on the league is optional :/
  isOwner: boolean;
  leagueHasDrafted: boolean;
  leagueIsOpen: boolean; // we don't want to show emails for open leagues
  onDelete: (inviteId: string) => void;
  onResend: (inviteId: string) => void;
  onDeleteMember: (id: string, email: string) => void;
  reactivatedLeague?: boolean;
};

const inviteActions = {
  resend: 'Resend',
  delete: 'Delete',
  deleteMember: 'Delete Member',
};

const MemberList = (props: MemberListType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState<JSX.Element[]>();
  const { showToast } = useContext(ToastContext);
  const { userNavigation } = useUserDetailsNavigation();

  const open = Boolean(anchorEl);

  interface memberList {
    profilePicture: JSX.Element | string;
    email: string;
    name: string;
    isOnBoarded: boolean;
    id: string;
    isOwner: boolean;
    notifications: boolean;
  }

  const membersToShow: memberList[] = [];

  const memberMap = new Map();

  props.members.forEach((member) =>
    memberMap.set(member.email.toLocaleLowerCase(), member),
  );

  // filter out invites for members that have already joined
  const nonJoinedInvites = props.invites.filter(
    (invite) => !memberMap.get(invite.to[0].toLocaleLowerCase()),
  );
  // Check through the invites
  nonJoinedInvites.forEach((invite) =>
    membersToShow.push({
      profilePicture: (
        <FontAwesomeIcon icon={faUserLarge} className="text-center" />
      ),
      email: invite.to[0].toLocaleLowerCase(),
      name: invite.to[0].toLocaleLowerCase(), // this will work for the name on the invite
      isOnBoarded: false,
      id: invite.id,
      isOwner: false, //and owner wouldn't be an ivite
      notifications: false,
    }),
  );

  // Add joined members to the table
  props.members.forEach((member) =>
    membersToShow.push({
      profilePicture: (
        <Image
          imageUrl={member.profileImage}
          title={member.fullName}
          key={member.id}
          specialClass="mr-2"
          clickable={true}
          clickAction={() => userNavigation(member.id)}
        />
      ),
      email: member.email,
      name: member.fullName,
      isOnBoarded: true,
      id: member.id,
      isOwner: props.leagueOwner.id === member.id ? true : false,
      notifications: member.playerId ? true : false,
    }),
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    member: memberList,
  ) => {
    setAnchorEl(event.currentTarget);
    buildMenuItems(member);
  };

  const handleMemberAction = () => {
    setAnchorEl(null);
    showToast({
      messageType: 'info',
      message: 'Please contact support to help with this request.',
    });
  };

  const handleClose =
    (actionToTake?: string, id?: string, email?: string) => () => {
      if (actionToTake && id) {
        switch (actionToTake) {
          case inviteActions.resend:
            // Deal with resend
            props.onResend(id);
            break;
          case inviteActions.delete:
            // Deal with delete
            props.onDelete(id);
            break;
          case inviteActions.deleteMember:
            // Deal with delete member
            props.onDeleteMember(id, email ? email : '');
            break;
        }
      }
      setAnchorEl(null);
    };

  const buildMenuItems = (member: memberList) => {
    if (member.isOnBoarded) {
      const menuItems = [
        <MenuItem
          onClick={handleMemberAction}
          key="transfer"
          data-e2e={`transfer_league_btn-${member.email}`}
        >
          Transfer
        </MenuItem>,
      ];

      if (!props.leagueHasDrafted) {
        menuItems.push(
          <MenuItem
            onClick={handleClose(
              inviteActions.deleteMember,
              member.id,
              member.email,
            )}
            key="delete-member"
            data-e2e={`delete_member_league_btn-${member.email}`}
          >
            Delete
          </MenuItem>,
        );
      }

      setMenuItems(menuItems);
    } else {
      setMenuItems([
        <MenuItem
          onClick={handleClose(inviteActions.resend, member.id)}
          key="resend"
          sx={{ paddingBottom: '0.25rem' }}
          data-e2e={`resend_invite_btn-${member.email}`}
        >
          Resend
        </MenuItem>,
        <MenuItem
          onClick={handleClose(inviteActions.delete, member.id)}
          key="delete"
          sx={{ paddingTop: '0.25rem' }}
          data-e2e={`delete_invite_btn-${member.email}`}
        >
          Cancel Invite
        </MenuItem>,
      ]);
    }
  };

  return membersToShow.length ? (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Avatar</TableCell>
              <TableCell>Name</TableCell>
              {props.isOwner && !props.leagueIsOpen && (
                <TableCell>Email</TableCell>
              )}
              <TableCell>Joined</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notifications</TableCell>
              {props.isOwner && (
                <TableCell data-e2e="members_action_column">Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* TODO add league owner */}
            {membersToShow.map((member) => (
              <TableRow
                key={member.email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                data-e2e={`invite_row_${member.email}`}
              >
                <TableCell sx={{ textAlign: 'center' }}>
                  {member.profilePicture}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {member.name}
                </TableCell>
                {props.isOwner && !props.leagueIsOpen && (
                  <TableCell component="th" scope="row">
                    {member.email}
                  </TableCell>
                )}
                <TableCell>{member.isOnBoarded ? 'Yes' : 'No'}</TableCell>
                <TableCell>{member.isOwner ? 'Owner' : 'Member'}</TableCell>
                <TableCell>{member.notifications ? 'Yes' : 'No'}</TableCell>
                {props.isOwner && (
                  <TableCell>
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        handleClick(event, member)
                      }
                      data-e2e={`invited_list_action_btn-${member.email}`}
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className="text-white"
                      />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems}
      </Menu>
    </div>
  ) : (
    <></>
  );
};

export default MemberList;
