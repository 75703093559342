import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
  limit,
  orderBy,
  onSnapshot,
  DocumentSnapshot,
  Unsubscribe,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { environment } from '../environments/environment.local.dev';
import {
  iLeague,
  iLeagueDb,
  iLeagueInvite,
  iLeagueMatchups,
  iUser,
  iLeagueMemberSquad,
  iLeagueMemberSquadDb,
  iLeagueSettings,
  iMatchupSquadItemDB,
  iLeagueMemberRankData,
  iLeagueMemberRanking,
  iAutoPickList,
  iDraft,
  iAppConfig,
  iTradeRequest,
  RequestStatus,
  mailType,
  emailTemplates,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import {
  DBCollections,
  DBStorageDir,
  defensiveScoreStats,
  passingScoreStats,
  rushingScoreStats,
} from '@shared/shared-utils';
import DocumentService from './document-service';
import { DBFirebaseDB } from './firebase-core';

export class LeagueService {
  private _documentService = new DocumentService();
  private _DBUsersCollection = collection(DBFirebaseDB, DBCollections.users);
  private _DBLeaguesCollection = collection(
    DBFirebaseDB,
    DBCollections.leagues,
  );
  private _DBInvitesCollection = collection(
    DBFirebaseDB,
    DBCollections.invites,
  );
  private _DBSettingsCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueSettings,
  );
  private _DBSquadsCollection = collection(DBFirebaseDB, DBCollections.squads);
  private _DBDraftsCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueDrafts,
  );
  private _DBLeagueMatchupsCollection = collection(
    DBFirebaseDB,
    DBCollections.matchups,
  );

  private _DBAppConfigCollection = collection(
    DBFirebaseDB,
    DBCollections.appConfig,
  );

  private _DBRequestsCollection = collection(
    DBFirebaseDB,
    DBCollections.requests,
  );

  private appConfigCache: iAppConfig | null = null;

  public async getAppConfig(platform: string) {
    //console.log('read - getAppConfig()');

    if (this.appConfigCache) {
      return this.appConfigCache;
    }
    const appConfigRef = doc(this._DBAppConfigCollection, platform);
    const appConfigSnapshot = await getDoc(appConfigRef);

    const returnedConfig = appConfigSnapshot.data() as iAppConfig;

    this.appConfigCache = returnedConfig;
    return returnedConfig;
  }

  public async getLeagues(userId: string): Promise<iLeague[] | null> {
    //console.log('read - getLeagues()');
    //TODO: TYPINGS this is possible but we should check in provider

    const leagueByMemberQuery = query(
      this._DBLeaguesCollection,
      where('memberIds', 'array-contains', userId),
      orderBy('updatedAt', 'desc'),
    );

    const leaguesSnapshotList = await getDocs(leagueByMemberQuery);

    if (leaguesSnapshotList.empty) {
      return null;
    }

    const leaguesToPopulate: Promise<iLeague>[] = [];
    for (let i = 0; i < leaguesSnapshotList.size; i++) {
      const doc = leaguesSnapshotList.docs[i];
      if (doc.exists()) {
        const leagueItem = doc.data() as iLeagueDb; //TODO Typings this was casted as iLeague. Look for other issues like this
        const populatedLeague: Promise<iLeague> =
          this.populateLeagueDetails(leagueItem);
        // TODO make this a promise all so we aren't waiting so long
        leaguesToPopulate.push(populatedLeague);
      }
    }

    return await Promise.all(leaguesToPopulate);
  }

  public async getOpenLeagues(year: number): Promise<iLeague[] | null> {
    const openLeaguesQuery = query(
      this._DBLeaguesCollection,
      where('isOpen', '==', true),
      where('draftStatus', '==', 'pending'),
      where('season', '==', 2024),
    );

    const leaguesSnapshotList = await getDocs(openLeaguesQuery);

    if (leaguesSnapshotList.empty) {
      return null;
    }

    const leaguesToPopulate: Promise<iLeague>[] = [];
    for (let i = 0; i < leaguesSnapshotList.size; i++) {
      const doc = leaguesSnapshotList.docs[i];
      if (doc.exists()) {
        const leagueItem = doc.data() as iLeagueDb;
        const populatedLeague: Promise<iLeague> =
          this.populateLeagueDetails(leagueItem);
        // TODO make this a promise all so we aren't waiting so long
        leaguesToPopulate.push(populatedLeague);
      }
    }

    return await Promise.all(leaguesToPopulate);
  }

  public async getLeagueById(id: string) {
    //console.log('read - getLeagueById()');
    // TODO Make this prettier, probably needs a helper that changes this from iLeagueDB to iLeague
    const leagueRef = doc(this._DBLeaguesCollection, id);
    const leagueDocRef = await getDoc(leagueRef);
    const leagueDetails = leagueDocRef.data() as iLeagueDb;
    const populatedLeague = await this.populateLeagueDetails(leagueDetails);

    return populatedLeague;
  }

  public async getLeagueInvitesById(
    leagueId: string,
  ): Promise<iLeagueInvite[]> {
    //console.log('read - getLeagueInvitesById()');
    const leagueInvitesQuery = query(
      this._DBInvitesCollection,
      where('leagueId', '==', leagueId),
    );

    const leagueInvitesSnapshotList = await getDocs(leagueInvitesQuery);
    const leagueInvites: iLeagueInvite[] = [];
    leagueInvitesSnapshotList.forEach((inviteDoc) => {
      leagueInvites.push({
        ...inviteDoc.data(),
        id: inviteDoc.id,
      } as iLeagueInvite);
    });

    return leagueInvites;
  }

  public async getLeagueRequestsForUser(
    email: string,
  ): Promise<iLeagueInvite[]> {
    //console.log('read - getLeagueRequestsForUser()');
    const leagueInvitesQuery = query(
      this._DBInvitesCollection,
      where('mailType', '==', mailType.leagueInvite),
      where('to', 'array-contains', email),
    );

    const leagueInvitesSnapshotList = await getDocs(leagueInvitesQuery);
    const leagueInvites: iLeagueInvite[] = [];
    leagueInvitesSnapshotList.forEach((inviteDoc) => {
      leagueInvites.push({
        ...inviteDoc.data(),
        id: inviteDoc.id,
      } as iLeagueInvite);
    });

    return leagueInvites;
  }

  public async getLeagueMemberDetails(memberIds: string[]): Promise<iUser[]> {
    //console.log('read - getLeagueMemberDetails()');
    const leagueMemberDetailQuery = query(
      this._DBUsersCollection,
      where('id', 'in', memberIds),
    );
    const leagueMemberDetailsSnapList = await getDocs(leagueMemberDetailQuery);

    if (leagueMemberDetailsSnapList.empty) {
      return [];
    }

    const memberDetailsList: iUser[] = [];
    leagueMemberDetailsSnapList.forEach((doc) => {
      if (doc.exists()) {
        memberDetailsList.push(doc.data() as iUser);
      }
    });

    return memberDetailsList;
  }

  public async getLeagueSettingsById(
    leagueId: string,
  ): Promise<iLeagueSettings> {
    //console.log('read - getLeagueSettingsById()');
    const leagueSettingsRef = doc(this._DBSettingsCollection, leagueId);
    const leagueSettingsSnapshot = await getDoc(leagueSettingsRef);
    return leagueSettingsSnapshot.data() as iLeagueSettings;
  }

  // TODO Typings create new type for an uncreated league
  public async createLeague(leagueDetails: Partial<iLeagueDb>) {
    const newDocRef = doc(this._DBLeaguesCollection);
    leagueDetails.id = newDocRef.id;
    return await this.updateLeague(leagueDetails);
  }

  public async duplicateLeague(
    leagueDetails: iLeagueDb,
    currentSeason: number,
  ) {
    const updatedLeagueDetails: iLeagueDb = {
      ...leagueDetails,
      season: currentSeason,
    };
    const newDocRef = doc(this._DBLeaguesCollection);
    leagueDetails.id = newDocRef.id;
    return await this.updateLeague(updatedLeagueDetails);
  }

  // TODO: This doesn't actually need the full iLeagueDb
  public async updateLeague(leagueDetails: Partial<iLeagueDb>) {
    const leagueRef = doc(this._DBLeaguesCollection, leagueDetails.id);
    await setDoc(leagueRef, leagueDetails, { merge: true });
    return await this.getLeagueById(leagueRef.id);
  }

  public async saveLeagueSetting(
    leagueId: string,
    leagueSettings: iLeagueSettings,
  ) {
    const leagueSettingsRef = doc(this._DBSettingsCollection, leagueId);
    await setDoc(leagueSettingsRef, leagueSettings);
    return await this.getLeagueById(leagueId);
  }

  public async addOrUpdateLeagueMatchups(
    leagueId: string,
    leagueMatchups: iLeagueMatchups,
  ) {
    const leagueMatchupRef = doc(this._DBLeagueMatchupsCollection, leagueId);
    await setDoc(leagueMatchupRef, leagueMatchups);
    return leagueMatchups;
  }

  public async getLeagueMatchups(leagueId: string) {
    //console.log('read - getLeagueMatchups()');
    const leagueMatchupRef = doc(this._DBLeagueMatchupsCollection, leagueId);
    const leagueMatchupSnapshot = await getDoc(leagueMatchupRef);
    return leagueMatchupSnapshot.data() as iLeagueMatchups;
  }

  public async updateSquadName(
    squadName: string,
    leagueId: string,
    userId: string,
  ) {
    const memberLeagueSquadRef = doc(
      this._DBSquadsCollection,
      leagueId + userId,
    );
    await setDoc(
      memberLeagueSquadRef,
      { squadName: squadName },
      { merge: true },
    );
    return squadName;
  }

  public async addOrUpdateLeagueMemberSquad(
    leagueId: string,
    userId: string,
    updateTeams: boolean,
    teams: string[],
    matchupSquad?: iMatchupSquadItemDB,
    results?: any,
  ) {
    const memberLeagueSquadRef = doc(
      this._DBSquadsCollection,
      leagueId + userId,
    );
    const leagueMemberSquad = updateTeams
      ? ({
          leagueId: leagueId,
          memberId: userId,
          teams: teams,
          matchupSquad: matchupSquad ? matchupSquad : {},
        } as iLeagueMemberSquadDb)
      : ({
          leagueId: leagueId,
          memberId: userId,
          matchupSquad: matchupSquad ? matchupSquad : {},
        } as iLeagueMemberSquadDb);

    // Check if new records have been passed in:
    if (results) {
      leagueMemberSquad.results = results;
    }
    await setDoc(memberLeagueSquadRef, leagueMemberSquad, { merge: true });
    return leagueMemberSquad;
  }

  public async addTradedTeamsToSquad(
    leagueId: string,
    userId: string,
    teamComing: string,
    teamLeaving: string,
    squad: iLeagueMemberSquadDb,
  ) {
    const memberLeagueSquadRef = doc(
      this._DBSquadsCollection,
      leagueId + userId,
    );

    // Save the teams coming and leaving
    const newTeamsLeaving = squad.tradedTeams?.teamsLeaving.length
      ? squad.tradedTeams?.teamsLeaving
      : [];
    const newTeamsComing = squad.tradedTeams?.teamsComing.length
      ? squad.tradedTeams?.teamsComing
      : [];

    await setDoc(
      memberLeagueSquadRef,
      {
        tradedTeams: {
          teamsLeaving: newTeamsLeaving.concat(teamLeaving),
          teamsComing: newTeamsComing.concat(teamComing),
        },
      },
      { merge: true },
    );
  }

  public async getLeagueMemberSquad(leagueId: string, userId: string) {
    //console.log('read - getLeagueMemberSquad()');
    const memberLeagueSquadRef = doc(
      this._DBSquadsCollection,
      leagueId + userId,
    );

    const memberLeagueSquad = await getDoc(memberLeagueSquadRef);
    return memberLeagueSquad.data() as iLeagueMemberSquadDb;
  }

  public async getAllLeagueSquads(leagueId: string) {
    //console.log('read - getAllLeagueSquads()');
    const leagueSquadsRef = query(
      this._DBSquadsCollection,
      where('leagueId', '==', leagueId),
    );
    const leagueSquadsResponse = await getDocs(leagueSquadsRef);

    if (leagueSquadsResponse.empty) {
      return [];
    }

    const leagueSquads: iLeagueMemberSquad[] = [];
    leagueSquadsResponse.forEach((doc) => {
      if (doc.exists()) {
        leagueSquads.push(doc.data() as iLeagueMemberSquad);
      }
    });

    return leagueSquads;
  }

  public async uploadBannerImage(
    fileToUpload: File,
    userDetails: iUser,
  ): Promise<string> {
    return await this._documentService.uploadFile(
      fileToUpload,
      DBStorageDir.leagueBannerImages,
      userDetails.id + '-' + new Date().toDateString(),
    );
  }

  public async inviteMember(
    email: string,
    leagueTitle: string,
    leagueId: string,
    inviterName: string,
  ) {
    const newDocRef = await doc(this._DBInvitesCollection);
    const inviteDetails = await setDoc(newDocRef, {
      to: [email],
      leagueId: leagueId,
      activityMessage: `${inviterName} invited you to join ${leagueTitle}`,
      template: {
        data: {
          hostUrl: environment.hostUrl,
          leagueId: leagueId,
          leagueName: leagueTitle,
        },
        name: emailTemplates.leagueInvite,
      },
      mailType: mailType.leagueInvite,
      delivery: null,
    } as Partial<iLeagueInvite>);
    return inviteDetails;
  }

  public async resendMemberInvite(inviteId: string) {
    try {
      //console.log('read - resendMemberInvite()');
      const inviteDocRef = doc(this._DBInvitesCollection, inviteId);
      const inviteDoc = await getDoc(inviteDocRef);
      const delivery = (inviteDoc.data() as iLeagueInvite).delivery;

      await setDoc(
        inviteDocRef,
        {
          delivery: {
            ...delivery,
            state: 'RETRY',
          },
        },
        { merge: true },
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  public async deleteMemberInvite(inviteId: string) {
    try {
      const docToDelete = doc(this._DBInvitesCollection, inviteId);

      deleteDoc(docToDelete);

      return true;
    } catch (error) {
      return false;
    }
  }

  // TODO Move all draft into draft service
  public async startLeagueDraft(leagueId: string, leagueDraft: iDraft) {
    const leagueDraftRef = doc(this._DBDraftsCollection, leagueId);
    await setDoc(leagueDraftRef, leagueDraft);
    return this.getLeagueDraft(leagueId);
  }

  public listenToDraftChanges(
    leagueId: string,
    callbackFun: (draft: iDraft) => void,
  ): Unsubscribe {
    const unsub = onSnapshot(
      doc(DBFirebaseDB, DBCollections.leagueDrafts, leagueId),
      (doc: DocumentSnapshot) => {
        callbackFun(doc.data() as iDraft);
      },
    );
    return unsub;
  }

  public async getLeagueDraft(leagueId: string): Promise<iDraft> {
    //console.log('read - getLeagueDraft()');
    const leagueDraftRef = doc(this._DBDraftsCollection, leagueId);
    const draftDoc = await getDoc(leagueDraftRef);
    return draftDoc.data() as iDraft;
  }

  public async updateDraft(draft: iDraft): Promise<boolean> {
    const leagueDraftRef = doc(this._DBDraftsCollection, draft.leagueId);
    await setDoc(leagueDraftRef, draft, { merge: true });
    return true;
  }

  public async getMyLeagueMatchups(leagueIds: string[]) {
    const promises: any = [];
    leagueIds.map((id) => {
      const leagueMatchupRef = doc(this._DBLeagueMatchupsCollection, id);
      //console.log('read - getMyLeagueMatchups()');
      promises.push(getDoc(leagueMatchupRef));
    });

    const allLeagueMatchups = await Promise.all(promises);

    const matchups: iLeagueMatchups[] = [];
    allLeagueMatchups.map((leagueMatchups) => {
      const data = leagueMatchups.data() as iLeagueMatchups;
      matchups.push(data);
    });
    return matchups;
  }

  public async getUserPendingRequests(userId: string) {
    //console.log('read - getLeagueRequests()');
    const leagueRequestQuery = query(
      this._DBRequestsCollection,
      where('approvers', 'array-contains', userId),
      where('status', '==', RequestStatus.pending),
    );
    const leagueRequestsSnapshotList = await getDocs(leagueRequestQuery);

    if (leagueRequestsSnapshotList.empty) {
      return [];
    }

    const leagueRequests: iTradeRequest[] = [];
    leagueRequestsSnapshotList.forEach((doc) => {
      if (doc.exists()) {
        leagueRequests.push(doc.data() as iTradeRequest);
      }
    });

    return leagueRequests;
  }

  public async getLeagueRequests(leagueId: string) {
    //console.log('read - getLeagueRequests()');
    const leagueRequestQuery = query(
      this._DBRequestsCollection,
      where('leagueId', '==', leagueId),
      where('status', '==', RequestStatus.accepted),
    );
    const leagueRequestsSnapshotList = await getDocs(leagueRequestQuery);

    if (leagueRequestsSnapshotList.empty) {
      return [];
    }

    const leagueRequests: iTradeRequest[] = [];
    leagueRequestsSnapshotList.forEach((doc) => {
      if (doc.exists()) {
        leagueRequests.push(doc.data() as iTradeRequest);
      }
    });
    return leagueRequests;
  }

  public async getProcessingLeagueRequests(leagueId: string) {
    //console.log('read - getPendingLeagueRequests()');
    const leagueRequestQuery = query(
      this._DBRequestsCollection,
      where('leagueId', '==', leagueId),
      where('status', '==', RequestStatus.processing),
    );
    const leagueRequestsSnapshotList = await getDocs(leagueRequestQuery);

    if (leagueRequestsSnapshotList.empty) {
      return [];
    }

    const leagueRequests: iTradeRequest[] = [];
    leagueRequestsSnapshotList.forEach((doc) => {
      if (doc.exists()) {
        leagueRequests.push(doc.data() as iTradeRequest);
      }
    });
    return leagueRequests;
  }

  public async createInitialLeagueLeaderBoard(league: iLeague) {
    const leagueRef = doc(this._DBLeaguesCollection, league.id);
    const initialLeaderBoard: iLeagueMemberRanking = {};

    // Just increase the rank as we go so that we have an order
    let rank = 1;
    league.memberIds?.forEach((memberId) => {
      const memberRankData: iLeagueMemberRankData = {
        lengthOfStreak: 0,
        pointsScored: 0,
        pointsScoredAgainst: 0,
        rank: rank,
        winningStreak: true,
        wins: 0,
        losses: 0,
      };

      rank++;
      initialLeaderBoard[memberId] = memberRankData;
    });

    await setDoc(
      leagueRef,
      {
        leaderBoard: initialLeaderBoard,
      },
      { merge: true },
    );
  }

  public async saveAutopickList(
    leagueId: string,
    autopickList: iAutoPickList[],
  ) {
    const updatedLeague: Partial<iLeague> = {
      autopickLists: autopickList,
    };

    const leagueRef = doc(this._DBLeaguesCollection, leagueId);
    await setDoc(leagueRef, updatedLeague, { merge: true });
  }

  public async getLeagueLeaderBoardData(leagueId: string) {
    //console.log('read - getLeagueLeaderBoardData()');
    const leagueRef = doc(this._DBLeaguesCollection, leagueId);
    const leagueSnap = await getDoc(leagueRef);
    const leaderBoardData: iLeagueMemberRanking =
      await leagueSnap.get('leaderBoard');
    return leaderBoardData;
  }

  public async populateLeagueDetails(league: iLeagueDb): Promise<iLeague> {
    const asyncCallsToMake: [
      Promise<iLeagueSettings>,
      Promise<iUser[]>,
      Promise<iLeagueInvite[]>,
      Promise<iLeagueMatchups>,
    ] = [
      this.getLeagueSettingsById(league.id),
      this.getLeagueMemberDetails(league.memberIds),
      this.getLeagueInvitesById(league.id),
      this.getLeagueMatchups(league.id),
    ];

    // eslint-disable-next-line prefer-const
    let [settings, members, invites, leagueMatchups] =
      await Promise.all(asyncCallsToMake);

    const leaderBoard = league.leaderBoard ? league.leaderBoard : {};
    //TODO Typings look into this. LeaderBoard is optional on iLeagueDb

    // Settings exist but scoring doesn't. We need to look into this.
    if (settings && !settings.scoring) {
      settings.scoring = {
        defensiveScoreStats: defensiveScoreStats,
        passingScoreStats: passingScoreStats,
        rushingScoreStats: rushingScoreStats,
      };
    }

    // Ideally we shouldn't get into this state.
    if (!leagueMatchups || !leagueMatchups.matchups.length) {
      leagueMatchups = {
        leagueId: league.id,
        matchups: [],
        weeklyReports: leagueMatchups?.weeklyReports
          ? leagueMatchups.weeklyReports
          : {},
      };
    }

    return {
      ...league,
      leaderBoard: leaderBoard,
      invites: invites,
      settings: settings,
      matchups: leagueMatchups.matchups,
      weeklyReports: leagueMatchups.weeklyReports,
      members: members,
      creator: members.find((member) => member.id === league.creator),
    } as iLeague;
  }

  // This will rebuild each league in the db.
  public async updateLeagueObject() {
    const leaguesSnapshot = await getDocs(this._DBLeaguesCollection);
    leaguesSnapshot.forEach(async (league) => {
      const leagueDocRef = doc(this._DBLeaguesCollection, league['id']);
      const leagueData = league.data();
      const invites = await this.getLeagueInvitesById(leagueData['id']);

      const updatedLeagueData = {
        ...leagueData,
        expectedNumMembers: invites.length + 1,
      };
      await setDoc(leagueDocRef, updatedLeagueData, { merge: true });
    });
  }
}
