const Checkbox = ({ label, customClass, ...otherProps }: any) => {
  return (
    <label
      className={`checkbox-container${customClass ? ` ${customClass}` : ''}`}
    >
      <input type="checkbox" {...otherProps} />
      <span className="checkmark"></span>
      <p className="text-base leading-7">{label}</p>
    </label>
  );
};

export default Checkbox;
