import { faClose, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, InputAdornment, IconButton, SvgIcon } from '@mui/material';
import { useState } from 'react';
import GiphySearchContextManager from '../giphy-gifs/GiphySearchContextManager';
import GiphySearch from '../giphy-gifs/GiphySearch';

type ChatInputProps = {
  sendNewMessage: (message: string, isGif?: boolean) => void;
};

const ChatInput = (props: ChatInputProps) => {
  const [message, setMessage] = useState<string>('');
  const [showGifSection, setShowGifSection] = useState(false);

  const selectedGif = (url: string) => {
    setShowGifSection(false);
    props.sendNewMessage(url, true);
  };

  return (
    <div className="fixed bottom-0 left-0 px-5 mr-8 z-10 mt-4 pb-4 w-full flex justify-center mx-auto bg-black opacity-1">
      <div className="w-full max-w-[560px]">
        {showGifSection && (
          <div className="top-0 left-0 w-full bg-primary min-h-[80px] z-[1000] rounded-tr-md rounded-tl-md">
            <GiphySearchContextManager>
              <GiphySearch onGifSelect={selectedGif} />
            </GiphySearchContextManager>
          </div>
        )}
        <TextField
          placeholder="Type your message here..."
          fullWidth
          multiline
          maxRows={2}
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="send chat"
                  onClick={() => {
                    props.sendNewMessage(message);
                    setMessage('');
                  }}
                  edge="end"
                >
                  <FontAwesomeIcon
                    icon={faLocationArrow}
                    className="text-white fa-sm"
                  />
                </IconButton>
                <IconButton
                  aria-label="send chat"
                  onClick={() => setShowGifSection((old) => !old)}
                  edge="end"
                >
                  {showGifSection ? (
                    <FontAwesomeIcon
                      icon={faClose}
                      className="text-white fa-sm px-[4px]"
                    />
                  ) : (
                    // Gif Icon
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        color="white"
                      >
                        <title>file-gif-box</title>
                        <path
                          fill="white"
                          stroke="none"
                          strokeWidth={0}
                          d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M10 10.5H7.5V13.5H8.5V12H10V13.7C10 14.4 9.5 15 8.7 15H7.3C6.5 15 6 14.3 6 13.7V10.4C6 9.7 6.5 9 7.3 9H8.6C9.5 9 10 9.7 10 10.3V10.5M13 15H11.5V9H13V15M17.5 10.5H16V11.5H17.5V13H16V15H14.5V9H17.5V10.5Z"
                        />
                      </svg>
                    </SvgIcon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 250 }}
          helperText={`${message.length}/${250}`}
        />
      </div>
    </div>
  );
};

export default ChatInput;
