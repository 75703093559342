import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import '../input.scss';

type PasswordInputProps = {
  showIconToggler?: boolean;
  syncShowPasswordState?: (newPasswordState: boolean) => void;
  passwordState?: boolean;
  label?: string;
  onChangeAction?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputValue?: any;
  inputId?: string;
  e2eTag?: string;
};

export function PasswordInput(props: PasswordInputProps) {
  const {
    showIconToggler,
    syncShowPasswordState,
    passwordState,
    label,
    onChangeAction,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleIconToggleClick = () => {
    setShowPassword(!showPassword);
    syncShowPasswordState && syncShowPasswordState(!showPassword);
  };

  useEffect(() => {
    if (passwordState) {
      setShowPassword(passwordState);
    } else {
      setShowPassword(false);
    }
  }, [passwordState]);

  return (
    <TextField
      fullWidth
      data-e2e={props.e2eTag}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleIconToggleClick()}
              edge="end"
            >
              {showIconToggler ? (
                <FontAwesomeIcon
                  className="absolute text-xl right-3"
                  icon={showPassword ? faEyeSlash : faEye}
                />
              ) : (
                <></>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChangeAction && onChangeAction(event)
      }
    />
  );
}

export default PasswordInput;
