import React, { useState } from 'react';
import Button from '@mui/material/Button';
import LeaveLeagueDialog from '../../dialogs/leave-league-confirm';

interface LeaveLeagueComponentProps {
  onConfirmLeave: () => void;
}

const LeaveLeagueComponent: React.FC<LeaveLeagueComponentProps> = ({
  onConfirmLeave,
}) => {
  const [leaveLeagueDialogOpen, setLeaveLeagueDialogOpen] = useState(false);

  const handleOpenLeaveLeagueDialog = () => {
    setLeaveLeagueDialogOpen(true);
  };

  const handleCloseLeaveLeagueDialog = () => {
    setLeaveLeagueDialogOpen(false);
  };

  const handleConfirmLeaveLeagueDialog = () => {
    onConfirmLeave(); // Call the callback provided by the parent component
    setLeaveLeagueDialogOpen(false);
  };

  return (
    <div className="full-width mx-2">
      <Button
        variant="contained"
        fullWidth
        onClick={handleOpenLeaveLeagueDialog}
      >
        Leave League
      </Button>
      <LeaveLeagueDialog
        open={leaveLeagueDialogOpen}
        onClose={handleCloseLeaveLeagueDialog}
        onConfirm={handleConfirmLeaveLeagueDialog}
      />
    </div>
  );
};

export default LeaveLeagueComponent;
