import { User } from 'firebase/auth';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import {
  emailTemplates,
  iNewUserMail,
  iUser,
  mailType,
} from '@shared/shared-utils/models';
import { DBCollections, DBStorageDir } from '@shared/shared-utils';
import DocumentService from './document-service';
import { DBFirebaseDB } from './firebase-core';

export class UserService {
  private _documentService = new DocumentService();
  private _DBUsersCollection = collection(DBFirebaseDB, DBCollections.users);
  private _DBInvitesCollection = collection(
    DBFirebaseDB,
    DBCollections.invites,
  );

  public async getUserByEmail(email: string): Promise<iUser | null> {
    //console.log('read - getUserByEmail()');

    const userByEmailQuery = query(
      this._DBUsersCollection,
      where('email', '==', email.toLocaleLowerCase()),
    );

    const userSnapshotList = await getDocs(userByEmailQuery);

    if (userSnapshotList.empty) {
      return null;
    }

    let userProfile;
    userSnapshotList.forEach((doc) => {
      if (doc.exists()) {
        userProfile = doc.data() as iUser;
      }
    });

    return userProfile ? userProfile : null;
  }

  public async getUserById(userId: string): Promise<iUser> {
    //console.log('read - getUserById');
    const userRef = await doc(this._DBUsersCollection, userId);
    const userDocRef = await getDoc(userRef);
    const user = userDocRef.data() as iUser;

    return user;
  }

  public async addUserProfile(
    userDetails: iUser,
    source: string,
    userInputedSource?: string,
    favoriteTeam?: string,
  ) {
    const userRef = doc(this._DBUsersCollection);

    userDetails.id = userRef.id;

    userDetails.email = userDetails.email.toLocaleLowerCase();

    return await setDoc(userRef, {
      ...userDetails,
      source: source,
      userInputedSource: userInputedSource ? userInputedSource : '',
      favoriteTeam: favoriteTeam ? favoriteTeam : '',
    });
  }

  public async updateUserProfile(userDetails: Partial<iUser>, userId: string) {
    const userRef = doc(this._DBUsersCollection, userId);

    return await setDoc(userRef, userDetails, { merge: true });
  }

  public async uploadProfilePic(
    user: User,
    fileToUpload: File,
  ): Promise<string> {
    return await this._documentService.uploadFile(
      fileToUpload,
      DBStorageDir.profileImages,
      user.uid,
    );
  }

  public async sendInviteMemberEmail(email: string) {
    const newDocRef = await doc(this._DBInvitesCollection);
    const inviteDetails = await setDoc(newDocRef, {
      to: [email],
      template: {
        data: null,
        name: emailTemplates.newUser,
        type: mailType.newUser,
      },
      mailType: mailType.newUser,
      delivery: null,
    } as Partial<iNewUserMail>);
    return inviteDetails;
  }
}
