export const hasGameStarted = (gameTime?: string) => {
  // Lock 30 minutes before kick
  const currentDate = new Date();

  currentDate.setMinutes(currentDate.getMinutes() + 30);

  if (gameTime && currentDate.toISOString() > gameTime) {
    return true;
  }

  return false;
};
