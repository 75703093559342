import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Import the correct video icon
import { getAnalytics, logEvent } from 'firebase/analytics';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && <Box height="100%">{children}</Box>}
    </div>
  );
}

interface VideoPlayerProps {
  videoData: { label: string; url: string }[];
  buttonText: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoData, buttonText }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [isReady, setIsReady] = useState<boolean>(false); // New state to track readiness

  const analytics = getAnalytics();

  const handleOpen = () => {
    setOpen(true);
    setIsReady(true); // Set to true when the dialog is opened
    logEvent(analytics, 'video-banner-view', {
      content_type: 'viewed',
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<PlayArrowIcon />} // Use the PlayArrowIcon here
        fullWidth
      >
        {buttonText}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { height: '70vh', padding: 0 } }} // Maximize the dialog height
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8, zIndex: 1 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ padding: 0, height: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="video categories"
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            {videoData.map((video, index) => (
              <Tab key={index} label={video.label} />
            ))}
          </Tabs>
          {videoData.map((video, index) => (
            <TabPanel value={value} index={index} key={index}>
              {isReady && video.url ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: 'calc(100% - 48px)',
                  }}
                >
                  <ReactPlayer
                    url={video.url}
                    width="100%"
                    height="100%"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    controls
                    playsinline
                  />
                </div>
              ) : (
                <div>Loading...</div> // Fallback content
              )}
            </TabPanel>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoPlayer;
