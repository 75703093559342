import { Gif, SearchBar } from '@giphy/react-components';
import GiphyGifs from './GiphyGifs';
import { useContext, useEffect, useState } from 'react';
import { SearchContext } from '@giphy/react-components';
import { IGif } from '@giphy/js-types';

type GiphySearchProps = {
  onGifSelect: (url: string) => void;
  endOfSeason?: boolean;
};

const GiphySearch = (props: GiphySearchProps) => {
  const { term, setSearch, fetchGifs } = useContext(SearchContext);
  const [clearState, setClearState] = useState(true);
  const [gif, setGif] = useState<IGif>();

  // When the search is cleared, this resets the default search term to college football
  useEffect(() => {
    if (term === '') {
      setSearch('');
      setClearState(false);
      setTimeout(() => {
        setSearch('college football');
        setClearState(true);
      }, 500);
    }
  }, [term]);

  const getGifs = async () => {
    const gifs = await fetchGifs(0);
    const randomNumber = Math.floor(Math.random() * 15);
    setGif(gifs.data[randomNumber]);
  };

  useEffect(() => {
    if (props.endOfSeason) {
      getGifs();
    }
  }, []);

  return (
    <>
      {props.endOfSeason ? (
        <>{gif && <Gif gif={gif} width={300} />}</>
      ) : (
        <>
          <div className="m-auto pt-2 max-w-[1025px] w-[96%] h-[380px] overflow-scroll">
            <GiphyGifs onGifClick={props.onGifSelect} />
          </div>
          <div className="text-black text-right pr-[5px]">Powered By GIPHY</div>
          <SearchBar clear={clearState} initialTerm="college football" />
        </>
      )}
    </>
  );
};

export default GiphySearch;
