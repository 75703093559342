import { ReactNode, useContext, useEffect, useState } from 'react';
import ModalWrapper from '../../../components/modals/modal-wrapper/modal-wrapper';
import ListWidget from '../../../components/widgets/list-widget/list-widget';
import { iLeague, iLeagueSettings } from '@shared/shared-utils/models';
import { OperationsContext } from '../operations-provider';
import Button from '../../../components/ui/button/button';
import { useNavigate } from 'react-router-dom';
import ListItem from '../../../components/widgets/list-widget/list-item';
import { TeamsContext } from '../../../providers/teams-provider';
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { LeagueContext } from '../../../providers/league-provider';
import { environment } from '../../../environments/environment.local.dev';

type OLeagueDetailsType = {
  league: iLeague;
  toggleModal: () => void;
  resetData: () => void;
  owner: string;
  updateLeague: (league: iLeague) => void;
  superAdmin: boolean;
};

const OLeagueDetails = (props: OLeagueDetailsType) => {
  const { league, toggleModal, resetData, owner, updateLeague } = props;
  const {
    convertIdToEmail,
    getMemberSquad,
    deleteLeague,
    reDraftLeague,
    updateLeagueSeason,
  } = useContext(OperationsContext);
  const { getTeamById } = useContext(TeamsContext);
  const { appConfig } = useContext(LeagueContext);
  const [currentLeague, setCurrentLeague] = useState<iLeague>(league);
  const [leagueMemberList, setLeagueMemberList] = useState<ReactNode[]>();
  const [leagueSettings, setLeagueSettings] = useState<JSX.Element>();
  const [showMemberLeagueInfo, setShowMemberLeagueInfo] =
    useState<boolean>(false);
  const [memberTeamsList, setMemberTeamsList] = useState<ReactNode[]>();
  const [activeLineup, setActiveLineup] = useState<ReactNode[]>();
  const [selectedID, setSelectedID] = useState<string>();
  const [memberLeagueRecord, setMemberLeagueRecord] = useState<ReactNode[]>();
  const [invitesList, setInvitesList] = useState<JSX.Element>();
  const [currentSeason, setCurrentSeason] = useState<number>(
    currentLeague.season,
  );

  const navigate = useNavigate();

  useEffect(() => {
    leagueSettingsList(currentLeague.settings);
    leagueInvitesTable();
  }, []);

  const leagueMembersEmailList = async () => {
    const userEmailListItem: ReactNode[] = [];
    currentLeague?.memberIds?.forEach(async (memberID) => {
      const selected = selectedID === memberID ? true : false;

      userEmailListItem.push(
        <ListItem
          children={convertIdToEmail(memberID)}
          itemClick={() => handleMemberClick(memberID)}
          specialClass={selected ? 'bg-gray' : ''}
        />,
      );

      if (currentLeague.id && selected) {
        const squads = await getMemberSquad(currentLeague.id, memberID);
        if (squads) {
          const squadsList: ReactNode[] = [];
          squads.teams.forEach(async (team) => {
            const getTeam = await getTeamById(team);
            if (getTeam) {
              squadsList.push(<ListItem children={getTeam?.abbreviation} />);
            }
          });

          setMemberTeamsList(squadsList);

          const lineup = {
            ps1:
              squads.activeLineUp?.ps1 &&
              (await getTeamById(squads.activeLineUp?.ps1))?.abbreviation,
            ps2:
              squads.activeLineUp?.ps2 &&
              (await getTeamById(squads.activeLineUp?.ps2))?.abbreviation,
            rs1:
              squads.activeLineUp?.rs1 &&
              (await getTeamById(squads.activeLineUp?.rs1))?.abbreviation,
            rs2:
              squads.activeLineUp?.rs2 &&
              (await getTeamById(squads.activeLineUp?.rs2))?.abbreviation,
            ds1:
              squads.activeLineUp?.ds1 &&
              (await getTeamById(squads.activeLineUp?.ds1))?.abbreviation,
            ds2:
              squads.activeLineUp?.ds2 &&
              (await getTeamById(squads.activeLineUp?.ds2))?.abbreviation,
          };

          const activeLineupList: ReactNode[] = [
            <ListItem children={`PS1 = ${lineup.ps1 ?? 'Not selected'}`} />,
            <ListItem children={`PS2 = ${lineup.ps2 ?? 'Not selected'}`} />,
            <ListItem children={`RS1 = ${lineup.rs1 ?? 'Not selected'}`} />,
            <ListItem children={`RS2 = ${lineup.rs2 ?? 'Not selected'}`} />,
            <ListItem children={`DS1 = ${lineup.ds1 ?? 'Not selected'}`} />,
            <ListItem children={`DS2 = ${lineup.ds2 ?? 'Not selected'}`} />,
          ];

          setActiveLineup(activeLineupList);

          const memberRecord = [
            <ListItem
              children={`${league.leaderBoard[memberID].wins ?? 0} | ${
                league.leaderBoard[memberID].losses ?? 0
              }`}
            />,
          ];

          setMemberLeagueRecord(memberRecord);
        } else {
          <ListItem children={'Squad not picked'} />;
        }
      }
    });

    setLeagueMemberList(userEmailListItem);
  };

  const handleMemberClick = (memberID: string) => {
    if (selectedID === memberID) {
      setSelectedID('');
      setShowMemberLeagueInfo(false);
    } else {
      setSelectedID(memberID);
      setShowMemberLeagueInfo(true);
    }
  };

  const leagueSettingsList = async (leagueSettings: iLeagueSettings) => {
    const formattedDraftDate = leagueSettings?.draftDate
      ? new Date(leagueSettings?.draftDate.seconds * 1000).toLocaleDateString()
      : 'Not completed';

    setLeagueSettings(
      <div>
        <table className="table-auto border-2 border-solid">
          <thead className="border-b-2 border-solid">
            <tr>
              <td className="text-center " colSpan={2}>
                League Settings
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">Draft date</td>
              <td className="px-2">{`${formattedDraftDate}`}</td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">
                Free agency enabled
              </td>
              <td className="px-2">
                {leagueSettings.freeAgentEnabled ?? 'Not set'}
              </td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">League ID</td>
              <td className="px-2">{currentLeague?.id}</td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">Member count</td>
              <td className="px-2">{currentLeague?.memberIds?.length}</td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2"># Teams per member</td>
              <td className="px-2">
                {leagueSettings.numOfTeamsPerMember ?? 'Not set'}
              </td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">Trade enabled</td>
              <td className="px-2">
                {leagueSettings.tradeEnabled ?? 'Not set'}
              </td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">Draft Status</td>
              <td className="px-2">{currentLeague?.draftStatus}</td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">League Owner</td>
              <td className="px-2">{owner}</td>
            </tr>
            <tr className="border-b border-solid">
              <td className="border-r border-solid px-2">Created Date</td>
              <td className="px-2">
                {currentLeague.createdAt &&
                  currentLeague.createdAt.toLocaleDateString()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>,
    );
  };

  useEffect(() => {
    leagueMembersEmailList();
  }, [currentLeague, selectedID]);

  const leagueInvitesTable = () => {
    if (currentLeague.invites?.length) {
      console.log('true');
      const invites = currentLeague.invites.map((invite) => (
        <ListItem>{invite.to}</ListItem>
      ));
      const inviteList = (
        <ListWidget
          label="Invites"
          loading={invites?.length > 0 ? false : true}
          items={invites}
        />
      );
      setInvitesList(inviteList);
    }
  };

  const changeCurrentSeason = async (season: number) => {
    if (window.confirm('Are you sure you want to change the season?')) {
      const updatedLeagueData = await updateLeagueSeason(season, currentLeague);
      setCurrentLeague(updatedLeagueData);
      updateLeague(updatedLeagueData);
    }
  };

  //TODO:
  //* show teams movement history (if trades and free agency enabled)
  //* show leaderboard and records for each player
  //* show matchup schedule (past and upcoming)
  //* show matchup details when matchup is clicked
  return (
    <ModalWrapper
      title={`League Details: ${currentLeague?.title}`}
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={toggleModal}
    >
      <div className="mt-2 overflow-y-scroll max-h-[90vh] bg-db-header-background-color flex flex-col items-center">
        <div className="mt-3 mb-3">{leagueSettings}</div>
        <div className="matchup-header flex">
          <div className="flex items-start justify-between w-1/2 py-4">
            {leagueMemberList && (
              <ListWidget
                label="Members"
                loading={false}
                items={leagueMemberList}
              />
            )}
            {showMemberLeagueInfo &&
              memberTeamsList &&
              activeLineup &&
              memberLeagueRecord && (
                <div className="flex">
                  <ListWidget
                    label="Teams"
                    loading={false}
                    items={memberTeamsList}
                  />
                  <ListWidget
                    label="Lineup"
                    loading={false}
                    items={activeLineup}
                  />
                  <ListWidget
                    label="Record"
                    loading={false}
                    items={memberLeagueRecord}
                  />
                </div>
              )}
          </div>
        </div>
        {invitesList ? invitesList : <span>No invites</span>}
        <Button
          label="View League"
          onClick={() => navigate(`/league/${currentLeague?.id}`)}
        />
        <Tooltip
          title={
            !props.superAdmin && 'Must be a super admin to perform that action.'
          }
        >
          <Button
            label="Delete League"
            disabled={!props.superAdmin}
            onClick={async () => {
              if (props.superAdmin) {
                if (
                  window.confirm(
                    `Are you sure you want to delete ${currentLeague?.title}`,
                  )
                ) {
                  if (currentLeague) {
                    //TODO come up with a way to avoid this.
                    const didDelete = await deleteLeague(currentLeague.id);

                    if (didDelete) {
                      window.alert('League Deleted');
                      // Close dialog and reload leagues
                      resetData();
                      toggleModal();
                    }
                  }
                } else {
                  console.log('no go');
                }
              }
            }}
          />
        </Tooltip>
        <Tooltip
          title={
            !props.superAdmin && 'Must be a super admin to perform that action.'
          }
        >
          <Button
            label="Redraft League"
            disabled={!props.superAdmin}
            onClick={async () => {
              if (props.superAdmin) {
                if (
                  window.confirm(
                    `Are you sure you want to redraft ${currentLeague?.title}`,
                  )
                ) {
                  if (currentLeague) {
                    //TODO come up with a way to avoid this.
                    const didReset = await reDraftLeague(currentLeague.id);

                    if (didReset) {
                      window.alert('Draft reset');
                      resetData();
                      toggleModal();
                    }
                  }
                } else {
                  console.log('no go');
                }
              }
            }}
          />
        </Tooltip>
        <div className="db-list mt-6 mb-6">
          <h2 className="pb-1">Testing Functions</h2>
          <div className="overflow-scroll overflow-y-scroll max-h-96 db-list border-t-[1px] border-t-gray rounded-2xl">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Function</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={'leagueSeason'}>
                    <TableCell component="th" scope="row">
                      Set season for league
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={
                          environment.hostingProd
                            ? 'Cannot perform that function on PROD'
                            : !props.superAdmin &&
                              'Must be a super admin to perform that action.'
                        }
                      >
                        <div>
                          <Select
                            value={currentSeason}
                            onChange={(e) =>
                              changeCurrentSeason(+e.target.value)
                            }
                            disabled={
                              environment.hostingProd || !props.superAdmin
                            }
                          >
                            {appConfig.allSeasons?.map((season) => (
                              <MenuItem key={season} value={season}>
                                {season}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default OLeagueDetails;
