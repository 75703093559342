import { useContext, useState, useEffect } from 'react';
import { createTabItems, createTabItemsAll } from './league-constants';
import InviteMembers from './widgets/invite-members';
import TabHeader from '../../../components/ui/tabs/tab-header';
import LeagueBasicDetails from './widgets/league-basic-details';
import Header from '../../../components/widgets/header/header';
import { LeagueContext } from '../../../providers/league-provider';
import LeagueSettings from './widgets/league-settings';
import { iLeague } from '@shared/shared-utils/models';
import { useNavigate } from 'react-router-dom';

export function CreateLeague() {
  const { currentLeague, appConfig } = useContext(LeagueContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [createClicked, setCreateClicked] = useState<boolean>(false);
  const [newlyCreatedLeague, setNewlyCreatedLeague] = useState<iLeague>();
  const [expectedNumLeagueMembers, setExpectedNumLeagueMembers] =
    useState<number>();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentLeague && createClicked) {
      setNewlyCreatedLeague(currentLeague);
    }
  }, [currentLeague, createClicked]);

  return (
    <div id="db-create-league" className="flex flex-col">
      <Header
        title={'Create League'}
        isHome={false}
        subContent={
          <TabHeader
            selectedTabIndex={selectedTabIndex}
            tabItems={!newlyCreatedLeague ? createTabItems : createTabItemsAll}
            onTabChange={(selectedIndex) => {
              setSelectedTabIndex(selectedIndex);
            }}
          />
        }
      />
      <div className="flex-1 pt-[7.8rem]">
        {selectedTabIndex === 0 && !newlyCreatedLeague && (
          <LeagueBasicDetails
            onFinish={() => {
              setCreateClicked(true);
              setSelectedTabIndex(selectedTabIndex);
            }}
            currentSeason={appConfig.currentSeason}
          />
        )}
        {selectedTabIndex === 0 && newlyCreatedLeague && (
          <InviteMembers
            showMembers={true}
            isLeagueActive={true}
            isOwner={true}
            league={newlyCreatedLeague}
            onFinish={() => {
              setSelectedTabIndex(selectedTabIndex + 1);
            }}
            getExpectedNumLeagueMembers={setExpectedNumLeagueMembers}
            updateLeagueState={setNewlyCreatedLeague}
          />
        )}
        {selectedTabIndex === 1 && newlyCreatedLeague && (
          <LeagueSettings
            createdLeagueId={newlyCreatedLeague.id}
            onFinish={() => {
              navigate('/home');
            }}
            // False because only league owner can be on this page
            readOnly={false}
            // False because only league owner can be on this page
            readOnlyScoring={false}
            reactivateLeagueOption={false}
            league={newlyCreatedLeague}
            expectedNumLeagueMembers={expectedNumLeagueMembers}
            isAdmin={true}
          />
        )}
      </div>
    </div>
  );
}

export default CreateLeague;
