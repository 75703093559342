import React from 'react';
import { iTeam, iUser, iRoundPick } from '@shared/shared-utils/models';

interface iDisplayedPick extends iRoundPick {
  position: number;
}
interface DraftPickCardProps {
  pick: iDisplayedPick;
  user: iUser | undefined;
  team: iTeam | undefined;
  borderColor: string;
}

const DraftPickCard: React.FC<DraftPickCardProps> = ({
  pick,
  user,
  team,
  borderColor,
}) => {
  return (
    <div
      className={`p-2 border-2 rounded-lg text-center`}
      style={{ borderColor: borderColor }}
    >
      <div className="font-bold mb-2">{team?.school}</div>
      <div
        style={{
          backgroundImage: `url(${team?.logos[0].replace(/http:/g, 'https:')})`,
        }}
        className="bg-no-repeat bg-cover bg-center rounded-full h-10 w-10 mx-auto team-image"
        title={team?.school}
      />
      <div className="text-sm text-gray-600 mt-2">
        Picked by: {user?.fullName}
      </div>
      <div className="text-xs">
        Round {pick.round}, Pick {pick.position}
      </div>
    </div>
  );
};

export default DraftPickCard;
