import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import './../input.scss';
import { useEffect, useState } from 'react';

type dateInputType = {
  onChange: (date: Date | null) => void;
  placeholder: string;
  initialValue?: Date;
  showTime?: boolean;
  disabled?: boolean;
  e2eTag?: string;
};

export function DateInput(props: dateInputType) {
  const [startDate, setStartDate] = useState<Date | null>(
    props.initialValue ? props.initialValue : null,
  );

  const handleDateSelect = (dateValue: Date | null) => {
    setStartDate(dateValue);
    props.onChange(dateValue);
  };

  useEffect(() => {
    if (props.initialValue) setStartDate(props.initialValue);
  }, [props.initialValue]);

  return (
    <div
      className={`relative input-holder ${props.disabled && 'opacity-30'}`}
      data-e2e={props.e2eTag}
    >
      <DatePicker
        selected={startDate}
        onChange={(date) => handleDateSelect(date)}
        placeholderText={props.placeholder}
        showTimeSelect={props.showTime}
        timeFormat="hh:mm aa"
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="MMMM d, yyyy h:mm aa"
        withPortal
        disabled={props.disabled}
      />
      <FontAwesomeIcon icon={faCalendar} className="absolute top-3 right-3" />
    </div>
  );
}

export default DateInput;
