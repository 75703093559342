import { ReactNode, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  iLeague,
  iUser,
  iMatchup,
  iMyMatchup,
} from '@shared/shared-utils/models';
import { AuthContext } from '../../../../providers/auth-provider';
import { LeagueContext } from '../../../../providers/league-provider';
import Image from '../../../../components/widgets/image';
import './../home.scss';
import { getMatchupScores, getSquadName } from '@shared/shared-utils';
import { TeamsContext } from '../../../../providers/teams-provider';

const MatchpResults = ({
  myMatchups,
  leagues,
  matchupClick,
}: {
  myMatchups: iMyMatchup[];
  leagues: iLeague[];
  matchupClick: (
    matchupWeek: number,
    matchup: iMyMatchup,
    league: iLeague,
  ) => void;
}) => {
  const { userDetails } = useContext(AuthContext);
  const { appConfig, getMemberSquad, getLeagueDetails } =
    useContext(LeagueContext);
  const { getResults } = useContext(TeamsContext);

  const [myMatchupResults, setMyMatchupResults] = useState<ReactNode[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(2022);

  let cachedResults: any = null;

  useEffect(() => {
    if (appConfig) {
      createMatchupList(myMatchups);
      setCurrentYear(appConfig.currentSeason);
    }
  }, [myMatchups, appConfig]);

  const getMemberCard = (memberDetails: iUser, leagueId: string) => {
    return (
      <div className="result-list-item">
        <div className="flex items-center justify-start min-w-[40%]">
          <Image
            key={memberDetails.id}
            imageUrl={memberDetails.profileImage}
            title={memberDetails.fullName}
            specialClass="mr-2"
          />
          <span className="text-base">
            {getSquadName(memberDetails, leagueId)}
          </span>
        </div>
      </div>
    );
  };

  const getInProgressResults = async (
    matchUp: iMatchup,
    league: iLeague,
    round: number,
  ) => {
    let totals: { memberATotal: number; memberBTotal: number } = {
      memberATotal: 0,
      memberBTotal: 0,
    };

    try {
      const memberASq = await getMemberSquad(league.id, matchUp.memberA);
      const memberBSq = await getMemberSquad(league.id, matchUp.memberB);

      if (!cachedResults) {
        cachedResults = await getResults(round, currentYear);
      }
      if (memberASq && memberBSq) {
        totals = getMatchupScores(
          round,
          memberASq,
          memberBSq,
          cachedResults,
          league.settings?.scoring,
        );
      }
    } catch (error) {
      console.error('Error getting ip results', error);
    }

    return totals;
  };

  const createMatchupList = async (matchups: iMyMatchup[]) => {
    if (matchups) {
      const membersList = leagues.flatMap((league) =>
        league.members ? [...league.members] : [],
      );
      matchups.sort((a, b) => b.round - a.round);
      const matchupList = await Promise.all(
        matchups.map(async (matchup: iMyMatchup, index: number) => {
          const memberADetails = membersList?.find(
            (member) => member.id === matchup.memberA,
          );
          const memberBDetails = membersList?.find(
            (member) => member.id === matchup.memberB,
          );
          const league = leagues.find(
            (league) => league.id === matchup.leagueId,
          );
          const isWin = matchup.winnerId === userDetails?.id ? true : false;
          const bgClass = matchup.winnerId
            ? isWin
              ? 'bg-[#065605]'
              : 'bg-[#81280C]'
            : 'bg-gray';

          if (!matchup.winnerId && league) {
            const totals = await getInProgressResults(
              matchup,
              league,
              matchup.round,
            );
            matchup.memberAPoints = totals.memberATotal;
            matchup.memberBPoints = totals.memberBTotal;
          }

          if (memberADetails && memberBDetails && league) {
            return (
              <Link
                key={index}
                to={`/league/${matchup.leagueId}`}
                className="mr-6 no-underline cursor-pointer text-primary db-list list-300"
                onClick={() => {
                  league && matchupClick(matchup.round, matchup, league);
                }}
              >
                <div
                  className={`${bgClass} flex items-center justify-between w-full result-header`}
                >
                  <div className="flex flex-col">
                    <h2>
                      {matchup.winnerId
                        ? isWin
                          ? 'Win'
                          : 'Loss'
                        : appConfig.currentWeek === matchup.round
                        ? 'In Progress'
                        : 'Upcoming'}
                    </h2>
                    <div
                      className="truncate max-w-[200px]"
                      data-toggle="tooltip"
                      title={league?.title}
                    >
                      {league?.title}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-base">week</div>
                    <h2>{matchup.round}</h2>
                  </div>
                </div>
                <div className="p-4 db-list-items-wrapper">
                  <div className="flex items-center justify-between">
                    {getMemberCard(memberADetails, league?.id)}
                    {matchup.memberAPoints && Math.trunc(matchup.memberAPoints)}
                  </div>

                  <div className="separator">
                    <div className="vs-circle">vs</div>
                  </div>
                  <div className="flex items-center justify-between">
                    {getMemberCard(memberBDetails, league?.id)}
                    {matchup.memberBPoints && Math.trunc(matchup.memberBPoints)}
                  </div>
                </div>
              </Link>
            );
          }
          return null;
        }),
      );
      setMyMatchupResults(matchupList);
    }
  };
  return (
    <div className="flex w-full overflow-x-scroll">{myMatchupResults}</div>
  );
};

export default MatchpResults;
