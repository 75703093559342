import { iRoundPick, iDraft } from '@shared/shared-utils/models';

const areRoundPicksEqual = (pick1: iRoundPick, pick2: iRoundPick): boolean => {
  return (
    pick1.memberId === pick2.memberId &&
    pick1.teamId === pick2.teamId &&
    pick1.slot === pick2.slot &&
    pick1.round === pick2.round &&
    pick1.pickBy === pick2.pickBy
  );
};

export const areDraftsEqual = (draft1: iDraft, draft2: iDraft): boolean => {
  if (draft1.status !== draft2.status) return false;
  if (draft1.leagueId !== draft2.leagueId) return false;
  if (draft1.numberOfRounds !== draft2.numberOfRounds) return false;
  if (draft1.round !== draft2.round) return false;
  if (draft1.members.length !== draft2.members.length) return false;
  if (draft1.members.some((member, index) => member !== draft2.members[index]))
    return false;
  if (JSON.stringify(draft1.pickOrder) !== JSON.stringify(draft2.pickOrder))
    return false;
  if (draft1.picks.length !== draft2.picks.length) return false;
  if (
    draft1.picks.some(
      (pick, index) => !areRoundPicksEqual(pick, draft2.picks[index]),
    )
  )
    return false;
  if (JSON.stringify(draft1.membersList) !== JSON.stringify(draft2.membersList))
    return false;
  if (
    JSON.stringify(draft1.currentRoundPickOrderList) !==
    JSON.stringify(draft2.currentRoundPickOrderList)
  )
    return false;

  return true;
};
