import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ChangeEvent } from 'react';

type UserConsentType = {
  setTermsAgreed: (agreed: boolean) => void;
  termsAgreed?: boolean;
};

const UserConsentCheckbox = (props: UserConsentType) => {
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.termsAgreed}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.setTermsAgreed(e.target.checked);
              }}
            />
          }
          label={
            <div>
              <span>I agree to the </span>
              <a
                href="https://squadblitz.com/terms-and-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>*
            </div>
          }
        />
      </FormGroup>
    </div>
  );
};

export default UserConsentCheckbox;
