import Modal from '@mui/material/Modal';
import Moment from 'react-moment';
import { allTrophies, iTrophy, iUserTrophy } from '@shared/shared-utils/models';
import { Timestamp } from 'firebase/firestore';

type TrophyZoomProps = {
  trophy: iTrophy;
  onCloseClicked: () => void;
  trophyData: iUserTrophy;
  open: boolean;
};

const TrophyZoom = (props: TrophyZoomProps) => {
  try {
    return (
      <Modal open={props.open} onClose={props.onCloseClicked}>
        <div className="flex flex-col items-center max-h-[100vh] overflow-scroll">
          <div className="flex flex-col w-[85%] md:w-[60%] mt-[10vh] bg-gray p-4 border-white border-solid rounded border-[3px]">
            <div className="w-full flex flex-row justify-end">
              <img
                src="/assets/images/cancel.png"
                className="w-4 h-4 cursor-pointer ml-auto"
                onClick={props.onCloseClicked}
                alt="cancel"
                data-e2e="trophy_zoom_close_button"
              />
            </div>
            <img src={props.trophy.path} alt={props.trophy.title} />
            <span className="font-bold">{props.trophy.title}</span>
            <span>{props.trophy.description}</span>
            {props.trophyData.trophyType !==
              allTrophies['adminSB'].trophyType && (
              <span>
                Awarded on{' '}
                <Moment format="M/D/YY">{props.trophyData.awardDate}</Moment>
              </span>
            )}
            {props.trophyData.progress && (
              <span>Current progress: {props.trophyData.progress}</span>
            )}
          </div>
        </div>
      </Modal>
    );
  } catch (err) {
    return null;
  }
};

export default TrophyZoom;
