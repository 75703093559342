import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const AboutUsPage = () => {
  return (
    <div className="flex flex-col football-bg items-center p-4">
      <Link to="/">
        <img
          src="../../../assets/images/centered-logo.png"
          alt="centered-logo"
        />
      </Link>
      <div className="italic pt-2 pb-4 text-sm">
        College Fantasy Football That Works!
      </div>
      {/* TODO: style this section? */}
      <div className="py-4">
        Conventional fantasy football just doesn't work for college football. We
        are college football fans, but we are tired of trying to keep up with
        obscure players on teams that we have never heard of. So, we decided to
        do something about it by creating Squad Blitz! Instead of drafting
        players, you will draft teams and compete in weekly matchups against
        your friends! It is traditional fantasy sports built the right way for
        college football fans!
      </div>
      <div className="flex flex-col items-start w-full space-y-3">
        <span>
          Visit us online at{' '}
          <a href="https://squadblitz.com/" target="_blank" rel="noreferrer">
            squadblitz.com
          </a>
        </span>

        <span>
          Need to contact us? Email us at{' '}
          <a href="mailto:squadblitzapp@gmail.com?subject=Contact SquadBlitz">
            squadblitzapp@gmail.com
          </a>
          Text us at <a href="tel:850-816-0398">(850) 816-0398</a>
          (this number only accepts texts)
        </span>
        {/* TODO: add version number */}
        <span>
          Version:{' '}
          {document
            .querySelector('meta[name="version"]')
            ?.getAttribute('content')}
        </span>
      </div>
      <div className="w-full text-right pt-4">
        <Link
          to="/"
          style={{
            color: '#00BE2A',
          }}
        >
          Back
        </Link>
      </div>
    </div>
  );
};

export default AboutUsPage;
