import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/auth-provider';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailInput from '../../../components/ui/email-input/email-input';
import { ToastContext } from '../../../providers/toast-provider';
import Loading from '../../../components/ui/loading-animation';
import AuthWrapper from '../../../components/ui/auth-wrapper/auth-wrapper';
import { Button } from '@mui/material';

const ForgotPassword = ({ props }: any) => {
  const { isLoggedIn, currentUser, sendResetPasswordEmail } =
    useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const mEmail = state?.userEmail ? state?.userEmail : undefined;
  const [email, setEmail] = useState<string | undefined>(mEmail);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);

  const resetPassword = async () => {
    if (email) {
      try {
        await sendResetPasswordEmail(email);
        showToast({
          messageType: 'info',
          message:
            'If an account associated with this email exists, we will send a reset link.',
        });
        setForgotPasswordLoading(false);
        navigate('/login');
      } catch (error) {
        showToast({
          messageType: 'error',
          message: `Something is wrong with that email. Please try again. ${error}`,
        });
        setForgotPasswordLoading(false);
      }
    }
  };

  const navigateToCorrectPage = async () => {
    if (isLoggedIn) {
      const redirectUrl = localStorage.getItem('rTo');
      if (redirectUrl) {
        navigate(redirectUrl);
        return;
      }
      navigate('/home');
    }
  };

  useEffect(() => {
    navigateToCorrectPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, currentUser]);

  return forgotPasswordLoading ? (
    <Loading></Loading>
  ) : (
    <AuthWrapper>
      <h2>Forgot Password?</h2>
      <p className="mt-4 mb-4">We will send a reset link to your e-mail address.</p>
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          if (email) {
            setForgotPasswordLoading(true);
            resetPassword();
          }
        }}
      >
        <div>
          <EmailInput
            label="Email Address"
            value={email}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="flex justify-between mt-4">
          <Button
            color="secondary"
            onClick={() => {
              navigate('/');
            }}
          >
            CANCEL
          </Button>
          <Button
            disabled={!email}
            variant="outlined"
            onClick={() => {
              setForgotPasswordLoading(true);
              resetPassword();
            }}
          >
            SEND
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ForgotPassword;
