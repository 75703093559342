import '../input.scss';

export function LineButton(props: any) {
  return (
    <button
      type="button"
      className="mr-4 font-semibold underline text-danger"
      {...props}
    >
      {props.label}
    </button>
  );
}

export default LineButton;
