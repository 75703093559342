import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface NonLeagueItemProps {
  to?: string;
  backgroundImage?: string;
  e2eTag?: string;
  icon: IconDefinition;
  iconOnClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  linkOnClick?: () => void;
  iconDataE2E: string;
  upperText: string;
  lowerText: string;
  showUpperTextOverlay?: boolean;
  isDisabled?: boolean;
}

function NonLeagueItem({
  to,
  backgroundImage,
  e2eTag,
  icon,
  iconOnClick,
  linkOnClick,
  iconDataE2E,
  upperText,
  lowerText,
  showUpperTextOverlay = false,
  isDisabled = false,
}: NonLeagueItemProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    if (isDisabled) {
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const backgroundStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : {};

  const upperTextStyle = showUpperTextOverlay
    ? { backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white' }
    : {};

  const Layout = () => (
    <>
      <div
        className={`w-64 h-64 overflow-hidden container flex items-center justify-center relative ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={handleOpenDialog}
      >
        <div className="absolute inset-0 bg-black opacity-30 z-10" />
        {backgroundImage && (
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={backgroundStyle}
          />
        )}
        <div className="flex flex-col items-center z-10">
          <div className="border-2 border-accent bg-black rounded-full p-4 mb-2">
            <FontAwesomeIcon
              icon={icon}
              className="text-white fa-2xl"
              onClick={iconOnClick}
              data-e2e={iconDataE2E}
            />
          </div>
          <div className="text-center" style={upperTextStyle}>
            {upperText}
          </div>
        </div>
      </div>
      <div className="flex items-center border-t-2 border-accent w-64 h-40 bg-db-background-color">
        <div className="flex items-center text-center">{lowerText}</div>
      </div>
    </>
  );

  return (
    <>
      {isDisabled ? (
        <div
          className="block overflow-hidden no-underline text-primary league-item rounded-2xl max-w-64 opacity-50 cursor-not-allowed"
          data-e2e={e2eTag}
          onClick={handleOpenDialog}
        >
          <Layout />
        </div>
      ) : to ? (
        <Link
          to={to}
          className="block overflow-hidden no-underline cursor-pointer text-primary league-item rounded-2xl max-w-64"
          data-e2e={e2eTag}
        >
          <Layout />
        </Link>
      ) : (
        <a
          onClick={(event) => {
            if (linkOnClick) {
              event.preventDefault();
              linkOnClick();
            }
          }}
          href="/"
          className="block overflow-hidden no-underline cursor-pointer text-primary league-item rounded-2xl max-w-64"
          data-e2e={e2eTag}
        >
          <Layout />
        </a>
      )}

      {/* MUI Dialog with Close Button */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          We're Making Key Upgrades
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          We're making key upgrades to our playbook—revamping season-long
          leagues and introducing Daily Fantasy Sports contests. Stay tuned as
          we roll out improvements leading up to kickoff!
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NonLeagueItem;
