import Table from '../../../components/ui/table/table';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { iTeam } from '@shared/shared-utils/models';
import { hasGameStarted } from '../../../utils/game-utils';

type OTeamTableRow = {
  allTeams: iTeam[];
  activeStatusFilterOption?: string;
  globalFilter: string;
  updateGlobalFilter: any;
  columnFilters: ColumnFiltersState;
  updateColFilter: any;
  rowClick: (team: iTeam) => void;
  getTeamRowCount: (numRows: number) => void;
};

const OTeamsTable = (props: OTeamTableRow) => {
  const {
    allTeams,
    globalFilter,
    updateGlobalFilter,
    columnFilters,
    updateColFilter,
    rowClick,
    getTeamRowCount,
  } = props;
  const columnHelper = createColumnHelper<iTeam>();

  const headerStyling = (props?: string) => {
    return <div className="text-center">{props}</div>;
  };

  const defaultColumns = [
    columnHelper.accessor((row) => `${row.school}`, {
      id: 'schoolName',
      header: () => headerStyling('School'),
    }),
    columnHelper.accessor((row) => `${row.id}`, {
      id: 'teamId',
      header: () => headerStyling('Team ID'),
    }),
    columnHelper.accessor(
      (row) => `${row.nextGame?.start_date?.split('T')[0]}`,
      {
        id: 'nextGame',
        header: () => headerStyling('Next Game'),
      },
    ),
    columnHelper.accessor((row) => hasGameStarted(row.nextGame?.start_date), {
      id: 'teamLocked',
      header: () => headerStyling('Team Locked?'),
    }),
  ];

  return (
    <Table
      data={allTeams}
      columns={defaultColumns}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      rowSelection={''}
      updateGlobalFilter={updateGlobalFilter}
      updateColFilter={updateColFilter}
      canSelect={1}
      rowClick={rowClick}
      selectedRow={''}
      getRowCount={getTeamRowCount}
    />
  );
};

export default OTeamsTable;
