import LeagueRecord from '../../../modules/authorized-pages/league/widgets/league-record';
import { Link } from 'react-router-dom';
import {
  iLeague,
  leagueDraftStatus,
  iLeagueMemberSquadDb,
} from '@shared/shared-utils/models';
import Image from '../image';
import { useContext, useEffect, useState } from 'react';
import { LeagueContext } from '../../../providers/league-provider';
import './league-item.scss';
import { ordinalRanking } from '@shared/shared-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTrendDown,
  faArrowTrendUp,
} from '@fortawesome/free-solid-svg-icons';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material';

type LeagueItemType = {
  league: iLeague;
  userId: string;
  leagueActive: boolean;
  unreadMessages: boolean;
};

const LeagueItem = (props: LeagueItemType) => {
  const { league, userId, leagueActive, unreadMessages } = props;
  const [leagueSquad, setLeagueSquad] = useState<iLeagueMemberSquadDb>();
  const { getMyLeagueSquad, setCurrentLeague } = useContext(LeagueContext);
  const [userRanking, setUserRanking] = useState<{
    number: number;
    suffix: string;
  }>();
  const [movementIndicator, setMovementIndicator] = useState<JSX.Element>();

  //Get the league member's current squad and pass into LeagueRecord
  const getCurrentLeagueSquad = async () => {
    if (league.id) {
      const squad = await getMyLeagueSquad(league.id);
      if (squad) {
        setLeagueSquad(squad);
      }
    }
  };

  //Gets the league leaderboard data from the db and sets the user's rank as ordinal rank
  const getUserBoardData = async () => {
    if (league.leaderBoard) {
      setUserRanking(ordinalRanking[league.leaderBoard[userId]?.rank]?.numeric);

      //If user has a winning streak, show arrow up icon
      if (league.leaderBoard[userId]?.winningStreak) {
        const movementIcon = (
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            size="sm"
            color="rgb(34 197 94)"
          />
        );
        setMovementIndicator(movementIcon);

        //If user does not have a winning streak, show arrow up icon
      } else {
        const movementIcon = (
          <FontAwesomeIcon
            icon={faArrowTrendDown}
            size="sm"
            color="rgb(239 68 68)"
          />
        );
        setMovementIndicator(movementIcon);
      }
    }
  };

  useEffect(() => {
    getCurrentLeagueSquad();
  }, [league]);

  useEffect(() => {
    getUserBoardData();
  }, [league.id]);

  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: 88,
      left: 63,
    },
  }));

  return (
    <StyledBadge
      badgeContent={unreadMessages ? 'Unread messages' : null}
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Link
        to={
          league.draftStatus === leagueDraftStatus.done
            ? `/league/${league.id}`
            : `/draft/${league.id}`
        }
        className={`block overflow-hidden no-underline cursor-pointer text-primary league-item rounded-2xl max-w-64${
          league.draftStatus === leagueDraftStatus.inProgress ? ' drafting' : ''
        }`}
        onClick={() => setCurrentLeague(league)}
        data-e2e={`leagueItem_${league.id}`}
      >
        <div className="w-64 h-64 overflow-hidden image container">
          {!leagueActive && (
            <div className={`inactive-banner uppercase w-full py-2`}>
              Season Inactive
            </div>
          )}
          <img
            src={league.bannerImage}
            className="w-64 h-64"
            alt={league.title}
          />
          <div className="opacity-50 bottom-[8px] left-[4%] absolute min-w-[100px] min-h-[50px] bg-black"></div>
          <div className="bottom-left">
            {userRanking?.number}
            <sup>{userRanking?.suffix}</sup>{' '}
            {movementIndicator && movementIndicator}
          </div>
        </div>
        <div className="flex flex-col justify-evenly w-64 h-40 px-4 pt-2 pb-4 details bg-db-background-color">
          <div className="text-lg font-bold">{league.title}</div>
          <LeagueRecord
            results={{
              wins: league?.leaderBoard[userId]?.wins,
              losses: league?.leaderBoard[userId]?.losses,
            }}
            stylingClasses={{
              titleWLT: 'text-xs',
              record: 'text-xs font-bold',
            }}
          />
          <div className="flex mt-2 members-list">
            {league?.members?.length &&
              league?.members?.map((member) => (
                <Image
                  key={member.id}
                  imageUrl={member?.profileImage}
                  title={member?.fullName}
                  size={8}
                />
              ))}
          </div>
        </div>
      </Link>
    </StyledBadge>
  );
};

export default LeagueItem;
