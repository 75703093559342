import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import ImagePicker from '../../../components/ui/image-picker/image-picker';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { LeagueContext } from '../../../providers/league-provider';
import { leagueDraftStatus } from '@shared/shared-utils/models';

type LeagueType = {
  title: string;
  leagueDescription: string;
  leagueBanner?: File;
  season: number;
  isOpen: boolean;
  internalLeague: boolean;
  creator: string;
  draftStatus: leagueDraftStatus;
};

type BasicDetailsProps = {
  league?: LeagueType;
  updateDetails: (details: LeagueType) => void;
};

const BasicDetails = ({ league, updateDetails }: BasicDetailsProps) => {
  const { appConfig } = useContext(LeagueContext);

  const [leagueName, setLeagueName] = useState(league?.title ?? '');
  const [leagueDescription, setLeagueDescription] = useState(
    league?.leagueDescription ?? '',
  );
  const [leagueBanner, setLeagueBanner] = useState<File | undefined>(
    league?.leagueBanner,
  );
  const [selectedSeason, setSelectedSeason] = useState(
    league?.season ?? appConfig.currentSeason,
  );
  const [leagueIsOpen, setLeagueIsOpen] = useState<boolean>(
    league?.isOpen ?? false,
  );
  const [leagueIsInternal, setLeagueIsInternal] = useState<boolean>(
    league?.internalLeague ?? false,
  );
  const [leagueCreator, setLeagueCreator] = useState<string>(
    league?.creator ?? '',
  );
  const [draftStatus, setDraftStatus] = useState<leagueDraftStatus>(
    leagueDraftStatus.pending,
  );

  // Sync state when a new league is provided
  useEffect(() => {
    if (league) {
      setLeagueName(league.title);
      setLeagueDescription(league.leagueDescription);
      setLeagueBanner(league.leagueBanner);
      setSelectedSeason(league.season);
      setLeagueIsOpen(league.isOpen);
      setLeagueIsInternal(league.internalLeague);
      setDraftStatus(league.draftStatus);
    }
  }, [league]);

  // Update parent component when values change
  useEffect(() => {
    updateDetails({
      title: leagueName,
      leagueDescription: leagueDescription,
      leagueBanner,
      season: selectedSeason,
      isOpen: leagueIsOpen,
      internalLeague: leagueIsInternal,
      creator: leagueCreator,
      draftStatus: draftStatus,
    });
  }, [
    leagueName,
    leagueDescription,
    leagueBanner,
    selectedSeason,
    leagueIsOpen,
    leagueIsInternal,
    leagueCreator,
    draftStatus,
  ]);

  return (
    <div className="space-y-4">
      <FormControl fullWidth>
        <InputLabel id="season-select-label">Season</InputLabel>
        <Select
          labelId="season-select-label"
          id="season-select"
          value={selectedSeason}
          onChange={(e) => setSelectedSeason(+e.target.value)}
          label="Season"
        >
          {appConfig.allSeasons.map((season) => (
            <MenuItem key={season} value={season}>
              {season}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        value={leagueCreator}
        label="League Creator Email"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setLeagueCreator(event.target.value);
        }}
        required={true}
      />

      <ImagePicker
        label="Add league banner *"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files?.length) {
            setLeagueBanner(e.target.files[0]);
          }
        }}
      />

      <TextField
        fullWidth
        value={leagueName}
        label="League Name"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.value.length < 31) {
            setLeagueName(event.target.value);
          }
        }}
        required={true}
      />

      <TextField
        fullWidth
        value={leagueDescription}
        label="League Description"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setLeagueDescription(event.target.value);
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={leagueIsInternal}
              onChange={(e) => setLeagueIsInternal(e.target.checked)}
            />
          }
          label="Internal League"
        />
      </FormGroup>

      <FormControl fullWidth>
        <InputLabel id="draftStatus-select-label">Draft Status</InputLabel>
        <Select
          labelId="draftStatus-select-label"
          id="draftStatus-select"
          value={draftStatus}
          onChange={(e) => setDraftStatus(e.target.value as leagueDraftStatus)}
          label="Draft Status"
        >
          <MenuItem
            key={leagueDraftStatus.pending}
            value={leagueDraftStatus.pending}
          >
            {leagueDraftStatus.pending}
          </MenuItem>
          <MenuItem
            key={leagueDraftStatus.inProgress}
            value={leagueDraftStatus.inProgress}
          >
            {leagueDraftStatus.inProgress}
          </MenuItem>
          <MenuItem key={leagueDraftStatus.done} value={leagueDraftStatus.done}>
            {leagueDraftStatus.done}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default BasicDetails;
