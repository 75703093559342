import { ReactNode, useEffect, useState } from 'react';
import ListWidget from '../../../../../components/widgets/list-widget/list-widget';
import { matchupFilterOptions } from '../../league-constants';
import { iMatchup, iUser } from '@shared/shared-utils/models';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getSquadName } from '@shared/shared-utils';
import Image from '../../../../../components/widgets/image';
import ListItem from '../../../../../components/widgets/list-widget/list-item';

type WeeklyMatchupsProps = {
  selectedWeek: number;
  matchupsLoading: boolean;
  handleWeekChange: (week: number) => void;
  matchupsData: {
    matchup: iMatchup;
    memberADetails: iUser | undefined;
    memberBDetails: iUser | undefined;
    memberAPoints: number;
    memberBPoints: number;
  }[];
  handleMatchupClick: (matchupIndex: number) => void;
  leagueId: string;
  userId: string;
};

export const WeeklyMatchups = (props: WeeklyMatchupsProps) => {
  const [matchupList, setMatchupList] = useState<ReactNode[]>();
  const getListForSeeding = (rating: number) => {
    switch (rating) {
      case 1:
        return 'Championship Game';
      case 2:
        return 'Winners Consolation Game';
      default:
        return '';
    }
  };

  const buildMatchups = () => {
    const matchUpItems = [];
    for (let i = 0; i < props.matchupsData.length; i++) {
      const matchup = props.matchupsData[i].matchup;
      const memberADetails = props.matchupsData[i].memberADetails;
      const memberBDetails = props.matchupsData[i].memberBDetails;
      const memberAPoints = props.matchupsData[i].memberAPoints;
      const memberBPoints = props.matchupsData[i].memberBPoints;

      if (memberADetails && memberBDetails) {
        matchUpItems.push(
          <ListItem
            children={
              <div className="flex flex-col">
                <div className="text-center">
                  {/* Show only in week 13 and for the top 2 games */}
                  {matchup?.postSeasonRank &&
                    matchup.round === 13 &&
                    getListForSeeding(matchup.postSeasonRank)}
                </div>
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    props.handleMatchupClick(i);
                    const analytics = getAnalytics();
                    logEvent(analytics, 'select_content', {
                      content_type: 'matchup-details',
                      content_id: '',
                      user_id: props.userId,
                    });
                  }}
                >
                  <div className="flex items-center justify-start min-w-[40%]">
                    <Image
                      imageUrl={memberADetails.profileImage}
                      title={memberADetails.fullName}
                      key={memberADetails.id}
                      specialClass="mr-2"
                    />
                    <div className="flex flex-col items-start">
                      <span className="text-base">
                        {getSquadName(memberADetails, props.leagueId)}
                      </span>
                      {memberAPoints && <span>{memberAPoints}</span>}
                    </div>
                  </div>
                  <div className="matchup-item min-w-[20%] text-center">
                    {matchup.winnerId && (
                      <div
                        className={`${
                          matchup.winnerId === memberADetails.id
                            ? 'win-l'
                            : 'loss-l'
                        }`}
                      ></div>
                    )}
                    <div className="flex justify-around">
                      <div>{matchup.memberASeed}</div>
                      Vs
                      <div>{matchup.memberBSeed}</div>
                    </div>

                    {matchup.winnerId && (
                      <div
                        className={`${
                          matchup.winnerId === memberBDetails.id
                            ? 'win-r'
                            : 'loss-r'
                        }`}
                      ></div>
                    )}
                  </div>
                  <div className="flex items-center justify-end min-w-[40%]">
                    <div className="flex flex-col items-end">
                      <span className="text-base text-right">
                        {getSquadName(memberBDetails, props.leagueId)}
                      </span>
                      {memberBPoints && <span>{memberBPoints}</span>}
                    </div>
                    <Image
                      imageUrl={memberBDetails.profileImage}
                      title={memberBDetails.fullName}
                      key={memberBDetails.id}
                      specialClass="ml-2"
                    />
                  </div>
                </div>
              </div>
            }
          />,
        );
      }
    }

    if (props.selectedWeek > 12) {
      // Show which round the playoff is on and push to the front of the array
      const winnersBracketListItem = (
        <ListItem
          children={
            <div>
              <div className="text-center">Winners Bracket</div>
            </div>
          }
        />
      );

      const losersBracketListItem = (
        <ListItem
          children={
            <div>
              <div className="text-center">Consolation Bracket</div>
            </div>
          }
        />
      );

      if (!matchUpItems.length) {
        matchUpItems.push(
          <ListItem
            children={
              <div>
                <div className="text-center">
                  Playoff Matchups Not Yet Available!
                </div>
              </div>
            }
          />,
        );
      } else {
        matchUpItems.unshift(winnersBracketListItem);

        if (matchUpItems.length >= 4) {
          // add the losers bracket to the 4th slot of the array
          matchUpItems.splice(3, 0, losersBracketListItem);
        }
      }
    }

    setMatchupList(matchUpItems);
  };

  useEffect(() => {
    buildMatchups();
  }, [props.selectedWeek, props.matchupsData]);
  //TODO should this run if loading changes?

  return (
    <>
      {/* matchupList will not be undefined if the build function ran */}
      {matchupList && (
        <ListWidget
          filterOptions={matchupFilterOptions}
          filterInitialValue={props.selectedWeek}
          filterPlaceholder={'Week ' + props.selectedWeek}
          onFilterChange={(optionSelected) => {
            props.handleWeekChange(optionSelected.value);
          }}
          items={matchupList} //TODO handle empty array
          label={
            props.selectedWeek > 12
              ? `Playoffs - Round ${props.selectedWeek === 13 ? '1' : '2'}`
              : 'Matchups'
          }
          loading={props.matchupsLoading}
        />
      )}
    </>
  );
};

export default WeeklyMatchups;
