import '../input.scss';

export function Button(props: any) {
  return (
    <button
      type="button"
      className={`${props.color ? props.color : 'bg-accent'} m-px`}
      {...props}
    >
      {props.label}
    </button>
  );
}

export default Button;
