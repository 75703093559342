import { useContext, useState } from 'react';
import '../../components/ui/select/select-input';
import '../../components/ui/input.scss';
import Header from '../../components/widgets/header/header';
import { iAppConfig, iLeague, iTeam, iUser } from '@shared/shared-utils/models';
import { AuthContext } from '../../providers/auth-provider';
import Redirect from '../unauthorized-pages/redirect/redirect';
import OLeagueDetails from './o-league/o-league-details';
import { OperationsContext } from './operations-provider';
import OLeaguesTable from '../operations-portal/o-league/o-leagues-table';
import OUsersTable from './o-user/o-users-table';
import DebouncedInput from '../../components/ui/debounced-input/debounced-input';
import { ColumnFiltersState } from '@tanstack/react-table';
import OTeamsTable from './o-team/o-teams-table';
import OUserDetails from './o-user/o-user-details';
import { LeagueContext } from '../../providers/league-provider';
import OTeamDetails from './o-team/o-team-details';
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Button,
} from '@mui/material';
import { environment } from '../../environments/environment.local.dev';

const OperationsPortal = () => {
  const { appConfig } = useContext(LeagueContext);
  const { superAdminStatus, adminStatus } = useContext(AuthContext);
  const {
    allLeagues,
    allUsers,
    allTeams,
    initOperationsData,
    updateAppConfig,
  } = useContext(OperationsContext);
  const [currentAppConfig, setCurrentAppConfig] =
    useState<iAppConfig>(appConfig);
  const [clickedLeague, setClickedLeague] = useState<iLeague>();
  const [clickedUser, setClickedUser] = useState<iUser>();
  const [clickedTeam, setClickedTeam] = useState<iTeam>();
  const [showLeagueDetailsModal, setShowLeagueDetailsModal] = useState(false);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState<string>('All');
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [currentSeason, setCurrentSeason] = useState<number>(
    currentAppConfig.currentSeason,
  );
  const [currentWeek, setCurrentWeek] = useState<number>(
    currentAppConfig.currentWeek,
  );
  const [numLeaguesReturned, setNumLeaguesReturned] = useState<number>();
  const [numUsersReturned, setNumUsersReturned] = useState<number>();
  const [numTeamsReturned, setNumTeamsReturned] = useState<number>();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingDataType, setLoadingDataType] = useState('');

  const redirectUser = superAdminStatus || adminStatus ? false : true;

  const handleLeagueClick = (league?: iLeague) => {
    setClickedLeague(league);
    toggleOLeagueDetailsModal();
  };

  const handleUserClick = (user: iUser) => {
    setClickedUser(user);
    toggleOUserDetailsModal();
  };

  const handleTeamClick = (team: iTeam) => {
    setClickedTeam(team);
    toggleOTeamDetailsModal();
  };

  const toggleOLeagueDetailsModal = () => {
    setShowLeagueDetailsModal(!showLeagueDetailsModal);
  };

  const toggleOUserDetailsModal = () => {
    setShowUserDetailsModal(!showUserDetailsModal);
  };

  const toggleOTeamDetailsModal = () => {
    setShowTeamDetailsModal(!showTeamDetailsModal);
  };

  const leagueOwnerEmail = (league: iLeague) => {
    const creatorID = league.creator?.id;
    const userMatch = allUsers?.find((user) => user.id === creatorID);
    return userMatch?.email;
  };

  const selectChange = (e: { target: { value: string } }) => {
    switch (e.target.value) {
      case 'Active':
        setFilterStatus('Active');
        setGlobalFilter(String(true));
        break;
      case 'Inactive':
        setFilterStatus('Inactive');
        setGlobalFilter(String(false));
        break;
      case 'All':
        setFilterStatus('All');
        setGlobalFilter('');
        break;
      case 'External':
        setFilterStatus('External');
        setGlobalFilter('External');
        break;
      default:
        setFilterStatus(' ');
        setGlobalFilter('');
        break;
    }
  };

  const loadData = async (dataType: 'all' | 'leagues' | 'users' | 'teams') => {
    setLoadingData(true);
    await initOperationsData(dataType);
    setDataLoaded(true);
    setLoadingData(false);
  };

  const changeCurrentSeason = async (season: number) => {
    if (window.confirm('Are you sure you want to change the season?')) {
      const newAppConfig = await updateAppConfig(
        season,
        currentWeek,
        currentAppConfig,
      );
      setCurrentSeason(season);
      setCurrentAppConfig(newAppConfig);
    }
  };

  const changeCurrentWeek = async (week: number) => {
    if (window.confirm('Are you sure you want to change the week?')) {
      const newAppConfig = await updateAppConfig(
        currentSeason,
        week,
        currentAppConfig,
      );
      setCurrentWeek(week);
      setCurrentAppConfig(newAppConfig);
    }
  };

  return redirectUser ? (
    <Redirect />
  ) : (
    <div className="flex flex-col football-bg">
      <Header
        title="Operations Portal"
        isHome={false}
        cancelLink="/home"
        isOP={true}
      />
      <div className="pt-[7rem]">
        <div className="flex flex-col items-center mt-8 space-y-1">
          {allLeagues?.length ?? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                loadData('leagues');
                setLoadingDataType('leagues');
              }}
              disabled={loadingData && loadingDataType === 'leagues'}
            >
              {loadingData && loadingDataType === 'leagues'
                ? 'Loading...'
                : 'Load Leagues'}
            </Button>
          )}
          {allUsers?.length ?? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                loadData('users');
                setLoadingDataType('users');
              }}
              disabled={loadingData && loadingDataType === 'users'}
            >
              {loadingData && loadingDataType === 'users'
                ? 'Loading...'
                : 'Load Users'}
            </Button>
          )}
          {allTeams?.length ?? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                loadData('teams');
                setLoadingDataType('teams');
              }}
              disabled={loadingData && loadingDataType === 'teams'}
            >
              {loadingData && loadingDataType === 'teams'
                ? 'Loading...'
                : 'Load Teams'}
            </Button>
          )}
          {!dataLoaded && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                loadData('all');
                setLoadingDataType('all');
              }}
              disabled={loadingData && loadingDataType === 'all'}
            >
              {loadingData && loadingDataType === 'all'
                ? 'Loading...'
                : 'Load All Data'}
            </Button>
          )}
        </div>

        <div>
          <div className="flex bg-db-background-color z-40 sticky top-[106px] pb-2">
            <select
              name="activeStatus"
              value={filterStatus}
              onChange={selectChange}
              className="input-holder bg-transparent p-2 border font-lg border-primary border-b-primary"
              style={{
                width: '80px',
              }}
            >
              <option value={'All'} className="text-black">
                All
              </option>
              <option value={'Active'} className="text-black">
                Active
              </option>
              <option value={'Inactive'} className="text-black">
                Inactive
              </option>
              <option value={'External'} className="text-black">
                External Leagues Only
              </option>
            </select>
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              className="p-2 border font-lg border-primary"
              placeholder="Search anything"
              inputStyle="w-full"
            />
          </div>

          <div className="flex flex-1 justify-between pt-[3.8rem]">
            <h4>League Data</h4>
            <span>
              {'Total: ' + (numLeaguesReturned || allLeagues?.length)}
            </span>
          </div>
          <div className="db-list mt-6 mb-6">
            <div className="overflow-scroll overflow-y-scroll max-h-96 db-list border-t-[1px] border-t-gray rounded-2xl">
              <OLeaguesTable
                leagues={allLeagues || []}
                rowClick={handleLeagueClick}
                getOwner={leagueOwnerEmail}
                globalFilter={globalFilter}
                updateGlobalFilter={setGlobalFilter}
                columnFilters={columnFilters}
                updateColFilter={setColumnFilters}
                getLeagueRowCount={setNumLeaguesReturned}
              />
            </div>
            {showLeagueDetailsModal && clickedLeague && (
              <OLeagueDetails
                league={clickedLeague}
                toggleModal={toggleOLeagueDetailsModal}
                resetData={() => loadData('all')}
                owner={leagueOwnerEmail(clickedLeague) || ''}
                updateLeague={setClickedLeague}
                superAdmin={superAdminStatus}
              />
            )}
          </div>
          <div className="flex flex-1 justify-between pt-[2rem]">
            <h4>User Data</h4>
            <span>{'Total: ' + (numUsersReturned || allUsers?.length)}</span>
          </div>
          <div className="db-list mt-6 mb-6">
            <div className="overflow-scroll overflow-y-scroll max-h-96 db-list border-t-[1px] border-t-gray rounded-2xl">
              <OUsersTable
                allLeagues={allLeagues || []}
                allUsers={allUsers || []}
                activeStatusFilterOption={filterStatus}
                globalFilter={globalFilter}
                updateGlobalFilter={setGlobalFilter}
                columnFilters={columnFilters}
                updateColFilter={setColumnFilters}
                rowClick={handleUserClick}
                getUserRowCount={setNumUsersReturned}
              />
            </div>
            {showUserDetailsModal && clickedUser && (
              <OUserDetails
                user={clickedUser}
                toggleModal={toggleOUserDetailsModal}
              />
            )}
          </div>
          <div className="flex flex-1 justify-between pt-[2rem]">
            <h4>Team Data</h4>
            <span>{'Total: ' + (numTeamsReturned || allTeams?.length)}</span>
          </div>
          <div className="db-list mt-6 mb-6">
            <div className="overflow-scroll overflow-y-scroll max-h-96 db-list border-t-[1px] border-t-gray rounded-2xl">
              <OTeamsTable
                allTeams={allTeams || []}
                activeStatusFilterOption={filterStatus}
                globalFilter={globalFilter}
                updateGlobalFilter={setGlobalFilter}
                columnFilters={columnFilters}
                updateColFilter={setColumnFilters}
                rowClick={handleTeamClick}
                getTeamRowCount={setNumTeamsReturned}
              />
            </div>
            {showTeamDetailsModal && clickedTeam && (
              <OTeamDetails
                team={clickedTeam}
                appConfig={currentAppConfig}
                toggleModal={toggleOTeamDetailsModal}
                superAdmin={superAdminStatus}
              />
            )}
          </div>
          <div className="db-list mt-6 mb-6">
            <h2 className="pb-1">Testing Functions</h2>
            <div className="overflow-scroll overflow-y-scroll max-h-96 db-list border-t-[1px] border-t-gray rounded-2xl">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Function</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={'currentSeason'}>
                      <TableCell component="th" scope="row">
                        Set appConfig.currentSeason
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip
                          title={
                            environment.hostingProd
                              ? 'Cannot perform that function on PROD'
                              : !superAdminStatus &&
                                'Must be a super admin to perform that action.'
                          }
                        >
                          <div>
                            <Select
                              value={currentSeason}
                              onChange={(e) =>
                                changeCurrentSeason(+e.target.value)
                              }
                              disabled={
                                environment.hostingProd || !superAdminStatus
                              }
                            >
                              {currentAppConfig.allSeasons?.map((season) => (
                                <MenuItem key={season} value={season}>
                                  {season}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow key={'currentWeek'}>
                      <TableCell component="th" scope="row">
                        Set appConfig.currentWeek
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip
                          title={
                            environment.hostingProd
                              ? 'Cannot perform that function on PROD'
                              : !superAdminStatus &&
                                'Must be a super admin to perform that action.'
                          }
                        >
                          <div>
                            <Select
                              value={currentWeek}
                              onChange={(e) =>
                                changeCurrentWeek(+e.target.value)
                              }
                              disabled={
                                environment.hostingProd || !superAdminStatus
                              }
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                              <MenuItem value={11}>11</MenuItem>
                              <MenuItem value={12}>12</MenuItem>
                              <MenuItem value={13}>13</MenuItem>
                            </Select>
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationsPortal;
