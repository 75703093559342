import React from 'react';
import Image from '../widgets/image';

interface PlayerCardProps {
  name?: string;
  score?: number;
  imageUrl?: string;
  category: string;
  ringColor: string;
  backgroundColor: string;
  titleBackgroundColor: string;
  marginRight?: string;
  marginLeft?: string;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
  name,
  score,
  imageUrl,
  category,
  ringColor,
  backgroundColor,
  titleBackgroundColor,
  marginRight,
  marginLeft,
}) => {
  return (
    <div className="flex flex-col items-center mb-2 w-full h-full">
      <div
        className={`p-2 rounded-lg ring ${ringColor} flex flex-col items-center justify-center w-full`}
        style={{ backgroundColor, marginRight, marginLeft }}
      >
        <div
          className="flex items-center justify-center rounded-lg py-2 mb-2 capitalize text-black font-bold w-full"
          style={{
            backgroundColor: titleBackgroundColor,
            whiteSpace: 'normal', // Change to allow line breaking
            wordBreak: 'break-word', // Ensure long words break correctly
          }}
        >
          <div className="mx-auto text-center w-full max-w-[100px] sm:max-w-full">
            {' '}
            {/* Use max-width to control wrapping */}
            {category}
          </div>
        </div>

        <div className="w-full flex items-center flex-col justify-center mb-2">
          <Image
            imageUrl={imageUrl}
            title={name || ''}
            size={12}
            specialClass="mr-2"
            clickable={false}
          />
          <div className="text-lg font-semibold text-black">{name}</div>
        </div>
        <div className="text-gray-600">
          <span className="text-black font-semibold">Score:</span>{' '}
          <span className="text-black">{score?.toFixed(1)}</span>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
