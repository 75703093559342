import { iCategoryStatsItem, iOrdinalRankingConst } from "../models";


export enum DBCollections {
  users = 'users',
  leagues = 'leagues',
  matchups = 'matchups',
  invites = 'invites',
  squads = 'squads',
  leagueSettings = 'leagueSettings',
  leagueDrafts = 'leagueDrafts',
  teamsMetaData = 'teamsMetaData',
  requests = 'requests',
  appConfig = 'appConfig',
  results = 'gameResults',
  weeklyScoreboard = 'weeklyScoreboard',
  leagueChats = "leagueChats",
  trophies = "trophies",
  usersTrophies = "usersTrophies",
  teamData = "teamData",
}

export enum DBUserRoles {
  admin = 'Admin',
  superAdmin = 'Super Admin',
  leagueMember = 'League Member',
}

export enum DBStorageDir {
  profileImages = 'profilePics',
  leagueBannerImages = 'leagueBanners',
}

export enum DiscordWebhooks {
  activityChannel = 'https://discord.com/api/webhooks/1021892337128984637/4Xg8ADL3nKkOfKg3qIP66rwRjf-F0mTgijIY9DXHe11o_pPHG6ltqx0XjKyEemAVh2fx',
}

export enum DefensivePointsAllowed {
  pointsAllowed7 = 'Points Allowed < 7',
  pointsAllowed14 = 'Points Allowed < 14',
  pointsAllowed28 = 'Points Allowed < 28',
  pointsAllowed35 = 'Points Allowed < 35',
  pointsAllowed36 = 'Points Allowed > 35',
}


export const passingScoreStats: iCategoryStatsItem[] = [
  {
    name: 'Passing Yards',
    key: 'netPassingYards',
    value: 1,
    perYard: 20,
    multiplier: 1 / 20,
    total: false,
  },
  {
    name: 'Passing TDs',
    key: 'passingTDs',
    value: 6,
    perYard: 0,
    multiplier: 6,
    total: true,
  },
  {
    name: 'Interceptions Thrown',
    key: 'interceptions',
    value: -6,
    perYard: 0,
    multiplier: -6,
    total: true,
  },
];

export const rushingScoreStats: iCategoryStatsItem[] = [
  {
    name: 'Rushing Yards',
    key: 'rushingYards',
    value: 1,
    perYard: 10,
    multiplier: 1 / 10,
    total: false,
  },
  {
    name: 'Rushing TDs',
    key: 'rushingTDs',
    value: 6,
    perYard: 0,
    multiplier: 6,
    total: true,
  },
  {
    name: 'Fumbles Lost',
    key: 'fumblesLost',
    value: -6,
    perYard: 0,
    multiplier: -6,
    total: true,
  },
];

export const defensiveScoreStats: iCategoryStatsItem[] = [
  {
    name: 'Sacks',
    key: 'sacks',
    value: 2,
    perYard: 0,
    multiplier: 2,
    total: true,
  },
  {
    name: 'Interceptions',
    key: 'passesIntercepted',
    value: 4,
    perYard: 0,
    multiplier: 4,
    total: true,
  },
  {
    name: 'Fumbles Recovered',
    key: 'fumblesRecovered',
    value: 4,
    perYard: 0,
    multiplier: 4,
    total: true,
  },
  {
    name: 'Defensive TDs',
    key: 'defensiveTDs',
    value: 6,
    perYard: 0,
    multiplier: 6,
    total: true,
  },
  {
    name: 'Kick Return TDs',
    key: 'kickReturnTDs',
    value: 6,
    perYard: 0,
    multiplier: 6,
    total: true,
  },
  {
    name: 'Punt Return TDs',
    key: 'puntReturnTDs',
    value: 6,
    perYard: 0,
    multiplier: 6,
    total: true,
  },
  {
    name: 'Kicking Points',
    key: 'kickingPoints',
    value: 1,
    perYard: 0,
    multiplier: 1,
    total: true,
  },
  {
    name: 'Points Allowed',
    key: 'pointsScoredAgainst',
    value: 10,
    perYard: 0,
    multiplier: 10,
    total: true,
    scoringBreakdown: {
      [DefensivePointsAllowed.pointsAllowed7]: 10,
      [DefensivePointsAllowed.pointsAllowed14]: 7,
      [DefensivePointsAllowed.pointsAllowed28]: 4,
      [DefensivePointsAllowed.pointsAllowed35]: 0,
      [DefensivePointsAllowed.pointsAllowed36]: -4,
    }
  },
  // {
  //   name: 'Points Allowed < 7',
  //   key: 'pointsScoredAgainst',
  //   value: 10,
  //   perYard: 0,
  //   multiplier: 10,
  //   total: true
  // },
  // {
  //   name: 'Points Allowed < 14',
  //   key: 'pointsScoredAgainst',
  //   value: 7,
  //   perYard: 0,
  //   multiplier: 7,
  //   total: true
  // },
  // {
  //   name: 'Points Allowed < 28',
  //   key: 'pointsScoredAgainst',
  //   value: 4,
  //   perYard: 0,
  //   multiplier: 4,
  //   total: true
  // },
  // {
  //   name: 'Points Allowed < 35',
  //   key: 'pointsScoredAgainst',
  //   value: 0,
  //   perYard: 0,
  //   multiplier: 0,
  //   total: true
  // },
  // {
  //   name: 'Points Allowed > 35',
  //   key: 'pointsScoredAgainst',
  //   value: -4,
  //   perYard: 0,
  //   multiplier: -4,
  //   total: true
  // },
  // {
  //   name: 'Yards Gained Against',
  //   key: 'yardsGainedAgainst',
  //   value: 1,
  //   perYard: 0,
  //   multiplier: 1,
  //   total: true,
  // },
];


export const allScoringStats = [
  ...passingScoreStats,
  ...rushingScoreStats,
  ...defensiveScoreStats,
];

export const ordinalRanking: iOrdinalRankingConst = {
  0: {
    alpha: 'zeroth',
    numeric: {
      number: 0,
      suffix: 'th',
    },
  },
  1: {
    alpha: 'first',
    numeric: {
      number: 1,
      suffix: 'st',
    },
  },
  2: {
    alpha: 'second',
    numeric: {
      number: 2,
      suffix: 'nd',
    },
  },
  3: {
    alpha: 'third',
    numeric: {
      number: 3,
      suffix: 'rd',
    },
  },
  4: {
    alpha: 'fourth',
    numeric: {
      number: 4,
      suffix: 'th',
    },
  },
  5: {
    alpha: 'fifth',
    numeric: {
      number: 5,
      suffix: 'th',
    },
  },
  6: {
    alpha: 'sixth',
    numeric: {
      number: 6,
      suffix: 'th',
    },
  },
  7: {
    alpha: 'seventh',
    numeric: {
      number: 7,
      suffix: 'th',
    },
  },
  8: {
    alpha: 'eighth',
    numeric: {
      number: 8,
      suffix: 'th',
    },
  },
  9: {
    alpha: 'ninth',
    numeric: {
      number: 9,
      suffix: 'th',
    },
  },
  10: {
    alpha: 'tenth',
    numeric: {
      number: 10,
      suffix: 'th',
    },
  },
  11: {
    alpha: 'eleventh',
    numeric: {
      number: 11,
      suffix: 'th',
    },
  },
  12: {
    alpha: 'twelfth',
    numeric: {
      number: 12,
      suffix: 'th',
    },
  },
  13: {
    alpha: 'thirteenth',
    numeric: {
      number: 13,
      suffix: 'th',
    },
  },
  14: {
    alpha: 'fourteenth',
    numeric: {
      number: 14,
      suffix: 'th',
    },
  },
  15: {
    alpha: 'fifteenth',
    numeric: {
      number: 15,
      suffix: 'th',
    },
  },
  16: {
    alpha: 'sixteenth',
    numeric: {
      number: 16,
      suffix: 'th',
    },
  },
  17: {
    alpha: 'seventeenth',
    numeric: {
      number: 17,
      suffix: 'th',
    },
  },
  18: {
    alpha: 'eighteenth',
    numeric: {
      number: 18,
      suffix: 'th',
    },
  },
  19: {
    alpha: 'nineteenth',
    numeric: {
      number: 19,
      suffix: 'th',
    },
  },
  20: {
    alpha: 'twentieth',
    numeric: {
      number: 20,
      suffix: 'th',
    },
  },
};

/**
 * Used for values > 20. Should be a whole number
 */
export function getOrdinalRankingSuffix(number: number): string {
  if (number < 21) {
    return ordinalRanking[number].numeric.suffix;
  }

  return generateOrdinalRanking(number);
}

function generateOrdinalRanking(number: number): string {
  const lastDigit = number % 10;
  const secondToLastDigit = Math.floor((number % 100) / 10);

  let suffix = 'th';

  if (lastDigit === 1 && secondToLastDigit !== 1) {
    suffix = 'st';
  } else if (lastDigit === 2 && secondToLastDigit !== 1) {
    suffix = 'nd';
  } else if (lastDigit === 3 && secondToLastDigit !== 1) {
    suffix = 'rd';
  }

  return `${suffix}`;
}
