import { KeyboardEvent, useEffect, useState } from 'react';
import './select.scss';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

export type OptionType = {
  value: any;
  label: string;
};

type SelectType = {
  options: OptionType[];
  showLabelAlways: boolean;
  additionalClasses?: string;
  initialValue?: any;
  disabled?: boolean;
  onChange: (value: any) => void;
  placeholder: string;
};

const Select = (props: SelectType) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props.initialValue
      ? props.options.map((obj) => obj.value).indexOf(props.initialValue)
      : 0,
  );

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index: number) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index: number) => (e: KeyboardEvent) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(
          selectedOption !== null && selectedOption - 1 >= 0
            ? selectedOption - 1
            : props.options.length - 1,
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(
          selectedOption !== null && selectedOption === props.options.length - 1
            ? 0
            : selectedOption !== null
            ? selectedOption + 1
            : 1,
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedOption !== null) props.onChange(props.options[selectedOption]);
  }, [selectedOption]);

  return (
    <ClickAwayListener onClickAway={() => setIsOptionsOpen(false)}>
      <div className="db-select input-holder">
        <div
          className={
            props.additionalClasses
              ? `container ${props.additionalClasses}`
              : 'container'
          }
        >
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            className={isOptionsOpen ? 'expanded relative' : 'relative'}
            onClick={toggleOptions}
            onKeyDown={handleListKeyDown}
            disabled={props.disabled}
          >
            {(props.showLabelAlways || selectedOption === null) && (
              <span
                className={
                  selectedOption !== null &&
                  props.options[selectedOption]?.label
                    ? 'absolute -top-3 text-xs left-0'
                    : 'text-gray '
                }
              >
                {props.placeholder}
              </span>
            )}
            {selectedOption !== null &&
              props.options[selectedOption]?.label &&
              props.options[selectedOption]?.label}
          </button>
          <ul
            className={`options ${isOptionsOpen ? 'show' : ''}`}
            role="listbox"
            aria-activedescendant={
              selectedOption !== null
                ? props.options[selectedOption]?.label
                : props.placeholder
            }
            tabIndex={-1}
            onKeyDown={handleListKeyDown}
          >
            {props.options.map((option, index) => (
              <li
                id={option.value}
                role="option"
                key={option.value}
                aria-selected={selectedOption === index}
                tabIndex={0}
                onKeyDown={handleKeyDown(index)}
                onClick={() => {
                  setSelectedThenCloseDropdown(index);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Select;
