import {
  faArrowTrendUp,
  faArrowTrendDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { iLeagueMemberRanking, iUser } from '@shared/shared-utils/models';
import LeagueRecord from '../../widgets/league-record';

type LeagueStandingsProps = {
  userDetails: iUser;
  leaderBoard: iLeagueMemberRanking;
  userRank: {
    alpha: string;
    numeric: {
      number: number;
      suffix: string;
    };
  };
};

const LeagueStandings = (props: LeagueStandingsProps) => {
  const [headerRanking, setHeaderRanking] = useState<JSX.Element>();

  const headerRankingDisplay = (userId: string) => {
    //If user has a winning streak, show arrow up icon
    if (props.leaderBoard[userId].winningStreak) {
      const movementIcon = (
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            size="sm"
            color="rgb(34 197 94)"
            className="pr-1 pb-1"
          />
          <span className="text-2xl">
            {props.userRank.numeric.number}
            <sup>{props.userRank.numeric.suffix}</sup>
          </span>
        </div>
      );
      setHeaderRanking(movementIcon);

      //If user does not have a winning streak, show arrow up icon
    } else {
      const movementIcon = (
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faArrowTrendDown}
            size="sm"
            color="rgb(239 68 68)"
            className="pr-1 pb-1"
          />
          <span className="text-2xl">
            {props.userRank.numeric.number}
            <sup>{props.userRank.numeric.suffix}</sup>
          </span>
        </div>
      );
      setHeaderRanking(movementIcon);
    }
  };

  useEffect(() => {
    headerRankingDisplay(props.userDetails.id);
  }, [props.userRank]);

  return (
    <>
      <div className="flex justify-between w-full">
        <LeagueRecord
          results={{
            wins: props.leaderBoard[props.userDetails.id]?.wins,
            losses: props.leaderBoard[props.userDetails.id]?.losses,
          }}
          stylingClasses={{
            titleWLT: 'text-xs',
            record: 'text-2xl font-bold',
          }}
        />
        <div className="flex flex-col items-end">
          <p className="text-xs">Rank</p>
          <div
            className="text-2xl font-bold text-right"
            data-e2e="user_ordinal_ranking"
          >
            {headerRanking}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeagueStandings;
