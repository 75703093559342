import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../providers/auth-provider';
import { getAuth, getRedirectResult } from 'firebase/auth';
import EmailInput from '../../../components/ui/email-input/email-input';
import { Button } from '@mui/material';
import { ToastContext } from '../../../providers/toast-provider';
import Loading from '../../../components/ui/loading-animation';
import AuthWrapper from '../../../components/ui/auth-wrapper/auth-wrapper';

export function Login() {
  const {
    isLoggedIn,
    isUserProfileComplete,
    currentUser,
    signInWithGoogle,
    getUserLoginMethods,
    authHasChecked,
  } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const DBAuth = getAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | undefined>();
  const [authLoading, setAuthLoading] = useState(true);
  const [redirectLoading, setRedirectLoading] = useState(true);

  const checkProvidersAndNavigate = async () => {
    if (email) {
      try {
        const loginMethods = await getUserLoginMethods(email);

        if (loginMethods.length === 0) {
          navigate('/signup', {
            state: {
              mEmail: email,
            },
          });
        }

        if (loginMethods.length === 1 && loginMethods[0] === 'google.com') {
          signInWithGoogle();
        }

        if (
          (loginMethods.length === 1 && loginMethods[0] === 'password') ||
          loginMethods.length > 1
        ) {
          navigate('/password', {
            state: {
              mEmail: email,
            },
          });
        }
      } catch (error) {
        showToast({
          messageType: 'error',
          message: 'Error while signing in. Please try again.',
        });
        console.log('Error while signing in: ', error);
        setAuthLoading(false);
      }
    }
  };

  const navigateToCorrectPage = async () => {
    if (isLoggedIn) {
      const redirectUrl = localStorage.getItem('rTo');
      if (redirectUrl) {
        navigate(redirectUrl);
        return;
      }
      navigate('/home');
      return;
    }

    if (currentUser) {
      const userProfileComplete = await isUserProfileComplete(currentUser);
      if (!userProfileComplete) {
        navigate('/signup', {
          state: {
            mEmail: currentUser.email,
          },
        });
      }
    }

    setAuthLoading(false);
  };

  const loginCheck = async () => {
    await navigateToCorrectPage();
  };

  const checkRedirect = async () => {
    // TODO: This returns false the first time still. I need to figure out why to handle it better
    // When we fix the issues with loading then we can have this load easier
    try {
      const result = await getRedirectResult(DBAuth);

      if (result) {
        await navigateToCorrectPage();
      }
    } catch (error) {
      console.log('error with redirect', error);
    }

    setRedirectLoading(false);
  };

  useEffect(() => {
    if (authHasChecked) {
      checkRedirect();
      loginCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, currentUser, authHasChecked]);

  return authLoading || redirectLoading ? (
    <Loading></Loading>
  ) : (
    <AuthWrapper>
      <h2 className="font-bold mb-4">LOGIN / SIGN UP</h2>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (email) {
            setAuthLoading(true);
            checkProvidersAndNavigate();
          }
        }}
      >
        <div className="mb-4">
          {/* <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
            /> */}
          <EmailInput
            label="Email Address"
            e2eTag="login-input"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <Button
          disabled={!email}
          onClick={() => {
            setAuthLoading(true);
            checkProvidersAndNavigate();
          }}
          variant="contained"
          color="primary"
          fullWidth
          data-e2e="next"
        >
          NEXT
        </Button>
      </form>
    </AuthWrapper>
  );
}

export default Login;
