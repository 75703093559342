import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { iLeague, iMatchup } from '@shared/shared-utils/models';
import { LeagueContext } from '../../../providers/league-provider';
import { useContext } from 'react';
import ModalWrapper from '../modal-wrapper/modal-wrapper';
import MatchupDetails from './matchup-details';

type MatchupListProp = {
  matchups: iMatchup[];
  league: iLeague;
  selectedMatchupIndex: number;
};

const MatchupList = ({
  matchups,
  league,
  selectedMatchupIndex,
}: MatchupListProp) => {
  const { toggleShowMatchupModal, toggleHomeMatchupClicked } =
    useContext(LeagueContext);
  return (
    <ModalWrapper
      title={'Matchup Details'}
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={() => {
        toggleShowMatchupModal();
        toggleHomeMatchupClicked();
      }}
    >
      {/* <Swiper
        pagination={{
          clickable: true,
          bulletClass: 'swiper-pagination-bullet bg-white',
        }}
        modules={[Pagination]}
        className="mySwiper"
        initialSlide={selectedMatchupIndex}
      >
        {matchups?.map((matchup: iMatchup, index: number) => (
          <SwiperSlide key={index}>
            <MatchupDetails
              round={matchup.round}
              memberA={matchup.memberA}
              memberB={matchup.memberB}
              league={league}
              showUpcoming={true}
            />
          </SwiperSlide>
        ))}
      </Swiper> */}
      <MatchupDetails
        round={matchups[selectedMatchupIndex].round}
        memberA={matchups[selectedMatchupIndex].memberA}
        memberB={matchups[selectedMatchupIndex].memberB}
        league={league}
        showUpcoming={true}
      />
    </ModalWrapper>
  );
};

export default MatchupList;
