import { iLeagueMemberSquadDb, iMatchup, iLeagueScoring } from '../models/league';
import { iCategoryStatsItem, iStatScoreItem } from '../models/team';
import { DefensivePointsAllowed } from './constants';

export const generateLeagueMatchups = (
  memberIds: string[]
) => {
  const matchUps: iMatchup[] = [];

  const roundRobinMatchUps: string[][] = roundRobin(
    memberIds.length,
    memberIds
  );
  // season is 14 weeks, save the last 2 for the playoffs
  let overflowMatchUpsIndex = 0;
  for (let i = 0; i < 12; i++) {
    let matchupIdex = i;
    // This means that we are going to have to start at the beginning of the list again
    if (i >= roundRobinMatchUps.length) {
      // Make sure we don't need to start again. Think 4 league members but needing to fill out 12 weeks
      if (overflowMatchUpsIndex >= roundRobinMatchUps.length) {
        overflowMatchUpsIndex = 0;
      }

      matchupIdex = overflowMatchUpsIndex;
      overflowMatchUpsIndex++;
    }
    roundRobinMatchUps[matchupIdex].forEach((matchUp) => {
      matchUps.push({
        memberA: matchUp[0],
        memberAPoints: 0,
        memberB: matchUp[1],
        memberBPoints: 0,
        round: i + 1,
        winnerId: '',
      });
    });
  }

  console.log(matchUps);
  return matchUps;
};

export const getScores = (results: any, teamId: string, leagueScoring: iLeagueScoring, slot?: string) => {
  try {
    const gameResult = results[teamId.split('-')[1]];
    const allScoringStats = [
      ...leagueScoring.passingScoreStats,
      ...leagueScoring.rushingScoreStats,
      ...leagueScoring.defensiveScoreStats
    ];
    const allStats = calculateScore(allScoringStats, gameResult);
    switch (slot) {
      case 'ps1':
      case 'ps2':
      case 'PS':
        return calculateScore(leagueScoring.passingScoreStats, gameResult);
      case 'rs1':
      case 'rs2':
      case 'RS':
        return calculateScore(leagueScoring.rushingScoreStats, gameResult);
      case 'ds1':
      case 'ds2':
      case 'DS':
        return calculateScore(leagueScoring.defensiveScoreStats, gameResult);
      default:
        return {
          ...allStats,
          passingStats: calculateScore(leagueScoring.passingScoreStats, gameResult),
          rushingStats: calculateScore(leagueScoring.rushingScoreStats, gameResult),
          defensiveStats: calculateScore(leagueScoring.defensiveScoreStats, gameResult),
        };
    }
  } catch (error) {
    console.log('getScores error', error);
  }

  return { total: 0, categoryStatScores: [] };
};

const calculateScore = (
  categoryStats: iCategoryStatsItem[],
  gameResult: any
) => {
  let totalScore = 0;
  const categoryStatScoresList: iStatScoreItem[] = [];
  categoryStats.forEach((scoreStat: iCategoryStatsItem, index: number) => {
    const resultStat = gameResult?.stats.find(
      (stat: any) => stat.category === scoreStat.key
    );
    // If there is no stat set it to 0
    let categoryStatScore = 0

    if (scoreStat.key === 'pointsScoredAgainst' && scoreStat.scoringBreakdown) {
      // TODO: Figure how to make sure the team wasn't on a bye
      // Calculate based on tier
      if (!resultStat?.stat && resultStat?.stat !== 0) {
        categoryStatScore = 0;
      }
      else if (resultStat?.stat < 7) {
        categoryStatScore = scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed7];
      } else if (resultStat?.stat < 14) {
        categoryStatScore = scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed14];
      } else if (resultStat?.stat < 28) {
        categoryStatScore = scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed28];
      } else if (resultStat?.stat < 35) {
        categoryStatScore = scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed35];
      } else {
        console.log('##STAT##', resultStat?.stat)
        categoryStatScore = scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed36]
      }
    } else {
      categoryStatScore = Number(
        (resultStat?.stat
          ? Math.round(Number(resultStat?.stat) * scoreStat.multiplier * 10) / 10
          : 0
        ).toFixed(1)
      );
    }


    totalScore += categoryStatScore;
    categoryStatScoresList.push({
      stat: scoreStat,
      score: categoryStatScore,
      statValue: resultStat?.stat ? resultStat.stat : 0, // set to 0 if it doesn't exist
    });
  });
  return {
    total: Number((Math.round(totalScore * 10) / 10).toFixed(1)),
    categoryStatScores: categoryStatScoresList,
  };
};

export const getMatchupScores = (
  round: number,
  memberASq: iLeagueMemberSquadDb,
  memberBSq: iLeagueMemberSquadDb,
  results: any,
  leagueScoring: iLeagueScoring,
) => {
  let memberATotal = 0;
  let memberBTotal = 0;
  if (memberASq.matchupSquad[round] && memberBSq.matchupSquad[round]) {
    const ps1AScore = getScores(
      results,
      memberASq.matchupSquad[round].ps1,
      leagueScoring,
      'ps1'
    );
    const ps2AScore = getScores(
      results,
      memberASq.matchupSquad[round].ps2,
      leagueScoring,
      'ps2'
    );
    const rs1AScore = getScores(
      results,
      memberASq.matchupSquad[round].rs1,
      leagueScoring,
      'rs1'
    );
    const rs2AScore = getScores(
      results,
      memberASq.matchupSquad[round].rs2,
      leagueScoring,
      'rs2'
    );
    const ds1AScore = getScores(
      results,
      memberASq.matchupSquad[round].ds1,
      leagueScoring,
      'ds1'
    );
    const ds2AScore = getScores(
      results,
      memberASq.matchupSquad[round].ds2,
      leagueScoring,
      'ds2'
    );

    const memberATotalScore =
      ps1AScore?.total +
      ps2AScore?.total +
      rs1AScore?.total +
      rs2AScore?.total +
      ds1AScore?.total +
      ds2AScore?.total;

    memberATotal = Number((Math.round(memberATotalScore * 10) / 10).toFixed(1));

    const ps1BScore = getScores(
      results,
      memberBSq.matchupSquad[round].ps1,
      leagueScoring,
      'ps1'
    );
    const ps2BScore = getScores(
      results,
      memberBSq.matchupSquad[round].ps2,
      leagueScoring,
      'ps2'
    );
    const rs1BScore = getScores(
      results,
      memberBSq.matchupSquad[round].rs1,
      leagueScoring,
      'rs1'
    );
    const rs2BScore = getScores(
      results,
      memberBSq.matchupSquad[round].rs2,
      leagueScoring,
      'rs2'
    );
    const ds1BScore = getScores(
      results,
      memberBSq.matchupSquad[round].ds1,
      leagueScoring,
      'ds1'
    );
    const ds2BScore = getScores(
      results,
      memberBSq.matchupSquad[round].ds2,
      leagueScoring,
      'ds2'
    );

    const memberBTotalScore =
      ps1BScore.total +
      ps2BScore.total +
      rs1BScore.total +
      rs2BScore.total +
      ds1BScore.total +
      ds2BScore.total;

    memberBTotal = Number((Math.round(memberBTotalScore * 10) / 10).toFixed(1));
  }

  return {
    memberATotal: memberATotal,
    memberBTotal: memberBTotal,
  };
};

const DUMMY = -1;
// returns an array of round representations (array of player pairs).
// http://en.wikipedia.org/wiki/Round-robin_tournament#Scheduling_algorithm
// params - rounds and array of member ids
const roundRobin = (n: number, ps?: any): Array<Array<any>> => {
  // n = num players
  const rs: any = []; // rs = round array
  if (!ps) {
    ps = [];
    for (let k = 1; k <= n; k += 1) {
      ps.push(k);
    }
  } else {
    ps = ps.slice();
  }

  if (n % 2 === 1) {
    ps.push(DUMMY); // so we can match algorithm for even numbers
    n += 1;
  }
  for (let j = 0; j < n - 1; j += 1) {
    rs[j] = []; // create inner match array for round j
    for (let i = 0; i < n / 2; i += 1) {
      const o = n - 1 - i;
      if (ps[i] !== DUMMY && ps[o] !== DUMMY) {
        // flip orders to ensure everyone gets roughly n/2 home matches
        const isHome = i === 0 && j % 2 === 1;
        // insert pair as a match - [ away, home ]
        rs[j].push([isHome ? ps[o] : ps[i], isHome ? ps[i] : ps[o]]);
      }
    }
    ps.splice(1, 0, ps.pop()); // permutate for next round
  }
  return rs;
};
