type creatorHighlightMap = Record<
    number,
    { logoUrl: string; linkUrl: string; name: string } | undefined
>;

export const creatorHighlightMap: creatorHighlightMap = {
    1: {
        logoUrl:
            'https://yt3.googleusercontent.com/4A3RBdnTJ1sVNCYlYh-mHqcIAm6hnzf_VW3yxTSvvqgLfE2Gwefl-8xQI1MZuIl-P1KUitXg0g=s160-c-k-c0x00ffffff-no-rj',
        linkUrl: 'https://www.youtube.com/@collegefootballwithsam',
        name: 'College Football with Sam',
    },
    2: {
        logoUrl:
            'https://yt3.googleusercontent.com/u52LSXjeKZLJgJ-NZn-wbbyvXvedfo5vXE87P99jcPk2-36YeOu7QMFIqHN8lfN5o-CAtcPJ=s160-c-k-c0x00ffffff-no-rj',
        linkUrl: 'https://www.youtube.com/@AlwaysTalkinBall',
        name: 'Always Talkin Ball',
    },
    3: {
        logoUrl:
            'https://ugc.production.linktr.ee/d477d0f8-f845-4257-b661-5d850ca3a2ed_1000003535.jpeg?io=true&size=avatar-v3_0',
        linkUrl: 'https://linktr.ee/TheVFLshow',
        name: 'The VFL Show',
    },
    4: {
        logoUrl:
            'https://yt3.googleusercontent.com/yoIE78-saMqbnCIl-PssGv9dT2xboi6PjuoePH7DowiUgj9t-YwN6er52t-bO4b73__22Oe3gA=s160-c-k-c0x00ffffff-no-rj',
        linkUrl: 'https://thecollegefantasyfootballsite.com/',
        name: 'The College Fantasy Football Site',
    },
    5: {
        logoUrl:
            'https://yt3.googleusercontent.com/Fz8b1bbDx3yOCeUCXorNPYXD1qDluCCfHAKvP8sE1-pODd7oFMGfJmcP3aGEpztnyZMkG0sBBR8=s160-c-k-c0x00ffffff-no-rj',
        linkUrl: 'https://www.youtube.com/@BreakTheHuddle_',
        name: 'Break The Huddle',
    },
    6: {
        logoUrl:
            'https://yt3.googleusercontent.com/sAoK21vII7S_IceKH2F-Kge9E5UsuMYsj_AhIrpphxzsHEz5tQCeBPoEKuR_a2R4T5gAz5BFMO8=s160-c-k-c0x00ffffff-no-rj',
        linkUrl: 'https://linktr.ee/outofrhythmfant',
        name: 'Out of Rhythm Fantasy',
    },
    7: {
        logoUrl:
            'https://yt3.googleusercontent.com/ytc/AIdro_mJVBY2DtyTSFM7WiqiRc0x1XCoYK1XEPNEeBObbv3ZYSo=s160-c-k-c0x00ffffff-no-rj',
        linkUrl: 'https://www.youtube.com/@MarkRogersVOCFB',
        name: 'The Voice of College Football',
    }
};