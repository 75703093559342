import { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';

import ListItem from '../../../../components/widgets/list-widget/list-item';
import ListWidget from '../../../../components/widgets/list-widget/list-widget';
import {
  RequestStatus,
  iLeague,
  iMatchup,
  iTradeRequest,
  iWeeklyReport,
} from '@shared/shared-utils/models';
import { LeagueContext } from '../../../../providers/league-provider';

interface iLeagueActivity
  extends Partial<iMatchup>,
    Partial<iTradeRequest>,
    Partial<iWeeklyReport> {}

interface LeagueActivityProps {
  league: iLeague;
  weeklyReportClicked: (week: number) => void;
  draftReacapClicked: () => void;
}

const LeagueActivity = ({
  league,
  weeklyReportClicked,
  draftReacapClicked,
}: LeagueActivityProps) => {
  const { getLeagueRequests, getProcessingLeagueRequests, appConfig } =
    useContext(LeagueContext);
  const [leagueActivityData, setLeagueActivityData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (league?.id) {
      prepareLeagueActivityData();
    }
  }, [league]);

  const calculateDateFromWeek = (matchup: iMatchup) => {
    const resultTime = new Date();
    const currentWeekDay =
      resultTime.getDay() > 0 ? resultTime.getDay() - 1 : 6;
    resultTime.setDate(resultTime.getDate() - currentWeekDay);
    if (appConfig?.currentWeek)
      resultTime.setDate(
        resultTime.getDate() - (appConfig?.currentWeek - matchup.round) * 7,
      );
    resultTime.setHours(19, 0, 0, 0);
    return resultTime;
  };

  const getSortKey = (item: iLeagueActivity) => {
    switch (true) {
      case 'requesterDetails' in item:
        return item.createdDate.toDate();
      case 'resultTime' in item:
        return item.resultTime;
      case 'mostPointsScored' in item:
        return new Date(item.dateCreated.seconds * 1000);
    }
  };

  const prepareLeagueActivityData = async () => {
    const results = league?.matchups?.filter((item) => item.winnerId);
    const requests = league?.id ? await getLeagueRequests(league?.id) : [];
    const processingRequests = league?.id
      ? await getProcessingLeagueRequests(league?.id)
      : [];
    const reports = !league.weeklyReports
      ? []
      : Object.keys(league.weeklyReports).map(
          (key: unknown) =>
            league.weeklyReports[key as keyof typeof league.weeklyReports],
        );

    const resultsWithDate = results?.length
      ? results.map((item) => {
          if (item.resultTime) {
            return {
              ...item,
              resultTime: item.resultTime.toDate(),
            };
          }
          const resultTime = calculateDateFromWeek(item);
          return {
            ...item,
            resultTime: new Date(resultTime),
          };
        })
      : [];

    const combinedData: iLeagueActivity[] = [
      ...requests,
      ...resultsWithDate,
      ...reports,
      ...processingRequests,
    ];

    combinedData.sort((a: iLeagueActivity, b: iLeagueActivity) => {
      const aDate = getSortKey(a);
      const bDate = getSortKey(b);
      return +bDate - +aDate;
    });

    createList(combinedData);
    setIsLoading(false);
  };

  const getListItemByActivityType = (item: iLeagueActivity) => {
    switch (true) {
      case 'requesterDetails' in item: {
        const requester = <b>{item.requesterDetails?.fullName}</b>;
        const tradedTeam = <b>{item.tradedTeam?.school}</b>;
        const requestedTeam = <b>{item.requestedTeam?.school}</b>;
        return (
          <div>
            <div className="text-sm">
              {item.requestedTeamOwner ? (
                <>
                  {item.status === RequestStatus.accepted
                    ? 'ACCEPTED: '
                    : 'PROCESSING: '}
                  {requester}{' '}
                  {item.status === RequestStatus.accepted
                    ? ' traded '
                    : ' is trading '}{' '}
                  {tradedTeam} for <b>{item.requestedTeamOwner.fullName}</b>'s{' '}
                  {requestedTeam}
                </>
              ) : (
                <>
                  {requester} dropped {tradedTeam} and added {requestedTeam}
                </>
              )}
            </div>
            <Moment format="MMMM DD, YYYY h:mm A">
              {item.createdDate.toDate()}
            </Moment>
          </div>
        );
      }

      case 'resultTime' in item: {
        const memberADetails = league.members?.find(
          (member) => member.id === item.memberA,
        );
        const memberBDetails = league.members?.find(
          (member) => member.id === item.memberB,
        );
        const winner =
          item.winnerId === item.memberA
            ? memberADetails?.fullName
            : memberBDetails?.fullName;
        const looser =
          item.winnerId === item.memberB
            ? memberADetails?.fullName
            : memberBDetails?.fullName;
        return (
          <div>
            <div className="text-sm">
              <b>{winner}</b> defeated <b>{looser}</b>
            </div>
            <Moment format="MMMM DD, YYYY h:mm A">{item.resultTime}</Moment>
          </div>
        );
      }

      case 'mostPointsScored' in item: {
        return (
          <div onClick={() => weeklyReportClicked(item.week as number)}>
            <div className="text-sm">View Week {item.week} Report Here!</div>
            <Moment format="MMMM DD, YYYY h:mm A">
              {new Date(item.dateCreated.seconds * 1000)}
            </Moment>
          </div>
        );
      }

      default:
        return null;
    }
  };

  const createList = (combinedData: iLeagueActivity[]) => {
    const listView = combinedData.map(
      (item: iLeagueActivity, index: number) => {
        const listItem = getListItemByActivityType(item);
        return (
          <ListItem
            specialClass="cursor-default"
            key={index}
            children={
              <div className="flex items-center justify-between">
                <div className="flex flex-row items-center ">{listItem}</div>
              </div>
            }
          />
        );
      },
    );

    listView.push(
      <ListItem
        specialClass="cursor-default"
        children={
          <div className="flex items-center justify-between">
            <div
              className="flex flex-row items-center "
              onClick={draftReacapClicked}
            >
              View Draft Recap!
            </div>
          </div>
        }
      />,
    );

    setLeagueActivityData(listView);
  };

  return (
    <ListWidget
      label="League Activity"
      items={leagueActivityData}
      loading={isLoading}
    />
  );
};

export default LeagueActivity;
