import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';

import Header from '../../../../components/widgets/header/header';
import { LeagueContext } from '../../../../providers/league-provider';
import TabHeader from '../../../../components/ui/tabs/tab-header';
import { tabItemsDetails } from '../league-constants';
import InviteMembers from '../widgets/invite-members';
import LeagueSettings from '../widgets/league-settings';
import {
  iMatchup,
  iMatchupSquad,
  iMatchupSquadItemDB,
  leagueDraftStatus,
  iTeam,
  iLeague,
  iUser,
} from '@shared/shared-utils/models';
import { getMatchupScores, getSquadName } from '@shared/shared-utils';
import { AuthContext } from '../../../../providers/auth-provider';
import { TeamsContext } from '../../../../providers/teams-provider';
import Button from '../../../../components/ui/button/button';
import LeaderBoard from '../widgets/leaderboard';
import '../league.scss';
import MatchupList from '../../../../components/modals/matchup-details/matchup-list';
import LeagueActivity from '../widgets/league-activity';
import SeasonEnd from '../season-end';
import Loading from '../../../../components/ui/loading-animation';
import ModalWrapper from '../../../../components/modals/modal-wrapper/modal-wrapper';
import TeamDetails from '../../../../components/modals/team-details/team-details';
import LeagueChat from '../widgets/league-chat/league-chat';
import { ToastContext } from '../../../../providers/toast-provider';
import { ChatContext } from '../../../../providers/chat-provider';
import WeeklyReport from '../../../../components/modals/weekly-report/weekly-report';
import { UserService } from '../../../../services/user-service';
import LeagueStandings from './page-sections/league-standings';
import MySquad from './page-sections/my-squad';
import WeeklyMatchupSquads from './page-sections/weekly-matchup-squads';
import WeeklyMatchups from './page-sections/weekly-matchups';
import MemberList from './page-sections/member-list';
import SquadName from '../squad-name';
import DraftOverview from '../../../../components/modals/draft-overview/draft-overview';
import {
  initMatchupList,
  mapMatchupSquads,
} from './helpers/league-details.helpers';

export function LeagueDetails() {
  const { userDetails, setUserDetails } = useContext(AuthContext);
  const { getTeamById, getResults } = useContext(TeamsContext);
  const params: any = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    getLeagueDetails,
    myCurrentLeagueSquad,
    toggleShowMatchupModal,
    showMatchupModal,
    getMyLeagueSquad,
    appConfig,
    homeMatchupClicked,
    matchupWeek,
    resultsMatchup,
    amICurrentLeagueOwner,
    getMemberSquad,
    userRank,
  } = useContext(LeagueContext);
  const { showToast } = useContext(ToastContext);
  const { checkForUnreadMessages } = useContext(ChatContext);
  const [leagueDetails, setLeagueDetails] = useState<iLeague>();
  const [asCreator, setAsCreator] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [squadList, setSquadList] = useState<any[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<number>(
    appConfig.currentWeek,
  );
  const [selectedMatchup, setSelectedMatchup] = useState<number>(0);
  const [customWeeklyReport, setCustomWeeklyReport] = useState<number | null>(
    null,
  );
  const [selectedWeekMatchUps, setSelectedWeekMatchups] =
    useState<iMatchup[]>();
  const [matchupSquad, setMatchupSquad] = useState<iMatchupSquad>();
  const [isLeagueActive, setIsLeagueActive] = useState<boolean>(true);
  const [viewLeagueDetails, setViewLeagueDetails] =
    useState<boolean>(isLeagueActive);
  const [tradedTeams, setTradedTeams] = useState<string[]>([]);
  const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(false);
  const [showWeeklyReport, setShowWeeklyReport] = useState(false);
  const [showDraftOverview, setShowDraftOverview] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<iTeam>();
  const [loading, setLoading] = useState<boolean>();
  const [showChatBadge, setShowChatBadge] = useState(false);
  const [matchupsLoading, setMatchupsLoading] = useState(true);
  const [squadName, setSquadName] = useState<string>('');
  const [usersSelectedWeekResults, setUsersSelectedWeekResults] =
    useState<iMatchup>({
      round: 1,
      memberA: '',
      memberB: '',
      memberAPoints: 0,
      memberBPoints: 0,
    });
  const [dataForMatchups, setDataForMatchups] = useState<
    {
      matchup: iMatchup;
      memberADetails: iUser | undefined;
      memberBDetails: iUser | undefined;
      memberAPoints: number;
      memberBPoints: number;
    }[]
  >();

  const userService = new UserService();
  const analytics = getAnalytics();

  const navigateToUpdateSquad = () => {
    logEvent(analytics, 'select_content', {
      content_type: 'navigate-to-update',
      content_id: '',
    });
    navigate('update-squad', {
      state: {
        myTeams: squadList,
        allMatchupSquads: myCurrentLeagueSquad?.matchupSquad,
        squadName: squadName,
        leagueId: leagueDetails?.id,
      },
    });
  };

  const navigateToGameDayGuide = () => {
    logEvent(analytics, 'select_content', {
      content_type: 'navigate-to-gameday',
      content_id: '',
    });
    navigate('gameday-guide', {
      state: {
        myTeams: squadList,
        allMatchupSquads: myCurrentLeagueSquad?.matchupSquad,
      },
    });
  };

  const initLeagueDetails = async () => {
    if (params) {
      try {
        const returnedLeague = await getLeagueDetails(params['leagueId'], true);

        if (returnedLeague) {
          setLeagueDetails(returnedLeague);
        }
      } catch (error) {
        navigate('/');
      }
    }
  };

  const mapMyLeagueSquads = async (teamIds: string[]) => {
    const teamsData = [];
    for (let i = 0; i < teamIds.length; i++) {
      const teamData = await getTeamById(teamIds[i]);
      teamsData.push(teamData);
    }
    setSquadList(teamsData);
  };

  const handleWeeklyReportClicked = (week: number) => {
    setCustomWeeklyReport(week);

    // get the matchups for this week
    const matchupsForThisWeek = leagueDetails?.matchups.filter((matchUps) => {
      return matchUps.round === week;
    });

    // Get the matchup for the user
    const matchUpForUser = matchupsForThisWeek?.find((matchUp) => {
      return (
        matchUp.memberA === userDetails?.id ||
        matchUp.memberB === userDetails?.id
      );
    });

    if (matchUpForUser) {
      setUsersSelectedWeekResults(matchUpForUser);
    }

    setShowWeeklyReport(true);
    // Do whatever you need to do with the selected week, such as showing a modal or updating state.
  };

  const handleUpdateSquadName = async (newName: string) => {
    if (leagueDetails && userDetails) {
      // Update that it has been shown
      const newSquadNameProperty: any = {
        squadNames: {
          ...userDetails?.squadNames,
          [leagueDetails.id]: newName,
        },
      };

      try {
        // Update the squad name on the server
        await userService.updateUserProfile(
          newSquadNameProperty,
          userDetails.id,
        );

        const newUserDetails = {
          ...userDetails,
          ...newSquadNameProperty,
        };

        // Update league details for this member
        setLeagueDetails({
          ...leagueDetails,
          members: leagueDetails.members.map((m) =>
            m.id === userDetails.id ? newUserDetails : m,
          ),
        });

        // Set the new squad name to local state
        setUserDetails(newUserDetails);

        // Update the squad name
        setSquadName(newName);

        // Log the action
        logEvent(analytics, 'squad-name-update', {
          content_type: 'update-successful',
          content_id: userDetails?.id,
        });
      } catch (error) {
        console.log('ERROR updating squad name', error);
        showToast({
          messageType: 'error',
          message: 'Error updating squad name. Please try again.',
        });
      }
    }
  };

  // Runs 4 times
  useEffect(() => {
    if (searchParams.get('tab')) {
      // Right now its only chat. We will need to beef this up in the future
      setSelectedTabIndex(1);
    }
  }, [leagueDetails, matchupSquad]);

  useEffect(() => {
    if (userDetails?.id && leagueDetails?.id) {
      setSquadName(getSquadName(userDetails, leagueDetails.id));
    }
  }, [leagueDetails, userDetails?.id]);

  useEffect(() => {
    if (userDetails?.id && leagueDetails?.id) {
      // See if we need to show the weekly report

      // If we have the status, set it here. If not, set it to show
      const reportStatus = userDetails.weeklyReportStatus?.[leagueDetails.id]
        ? userDetails.weeklyReportStatus[leagueDetails.id]
        : appConfig.currentWeek - 1;

      // Make sure the report is ready and that they haven't seen it yet
      if (
        reportStatus < appConfig.currentWeek &&
        leagueDetails.weeklyReports?.[reportStatus]
      ) {
        // Show weekly report
        handleWeeklyReportClicked(appConfig.currentWeek - 1);
        // update that it has been shown
        const newUserDetails: iUser = {
          ...userDetails,
          weeklyReportStatus: {
            ...userDetails.weeklyReportStatus,
            [leagueDetails.id]: appConfig.currentWeek,
          },
        };

        setCustomWeeklyReport(reportStatus);
        userService.updateUserProfile(newUserDetails, userDetails.id);
        setUserDetails(newUserDetails);
      }
    }
  }, [appConfig, userDetails?.id, leagueDetails?.id]);

  useEffect(() => {
    if (leagueDetails) {
      setShowChatBadge(
        leagueDetails.lastMessageSent
          ? checkForUnreadMessages(leagueDetails)
          : false,
      );
    }
  }, [leagueDetails?.lastMessageSent]);

  const setMatchupList = async () => {
    if (leagueDetails) {
      const { selectedWeekMatchUpsData, matchupsData } = await initMatchupList(
        leagueDetails,
        selectedWeek,
        getMemberSquad,
        getResults,
        appConfig.currentSeason,
      );

      setSelectedWeekMatchups(selectedWeekMatchUpsData);
      setDataForMatchups(matchupsData);
      setMatchupsLoading(false);
    }
  };
  useEffect(() => {
    setMatchupList();
  }, [selectedWeek, leagueDetails, homeMatchupClicked]);

  useEffect(() => {
    if (leagueDetails?.id && leagueDetails?.id === params['leagueId']) {
      if (leagueDetails.draftStatus === leagueDraftStatus.done) {
        getMyLeagueSquad(leagueDetails.id);
      }
    }
  }, [leagueDetails]);

  useEffect(() => {
    if (myCurrentLeagueSquad) {
      mapMyLeagueSquads(myCurrentLeagueSquad.teams);
      const fetchMatchupSquads = async () => {
        const squads = await mapMatchupSquads(
          myCurrentLeagueSquad.matchupSquad,
          appConfig.currentWeek,
          getTeamById,
        );
        setMatchupSquad(squads);
      };
      fetchMatchupSquads();

      if (myCurrentLeagueSquad.tradedTeams) {
        setTradedTeams(myCurrentLeagueSquad.tradedTeams.teamsLeaving);
      }
    }

    if (appConfig) {
      // Set the initial week for the selection below
      setSelectedWeek(appConfig.currentWeek);

      if (leagueDetails) {
        if (
          appConfig.currentSeason === leagueDetails.season &&
          appConfig.seasonActive
        ) {
          setIsLeagueActive(true);
        } else {
          setIsLeagueActive(false);
        }

        setAsCreator(amICurrentLeagueOwner());
      }
    }
  }, [myCurrentLeagueSquad, appConfig]);

  useEffect(() => {
    if (homeMatchupClicked) {
      if (leagueDetails?.matchups) {
        const selectedWeekMatchUpsData: iMatchup[] = [];
        for (let i = 0; i < leagueDetails.matchups.length; i++) {
          const matchup = leagueDetails.matchups[i];
          if (matchup.round === matchupWeek) {
            selectedWeekMatchUpsData.push(matchup);
          }

          if (
            matchup.memberA === resultsMatchup?.memberA &&
            matchup.memberB === resultsMatchup.memberB
          ) {
            setSelectedMatchup(i);
          }
        }
        setSelectedWeekMatchups(selectedWeekMatchUpsData);
      }
    }
  }, [leagueDetails?.matchups]);

  useEffect(() => {
    initLeagueDetails();

    if (localStorage.getItem('rTo')) {
      const path = localStorage.removeItem('rTo');
    }
  }, []);

  useEffect(() => {
    userDetails &&
      leagueDetails &&
      matchupSquad &&
      userRank !== undefined &&
      dataForMatchups?.length &&
      setLoading(false);
  }, [userDetails, leagueDetails, matchupSquad, userRank, dataForMatchups]);

  return loading ? (
    <Loading></Loading>
  ) : (
    leagueDetails && userDetails && (
      <div
        id="db-league-details"
        className="flex flex-col football-bg"
        data-e2e="league_details_page"
      >
        <Header
          title={'LEAGUE: ' + leagueDetails.title}
          isHome={false}
          cancelLink="/home"
          subContent={
            <TabHeader
              selectedTabIndex={selectedTabIndex}
              tabItems={tabItemsDetails.map((item) => ({
                ...item,
                customClass:
                  leagueDetails.draftStatus === leagueDraftStatus.inProgress &&
                  item.key !== 'clDetails'
                    ? ' disabled'
                    : '',
              }))}
              onTabChange={(selectedIndex) => {
                setSelectedTabIndex(selectedIndex);
              }}
              showBadge={showChatBadge}
            />
          }
        />
        {!isLeagueActive && (
          <SeasonEnd
            toggleLeagueDetails={() => {
              setViewLeagueDetails(!viewLeagueDetails);
            }}
            userRank={userRank?.alpha}
            seasonYear={leagueDetails.season}
            viewLeagueDetails={viewLeagueDetails}
            numberOfSquads={leagueDetails.settings?.numOfMembers}
            userDetails={userDetails}
          />
        )}
        <div
          className={viewLeagueDetails ? 'flex flex-1 pt-[7.8rem]' : 'hidden'}
        >
          {selectedTabIndex === 0 && (
            <div className="max-w-[560px] overflow-hidden w-full">
              {isLeagueActive && selectedTabIndex === 0 && (
                <div className="p-4 pb-1">
                  <SquadName
                    initialSquadName={getSquadName(
                      userDetails,
                      leagueDetails.id,
                    )}
                    onUpdateSquadName={handleUpdateSquadName}
                  ></SquadName>
                </div>
              )}

              {userRank && (
                <div className="p-4 pb-1">
                  <LeagueStandings
                    userDetails={userDetails}
                    leaderBoard={leagueDetails.leaderBoard}
                    userRank={userRank}
                  />
                </div>
              )}
              <div className="mt-2">
                <MySquad
                  setShowTeamDetailsModal={setShowTeamDetailsModal}
                  setSelectedTeam={setSelectedTeam}
                  squadList={squadList}
                  tradedTeams={tradedTeams}
                />
              </div>
              {matchupSquad && (
                <div className={`mt-4 ${!isLeagueActive && 'hidden'}`}>
                  <WeeklyMatchupSquads
                    navigateToUpdateSquad={navigateToUpdateSquad}
                    matchupSquad={matchupSquad}
                    leagueDetails={leagueDetails}
                  />
                </div>
              )}
              <div className={`mx-3 mt-4 ${!isLeagueActive && 'hidden'}`}>
                <Button
                  label="Game Day Guide"
                  className="w-full bg-accent"
                  onClick={() => {
                    navigateToGameDayGuide();
                  }}
                />
              </div>
              {leagueDetails?.matchups?.length && dataForMatchups && (
                <div className="px-4 mt-6">
                  <WeeklyMatchups
                    selectedWeek={selectedWeek}
                    matchupsLoading={matchupsLoading}
                    handleWeekChange={setSelectedWeek}
                    matchupsData={dataForMatchups}
                    handleMatchupClick={(matchupIndex) => {
                      setSelectedMatchup(matchupIndex);
                      toggleShowMatchupModal();
                    }}
                    leagueId={leagueDetails.id}
                    userId={userDetails.id}
                  />
                </div>
              )}
              {leagueDetails.leaderBoard && (
                <div className="px-4 mt-8">
                  <LeaderBoard league={leagueDetails} />
                </div>
              )}
              {leagueDetails.id && (
                <div className="px-4 mt-8">
                  <LeagueActivity
                    league={leagueDetails}
                    weeklyReportClicked={handleWeeklyReportClicked}
                    draftReacapClicked={() => {
                      setShowDraftOverview(true);
                    }}
                  />
                </div>
              )}
              <div className="px-4 mt-10 mb-12">
                <MemberList leagueMembers={leagueDetails.members} />
              </div>
            </div>
          )}
          {selectedTabIndex === 1 && <LeagueChat league={leagueDetails} />}
          {selectedTabIndex === 2 && (
            <InviteMembers
              showMembers={true}
              league={leagueDetails}
              isLeagueActive={isLeagueActive}
              isOwner={asCreator}
              onFinish={() => {
                setSelectedTabIndex(selectedTabIndex + 1);
                setLeagueDetails;
              }}
              //user can only add invites if is leagueOwner, current week is not > 1, and the draft hasn't started/completed
              // I think we can just put true here. We can't get here until a draft is done
              readonly={
                !asCreator ||
                appConfig.currentWeek > 1 ||
                leagueDetails.draftStatus !== leagueDraftStatus.pending
              }
              updateLeagueState={setLeagueDetails}
            />
          )}
          {selectedTabIndex === 3 && (
            <LeagueSettings
              league={leagueDetails}
              // If not league owner or draft has started/is completed, settings will be readonly
              readOnly={
                !asCreator ||
                leagueDetails.draftStatus !== leagueDraftStatus.pending
              }
              // If not league owner or we are past week 1, scoring will be readonly.
              // Make sure draft is done so that we don't prevent late start leagues from editing scoring
              // prior to their draft.
              readOnlyScoring={
                !asCreator ||
                (appConfig.currentWeek > 1 &&
                  leagueDetails.draftStatus === leagueDraftStatus.done)
              }
              // TODO: REACTIVATE LEAGUE
              reactivateLeagueOption={
                false
                // asCreator && !isLeagueActive
              }
              onFinish={() => setSelectedTabIndex(0)}
              isAdmin={asCreator}
              expectedNumLeagueMembers={
                leagueDetails?.expectedNumMembers ??
                leagueDetails.expectedNumMembers
              }
            />
          )}
        </div>
        {showMatchupModal && selectedMatchup >= 0 && selectedWeekMatchUps && (
          <MatchupList
            matchups={selectedWeekMatchUps}
            league={leagueDetails}
            selectedMatchupIndex={selectedMatchup}
          />
        )}
        {showWeeklyReport && (
          <WeeklyReport
            weeklyReport={
              customWeeklyReport
                ? leagueDetails.weeklyReports[customWeeklyReport]
                : leagueDetails.weeklyReports[appConfig.currentWeek]
            }
            members={leagueDetails.members}
            onCloseClick={() => {
              setShowWeeklyReport(false);
              setCustomWeeklyReport(appConfig.currentWeek);
            }}
            matchupResults={usersSelectedWeekResults}
            leagueName={leagueDetails.title}
          />
        )}
        {showDraftOverview && (
          <DraftOverview
            leagueId={leagueDetails.id}
            users={leagueDetails.members}
            onCloseClick={() => {
              setShowDraftOverview(false);
            }}
          />
        )}
        {showTeamDetailsModal && selectedTeam && (
          <ModalWrapper
            title={'Team Details'}
            backEnabled={false}
            closeOnTapOutside={false}
            onCloseClicked={() => {
              setShowTeamDetailsModal(false);
            }}
          >
            <TeamDetails
              team={selectedTeam}
              onError={() => {
                setSelectedTeam(undefined);
                setShowTeamDetailsModal(false);
                showToast({
                  messageType: 'error',
                  message:
                    'Unable to show team details at this time. Please try again later.',
                });
              }}
            />
          </ModalWrapper>
        )}
      </div>
    )
  );
}

export default LeagueDetails;
