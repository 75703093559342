import { iLeagueScoring } from '@shared/shared-utils/models';

export const isLeagueScoringDifferent = (newScoring: iLeagueScoring, originalScoring: iLeagueScoring) => {
  let isDifferent = false;

  const newDefensiveMap = new Map();
  newScoring.defensiveScoreStats.forEach((stat) => {
    newDefensiveMap.set(stat.key, stat.multiplier);
  });

  originalScoring.defensiveScoreStats.forEach((stat) => {
    if (newDefensiveMap.get(stat.key) !== stat.multiplier) {
      isDifferent = true;
    }
  });

  const newPassingMap = new Map();
  newScoring.passingScoreStats.forEach((stat) => {
    newPassingMap.set(stat.key, stat.multiplier);
  });

  originalScoring.passingScoreStats.forEach((stat) => {
    if (newPassingMap.get(stat.key) !== stat.multiplier) {
      isDifferent = true;
    }
  });

  const newRushingMap = new Map();
  newScoring.rushingScoreStats.forEach((stat) => {
    newRushingMap.set(stat.key, stat.multiplier);
  });

  originalScoring.rushingScoreStats.forEach((stat) => {
    if (newRushingMap.get(stat.key) !== stat.multiplier) {
      isDifferent = true;
    }
  });

  return isDifferent;
};
