import { ReactNode, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import Button from '../../../components/ui/button/button';
import Loading from '../../../components/ui/loading-animation';
import GiphySearchContextManager from './widgets/giphy-gifs/GiphySearchContextManager';
import GiphySearch from './widgets/giphy-gifs/GiphySearch';
import { iUser } from '@shared/shared-utils/models';
import { getTrophyFromRank } from '../../../assets/trophies/trophy-icons';
import TrophyZoom from '../../../components/widgets/trophy-zoom';

type SeasonEndProps = {
  toggleLeagueDetails: () => void;
  userRank?: string;
  numberOfSquads?: number;
  seasonYear: number;
  viewLeagueDetails: boolean;
  userDetails: iUser;
};

const SeasonEnd = (props: SeasonEndProps) => {
  const [leagueRankTrophyDisplay, setLeagueRankTrophyDisplay] =
    useState<ReactNode>();
  const [trophyZoomDisplay, setTrophyZoomDisplay] = useState<ReactNode>();
  const [showTrophyZoom, setShowTrophyZoom] = useState(false);

  const {
    toggleLeagueDetails,
    userRank,
    seasonYear,
    viewLeagueDetails,
    numberOfSquads,
    userDetails,
  } = props;

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userRank) {
      setLoading(false);
    }
  }, [userRank]);

  useEffect(() => {
    // If the user rank is 1, 2, or 3, get the respective trophy
    if (userRank === 'first' || userRank === 'second' || userRank === 'third') {
      const trophyToShow = getTrophyFromRank(userRank);
      if (trophyToShow?.trophyType) {
        if (
          userDetails.userTrophies &&
          userDetails.userTrophies?.[trophyToShow.trophyType]
        ) {
          const userTrophyData =
            userDetails.userTrophies?.[trophyToShow.trophyType][seasonYear];
          if (userTrophyData) {
            setLeagueRankTrophyDisplay(
              <div className="w-[30%] cursor-pointer">
                <img
                  src={trophyToShow.path}
                  onClick={() => {
                    setShowTrophyZoom(true);
                    console.log('clicked');
                  }}
                />
              </div>,
            );
            setTrophyZoomDisplay(
              <TrophyZoom
                trophy={trophyToShow}
                trophyData={userTrophyData}
                onCloseClicked={() => setShowTrophyZoom(false)}
                open={true}
              />,
            );
          }
        }
      }
    }
  }, []);

  return (
    <div className="mt-[7.8rem]">
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <Confetti recycle={false} numberOfPieces={500} />
          <div className="flex flex-col justify-center items-center p-2 text-lg">
            <div className="text-center ">
              Thanks for competing this year! You came in{' '}
              <span className="text-lg font-semibold uppercase green-text">
                {userRank}
              </span>{' '}
              place out of {numberOfSquads} squads!
            </div>
            <div className="my-6">
              <GiphySearchContextManager defaultSearchTerm="college football celebrations">
                <GiphySearch onGifSelect={() => {}} endOfSeason={true} />
              </GiphySearchContextManager>
            </div>
            {leagueRankTrophyDisplay && leagueRankTrophyDisplay}
            <div className="text-center ">
              Make plans to join us next season!!!!
            </div>
          </div>
          <div className="flex justify-center pt-4">
            <Button
              label={`${viewLeagueDetails ? 'Hide' : 'View'} League Details`}
              onClick={() => toggleLeagueDetails()}
            />
          </div>
        </div>
      )}
      {trophyZoomDisplay && showTrophyZoom && trophyZoomDisplay}
    </div>
  );
};

export default SeasonEnd;
