import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import './../modal.scss';
import { ReactNode } from 'react';

type ModalWrapperType = {
  children: ReactNode;
  title: string;
  backEnabled: boolean;
  closeOnTapOutside: boolean;
  onCloseClicked?: () => void;
  onBackClicked?: () => void;
};

const ModalWrapper = ({
  children,
  title,
  backEnabled,
  closeOnTapOutside,
  onCloseClicked,
  onBackClicked,
}: ModalWrapperType) => {
  return (
    <div
      className="db-modal-backdrop"
      onClick={() => {
        if (closeOnTapOutside && onCloseClicked) onCloseClicked();
      }}
    >
      <div className="db-modal-content">
        <div className="flex items-center justify-between p-4 pb-2">
          <div className="flex items-center justify-start">
            {backEnabled && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 text-xl cursor-pointer"
                onClick={() => {
                  if (onBackClicked) onBackClicked();
                }}
                data-e2e="modal_back_btn"
              />
            )}
            <div className="text-lg" data-e2e="modal_title">
              {title}
            </div>
          </div>
          {!backEnabled && (
            <img
              src="/assets/images/cancel.png"
              className="w-4 h-4 mr-1 cursor-pointer "
              onClick={() => {
                if (onCloseClicked) onCloseClicked();
              }}
              alt="cancel"
              data-e2e="modal_close_btn"
            />
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ModalWrapper;
