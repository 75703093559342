import { useState, useEffect } from 'react';
import { iDraft } from '@shared/shared-utils/models';
import {
  draftDisplayTimer,
  secondsBetweenPicks,
} from './helpers/seconds-between-picks';

type DraftPickTimerType = {
  draftDetails: iDraft;
  getPickTimerStatus: (isExpired: boolean) => void;
  pickTimerReset: boolean;
  initalDisplayTimer: string;
};

const DraftPickTimer = (props: DraftPickTimerType) => {
  const [pickTimeLeft, setPickTimeLeft] = useState<number>(0);
  const [displayTimer, setDisplayTimer] = useState<string>(
    props.initalDisplayTimer,
  );
  const [pickTimer, setPickTimer] = useState<any>();

  //Starts the timer upon render
  useEffect(() => {
    startPickTimer();
  }, [props.draftDetails]);

  //Reset the pick timer
  useEffect(() => {
    if (props.pickTimerReset) {
      setPickTimeLeft(0);
      props.getPickTimerStatus(false);
    }
  }, [props.pickTimerReset]);

  //Tells the parent that the time has expired
  useEffect(() => {
    if (!pickTimeLeft || (pickTimeLeft && pickTimeLeft <= 0)) {
      props.getPickTimerStatus(true);
    }
  }, [pickTimeLeft]);

  const startPickTimer = () => {
    clearInterval(pickTimer);
    const timer = setInterval(() => {
      const secondsLeft = secondsBetweenPicks(props.draftDetails.nextPickBy);

      setDisplayTimer(draftDisplayTimer(secondsLeft));

      setPickTimeLeft(secondsLeft);
    }, 1000);

    setPickTimer(timer);
  };

  return (
    <div>
      <div
        className={`flex items-center justify-center w-12 p-3 font-bold rounded-full bg-gray ${
          pickTimeLeft > 5
            ? 'animate-timer-blink'
            : 'animate-elapsed-timer-blink'
        }`}
      >
        <span>{displayTimer}</span>
      </div>
    </div>
  );
};

export default DraftPickTimer;
