import React, { ReactNode, useRef, useState } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faCircleExclamation,
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

export interface iToastOptions {
  messageType: 'error' | 'info' | 'success' | 'warning';
  message: string;
  clickActions?: ReactNode | null;
  persist?: boolean;
  autoHideDuration?: number;
  dataTag?: string;
}

type ToastProviderProps = {
  children: ReactNode;
};

type ToastContextProps = {
  showToast: (options: iToastOptions) => void;
  closeSnackBars: (snackbarKey?: SnackbarKey) => void;
  currentSnackbar?: React.MutableRefObject<SnackbarKey | undefined>;
};

export const ToastContext = React.createContext({} as ToastContextProps);

const ToastProvider = ({ children }: ToastProviderProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const currentSnackbar = useRef<SnackbarKey>();

  //This is the main toast function call
  const showToast = (options: iToastOptions) => {
    let snackBar;
    switch (options.messageType) {
      case 'error':
        snackBar = showErrorToast(options);
        break;
      case 'info':
        snackBar = showInfoToast(options);
        break;
      case 'success':
        snackBar = showSuccessToast(options);
        break;
      case 'warning':
        snackBar = showWarningToast(options);
        break;
    }
    currentSnackbar.current = snackBar;
  };

  //TODO: use color variables instead (i.e. 'bg-accent')
  const showInfoToast = (options: iToastOptions) => {
    const key = enqueueSnackbar(options.message, {
      content: (
        <Alert
          icon={<FontAwesomeIcon icon={faCircleInfo} color="#00BE2A" />}
          sx={{
            backgroundColor: '#343434',
            color: '#00BE2A',
          }}
          action={options.clickActions}
          data-e2e={
            options.dataTag ? `info-toast-${options.dataTag}` : `info-toast`
          }
        >
          {options.message}
        </Alert>
      ),
      persist: options.persist,
      autoHideDuration:
        options.autoHideDuration ?? (options.clickActions ? 10000 : 4000),
      preventDuplicate: true,
    });
    return key;
  };

  const showErrorToast = (options: iToastOptions) => {
    const key = enqueueSnackbar(options.message, {
      content: (
        <Alert
          icon={<FontAwesomeIcon icon={faCircleExclamation} color="red" />}
          sx={{
            backgroundColor: '#1f1f1f',
            color: 'red',
          }}
          action={options.clickActions}
          data-e2e={
            options.dataTag ? `error-toast-${options.dataTag}` : `error-toast`
          }
        >
          {options.message}
        </Alert>
      ),
      persist: options.persist,
      autoHideDuration:
        options.autoHideDuration ?? (options.clickActions ? 10000 : 6000),
      preventDuplicate: true,
    });
    return key;
  };

  const showSuccessToast = (options: iToastOptions) => {
    const key = enqueueSnackbar(options.message, {
      content: (
        <Alert
          icon={<FontAwesomeIcon icon={faCircleCheck} color="white" />}
          sx={{
            backgroundColor: '#00BE2A',
            color: 'white',
          }}
          action={options.clickActions}
          data-e2e={
            options.dataTag
              ? `success-toast-${options.dataTag}`
              : `success-toast`
          }
        >
          {options.message}
        </Alert>
      ),
      persist: options.persist,
      autoHideDuration:
        options.autoHideDuration ?? (options.clickActions ? 10000 : 4000),
      preventDuplicate: true,
    });
    return key;
  };

  const showWarningToast = (options: iToastOptions) => {
    const key = enqueueSnackbar(options.message, {
      content: (
        <Alert
          icon={<FontAwesomeIcon icon={faTriangleExclamation} color="yellow" />}
          sx={{
            backgroundColor: '#1f1f1f',
            color: 'yellow',
          }}
          action={options.clickActions}
          data-e2e={
            options.dataTag
              ? `warning-toast-${options.dataTag}`
              : 'warning-toast'
          }
        >
          {options.message}
        </Alert>
      ),
      persist: options.persist,
      autoHideDuration:
        options.autoHideDuration ?? (options.clickActions ? 10000 : 6000),
      preventDuplicate: true,
    });
    return key;
  };

  /**
   *
   * @param snackbarKey Closes a specific snackbar. If blank, will close all snackbars.
   */
  const closeSnackBars = (snackbarKey?: SnackbarKey) => {
    closeSnackbar(snackbarKey);
  };

  return (
    <ToastContext.Provider
      value={{
        showToast,
        closeSnackBars,
        currentSnackbar,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
