import { iUser } from "../models";

// Check if any properties are empty
export const hasEmptyProperty = (obj: any): boolean => {
    for (const key in obj) {
        if (!obj[key]) {
            return true;
        }
    }
    return false;
};

// Check if all properties are unique
export const arePropertiesUnique = (obj: any): boolean => {
    const valuesSet = new Set(Object.values(obj));
    return valuesSet.size === Object.keys(obj).length;
};

export const getSquadName = (userDetails: iUser, leagueId: string) => {
    return userDetails?.squadNames && userDetails?.squadNames[leagueId]
        ? userDetails.squadNames[leagueId]
        : userDetails?.fullName;
};