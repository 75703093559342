import { iLeague, iMatchup, iMatchupSquadItemDB, iUser } from '@shared/shared-utils/models';
import { getMatchupScores } from '@shared/shared-utils';

/**
 * Maps matchup squads for the current week.
 */
export const mapMatchupSquads = async (
    matchupItems: iMatchupSquadItemDB,
    currentWeek: number,
    getTeamById: (id: string) => Promise<any>,
) => {
    const mappedMatchupSquad: any = {};
    const matchupSquadItem = matchupItems[currentWeek];

    if (matchupSquadItem) {
        for (const [key, teamId] of Object.entries(matchupSquadItem)) {
            mappedMatchupSquad[key] = await getTeamById(String(teamId));
        }
    } else {
        for (const key of ['ps1', 'ps2', 'rs1', 'rs2', 'ds1', 'ds2']) {
            mappedMatchupSquad[key] = null;
        }
    }

    return mappedMatchupSquad;
};

/**
 * Initializes the matchup list and calculates scores.
 */
export const initMatchupList = async (
    leagueDetails: iLeague | undefined,
    selectedWeek: number,
    getMemberSquad: (leagueId: string, memberId: string) => Promise<any>,
    getResults: (round: number, season: number) => Promise<any>,
    currentSeason: number,
) => {
    const matchupsData: {
        matchup: iMatchup;
        memberADetails: iUser | undefined;
        memberBDetails: iUser | undefined;
        memberAPoints: number;
        memberBPoints: number;
    }[] = [];

    if (leagueDetails?.matchups) {
        const selectedWeekMatchUpsData: iMatchup[] = leagueDetails.matchups.filter(
            (matchup) => matchup.round === selectedWeek,
        );

        for (const matchup of selectedWeekMatchUpsData) {
            const memberADetails = leagueDetails.members?.find(
                (member) => member.id === matchup.memberA,
            );
            const memberBDetails = leagueDetails.members?.find(
                (member) => member.id === matchup.memberB,
            );

            const memberASq = await getMemberSquad(leagueDetails.id, matchup.memberA);
            const memberBSq = await getMemberSquad(leagueDetails.id, matchup.memberB);

            let memberAPoints = 0;
            let memberBPoints = 0;

            const results = await getResults(
                matchup.round,
                currentSeason,
            );

            if (memberASq && memberBSq && results) {
                const totals = getMatchupScores(
                    matchup.round,
                    memberASq,
                    memberBSq,
                    results,
                    leagueDetails.settings?.scoring,
                );

                memberAPoints = totals.memberATotal;
                memberBPoints = totals.memberBTotal;
            }

            matchupsData.push({
                matchup,
                memberADetails,
                memberBDetails,
                memberAPoints,
                memberBPoints,
            });
        }

        return { selectedWeekMatchUpsData, matchupsData };
    }
    return { selectedWeekMatchUpsData: [], matchupsData: [] };
};

