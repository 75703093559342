import {
  useReactTable,
  getCoreRowModel,
  SortingState,
  flexRender,
  getSortedRowModel,
  FilterFn,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import './table.scss';
import { rankItem } from '@tanstack/match-sorter-utils';

type TablePropsType = {
  data: any[];
  columns: any[];
  columnFilters: any;
  globalFilter: any;
  updateGlobalFilter: any;
  updateColFilter: any;
  rowSelection: any;
  canSelect: number;
  rowClick: any;
  selectedRow?: any;
  visibleColumns?: any[];
  noDataMessage?: string;
  cellRowsHighlight?: any;
  getRowCount?: (numRows: number) => void;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

const Table = ({
  data,
  columns,
  columnFilters,
  globalFilter,
  rowSelection,
  updateGlobalFilter,
  updateColFilter,
  canSelect,
  rowClick,
  selectedRow,
  visibleColumns,
  noDataMessage = 'No records found',
  cellRowsHighlight,
  getRowCount,
}: TablePropsType) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      rowSelection,
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: updateColFilter,
    onGlobalFilterChange: updateGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
  });

  // Scrolls to the selected element if there is one
  useEffect(() => {
    document.getElementById('selected')?.scrollIntoView({ block: 'center' });
  }, [selectedRow]);

  useEffect(() => {
    if (visibleColumns && visibleColumns.length > 0) {
      const allColumns = table.getAllColumns();
      allColumns.map((column) => {
        if (visibleColumns?.includes(column.id)) {
          column.toggleVisibility(true);
        } else {
          column.toggleVisibility(false);
        }
      });
    }
  }, [visibleColumns]);

  useEffect(() => {
    getRowCount && getRowCount(table.getRowModel().rows.length);
  }, [table.getRowModel().rows]);

  if (!table.getRowModel().rows.length) {
    return <div className="text-center p-2 bg-gray">{noDataMessage}</div>;
  }

  return (
    <table className="db-table mb-16">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                <div
                  {...{
                    className: header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  {{
                    asc: ' 🔼',
                    desc: ' 🔽',
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              id={`${
                selectedRow && row.original.id === selectedRow.id && 'selected'
              }`}
              className={`${
                selectedRow && row.original.id === selectedRow.id && 'selected'
              } ${
                rowSelection && Object.keys(rowSelection).length < canSelect
                  ? 'cursor-pointer'
                  : 'stop-selection'
              }`}
              onClick={() => {
                rowClick(row.original);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={
                    row.original.id === cellRowsHighlight?.id
                      ? 'highlightCell'
                      : ''
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
