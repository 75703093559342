type ImageProps = {
  imageUrl?: string;
  title: string | undefined;
  size?: any;
  specialClass?: string;
  specialImageClass?: string;
  clickable?: boolean;
  clickAction?: () => void;
};

const avatarBackground = [
  'rgb(249 115 22)',
  'rgb(234 179 8)',
  'rgb(101 163 13)',
  'rgb(34 197 94)',
  'rgb(14 165 233)',
  'rgb(59 130 246)',
  'rgb(139 92 246)',
  'rgb(168 85 247)',
  'rgb(239 68 68)',
  'rgb(16 185 129)',
];

const Image = (props: ImageProps) => {
  const generateRandomNumber = (userName: string): number => {
    let hash = 0;
    for (let i = 0; i < userName.length; i++) {
      hash = (hash << 5) - hash + userName.charCodeAt(i);
      hash = hash & hash; // Convert to 32-bit integer
    }
    const randomNumber = Math.abs(hash) % avatarBackground.length;
    return randomNumber;
  };

  const imageSize = props.size || 10;
  const randomColor =
    avatarBackground[generateRandomNumber(props.title ? props.title : '')];

  const getInitials = () => {
    const names = props.title?.split(' ');
    let initials = '';
    if (names?.length) {
      initials = names[0].charAt(0);
      if (names.length > 1) {
        initials += names[1].charAt(0);
      }
    }
    return initials.toUpperCase();
  };

  return (
    <div
      className={props.clickable ? 'cursor-pointer' : ''}
      onClick={() => {
        props.clickable && props.clickAction && props.clickAction();
      }}
    >
      {props.imageUrl?.length ? (
        <div className={`h-${imageSize} w-${imageSize} ${props.specialClass}`}>
          <div
            style={{
              backgroundImage: `url(${props.imageUrl})`,
            }}
            className={`image-holder bg-no-repeat bg-cover bg-primary rounded-full h-${imageSize} w-${imageSize} min-h-[${imageSize}] ${props.specialImageClass}`}
            title={props.title}
          />
        </div>
      ) : (
        <div className={`h-${imageSize} w-${imageSize} ${props.specialClass} `}>
          <div
            className={`${props.specialImageClass} rounded-full text-primary flex justify-center items-center pt-1 h-${imageSize} w-${imageSize} ${props.specialImageClass}`}
            style={{ backgroundColor: randomColor }}
            title={props.title}
          >
            {getInitials()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Image;
