import { Button, Checkbox, Input } from '@mui/material';
import ModalWrapper from '../../../components/modals/modal-wrapper/modal-wrapper';
import { useState } from 'react';

type InvitesModalProps = {
  pendingInvites: string[];
  onCloseClicked: () => void;
  onSendClicked: (invites: string[]) => void;
};

const InvitesModal = (props: InvitesModalProps) => {
  const [invites, setInvites] = useState<string[]>(props.pendingInvites);

  const handleInviteChange = (index: number, value: string) => {
    const newInvites = [...invites];
    newInvites[index] = value;
    setInvites(newInvites);
  };

  return (
    <ModalWrapper
      title="League Invites"
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={props.onCloseClicked}
    >
      <div className="flex justify-center">
        The following people do not have user accounts. Please double check the
        spelling.
      </div>
      {invites.map((invite, index) => (
        <Input
          key={invite}
          value={invite}
          onChange={(e) => handleInviteChange(index, e.target.value)}
        />
      ))}
      <div className="flex justify-between">
        <Button color="error" onClick={props.onCloseClicked}>
          Cancel
        </Button>
        <Button color="success" onClick={() => props.onSendClicked(invites)}>
          Send Invites
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default InvitesModal;
