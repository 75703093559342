import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../ui/loading-animation';

const customURIScheme = 'com.squadblitz://com.squadblitz/join';
const appStoreURL = 'https://apps.apple.com/us/app/squad-blitz/id1669667588'; // iOS app store URL
const playStoreURL =
  'https://play.google.com/store/apps/details?id=com.squadblitz'; // Android app store URL

const AppRedirectPage = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const [isActive, setIsActive] = React.useState(true);

  useEffect(() => {
    const handleFocus = () => setIsActive(true);
    const handleBlur = () => setIsActive(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  //Runs only once when this component is mounted
  useEffect(() => {
    openCustomURI();

    // Check if the URI scheme was opened after a delay
    setTimeout(() => {
      if (isActive) {
        redirectToAppStore();
      }
    }, 2000);
    // navigate('/home');
  }, []);

  const URIScheme = `${customURIScheme}/${params.leagueId}`;
  function openCustomURI() {
    window.location.href = URIScheme;
  }

  function redirectToAppStore() {
    // Redirect the user to the app store based on their platform
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    window.location.href = isIOS ? appStoreURL : playStoreURL;
  }

  return <Loading />;
};

export default AppRedirectPage;
