import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ModalWrapper from '../../../../../components/modals/modal-wrapper/modal-wrapper';
import { iLeagueScoring } from '@shared/shared-utils/models';
import LeagueScoringModal from '../../../../../components/modals/league/league-scoring-modal';
import {
  defensiveScoreStats,
  passingScoreStats,
  rushingScoreStats,
} from '@shared/shared-utils';
import { Tooltip } from '@mui/material';

type LeagueScoringProps = {
  leagueScoring: iLeagueScoring;
  readOnly: boolean;
  setLeagueScoringInSettings: (scoring: iLeagueScoring) => void;
  isAdmin: boolean;
};

const LeagueScoring = (props: LeagueScoringProps) => {
  //Check first for scoring saved on DB. If nothing, default is scoring constants
  const [leagueScoring, setLeagueScoring] = useState<iLeagueScoring>(
    props.leagueScoring,
  );
  const [showScoringModal, setShowScoringModal] = useState(false);

  if (!leagueScoring) {
    setLeagueScoring({
      defensiveScoreStats: defensiveScoreStats,
      passingScoreStats: passingScoreStats,
      rushingScoreStats: rushingScoreStats,
    });
  }

  const onScoringSubmit = (newScoring: iLeagueScoring) => {
    setLeagueScoring(newScoring);
    props.setLeagueScoringInSettings(newScoring);
  };

  return (
    leagueScoring && (
      <>
        <h3 className="border-b-[1px] border-b-accent pb-2 mt-6 flex justify-between items-center">
          Scoring
          {/* shows an edit icon for this section */}
          <Tooltip
            enterTouchDelay={0}
            title={
              // Shows if user is not league owner
              !props.isAdmin
                ? 'Only the league owner can edit settings.'
                : // Shows if user is league owner but it's too late in the season to change
                  props.readOnly &&
                  'Scoring cannot be edited this late in the season.'
            }
            data-e2e="league_settings_scoring_tooltip"
          >
            <span>
              {/* Edit icon is "disabled" if user is not league owner or if it's too late in the season to change*/}
              <FontAwesomeIcon
                icon={faPenToSquare}
                className={`${
                  !props.isAdmin && props.readOnly
                    ? 'opacity-30'
                    : 'cursor-pointer'
                }`}
                // Only allow click if user is league owner and not too late in the season
                // Opens modal to make edits
                onClick={() =>
                  props.isAdmin && !props.readOnly && setShowScoringModal(true)
                }
                data-e2e="league_settings_scoring_edit_btn"
              />
            </span>
          </Tooltip>
        </h3>
        <div className="text-sm pl-2 py-2 opacity-30">
          <div>
            <p className="font-bold pt-2">Passing Stats</p>
            <hr className="pb-2"></hr>
            {leagueScoring.passingScoreStats.map((details) => {
              return (
                <div
                  className="flex justify-between items-center pl-4"
                  key={details.key}
                  data-e2e={`scoring_key_${details.key}`}
                >
                  <div>{details.name}</div>
                  <div data-e2e={`scoring_multiplier_${details.key}`}>
                    {details.multiplier}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <p className="font-bold pt-2">Rushing Stats</p>
            <hr className="pb-2"></hr>
            {leagueScoring.rushingScoreStats.map((details) => {
              return (
                <div
                  className="flex justify-between items-center pl-4"
                  key={details.key}
                >
                  <div>{details.name}</div>
                  <div data-e2e={`scoring_multiplier_${details.key}`}>
                    {details.multiplier}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <p className="font-bold pt-2">Defensive Stats</p>
            <hr className="pb-2"></hr>
            {leagueScoring.defensiveScoreStats.map((details, index) => {
              return details.scoringBreakdown ? (
                <div key={details.key + index}>
                  <div>{details.name}</div>
                  {Object.entries(details.scoringBreakdown).map(
                    ([key, value]) => {
                      return (
                        <div
                          key={key + value}
                          className="flex justify-between items-center pl-4"
                        >
                          <div>{key}</div>
                          <div data-e2e={`scoring_multiplier_${key}`}>
                            {value}
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              ) : (
                <div
                  className="flex justify-between items-center pl-4"
                  key={details.key}
                >
                  <div>{details.name}</div>
                  <div data-e2e={`scoring_multiplier_${details.key}`}>
                    {details.multiplier}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {showScoringModal && (
          <ModalWrapper
            title="Scoring"
            backEnabled={false}
            closeOnTapOutside={false}
            onCloseClicked={() => setShowScoringModal(false)}
          >
            <LeagueScoringModal
              updateLeagueScoring={onScoringSubmit}
              closeModal={() => setShowScoringModal(false)}
              currentScoring={leagueScoring}
            />
          </ModalWrapper>
        )}
      </>
    )
  );
};

export default LeagueScoring;
