import Table from '../../../components/ui/table/table';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { iLeague, iUser } from '@shared/shared-utils/models';
import { useContext, useEffect, useState } from 'react';
import { OperationsContext } from '../operations-provider';

type OUserTableRow = {
  allLeagues: iLeague[];
  allUsers: iUser[];
  rowClick: (user: iUser) => void;
  activeStatusFilterOption?: string;
  globalFilter: string;
  updateGlobalFilter: any;
  columnFilters: ColumnFiltersState;
  updateColFilter: any;
  getUserRowCount: (numRows: number) => void;
};

const OUsersTable = (props: OUserTableRow) => {
  const {
    allUsers,
    allLeagues,
    globalFilter,
    updateGlobalFilter,
    columnFilters,
    updateColFilter,
    getUserRowCount,
  } = props;
  const [userTableData, setUserTableData] = useState<UserTableData[]>([]);

  interface UserTableData extends iUser {
    memberCount: number;
    leaguesOwned: number;
  }

  const columnHelper = createColumnHelper<UserTableData>();

  const headerStyling = (props?: string) => {
    return <div className="text-center">{props}</div>;
  };

  const leaguesCount = (user: iUser) => {
    const leaguesAsMember = allLeagues.filter(
      (league) => league.memberIds?.includes(user.id),
    );
    return leaguesAsMember.length;
  };

  const numberOfLeaguesOwned = (user: iUser) => {
    const ownedLeagues = allLeagues.filter(
      (league) => league.creator && league.creator.id === user.id,
    );
    return ownedLeagues.length;
  };

  const setupTableData = () => {
    const tableData = allUsers.map((user) => {
      return {
        ...user,
        memberCount: leaguesCount(user),
        leaguesOwned: numberOfLeaguesOwned(user),
      };
    });

    setUserTableData(tableData);
  };

  useEffect(() => {
    setupTableData();
  }, [allUsers]);

  const defaultColumns = [
    columnHelper.accessor('email', {
      id: 'userEmail',
      header: () => headerStyling('Email'),
    }),
    columnHelper.accessor('id', {
      id: 'userID',
      header: () => headerStyling('User ID'),
    }),
    columnHelper.accessor('fullName', {
      id: 'userFullName',
      header: () => headerStyling('Full Name'),
    }),
    columnHelper.accessor('phoneNumber', {
      id: 'phoneNumber',
      header: () => headerStyling('Phone Number'),
    }),
    columnHelper.accessor('memberCount', {
      id: 'memberCount',
      header: () => headerStyling('Leagues Count'),
    }),
    columnHelper.accessor((row) => row.isActive?.toString(), {
      id: 'activeStatus',
      header: () => headerStyling('Active?'),
    }),
    columnHelper.accessor('role', {
      id: 'userRole',
      header: () => headerStyling('Role'),
    }),
    columnHelper.accessor('leaguesOwned', {
      id: 'leaguesOwned',
      header: () => headerStyling('# Leagues Owned'),
    }),
    columnHelper.accessor('playerId', {
      id: 'playId',
      header: () => headerStyling('Player ID'),
    }),
    columnHelper.accessor(
      (row) => {
        if (row.createdDate) {
          return row.createdDate.toLocaleDateString();
        } else {
          return '';
        }
      },
      {
        id: 'createdDate',
        header: () => headerStyling('Created Date'),
      },
    ),
    columnHelper.accessor(
      (row) => {
        return row.discordAuthTokens?.discordUserId || '';
      },
      {
        id: 'discordID',
        header: () => headerStyling('Discord ID'),
      },
    ),
    columnHelper.accessor(
      (row) => {
        return row.userTrophies ? Object.keys(row.userTrophies).length : 0;
      },
      {
        id: 'userTrophies',
        header: () => headerStyling('# Trophies'),
      },
    ),
    columnHelper.accessor(
      (row) => {
        return row.messageHistory ? 'Chatting' : 'No chat history';
      },
      {
        id: 'messageHistory',
        header: () => headerStyling('Chat Active?'),
      },
    ),
  ];

  return (
    <Table
      data={userTableData}
      columns={defaultColumns}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      rowSelection={''}
      updateGlobalFilter={updateGlobalFilter}
      updateColFilter={updateColFilter}
      canSelect={1}
      rowClick={props.rowClick}
      selectedRow={''}
      getRowCount={getUserRowCount}
    />
  );
};

export default OUsersTable;
