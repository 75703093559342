import { ReactNode } from 'react';
import Select, { OptionType } from '../../ui/select/select-input';
import './list.scss';
import { Tooltip } from '@mui/material';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ListWidgetType = {
  items: ReactNode[];
  label: string;
  loading: boolean;
  infoText?: ReactNode;
  loadingText?: string;
  filterInitialValue?: any;
  filterPlaceholder?: string;
  filterOptions?: OptionType[];
  onFilterChange?: (filterOption: OptionType) => void;
  selectedStyle?: string;
  isSelected?: boolean;
  selectedClass?: string;
  height?: string;
};

const ListWidget = (props: ListWidgetType) => {
  return (
    <div className="db-list">
      <div className={`flex items-center justify-between w-full header`}>
        <div className="flex flex-row">
          <h3 className="mr-2">{props.label}</h3>
          {props.infoText && (
            <Tooltip title={props.infoText} enterTouchDelay={0}>
              <div>
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>
            </Tooltip>
          )}
        </div>
        {props.onFilterChange &&
          props.filterOptions &&
          props.filterInitialValue &&
          props.filterPlaceholder && (
            <Select
              options={props.filterOptions}
              placeholder={props.filterPlaceholder}
              initialValue={props.filterInitialValue}
              showLabelAlways={false}
              onChange={(option) => {
                if (props.onFilterChange) props.onFilterChange(option);
              }}
            />
          )}
      </div>
      <div className={`db-list-items-wrapper ${props.height}`}>
        {props.loading ? (
          <div className="p-4">
            {props.loadingText ? props.loadingText : 'Loading...'}
          </div>
        ) : (
          <div>
            {props.items.length ? (
              props.items.map((item, index) => (
                <div
                  className={`db-list-item-wrapper ${props.selectedClass}`}
                  key={index}
                  style={{
                    backgroundColor: props.isSelected
                      ? props.selectedStyle
                      : '',
                  }}
                >
                  {item}
                </div>
              ))
            ) : (
              <div className="m-4">No Items to Show!</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListWidget;
