import {
  iAutoPickList,
  iLeague,
  iUser,
  leagueDraftStatus,
  iTeam,
  iDraft,
  iRoundPick,
  iDraftPickOrder,
} from '@shared/shared-utils/models';
import { useEffect, useContext, useState, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/widgets/header/header';
import { LeagueContext } from '../../../providers/league-provider';
import LeagueDraftSettings from './widgets/draft-settings';
import { AuthContext } from '../../../providers/auth-provider';
import ListItem from '../../../components/widgets/list-widget/list-item';
import { TeamsContext } from '../../../providers/teams-provider';
import Image from '../../../components/widgets/image';
import { useHorizontalScroll } from '../../../hooks/use-hoz-scroll';
import ListWidget from '../../../components/widgets/list-widget/list-widget';
import SelectTeamModal from '../../../components/modals/select-team/select-team-modal';
import { doc, onSnapshot } from 'firebase/firestore';
import { DBCollections, DiscordWebhooks } from '@shared/shared-utils';
import { DBFirebaseDB } from '../../../services/firebase-core';
import TabHeader from '../../../components/ui/tabs/tab-header';
import LeagueSettings from './widgets/league-settings';
import InviteMembers from './widgets/invite-members';
import { tabItemsDetails } from './league-constants';
import { NotificationService } from '../../../services/notification-service';
import Button from '../../../components/ui/button/button';
import Loading from '../../../components/ui/loading-animation';
import { DiscordService } from '../../../services/discord-service';
import AutoPickList from './widgets/autopick-list';
import { ToastContext } from '../../../providers/toast-provider';
import LeagueChat from './widgets/league-chat/league-chat';
import { ChatContext } from '../../../providers/chat-provider';
import { TrophyContext } from '../../../providers/trophy-provider';
import LeaveLeagueComponent from '../../../components/ui/leave-league/leave-league';
import VideoBannerWithModal from '../../../components/ui/video-banner/video-banner';

const DraftLeague = () => {
  const _discordService = new DiscordService();
  const params: any = useParams();
  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);
  const {
    getLeagueDetails,
    amICurrentLeagueOwner,
    currentLeague,
    amILeagueMember,
    getLeagueDraft,
    startLeagueDraft,
    addLeaguePick,
    getMyLeagueSquad,
    appConfig,
    leaveLeague,
  } = useContext(LeagueContext);
  const { assignTrophy } = useContext(TrophyContext);
  const hozScrollRef = useHorizontalScroll();
  const {
    getAllTeamsData,
    showSelectTeamModal,
    toggleSelectTeamModalVisibility,
    getTeamById,
  } = useContext(TeamsContext);
  const { checkForUnreadMessages } = useContext(ChatContext);
  const [league, setLeague] = useState<iLeague>();
  const [asCreator, setAsCreator] = useState<boolean>(false);
  const [draft, setDraft] = useState<iDraft>();
  const [squadList, setSquadList] = useState<any[]>([]);
  const [currentTurnUser, setCurrentTurnUser] = useState<iUser>();
  const [myAutoPickList, setMyAutoPickList] = useState<iAutoPickList>();
  const [draftActivityItems, setDraftActivityItems] = useState<ReactNode[]>();
  const [totalSlotsForUser, setTotalSlotsForUser] = useState<number>(10);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [autoPickTeamSelected, setAutoPickTeamSelected] = useState<iTeam>();
  const [allMembersOnboarded, setAllMembersOnboarded] = useState(false);
  const [expectedNumLeagueMembers, setExpectedNumLeagueMembers] =
    useState<number>();
  const { showToast } = useContext(ToastContext);
  const [showChatBadge, setShowChatBadge] = useState<boolean>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [pickOrder, setPickOrder] =
    useState<(JSX.Element | (JSX.Element | undefined)[])[][]>();
  const [teamsExcluded, setTeamsExcluded] = useState<string[]>();
  const [leagueIsReactivated, setLeagueIsReactivated] = useState(false);

  const notificationService = new NotificationService();

  const isMyTurn = currentTurnUser?.id === userDetails?.id;

  const initLeagueDetails = async () => {
    if (params) {
      try {
        const league: iLeague = await getLeagueDetails(
          params['leagueId'],
          true,
        );
        setLeague(league);
        await getAllTeamsData();
      } catch (error) {
        navigate('/');
      }
    }
  };

  const getUserLeague = async () => {
    try {
      const league: iLeague = await getLeagueDetails(params['leagueId'], true);
      setLeague(league);
    } catch (error) {
      console.log(error);
      navigate('/');
    }
  };

  useEffect(() => {
    if (league?.memberIds?.length) {
      setExpectedNumLeagueMembers(league.expectedNumMembers);

      if (league?.memberIds?.length === league?.settings?.numOfMembers) {
        setAllMembersOnboarded(true);
      } else {
        setAllMembersOnboarded(false);
      }
    }
  }, [league?.memberIds, league?.settings?.numOfMembers]);

  const leaguePickTimeout = (league: iLeague) => {
    if (league.settings?.pickTimerUnit === 'minutes') {
      return league.settings?.pickTimer * 60;
    } else {
      return league.settings?.pickTimer;
    }
  };

  //Creates pick order for the entire draft
  //Each round the order is reversed (snake draft)
  const createDraftOrder = (
    numberOfDraftRounds: number,
    draftPickOrder: string[],
  ) => {
    const draftOrder: iDraftPickOrder = { 0: draftPickOrder };

    for (let i = 0; i < numberOfDraftRounds - 1; i++) {
      const previousOrder = draftOrder[i];
      const reversedOrder = previousOrder?.slice().reverse();
      draftOrder[i + 1] = reversedOrder;
    }

    return draftOrder;
  };

  const handleDraftStart = async () => {
    // Pull the league one more time to make sure all users are included
    await getUserLeague();

    if (league?.id && league.memberIds) {
      const shuffledOrder = league.memberIds
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

      const numberOfDraftRounds = league.settings?.numOfTeamsPerMember
        ? league.settings?.numOfTeamsPerMember
        : 10;

      const pickByTime =
        new Date().getTime() + leaguePickTimeout(league) * 1000;
      const leagueDraft: iDraft = {
        status: leagueDraftStatus.inProgress,
        leagueId: league.id,
        numberOfRounds: numberOfDraftRounds,
        round: 1,
        pendingPicksInRound: league.memberIds.length,
        picks: [],
        members: league.memberIds,
        pickOrder: createDraftOrder(numberOfDraftRounds, shuffledOrder),
        currentTurn: shuffledOrder[0],
        nextPickBy: new Date(pickByTime),
        startedOn: new Date(),
      };
      const mDraft = await startLeagueDraft(league, leagueDraft);

      // Send notification to users that the draft is starting
      const memberPlayerIds: string[] = [];
      league.members.map((member) => {
        if (member.playerId) {
          memberPlayerIds.push(member.playerId);
        }
      });
      notificationService.draftHasStarted(memberPlayerIds, league.title);

      _discordService.postDiscordMessage(
        `Draft starting for ${league.title}`,
        DiscordWebhooks.activityChannel,
      );

      if (mDraft) {
        setDraft(mDraft);
      }
    }
  };

  const onDraftUpdate = async () => {
    if (league && draft) {
      if (league.draftStatus === leagueDraftStatus.pending) {
        setLeague({ ...league, draftStatus: leagueDraftStatus.inProgress });
      }

      if (league.members) {
        const pickingUserDetails = league.members.find(
          (member) => member.id === draft.currentTurn,
        );

        setCurrentTurnUser(pickingUserDetails);
      }

      if (draft.picks && userDetails?.id) {
        const allPickItems = [];
        let allPicks = [...draft.picks];
        allPicks = allPicks.sort(
          (a: iRoundPick, b: iRoundPick) =>
            new Date(b.pickAt.toDate()).getTime() -
            new Date(a.pickAt.toDate()).getTime(),
        );

        for (let i = 0; i < allPicks.length; i++) {
          const pickListItem = await getPickListItem(allPicks[i], league);
          allPickItems.push(pickListItem);
        }

        setDraftActivityItems(allPickItems);

        // update the autopick list
        if (myAutoPickList) {
          filterPickedTeamsAndSet(draft.picks, myAutoPickList);
        }

        // Handle user specific information
        let allUserPicks: iRoundPick[] = draft.picks.filter(
          (pick) => pick.memberId === userDetails.id,
        );

        allUserPicks = allUserPicks.sort((a, b) => (a.slot < b.slot ? -1 : 1));
        mapMyLeagueSquads(allUserPicks.map((pick) => pick.teamId));
      }
    }
  };

  const getPickListItem = async (pick: iRoundPick, league: iLeague) => {
    const memberDetails = league.members?.find(
      (member) => member.id === pick.memberId,
    );
    const teamDetails = await getTeamById(pick.teamId);
    return (
      <ListItem
        children={
          memberDetails &&
          teamDetails && (
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start">
                <Image
                  key={memberDetails.id}
                  imageUrl={memberDetails.profileImage}
                  title={memberDetails.fullName}
                  specialClass="mr-2"
                />
                {memberDetails.fullName} picked
                <span className="pl-1 font-bold">{teamDetails.school}</span>
              </div>
              <div className="flex items-center justify-start">
                Slot
                <span className="pl-1 text-base font-bold">
                  {pick.slot + 1}
                </span>
                <div
                  key={teamDetails.id}
                  style={{
                    backgroundImage: `url(${teamDetails.logos[0].replace(
                      /http:/g,
                      'https:',
                    )})`,
                  }}
                  className="team-image-list bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mx-2 mr-0"
                  title={teamDetails.school}
                />
              </div>
            </div>
          )
        }
      />
    );
  };

  const mapMyLeagueSquads = async (teamIds: string[]) => {
    const teamsData = [];
    for (let i = 0; i < teamIds.length; i++) {
      const teamData = await getTeamById(teamIds[i]);
      teamsData.push(teamData);
    }
    if (teamsData.length < totalSlotsForUser) {
      for (let i = teamIds.length; i < totalSlotsForUser; i++) {
        teamsData.push(false);
      }
    }
    setSquadList(teamsData);
  };

  const getDraftDetails = async (mLeague: iLeague) => {
    if (mLeague.id && !draft) {
      const mDraft = await getLeagueDraft(mLeague.id);
      setDraft(mDraft);
    }
  };

  const selectTeamForASlot = async (slot: number, teamId: string) => {
    if (userDetails?.id && draft && league) {
      const updatedDraft: iDraft = { ...draft };
      const pickByTime =
        new Date().getTime() + leaguePickTimeout(league) * 1000;
      updatedDraft.nextPickBy = new Date(pickByTime);
      const leaguePick: iRoundPick = {
        memberId: draft.currentTurn,
        teamId: teamId,
        slot: slot,
        round: draft.round,
        pickAt: new Date(),
        pickBy: userDetails.id,
      };

      // Pick has been made
      await addLeaguePick(updatedDraft, leaguePick);

      // filter team from autoPick
      if (myAutoPickList) {
        setMyAutoPickList({
          ...myAutoPickList,
          teamOrder: myAutoPickList.teamOrder.filter(
            (teamId) => teamId !== leaguePick.teamId,
          ),
        });
      }
    }
  };

  const endDraftAndNavigate = async () => {
    setShowLoading(true);

    setLeague({
      ...league,
      draftStatus: leagueDraftStatus.done,
    } as iLeague);

    league?.isOpen &&
      (await assignTrophy(
        league?.creator,
        appConfig.currentSeason,
        'publicHero',
        'Public Hero trophy awarded!',
      ));

    // Lets wait for 1 second to let the DB populate

    setTimeout(() => {
      navigate('/league/' + params['leagueId']);
    }, 1000);
  };

  const startListeningToDraft = (leagueId: string) => {
    const unsub = onSnapshot(
      doc(DBFirebaseDB, DBCollections.leagueDrafts, leagueId),
      (doc) => {
        const updatedDraft = doc.data() as iDraft;
        if (updatedDraft) {
          setDraft(updatedDraft);
          if (updatedDraft.status === leagueDraftStatus.done) {
            endDraftAndNavigate();
          }
        }
      },
    );
    return unsub;
  };

  const filterPickedTeamsAndSet = (
    pickedTeams: iRoundPick[],
    autoPickList: iAutoPickList,
  ) => {
    const pickMap = new Map();

    // Create a map for quick search
    pickedTeams.forEach((pick) => {
      pickMap.set(pick.teamId, pick);
    });

    setMyAutoPickList({
      ...autoPickList,
      teamOrder: autoPickList.teamOrder.filter(
        (teamId) => !pickMap.has(teamId),
      ),
    });
  };

  const setAutoPickList = () => {
    if (league?.autopickLists && userDetails) {
      const myPickList = league.autopickLists.find((list) => {
        return list.ownerId === userDetails.id;
      });
      if (myPickList) {
        filterPickedTeamsAndSet(draft?.picks ? draft.picks : [], myPickList);
      } else {
        // We weren't able to find a list here, use the default
        const defaultPickList = {
          ownerId: userDetails.id ? userDetails.id : '',
          teamOrder: appConfig.defaultTeamRankings.split(','),
        };
        filterPickedTeamsAndSet(
          draft?.picks ? draft.picks : [],
          defaultPickList,
        );
      }
    } else {
      // set the default list for viewing
      const defaultPickList = {
        ownerId: userDetails?.id ? userDetails.id : '',
        teamOrder: appConfig.defaultTeamRankings.split(','),
      };
      filterPickedTeamsAndSet(draft?.picks ? draft.picks : [], defaultPickList);
    }
  };

  const handleLeaveLeague = async () => {
    setShowLoading(true);
    try {
      if (league?.id) {
        await leaveLeague(league.id);
        showToast({
          messageType: 'success',
          message: 'League successfully left!',
          dataTag: 'leave_league',
        });
        navigate('/home');
      } else {
        showToast({
          messageType: 'error',
          message:
            'An error occurred while leaving the league, contact support for help.',
          dataTag: 'leave_league_error',
        });
      }
    } catch (e) {
      showToast({
        messageType: 'error',
        message:
          'An error occurred while leaving the league, contact support for help.',
        dataTag: 'leave_league_error',
      });
      console.log(e);
    }
  };

  const handleOnAutoPickClick = () => {
    if (draft && league) {
      const userAutoPickList = league.autopickLists?.find(
        (autoPick: iAutoPickList) => autoPick.ownerId === draft.currentTurn,
      );
      let userPickList: string[] = [];
      if (userAutoPickList) {
        userPickList = userAutoPickList?.teamOrder;
      }
      if (!userAutoPickList && appConfig?.defaultTeamRankings) {
        userPickList = appConfig?.defaultTeamRankings.split(',');
      }
      const nextAvailableTeams = userPickList.filter((teamId) => {
        const pickedTeamsList = draft.picks.map((team) => team.teamId);
        return !pickedTeamsList.includes(teamId);
      });

      // Confirm they have not been selected

      selectTeamForASlot(draft.round - 1, nextAvailableTeams[0]);
    }
  };

  useEffect(() => {
    if (currentLeague?.id === params['leagueId'] && currentLeague?.id) {
      const amIMember = amILeagueMember();
      if (!amIMember) {
        navigate('/join/' + params['leagueId']);
      }
      const amICreator = amICurrentLeagueOwner();
      setAsCreator(amICreator);
      const numOfSlots = currentLeague?.settings?.numOfTeamsPerMember
        ? currentLeague.settings.numOfTeamsPerMember
        : 10;

      setTotalSlotsForUser(numOfSlots);
      const slotDetails = [];
      for (let i = 0; i < numOfSlots; i++) {
        slotDetails.push(false);
      }
      // setSquadList(slotDetails);
      if (
        currentLeague.draftStatus !== leagueDraftStatus.done &&
        Date.parse(new Date().toString()) >
          Date.parse(currentLeague.settings?.draftDate?.toDate())
      ) {
        getDraftDetails(currentLeague);
      }

      if (currentLeague.draftStatus === leagueDraftStatus.done) {
        getMyLeagueSquad(currentLeague.id);
      }
      setLeague(currentLeague);
    }
  }, [currentLeague]);

  useEffect(() => {
    const unsubscribe = startListeningToDraft(params['leagueId']);
    setAutoPickList();

    if (draft && draft?.status !== leagueDraftStatus.inProgress) {
      unsubscribe();
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [league]);

  useEffect(() => {
    onDraftUpdate();
  }, [draft]);

  useEffect(() => {
    initLeagueDetails();

    // Check for the "tab" parameter in the URL
    const urlParams = new URLSearchParams(window.location.search);

    const reactivatedLeagueFromParams = urlParams.get('reactivated');
    if (reactivatedLeagueFromParams === 'true') {
      setLeagueIsReactivated(true);
      setSelectedTabIndex(3);
    }
  }, []);

  const onAutoPickSelection = (team: iTeam) => {
    if (draft?.picks.find((pick) => pick.teamId === 'team-' + team.id)) {
      showToast({
        messageType: 'warning',
        message: 'That team has already been chosen. Please select another.',
      });
    } else {
      toggleSelectTeamModalVisibility();
      setAutoPickTeamSelected(team);
    }
  };

  // Scrolls (horizontally only) the current slot pick into view
  document
    .getElementById('current_slot')
    ?.scrollIntoView({ block: 'end', inline: 'center' });

  //Pulls the league data when the tabs change so it stays up to date
  useEffect(() => {
    getUserLeague();
  }, [selectedTabIndex]);

  useEffect(() => {
    if (league) {
      setShowChatBadge(
        league.lastMessageSent ? checkForUnreadMessages(league) : false,
      );
    }
  }, [league?.lastMessageSent, userDetails?.messageHistory]);

  useEffect(() => {
    upcomingPickOrder();
  }, [draft, currentTurnUser]);

  //Gets the upcoming pickers for each round
  const upcomingPickOrder = () => {
    if (draft && currentTurnUser) {
      const numOfPickers = draft.members.length;
      const currentRoundIndex = draft.round - 1;
      const currentRoundDraftOrder = draft.pickOrder[currentRoundIndex];

      const numOfPicksRemainingThisRound =
        numOfPickers - currentRoundDraftOrder.indexOf(currentTurnUser.id) - 1;

      const pickersRemainingThisRound = currentRoundDraftOrder.slice(
        -numOfPicksRemainingThisRound,
      );

      const allUpcomingPickerIds = [];
      numOfPicksRemainingThisRound > 0 &&
        allUpcomingPickerIds.push(pickersRemainingThisRound);

      const upcomingRounds = [];

      for (let i = draft.round + 1; i <= draft.numberOfRounds; i++) {
        upcomingRounds.push(i);
      }

      upcomingRounds.forEach((roundNum) => {
        allUpcomingPickerIds.push(draft.pickOrder[roundNum - 1]);
      });

      const pickOrderList = allUpcomingPickerIds.map((roundPickOrder) => {
        return roundPickOrder.map(
          (memberId: string) =>
            league?.members?.find((member) => member.id === memberId),
        );
      });

      const memberImages = league?.members.map((member, index) => {
        return {
          memberId: member.id,
          memberImage: (
            <Image
              key={`${member?.id}`}
              imageUrl={member?.profileImage}
              title={member?.fullName}
              size={12}
              specialClass="mr-2"
            />
          ),
        };
      });

      const order = pickOrderList
        ?.map((roundPickOrder, roundIndex) => {
          // This will be for all indexes over 1. If we aren't at the last pick, this will be the only one displayed
          const roundToDisplay =
            numOfPicksRemainingThisRound > 0
              ? draft.round + roundIndex - 1 // this is going to be the only divider shown
              : draft.round + roundIndex; // this is for "the turn" of the draft

          // Index of zero is only shown at "the turn" of the draft
          const roundDivider =
            roundIndex > 0 ? (
              <span
                key={roundIndex}
                className="inline-flex whitespace-nowrap items-center pr-2"
              >{`- End of ${roundToDisplay} -`}</span>
            ) : (
              <span key={'end' + draft.round}>
                {numOfPicksRemainingThisRound === 0 ? (
                  <span className="inline-flex whitespace-nowrap items-center pr-2">{`- End of ${draft.round} -`}</span>
                ) : (
                  <span></span>
                )}
              </span>
            );
          let members = roundPickOrder.map((member) => {
            return memberImages?.find(
              (memberObj) => memberObj.memberId === member?.id,
            )?.memberImage;
          });
          if (roundToDisplay === draft.round - 1) {
            // We are looking at the active round
            const beforeFilter = members.length;

            // Make sure the current picker isn't in the list
            members = members.filter(
              (member) => (member as JSX.Element).key !== currentTurnUser.id,
            );

            if (beforeFilter !== members.length) {
              // something has changed
              console.log('NOW IT IS CORRECT');
            }
          }

          return [roundDivider, members];
        })
        .concat([
          <span
            key={'endDraft'}
            className="inline-flex whitespace-nowrap items-center pr-2"
          >
            - End of Draft -
          </span>,
        ]);

      setPickOrder(order);
    }
  };

  useEffect(() => {
    if (draft) {
      const excludeTeams = draft.picks.map((pick) => pick.teamId);
      setTeamsExcluded(excludeTeams);
    } else {
      setTeamsExcluded([]);
    }
  }, [draft?.picks]);

  return !league || showLoading ? (
    <Loading></Loading>
  ) : (
    <div
      id="db-draft-league"
      className="flex flex-col football-bg"
      data-e2e="draft_league_page"
    >
      <Header
        title={'LEAGUE: ' + league.title}
        isHome={false}
        cancelLink="/home"
        subContent={
          <TabHeader
            selectedTabIndex={selectedTabIndex}
            tabItems={tabItemsDetails.map((item) => ({
              ...item,
            }))}
            onTabChange={(selectedIndex) => {
              setSelectedTabIndex(selectedIndex);
            }}
            showBadge={showChatBadge}
          />
        }
      />
      <div className="pt-[7.2rem]">
        {selectedTabIndex === 0 && (
          <div
            className="flex flex-col flex-1 max-w-[560px] overflow-hidden w-full"
            data-e2e="draft_details_page"
          >
            <LeagueDraftSettings
              league={league}
              isCreator={asCreator}
              onStartDraft={handleDraftStart}
              onAutoPickClicked={handleOnAutoPickClick}
              draft={draft}
              currentTurnUser={currentTurnUser}
              allMembersOnboarded={allMembersOnboarded}
              pickOrderDisplay={pickOrder}
            />
            <div>
              {league.draftStatus === leagueDraftStatus.pending && (
                <div className="my-4">
                  <VideoBannerWithModal
                    videoData={[
                      {
                        label: 'How the draft works!',
                        url: 'https://www.youtube.com/watch?v=YywncdmFMV0',
                      },
                    ]}
                    buttonText="Learn about the draft"
                  />
                </div>
              )}
              <p className="px-4 font-bold">My Squad</p>
              {league.draftStatus === leagueDraftStatus.pending && (
                <div className="p-2 text-center">
                  Your teams will appear here when the draft gets started!
                </div>
              )}
              <div
                className="flex w-full p-4 pb-1 overflow-x-scroll"
                ref={hozScrollRef}
              >
                {squadList.map((slot: iTeam, index: number) => {
                  const disabledStatus = isMyTurn && index + 1 === draft?.round;
                  return slot ? (
                    <div
                      key={index}
                      className={`flex flex-col items-center justify-start slot`}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${slot.logos[0].replace(
                            /http:/g,
                            'https:',
                          )})`,
                        }}
                        className="bg-no-repeat bg-cover bg-primary mx-4 mt-4 rounded-full h-16 w-16 min-h-[2rem] team-image"
                        title={slot.school}
                      />
                      <span className="p-2 break-words max-w-[110px] text-center">
                        {slot.school}
                      </span>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={`flex flex-col items-center justify-start rounded-2xl cursor-pointer${
                        disabledStatus &&
                        league?.draftStatus !== leagueDraftStatus.done
                          ? ' animate-bg-blink'
                          : ' disabled'
                      }`}
                      onClick={() => {
                        toggleSelectTeamModalVisibility();
                      }}
                    >
                      <div className={`w-16 mx-4 mt-4`}>
                        <img
                          src="/assets/images/add-button.png"
                          alt="add button"
                          className="w-16"
                          id="current_slot"
                        />
                      </div>
                      <span className="p-2">Slot {index + 1}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mx-4">
              <Button
                className="mt-4 mb-2 bg-accent w-full"
                label="View All Teams"
                onClick={() => {
                  toggleSelectTeamModalVisibility();
                }}
              />
            </div>
            {/* Autopick list shown prior to and during draft only */}
            {league.draftStatus !== leagueDraftStatus.done && (
              <AutoPickList
                league={league}
                updatedAutopickList={myAutoPickList}
                setPickForDraft={onAutoPickSelection}
              ></AutoPickList>
            )}
            <div className="px-4 mt-6 mb-6">
              <ListWidget
                items={
                  draftActivityItems?.length
                    ? draftActivityItems
                    : [<ListItem children={<div>No activities yet!</div>} />]
                }
                label={'Draft Activities'}
                loadingText="No activities yet!"
                loading={!draft || draft?.picks.length === 0}
              />
            </div>
            {showSelectTeamModal && (
              <SelectTeamModal
                title="Select Team"
                league={league}
                excludedTeams={teamsExcluded}
                onTeamSelect={(team) => {
                  if (draft?.round) {
                    // Make sure the team hasn't already been picked
                    if (
                      !draft.picks.some(
                        (pick) => pick.teamId === 'team-' + team.id,
                      )
                    ) {
                      selectTeamForASlot(draft.round - 1, 'team-' + team.id);
                    } else {
                      showToast({
                        messageType: 'error',
                        message:
                          'Sorry this team has already been selected! Please try again.',
                      });
                    }
                  }
                }}
                hideSelect={currentTurnUser?.id !== userDetails?.id}
                hideNextGame={true}
                hideLockedIcon={true}
                isLockedTeamSelectable={true}
                orderedList={myAutoPickList?.teamOrder}
                preSelectedTeam={autoPickTeamSelected}
                defaultSeason={appConfig.currentSeason - 1}
              />
            )}
            {/* Don't show to the league owner or if the league is not open */}
            {league.isOpen &&
              league.draftStatus === leagueDraftStatus.pending &&
              league.creator.id !== userDetails?.id && (
                <div className="full-width">
                  <LeaveLeagueComponent onConfirmLeave={handleLeaveLeague} />
                </div>
              )}
          </div>
        )}
        {selectedTabIndex === 1 && <LeagueChat league={league} />}

        {selectedTabIndex === 2 && (
          <InviteMembers
            showMembers={true}
            league={league}
            isLeagueActive={true}
            isOwner={asCreator}
            onFinish={() => setSelectedTabIndex(selectedTabIndex + 1)}
            //user can only add invites if is leagueOwner, current week is not > 1, and the draft hasn't started/completed
            readonly={
              !asCreator || league.draftStatus !== leagueDraftStatus.pending
            }
            getExpectedNumLeagueMembers={setExpectedNumLeagueMembers}
            updateLeagueState={setLeague}
            reactivatedLeague={leagueIsReactivated}
          />
        )}
        {selectedTabIndex === 3 && (
          <LeagueSettings
            league={league}
            isAdmin={asCreator}
            // If not league owner or draft has started/is complete, settings will be readonly
            readOnly={
              !asCreator || league.draftStatus !== leagueDraftStatus.pending
            }
            // If not league owner or (we are past week 1 and league drafted), scoring will be readonly
            readOnlyScoring={
              !asCreator ||
              (appConfig.currentWeek > 1 &&
                league.draftStatus === leagueDraftStatus.done)
            }
            reactivateLeagueOption={false}
            expectedNumLeagueMembers={expectedNumLeagueMembers}
          />
        )}
      </div>
    </div>
  );
};

export default DraftLeague;
