import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { discordConfig } from '../../environments/environment.local.dev';

type DiscordButtonType = {
  nativeDisabled?: boolean;
  disabledWithTooltip?: boolean;
};

const DiscordButton = (props: DiscordButtonType) => {
  const { oAuthUrl } = discordConfig;

  const handleDiscordSignIn = () => {
    window.location.href = oAuthUrl;
  };

  return (
    <Tooltip
      enterTouchDelay={0}
      title={
        props.disabledWithTooltip &&
        'You have opted out of Discord. Click the switch to the right to opt back in!'
      }
    >
      <Button
        variant="contained"
        onClick={() => !props.disabledWithTooltip && handleDiscordSignIn()}
        //TODO: Make this a global style variable for disabled MUI Buttons
        sx={
          props.disabledWithTooltip
            ? {
                color: 'rgba(255, 255, 255, 0.3)',
                bgcolor: 'rgba(255, 255, 255, 0.12)',
                cursor: 'default',
                ':hover': {
                  color: 'rgba(255, 255, 255, 0.3)',
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                },
              }
            : {}
        }
        className="w-full sm:w-max"
        disabled={props.nativeDisabled}
      >
        Login with Discord
      </Button>
    </Tooltip>
  );
};

export default DiscordButton;
