import { useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import TabHeaderItem from './tab-header-item';
import './tabs.scss';
import { Badge } from '@mui/material';

type TabHeaderType = {
  selectedTabIndex: number | undefined;
  onTabChange: (index: number) => void;
  tabItems: any[];
  showBadge?: boolean;
};

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const TabHeader = (props: TabHeaderType) => {
  const { selectedTabIndex, onTabChange, tabItems, showBadge } = props;
  const [tabs, setTabs] = useState<any[]>();
  const handleTabSelect = (index: number) => {
    onTabChange(index);
  };

  const mapTabItems = (items: any[]) => {
    return [...items].map((tab, index) => {
      tab.index = index;
      tab.selected = tab.index === selectedTabIndex;
      tab.onTabSelect = handleTabSelect;
      tab.key = tab.key;
      return tab;
    });
  };

  useEffect(() => {
    setTabs(mapTabItems(tabItems));
  }, [selectedTabIndex, tabItems]);

  return (
    <ul className="tab-header">
      {tabs &&
        tabs.map((tabItemDetails, index: number) => {
          return (
            <Badge
              variant="dot"
              invisible={
                tabItemDetails.key === 'clChat' && showBadge ? false : true
              }
              key={'chatBadge' + tabItemDetails.key}
              color="primary"
            >
              <TabHeaderItem
                key={index}
                {...tabItemDetails}
                showBadge={showBadge}
              />
            </Badge>
          );
        })}
    </ul>
  );
};

export default TabHeader;
