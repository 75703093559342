import React, { ReactNode, useState } from 'react';
import {
  iRoundPick,
  iLeague,
  iTradeRequest,
  iTeam,
  iGameResults,
  iSmallGame,
  iSeasonResults,
  iSeasonResult,
  iCategoryStatsItem,
  iStatScoreItem,
  statsGroup,
} from '@shared/shared-utils/models';
import { TeamsService } from '../services/teams-service';
import { DefensivePointsAllowed } from '@shared/shared-utils';

type TeamsProviderProps = { children: ReactNode };
type TeamsContextProps = {
  getAllTeamsData: () => Promise<iTeam[] | null>;
  teamsData: iTeam[];
  getTeamById: (teamId: string) => Promise<iTeam | undefined>;
  getLocalTeamById: (teamId: string) => iTeam | null;
  getTeamByUserSlot: (
    draftPicks: iRoundPick[],
    slot: number,
    userId: string,
  ) => Promise<iTeam | undefined>;
  getResults: (round: number, season: number) => Promise<iGameResults[]>;
  getSeasonResultsByTeam: (
    season: number,
    team: iTeam,
  ) => Promise<iGameResults[] | null>;
  getSeasonResultTotalsByTeam: (
    season: number,
    teamId: string,
  ) => Promise<iSeasonResult>;
  getSeasonResultTotals: (season: number) => Promise<iSeasonResults>;
  getRequestsForUser: (userId: string) => Promise<any>;
  //TODO: make league always defined
  acceptRequest: (
    request: iTradeRequest,
    currentWeek: number,
    league?: iLeague,
  ) => Promise<any>;
  rejectRequest: (request: iTradeRequest) => Promise<any>;
  cancelRequest: (request: iTradeRequest) => Promise<any>;
  getTeamRankingsPerCategory: (
    season: number,
    currentTeam: iTeam,
    league: iLeague,
  ) => Promise<{
    teamId: string;
    passing: {
      totalScore: number;
      rank: number;
      average: number;
    };
    rushing: {
      totalScore: number;
      rank: number;
      average: number;
    };
    defensive: {
      totalScore: number;
      rank: number;
      average: number;
    };
  }>;
  getCumulativeScores: (
    teamResults: iSeasonResult,
    league: iLeague,
  ) => {
    Passing: {
      name: statsGroup;
      value: number;
    };
    Rushing: {
      name: statsGroup;
      value: number;
    };
    Defensive: {
      name: statsGroup;
      value: number;
    };
  };
};

export const TeamsContext = React.createContext({} as TeamsContextProps);

const TeamsProvider = ({ children }: TeamsProviderProps) => {
  const _teamsService = new TeamsService();
  const [teamsData, setTeamsData] = useState<iTeam[]>([]);
  const [seasonGameResults, setSeasonGameResults] = useState<iSeasonResults>();

  const getTeamById = async (teamId: string): Promise<iTeam | undefined> => {
    if (teamsData?.length) {
      const teamFound = teamsData.find((team) => {
        return teamId && team.id === Number(teamId.split('-')[1]);
      });
      return teamFound;
    }

    const teams = await _teamsService.getAllTeams();
    if (teams) {
      // order the teams alphabetically by school name
      teams.sort((a, b) => a.school.localeCompare(b.school));
      setTeamsData(teams);
    }

    if (teams && teamId) {
      const teamIdSlit = teamId.split('-');
      const mTeamId = Number(teamIdSlit[1]);
      const teamFound = teams.find((team) => team.id === mTeamId);
      return teamFound;
    }
    return;
  };

  const getLocalTeamById = (teamId: string): iTeam | null => {
    if (teamsData?.length) {
      const teamFound = teamsData.find((team) => {
        return teamId && team.id === Number(teamId.split('-')[1]);
      });
      if (teamFound) {
        return teamFound;
      }
    }
    return null;
  };

  const getTeamByUserSlot = async (
    draftPicks: iRoundPick[],
    slot: number,
    userId: string,
  ): Promise<iTeam | undefined> => {
    const pick = draftPicks.find(
      (pick) => pick.memberId === userId && pick.slot === slot,
    );
    if (pick) {
      return await getTeamById(pick?.teamId);
    }
    return;
  };

  /**
   * Gets team meta data
   */
  const getAllTeamsData = async () => {
    let teams;
    if (teamsData.length > 0) {
      teams = teamsData;
    } else {
      teams = await _teamsService.getAllTeams();
      if (teams) {
        // order the teams alphabetically by school name
        teams.sort((a, b) => a.school.localeCompare(b.school));
        setTeamsData(teams);
      }
    }
    return teams;
  };

  /**
   * Gets the results for a given week and season
   */
  const getResults = async (round: number, season: number) => {
    const teamResults = await _teamsService.getGameResults(round, season);
    return teamResults;
  };

  /**
   * Gets the results for the given team only for the weeks they played. Does NOT total the results.
   */
  const getSeasonResultsByTeam = async (season: number, team: iTeam) => {
    const schedule = team[`${season}-schedule` as keyof typeof team] as {
      [key: number]: iSmallGame;
    };
    const scheduledWeeks = Object.values(schedule).map((game) => game.week);
    const seasonWeeks = scheduledWeeks.map((week) => season + '-' + week);
    const results = await _teamsService.getResultsBySeasonWeekIds(seasonWeeks);

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  };

  /**
   * Gets the result totals for the given team and given season.
   */
  const getSeasonResultTotalsByTeam = async (
    season: number,
    teamId: string,
  ) => {
    if (!seasonGameResults) {
      const allResults = await _teamsService.getGameResultsBySeason(season);
      setSeasonGameResults(allResults);
      return allResults[teamId];
    } else {
      return seasonGameResults[teamId];
    }
  };

  /**
   * Gets the result totals for all teams for the given season
   */
  const getSeasonResultTotals = async (season: number) => {
    const allResults = await _teamsService.getGameResultsBySeason(season);
    setSeasonGameResults(allResults);
    return allResults;
  };

  const getCumulativeScores = (teamResults: iSeasonResult, league: iLeague) => {
    const allStats: any = getScores(teamResults, league);
    const teamScores = {
      Passing: {
        name: statsGroup['Passing'],
        value: (allStats.passingStats?.total as number) || 0,
      },
      Rushing: {
        name: statsGroup['Rushing'],
        value: (allStats.rushingStats?.total as number) || 0,
      },
      Defensive: {
        name: statsGroup['Defensive'],
        value: (allStats.defensiveStats?.total as number) || 0,
      },
    };
    return teamScores;
  };

  const getScores = (teamResults: iSeasonResult, league: iLeague) => {
    try {
      const allScoringStats = [
        ...league.settings.scoring.passingScoreStats,
        ...league.settings.scoring.rushingScoreStats,
        ...league.settings.scoring.defensiveScoreStats,
      ];
      const allStats = calculateScore(allScoringStats, teamResults);

      return {
        ...allStats,
        passingStats: calculateScore(
          league.settings.scoring.passingScoreStats,
          teamResults,
        ),
        rushingStats: calculateScore(
          league.settings.scoring.rushingScoreStats,
          teamResults,
        ),
        defensiveStats: calculateScore(
          league.settings.scoring.defensiveScoreStats,
          teamResults,
        ),
      };
    } catch (error) {
      console.log('getScores error', error);
    }

    return { total: 0, categoryStatScores: [] };
  };

  const calculateScore = (
    categoryStats: iCategoryStatsItem[],
    teamResults: iSeasonResult,
  ) => {
    let totalScore = 0;
    const categoryStatScoresList: iStatScoreItem[] = [];
    categoryStats.forEach((scoreStat: iCategoryStatsItem) => {
      const resultStat = teamResults?.stats[scoreStat.key];
      // If there is no stat set it to 0
      let categoryStatScore = 0;

      if (
        scoreStat.key === 'pointsScoredAgainst' &&
        scoreStat.scoringBreakdown
      ) {
        // TODO: Figure how to make sure the team wasn't on a bye
        // Calculate based on tier
        if (!resultStat?.stat && resultStat?.stat !== 0) {
          categoryStatScore = 0;
        } else if (resultStat?.stat < 7) {
          categoryStatScore =
            scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed7];
        } else if (resultStat?.stat < 14) {
          categoryStatScore =
            scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed14];
        } else if (resultStat?.stat < 28) {
          categoryStatScore =
            scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed28];
        } else if (resultStat?.stat < 35) {
          categoryStatScore =
            scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed35];
        } else {
          categoryStatScore =
            scoreStat.scoringBreakdown[DefensivePointsAllowed.pointsAllowed36];
        }
      } else {
        categoryStatScore = Number(
          (resultStat?.stat
            ? Math.round(Number(resultStat?.stat) * scoreStat.multiplier * 10) /
              10
            : 0
          ).toFixed(1),
        );
      }

      totalScore += categoryStatScore;
      categoryStatScoresList.push({
        stat: scoreStat,
        score: categoryStatScore,
        statValue: resultStat?.stat ? resultStat.stat : 0, // set to 0 if it doesn't exist
      });
    });
    return {
      total: Number((Math.round(totalScore * 10) / 10).toFixed(1)),
      categoryStatScores: categoryStatScoresList,
    };
  };

  const getTeamRankingsPerCategory = async (
    season: number,
    currentTeam: iTeam,
    league: iLeague,
  ) => {
    const allTeamsResults = await getSeasonResultTotals(season);

    // Create an array of key-value pairs for each category
    const passingScoresArray: [string, number][] = [];
    const rushingScoresArray: [string, number][] = [];
    const defensiveScoresArray: [string, number][] = [];
    Object.keys(allTeamsResults).forEach((team) => {
      const cumulativeScores = getCumulativeScores(
        allTeamsResults[team],
        league,
      );
      passingScoresArray.push([team, cumulativeScores.Passing.value]);
      rushingScoresArray.push([team, cumulativeScores.Rushing.value]);
      defensiveScoresArray.push([team, cumulativeScores.Defensive.value]);
    });

    // TODO: should we set this in the current state to pull from so we don't have to keep performing this?
    // Sort each array based on values (descending)
    passingScoresArray.sort((a, b) => b[1] - a[1]);
    rushingScoresArray.sort((a, b) => b[1] - a[1]);
    defensiveScoresArray.sort((a, b) => b[1] - a[1]);

    // Find the team in each respective array
    const teamPassing = passingScoresArray.find(
      ([team, score]) => team === currentTeam.id.toString(),
    );
    const teamRushing = rushingScoresArray.find(
      ([team, score]) => team === currentTeam.id.toString(),
    );
    const teamDefensive = defensiveScoresArray.find(
      ([team, score]) => team === currentTeam.id.toString(),
    );

    const totalPassingScore = teamPassing ? teamPassing[1] : 0;
    const totalRushingScore = teamRushing ? teamRushing[1] : 0;
    const totalDefensiveScore = teamDefensive ? teamDefensive[1] : 0;

    const team = await getTeamById('team-' + currentTeam.id);

    const teamScoresRankings: {
      teamId: string;
      passing: {
        totalScore: number;
        rank: number;
        average: number;
      };
      rushing: {
        totalScore: number;
        rank: number;
        average: number;
      };
      defensive: {
        totalScore: number;
        rank: number;
        average: number;
      };
    } = {
      teamId: currentTeam.id.toString(),
      passing: {
        totalScore: totalPassingScore,
        rank: teamPassing ? passingScoresArray.indexOf(teamPassing) + 1 : 0,
        average: team?.gameSeasonTotals.games
          ? totalPassingScore / team?.gameSeasonTotals.games
          : 0,
      },
      rushing: {
        totalScore: totalRushingScore,
        rank: teamRushing ? rushingScoresArray.indexOf(teamRushing) + 1 : 0,
        average: team?.gameSeasonTotals.games
          ? totalRushingScore / team?.gameSeasonTotals.games
          : 0,
      },
      defensive: {
        totalScore: totalDefensiveScore,
        rank: teamDefensive
          ? defensiveScoresArray.indexOf(teamDefensive) + 1
          : 0,
        average: team?.gameSeasonTotals.games
          ? totalDefensiveScore / team?.gameSeasonTotals.games
          : 0,
      },
    };

    return teamScoresRankings;
  };

  const getRequestsForUser = async (userId: string) => {
    return await _teamsService.getUserRequests(userId);
  };

  const acceptRequest = async (request: iTradeRequest, currentWeek: number) => {
    return await _teamsService.acceptRequest(request, currentWeek);
  };

  const rejectRequest = async (request: iTradeRequest) => {
    return await _teamsService.rejectRequest(request);
  };

  const cancelRequest = async (request: iTradeRequest) => {
    return await _teamsService.cancelRequest(request);
  };

  return (
    <TeamsContext.Provider
      value={{
        getAllTeamsData,
        teamsData,
        getTeamById,
        getLocalTeamById,
        getTeamByUserSlot,
        getResults,
        getSeasonResultsByTeam,
        getSeasonResultTotalsByTeam,
        getSeasonResultTotals,
        getRequestsForUser,
        acceptRequest,
        rejectRequest,
        cancelRequest,
        getTeamRankingsPerCategory,
        getCumulativeScores,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export default TeamsProvider;
