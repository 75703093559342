import useUserDetailsNavigation from '../../../../../hooks/useUserDetailsNavigation';
import Image from '../../../../../components/widgets/image';
import { iUser } from '@shared/shared-utils/models';

type MemberListProps = {
  leagueMembers: iUser[];
};

const MemberList = (props: MemberListProps) => {
  const { userNavigation } = useUserDetailsNavigation();
  return (
    <>
      <div className="mb-2 font-bold">Members</div>
      <div className="flex mt-2 overflow-x-scroll members-list">
        {props.leagueMembers.map((member) => (
          <Image
            imageUrl={member?.profileImage}
            title={member.fullName}
            key={member.id}
            specialClass="mr-2"
            size={12}
            clickAction={() => userNavigation(member?.id ? member.id : '')}
            clickable={true}
          />
        ))}
      </div>
    </>
  );
};

export default MemberList;
