import { Card, CardContent, Typography } from '@mui/material';
import { useHorizontalScroll } from '../../../hooks/use-hoz-scroll';
import { getOrdinalRankingSuffix } from '@shared/shared-utils';

type TeamCategoryRankingProps = {
  teamRankings: {
    teamId: string;
    passing: {
      totalScore: number;
      rank: number;
      average: number;
    };
    rushing: {
      totalScore: number;
      rank: number;
      average: number;
    };
    defensive: {
      totalScore: number;
      rank: number;
      average: number;
    };
  };
};

/**
 * TODO:
 * calculate trend arrow or remove it
 */

const TeamCategoryRanking = (props: TeamCategoryRankingProps) => {
  const hozScrollRef = useHorizontalScroll();
  return (
    <div className="py-4">
      <div className="font-bold uppercase pb-6">STAT RANKINGS</div>
      <div
        className="flex flex-row space-x-4 overflow-x-scroll md:justify-center"
        ref={hozScrollRef}
      >
        <Card
          sx={{
            backgroundColor: 'transparent',
            minWidth: '160px',
          }}
        >
          <CardContent className="text-center">
            <Typography
              sx={{
                fontWeight: 'bold',
                fontStyle: 'uppercase',
                color: 'primary.main',
              }}
            >
              PASSING
            </Typography>
            <div className="flex flex-row items-center justify-center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}
              >
                {Number(props.teamRankings.passing.average).toFixed(1)}
              </Typography>
              <div className="flex flex-col items-center">
                <Typography
                  variant="body2"
                  sx={{
                    paddingLeft: '4px',
                    fontSize: '0.75rem',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  avg.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    paddingLeft: '4px',
                    fontSize: '0.75rem',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  points
                </Typography>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}
              >
                {props.teamRankings.passing.rank}
              </Typography>
              <Typography
                sx={{
                  // paddingLeft: '2px',
                  fontSize: '0.75rem',
                  paddingBottom: '14px',
                }}
              >
                {getOrdinalRankingSuffix(props.teamRankings.passing.rank)}
              </Typography>
              {/* <FontAwesomeIcon
                icon={faArrowTrendUp}
                size="xl"
                color="rgb(34 197 94)"
              /> */}
            </div>
          </CardContent>
        </Card>
        <Card
          sx={{
            backgroundColor: 'transparent',
            minWidth: '160px',
          }}
        >
          <CardContent className="text-center">
            <Typography
              sx={{
                fontWeight: 'bold',
                fontStyle: 'uppercase',
                color: 'primary.main',
              }}
            >
              RUSHING
            </Typography>
            <div className="flex flex-row items-center justify-center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}
              >
                {Number(props.teamRankings.rushing.average).toFixed(1)}
              </Typography>
              <div className="flex flex-col items-center">
                <Typography
                  variant="body2"
                  sx={{
                    paddingLeft: '4px',
                    fontSize: '0.75rem',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  avg.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    paddingLeft: '4px',
                    fontSize: '0.75rem',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  points
                </Typography>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}
              >
                {props.teamRankings.rushing.rank}
              </Typography>
              <Typography
                sx={{
                  // paddingLeft: '2px',
                  fontSize: '0.75rem',
                  paddingBottom: '14px',
                }}
              >
                {getOrdinalRankingSuffix(props.teamRankings.rushing.rank)}
              </Typography>
              {/* <FontAwesomeIcon
                icon={faArrowTrendUp}
                size="xl"
                color="rgb(34 197 94)"
              /> */}
            </div>
          </CardContent>
        </Card>
        <Card
          sx={{
            backgroundColor: 'transparent',
            minWidth: '160px',
          }}
        >
          <CardContent className="text-center">
            <Typography
              sx={{
                fontWeight: 'bold',
                fontStyle: 'uppercase',
                color: 'primary.main',
              }}
            >
              DEFENSE
            </Typography>
            <div className="flex flex-row items-center justify-center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}
              >
                {Number(props.teamRankings.defensive.average).toFixed(1)}
              </Typography>
              <div className="flex flex-col items-center">
                <Typography
                  variant="body2"
                  sx={{
                    paddingLeft: '4px',
                    fontSize: '0.75rem',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  avg.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    paddingLeft: '4px',
                    fontSize: '0.75rem',
                    verticalAlign: 'text-bottom',
                  }}
                >
                  points
                </Typography>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center">
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                }}
              >
                {props.teamRankings.defensive.rank}
              </Typography>
              <Typography
                sx={{
                  // paddingLeft: '2px',
                  fontSize: '0.75rem',
                  paddingBottom: '14px',
                }}
              >
                {getOrdinalRankingSuffix(props.teamRankings.defensive.rank)}
              </Typography>
              {/* <FontAwesomeIcon
                icon={faArrowTrendUp}
                size="xl"
                color="rgb(34 197 94)"
              /> */}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default TeamCategoryRanking;
