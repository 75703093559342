import { useEffect, useMemo, useState } from 'react';
import { iDraft, iRoundPick, iUser } from '@shared/shared-utils/models';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

type DraftDataProps = {
  draft: iDraft;
  members: iUser[];
  editDraft: boolean;
  onDraftChange: (newPicks: iRoundPick[]) => void;
};

const DraftData = (props: DraftDataProps) => {
  const [numDraftRounds, setNumDraftRounds] = useState<number[]>([1]);
  const [open, setOpen] = useState(true);
  const [picksMap, setPicksMap] = useState<Map<string, iRoundPick>>(new Map());

  useEffect(() => {
    const rounds = [];
    for (let i = 0; i < props.draft.numberOfRounds; i++) {
      rounds.push(i + 1);
    }
    setNumDraftRounds(rounds);
  }, []);

  useEffect(() => {
    const map = new Map<string, iRoundPick>();
    props.draft.picks.forEach((pick) => {
      map.set(pick.round + ':' + pick.memberId, pick);
    });
    setPicksMap(map);
  }, [props.draft.picks]);

  const handleTeamChange = (newTeam: string, mapKey: string) => {
    const newMap = () => {
      const updatedMap = new Map(picksMap);
      const pickData = updatedMap.get(mapKey);
      if (pickData) {
        updatedMap.set(mapKey, {
          ...pickData,
          teamId: newTeam,
        });
      }
      return updatedMap;
    };

    const updatedMap = newMap();

    setPicksMap(updatedMap);

    const updatedPicks: iRoundPick[] = [];
    updatedMap.forEach((pick) => updatedPicks.push(pick));
    props.onDraftChange(updatedPicks);
  };

  const memberMap = useMemo(() => {
    const map = new Map<string, iUser>();
    props.members.forEach((member) => {
      map.set(member.id, member);
    });
    return map;
  }, [props.members]);

  return (
    <TableContainer component={Paper} sx={{ marginY: 2 }}>
      <Table sx={{ width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow key={'header'}>
            <TableCell align="left">Pick #</TableCell>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">
              <div className="flex justify-between">
                <span>Team</span>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {open &&
            numDraftRounds.map((round) => (
              <>
                <TableRow key={round}>
                  <TableCell
                    colSpan={3}
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    *** Round {round} ***
                  </TableCell>
                </TableRow>
                {props.draft.picks
                  .filter((pick) => pick.round === round)
                  .map((filteredPick, index) => {
                    const mapKey = round + ':' + filteredPick.memberId;
                    const memberId = picksMap.get(mapKey)?.teamId;

                    return (
                      <TableRow
                        key={mapKey}
                        hover={props.editDraft}
                        sx={{
                          cursor: 'pointer',
                        }}
                        // onClick={() =>
                        //   props.onPickClick(mapKey)
                        // }
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">
                          {memberMap.get(filteredPick.memberId)?.fullName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ paddingY: props.editDraft ? '11px' : '16px' }}
                        >
                          {props.editDraft ? (
                            <TextField
                              value={memberId}
                              variant="standard"
                              size="small"
                              sx={{
                                fontSize: '0.875rem',
                              }}
                              onChange={(e) =>
                                handleTeamChange(e.target.value, mapKey)
                              }
                            />
                          ) : (
                            memberId
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DraftData;
