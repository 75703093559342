import React, { ReactNode, useContext } from 'react';
import { AuthContext } from './auth-provider';
import { iLeague } from '@shared/shared-utils/models';

type ChatContextProps = {
  checkForUnreadMessages: (league: iLeague) => boolean;
};

type ChatProviderProps = {
  children: ReactNode;
};

export const ChatContext = React.createContext({} as ChatContextProps);

const ChatProvider = ({ children }: ChatProviderProps) => {
  const { userDetails } = useContext(AuthContext);

  const checkForUnreadMessages = (league: iLeague) => {
    if (userDetails) {
      const lastChecked = userDetails.messageHistory?.[league.id];

      if (league.lastMessageSent) {
        if (!lastChecked) {
          return true;
        }

        if (lastChecked < league.lastMessageSent) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <ChatContext.Provider
      value={{
        checkForUnreadMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
