import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

interface NonLeagueItemProps {
  to?: string;
  backgroundImage?: string;
  e2eTag?: string;
  icon: IconDefinition;
  iconOnClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  linkOnClick?: () => void; // New prop for custom link behavior
  iconDataE2E: string;
  upperText: string;
  lowerText: string;
  showUpperTextOverlay?: boolean;
}

function NonLeagueItem({
  to,
  backgroundImage,
  e2eTag,
  icon,
  iconOnClick,
  linkOnClick,
  iconDataE2E,
  upperText,
  lowerText,
  showUpperTextOverlay = false,
}: NonLeagueItemProps) {
  const backgroundStyle = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
      }
    : {};

  const upperTextStyle = showUpperTextOverlay
    ? { backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white' }
    : {};

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (linkOnClick) {
      event.preventDefault(); // Prevent the default navigation behavior
      linkOnClick(); // Call the custom function
    }
  };

  const Layout = () => {
    return (
      <>
        <div className="w-64 h-64 overflow-hidden container flex items-center justify-center relative">
          <div
            className="absolute inset-0 bg-black opacity-30 z-10" // Semi-transparent overlay
          />
          {backgroundImage && (
            <div
              className="absolute inset-0  bg-cover bg-center"
              style={backgroundStyle}
            />
          )}
          <div className="flex flex-col items-center z-10">
            <div
              className={`border-2 border-accent bg-black rounded-full p-4 mb-2`}
            >
              <FontAwesomeIcon
                icon={icon}
                className={`text-white fa-2xl`}
                onClick={iconOnClick}
                data-e2e={iconDataE2E}
              />
            </div>
            <div className="text-center" style={upperTextStyle}>
              {upperText}
            </div>
          </div>
        </div>
        <div className="flex items-center border-t-2 border-accent w-64 h-40 bg-db-background-color">
          <div className="flex items-center text-center">{lowerText}</div>
        </div>
      </>
    );
  };

  return to ? (
    <Link
      to={to}
      className={`block overflow-hidden no-underline cursor-pointer text-primary league-item rounded-2xl max-w-64`}
      data-e2e={e2eTag}
    >
      <Layout />
    </Link>
  ) : (
    <a
      onClick={handleLinkClick}
      href="/"
      className={`block overflow-hidden no-underline cursor-pointer text-primary league-item rounded-2xl max-w-64`}
      data-e2e={e2eTag}
    >
      <Layout />
    </a>
  );
}

export default NonLeagueItem;
