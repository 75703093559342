/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { LeagueContext } from '../../../providers/league-provider';
import Header from '../../../components/widgets/header/header';
import LeagueList from '../../../components/widgets/league-list/league-list';
import { AuthContext } from '../../../providers/auth-provider';
import {
  iLeague,
  iLeagueInvite,
  iLeagueMatchups,
  iMyMatchup,
  iUser,
} from '@shared/shared-utils/models';
import MatchpResults from './widgets/matchup-results';
import Confetti from 'react-confetti';
import { currentDBProject } from '../../../services/firebase-core';
import Select from '../../../components/ui/select/select-input';
import { matchupFilterOptions } from '../league/league-constants';
import { HomeLoadingSkeleton } from './home-skeleton';
import UserActivity from '../league/widgets/user-activity';
import CreateLeagueFAB from '../../../components/ui/floating-button/create-league-fab';
import { TeamsContext } from '../../../providers/teams-provider';
import { Button } from '@mui/material';
import { DiscordService } from '../../../services/discord-service';
import { ToastContext } from '../../../providers/toast-provider';
import { UserService } from '../../../services/user-service';
import { Timestamp } from 'firebase/firestore';
import TrophyCase from '../profile/trophy-case';
import { TrophyContext } from '../../../providers/trophy-provider';
import centeredLogo from '../../../assets/images/centered-logo.png';
import VideoBannerWithModal from '../../../components/ui/video-banner/video-banner';

function Home() {
  const _discordService = new DiscordService();
  const {
    userDetails,
    getUserRole,
    superAdminStatus,
    adminStatus,
    discordAuthToken,
    setUserDetails,
  } = useContext(AuthContext);
  const {
    userLeaguesList,
    getUserLeagues,
    appConfig,
    handleHomeMatchupResultClick,
    getUserInvites,
    getLeagueDetails,
  } = useContext(LeagueContext);
  const { getAllTeamsData } = useContext(TeamsContext);
  const { showToast, closeSnackBars, currentSnackbar } =
    useContext(ToastContext);
  const { currentUsersTrophies } = useContext(TrophyContext);
  const userService = new UserService();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingTwitter, setLoadingTwitter] = useState<boolean>(false);
  const [myMatchups, setMyMatchups] = useState<iMyMatchup[]>([]);
  const [memberId, setMemberID] = useState<string>();
  const [selectedWeek, setSelectedWeek] = useState<number | undefined>(
    appConfig?.currentWeek,
  );
  const [showDiscordSnackbar, setShowDiscordSnackbar] = useState(false);
  const [authTokensSaved, setAuthTokensSaved] = useState(false);
  const [invitedLeagues, setInvitedLeagues] = useState<iLeague[]>([]);
  const [includeInternalLeagues, setIncludeInternalLeagues] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setUpHomePage();
  }, [userDetails]);

  useEffect(() => {
    getMyMatchups();
  }, [userLeaguesList]);

  useEffect(() => {
    localStorage.removeItem('rTo');
  }, []);

  useEffect(() => {
    // This lets flutter get the userID to update onesignal
    if (userDetails?.id) {
      // DO NOT DELETE
      console.log('flutterUserId ' + userDetails?.id);
    }
  }, [userDetails]);

  //Save Discord tokens or show snackbar
  useEffect(() => {
    // Check if the 'fromDiscordRedirect' query parameter is present in the URL
    const queryParams = new URLSearchParams(location.search);
    const fromDiscordRedirect = queryParams.get('fromDiscordRedirect');

    if (fromDiscordRedirect && !authTokensSaved) {
      handleSaveDiscordAuthTokensToUser();
      setAuthTokensSaved(true);
    } else {
      handleDiscordSnackbar();
    }

    // Clear URL parameters
    navigate('', { replace: true });
  }, [userDetails, userDetails?.discordAuthTokens]);

  //Whether to show the Discord signup reminder snackbar. Doesn't show by default.
  useEffect(() => {
    if (showDiscordSnackbar) {
      showDiscordSnackBar();
    }
  }, [showDiscordSnackbar]);

  const videoData = [
    {
      label: 'Intro',
      url: 'https://www.youtube.com/watch?v=23cjRnKoDVA',
    },
    {
      label: 'Joining',
      url: 'https://youtu.be/dizQfSkuYAg',
    },
    {
      label: 'Draft',
      url: 'https://www.youtube.com/watch?v=YywncdmFMV0',
    },
    {
      label: 'Lineup',
      url: 'https://youtu.be/jkn-ugK-8nM',
    },
  ];

  const setUpHomePage = async () => {
    if (loadingData) {
      if (userDetails?.id) {
        await getUserLeagues(userDetails.id);
        await getInvitesForUser();
        getUserRole();
        setMemberID(userDetails.id);
        setLoadingData(false);

        // Set teams data for viewing later
        getAllTeamsData();
      }
    }
  };

  const getMyMatchups = async () => {
    // TODO typings, shouldn't matchups already exist?
    if (userLeaguesList?.length) {
      const leagueIds = userLeaguesList.flatMap(
        (league) =>
          league.season === appConfig.currentSeason && league?.id
            ? [league.id]
            : [], //Make sure these are current matchups
      );

      // TODO Typings, why do we have so many different types for matchups
      const allLeagueMatchups: iLeagueMatchups[] = [];
      userLeaguesList.forEach((league) => {
        // make sure its the current year
        if (appConfig.currentSeason === league.season) {
          allLeagueMatchups.push({
            leagueId: league.id,
            matchups: league.matchups,
            weeklyReports: league.weeklyReports,
          });
        }
      });

      let matchups: iMyMatchup[] = [];
      allLeagueMatchups.forEach((leagueMatchup) => {
        if (leagueMatchup) {
          const data = leagueMatchup.matchups.map((matchup) => ({
            ...matchup,
            leagueId: leagueMatchup.leagueId,
          }));
          matchups = [...matchups, ...data];
        }
      });

      // filter current user's matchups from all matchups
      const filteredMatchups = matchups.filter(
        (matchup) =>
          matchup.memberA === userDetails?.id ||
          matchup.memberB === userDetails?.id,
      );

      setMyMatchups(filteredMatchups);
    }
  };

  const isDevDBUsed = () => {
    if (currentDBProject === 'squadblitz-dev') {
      return true;
    } else {
      return false;
    }
  };

  // When redirected here from <DiscordRedirectPage/>:
  // Adds the Discord tokens to the user object on the DB
  // The save triggers the cloud function to add the user to the Discord guild
  const handleSaveDiscordAuthTokensToUser = async () => {
    try {
      //Save Discord auth tokens to DB
      if (discordAuthToken && userDetails) {
        const updatedUserDetails: iUser = {
          ...userDetails,
          discordAuthTokens: discordAuthToken,
          discordReminderStatus: false,
        };

        await _discordService.saveDiscordAuthToUser(
          userDetails,
          discordAuthToken,
        );

        setUserDetails(updatedUserDetails);

        showToast({
          messageType: 'info',
          message:
            'We are adding you to our Discord server. Check there for all of the announcements and discussion! If you are not successfully added, please contact us.',
          persist: false,
          autoHideDuration: 5000,
        });
      }
    } catch (error) {
      console.log('Error saving Discord auth tokens to DB.', error);
    }
  };

  const handleDiscordSnackbar = async () => {
    //Make sure userDetails is retrieved first and check reminder status boolean
    if (
      userDetails &&
      !userDetails.discordAuthTokens &&
      userDetails.discordReminderStatus
    ) {
      const today = new Date();
      // If there is a last shown date, see if it was before today. Otherwise, show it
      if (userDetails.discordReminderLastShown) {
        const reminderTimestamp =
          userDetails.discordReminderLastShown as unknown as Timestamp;
        const reminderDate = reminderTimestamp
          ? reminderTimestamp.toDate()
          : null;

        if (reminderDate && reminderDate.getDate() < today.getDate()) {
          setShowDiscordSnackbar(true);
          const updatedUserDetails: iUser = {
            ...userDetails,
            discordReminderLastShown: today,
          };
          await userService.updateUserProfile(
            updatedUserDetails,
            userDetails.id,
          );
        } else {
          setShowDiscordSnackbar(false);
        }
      } else {
        setShowDiscordSnackbar(true);
        const updatedUserDetails: iUser = {
          ...userDetails,
          discordReminderLastShown: today,
        };
        await userService.updateUserProfile(updatedUserDetails, userDetails.id);
      }
    } else if (userDetails && userDetails.discordAuthTokens) {
      setShowDiscordSnackbar(false);
    }
  };

  const getInvitesForUser = async () => {
    if (userDetails) {
      let invites: iLeagueInvite[] = userDetails?.email
        ? await getUserInvites(userDetails?.email)
        : [];

      const userLeagues: iLeague[] = await getUserLeagues(userDetails?.id);

      // filter out joined leagues
      invites = invites.filter(
        (invite) =>
          !userLeagues.find((userLeague) => userLeague.id === invite.leagueId),
      );

      if (invites.length) {
        try {
          // pull information about the leagues left
          const promises = invites.map(
            async (invite) => await getLeagueDetails(invite.leagueId),
          );
          const leagues = await Promise.all(promises);

          setInvitedLeagues(leagues);
        } catch (error) {
          console.log(error);
        }
      } else {
        setInvitedLeagues([]);
      }
    }
  };

  const showDiscordSnackBar = async () => {
    const action = (
      <Button
        variant="text"
        onClick={() => {
          navigate('/profile');
          closeSnackBars(currentSnackbar?.current);
        }}
        sx={{
          color: 'yellow',
        }}
      >
        My Profile
      </Button>
    );

    showToast({
      messageType: 'warning',
      message:
        'You are missing really important information by not being on our Discord server! You can set this up in your profile.',
      clickActions: action,
      persist: false,
      autoHideDuration: 5000,
    });
  };

  return (
    <div id="db-home" className="flex flex-col football-bg">
      <Header title={'Home'} isHome={true} subContent={null} />
      {appConfig.currentWeek <= 1 && <CreateLeagueFAB></CreateLeagueFAB>}
      <div className="flex flex-col flex-1 pt-[7.8rem]">
        {/*This just shows the current project to make sure
        you're in dev mode  */}
        {isDevDBUsed() && <h4>{`current DB project = ${currentDBProject}`}</h4>}
        <h4 className="m-4">
          {(superAdminStatus || adminStatus) && (
            <Link to="/operations">Operations Portal</Link>
          )}
        </h4>
        {loadingData || !userDetails ? (
          <HomeLoadingSkeleton />
        ) : (
          <div>
            {/* TODO: make animation its own component?? */}
            {!appConfig?.seasonActive && (
              <div>
                <Confetti recycle={false} numberOfPieces={500} />
                <div className="p-6 my-4 text-center border border-accent">
                  A great {appConfig?.currentSeason} season has come to an end.
                  Thanks for playing and plan to join us next year!!!
                  <br></br>
                  <img
                    src={centeredLogo}
                    alt="Logo"
                    className="h-8 mx-auto mt-4"
                  />
                </div>
              </div>
            )}
            <UserActivity />
            <div className="my-4">
              <VideoBannerWithModal
                videoData={videoData}
                buttonText="Learn the basics!"
              />
            </div>
            <LeagueList
              leagues={
                userLeaguesList
                  ? includeInternalLeagues
                    ? userLeaguesList
                    : userLeaguesList.filter((league) => !league.internalLeague)
                  : []
              }
              invitedLeagues={
                includeInternalLeagues
                  ? invitedLeagues
                  : invitedLeagues.filter((league) => !league.internalLeague)
              }
              title="Leagues"
              user={userDetails}
              season={appConfig ? appConfig.currentSeason : 2022}
              includeInternalLeagues={setIncludeInternalLeagues}
            />
            {/* <div className="mt-8">
                  <h4 className="m-4">My Matchups</h4>
                  <div className="px-4 mt-2">
                        <UpcomingMatchups myMatchups={myMatchups} leagues={userLeaguesList}  />
                  </div>
                </div> */}
            <div className="mt-8">
              <div className="flex justify-between mb-4">
                <h4 className="mx-4 mt-4">Results</h4>
                <div className="min-w-[105px] max-w-fit mr-4">
                  <Select
                    showLabelAlways={false}
                    options={matchupFilterOptions}
                    placeholder={'Week ' + selectedWeek}
                    onChange={(optionSelected) => {
                      setSelectedWeek(optionSelected.value);
                    }}
                    initialValue={appConfig.currentWeek}
                  />
                </div>
              </div>
              <div className="flex px-4 mt-2">
                {userLeaguesList?.length &&
                userLeaguesList.some(
                  (league) => league.season === appConfig.currentSeason,
                ) ? (
                  <MatchpResults
                    myMatchups={myMatchups.filter((matchup) => {
                      return matchup.round === selectedWeek;
                    })}
                    leagues={userLeaguesList}
                    matchupClick={() => {}}
                  />
                ) : (
                  <div className="mb-8 text-center w-full">
                    Join a league to see your matchups!
                  </div>
                )}
              </div>
            </div>
            <div className="m-4">
              <TrophyCase userTrophies={currentUsersTrophies} />
            </div>
            {/* <div className="mt-8">
              <h4 className="m-4">
                {loadingTwitter && 'College Football News'}
              </h4>
              <div className="px-8">
                <Timeline
                  dataSource={{
                    sourceType: 'list',
                    ownerScreenName: 'twitter',
                    id: '1580256095448494080',
                  }}
                  options={{
                    chrome: 'noheader, nofooter',
                    theme: 'dark',
                    height: '600',
                  }}
                  onLoad={() => setLoadingTwitter(true)}
                />
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
