import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateProfile } from 'firebase/auth';
import {
  Autocomplete,
  FormGroup,
  Switch,
  TextField,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { MuiTelInput, MuiTelInputInfo, matchIsValidTel } from 'mui-tel-input';

import EmailInput from '../../../components/ui/email-input/email-input';
import TextInput from '../../../components/ui/text-input/text-input';
import ImagePicker from '../../../components/ui/image-picker/image-picker';
import PasswordInput from '../../../components/ui/password-input/password-input';
import Loading from '../../../components/ui/loading-animation';
import Header from '../../../components/widgets/header/header';
import DiscordButton from '../../../components/discord/discord-button';
import TrophyCase from './trophy-case';

import { AuthContext } from '../../../providers/auth-provider';
import { ToastContext } from '../../../providers/toast-provider';
import { TrophyContext } from '../../../providers/trophy-provider';
import { TeamsContext } from '../../../providers/teams-provider';

import { UserService } from '../../../services/user-service';
import { DiscordService } from '../../../services/discord-service';

import {
  iPushNotificationSettings,
  iTeam,
  iUser,
} from '@shared/shared-utils/models';
import { DBUserRoles, DiscordWebhooks } from '@shared/shared-utils';

// New Types
type Props = {};

// Context parts
const Profile = () => {
  const userService = new UserService();
  const _discordService = new DiscordService();
  const {
    currentUser,
    userDetails,
    setUserDetails,
    logOut,
    deleteUserAccount,
  } = useContext(AuthContext);
  const { currentUsersTrophies } = useContext(TrophyContext);
  const { getAllTeamsData } = useContext(TeamsContext);
  const { showToast, closeSnackBars, currentSnackbar } =
    useContext(ToastContext);

  const navigate = useNavigate();
  const [email, setEmail] = useState(userDetails?.email);
  const [profilePic, setProfilePic] = useState<File | undefined>();
  const [fullName, setFullName] = useState(userDetails?.fullName ?? '');
  const [profileLoading, setProfileLoading] = useState(!userDetails);
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    userDetails?.phoneNumber,
  );
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);
  const [userDiscordReminder, setUserDiscordReminder] = useState(
    userDetails?.discordReminderStatus ?? true,
  );
  const [userProfileUpdated, setUserProfileUpdated] = useState(false);
  const [showCharacterLimitError, setShowCharacterLimitError] = useState(false);
  const [showCharacterLimitHelperText, setShowCharacterLimitHelperText] =
    useState(false);
  const [allTeams, setAllTeams] = useState<iTeam[]>([]);
  const [favoriteTeam, setFavoriteTeam] = useState<string>('');

  const defaultPushNotificationSettings: iPushNotificationSettings = {
    draftCompleted: true,
    draftTurn: true,
    leagueInvite: true,
    matchupResultsUpdated: true,
    scoreUpdates: true,
    setLineupsReminder: true,
    teamByeWeek: true,
    tradeRequest: true,
  };
  const [notificationSettings, setNotificationSettings] =
    useState<iPushNotificationSettings>(
      userDetails?.notificationSettings ?? defaultPushNotificationSettings,
    );

  let password = '';

  // useEffect hooks
  useEffect(() => {
    if (userDetails?.notificationSettings) {
      //
    } else {
      setNotificationSettings(defaultPushNotificationSettings);
    }
  }, [userDetails?.notificationSettings]);

  useEffect(() => {
    if (userDetails?.favoriteTeam) {
      setFavoriteTeam(userDetails.favoriteTeam);
    }
  }, [currentUser]);

  useEffect(() => {
    loadTeams();
  }, []);

  // Methods
  const handleDialogClose = (confirmed: boolean) => async () => {
    setOpenDeletionDialog(false);

    if (confirmed && password) {
      await deleteUserAccount(password);
      window.location.reload();
    }
  };

  const loadTeams = async () => {
    const teams = await getAllTeamsData();
    setAllTeams(teams ? teams : []);
  };

  const updateUser = async () => {
    let imageLink = userDetails?.profileImage;
    if (currentUser) {
      if (profilePic) {
        imageLink = await userService.uploadProfilePic(currentUser, profilePic);
      }

      if (userDetails?.id) {
        // Update the user

        const updatedUserDetails: iUser = {
          ...userDetails,
          fullName: fullName ?? userDetails.fullName,
          email: email ?? userDetails.email,
          profileImage: imageLink ?? userDetails.profileImage,
          discordReminderStatus: userDiscordReminder,
          phoneNumber: userPhoneNumber,
          favoriteTeam: favoriteTeam ? favoriteTeam : userDetails.favoriteTeam,
        };

        await userService.updateUserProfile(
          updatedUserDetails,
          userDetails?.id,
        );

        setUserDetails(updatedUserDetails);
      } else {
        // TODO How is this possible?
        // Create the user
        const newUser = {
          fullName,
          email,
          profileImage: imageLink,
          isActive: true,
          role: DBUserRoles.leagueMember,
          notificationSettings: notificationSettings,
          termsAgreed: true,
          discordReminderStatus: userDiscordReminder,
        } as iUser;

        await userService.addUserProfile(newUser, '');

        _discordService.postDiscordMessage(
          `New user added: ${fullName} FROM PROFILE`,
          DiscordWebhooks.activityChannel,
        );

        if (email) {
          userService.sendInviteMemberEmail(email);
        }

        setUserDetails(newUser);
      }

      const userUpdated = await updateProfile(currentUser, {
        displayName: fullName,
        photoURL: imageLink,
      });

      if (userUpdated !== null) {
        navigate('/home');
      }
    }
  };

  const handleDiscordOpt = (checked: boolean) => {
    if (checked) {
      setUserDiscordReminder(true);
      closeSnackBars(currentSnackbar?.current);
    } else {
      setUserDiscordReminder(false);
      showToast({
        messageType: 'warning',
        message:
          "FYI, you'll miss out on important updates and some witty banter. Re-enable this anytime.",
      });
    }
    userDetails?.discordReminderStatus !== checked
      ? setUserProfileUpdated(true)
      : setUserProfileUpdated(false);
  };

  const handlePhoneNumberChange = (value: string, info: MuiTelInputInfo) => {
    setUserPhoneNumber(value);
    if (value !== userDetails?.phoneNumber) {
      const validNumber = matchIsValidTel(
        value,
        info.countryCode ? info.countryCode : undefined,
      );

      if (value === '' || info.nationalNumber === '' || validNumber) {
        setUserProfileUpdated(true);
      } else {
        setUserProfileUpdated(false);
      }
    } else {
      setUserProfileUpdated(false);
    }
  };

  const UserDeletionDialog = () => {
    return (
      <Dialog
        open={openDeletionDialog}
        onClose={handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          This will completely delete your account. Please enter your password to proceed:
          <PasswordInput
            showIconToggler={false}
            label={'Password'}
            onChangeAction={(event: ChangeEvent<HTMLInputElement>) => {
              password = event.target.value;
            }}
            inputId="primary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose(false)}>CANCEL</Button>
          <Button onClick={handleDialogClose(true)}>PROCEED</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // HTML Section
  return profileLoading || !userDetails ? (
    <Loading />
  ) : (
    <div id="db-Profile" className="flex flex-col">
      <Header
        title={'Update Profile'}
        isHome={false}
        subContent={
          <div className="pb-4">
            <p className="pt-2 text-xs">
              Email address cannot be updated. <br /> If you wish to do so,
              please{' '}
              <a
                href="mailTo:app@squadblitz.com"
                target="_blank"
                rel="noreferrer"
              >
                contact support
              </a>
              .
            </p>
          </div>
        }
      />
      <div className="flex flex-1 p-4 pt-[7.8rem]">
        <form
          className="flex flex-col justify-between flex-1"
          onSubmit={(event) => {
            event.preventDefault();
            if (userProfileUpdated) {
              setProfileLoading(true);
              updateUser();
            }
          }}
        >
          <div>
            <div className="mb-4 flex sm:justify-start">
              <ImagePicker
                label="Update profile picture"
                imageLink={userDetails?.profileImage}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files?.length) {
                    setProfilePic(e.target.files[0]);
                    setUserProfileUpdated(true);
                  }
                }}
              />
            </div>
            <div className="my-2 mb-4">
              <TextInput
                label="Full Name"
                value={fullName ?? userDetails?.fullName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length < 23) {
                    setFullName(event.target.value);
                    userDetails?.fullName !== event.target.value
                      ? setUserProfileUpdated(true)
                      : setUserProfileUpdated(false);
                    setShowCharacterLimitError(false);
                  } else {
                    setShowCharacterLimitError(true);
                    setShowCharacterLimitHelperText(true);
                  }
                }}
                error={showCharacterLimitError}
                helperText={
                  showCharacterLimitHelperText
                    ? `Name must be 22 characters or less. [${fullName?.length}/22]`
                    : undefined
                }
              />
            </div>
            <div className="my-2">
              <EmailInput
                label="Email address"
                value={userDetails?.email}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                disabled={true}
                helperText={'Email address cannot be updated here'}
              />
            </div>
            <div className="my-2 mt-4">
              <MuiTelInput
                value={userPhoneNumber}
                onChange={(value, info) => handlePhoneNumberChange(value, info)}
                defaultCountry="US"
                label="Phone Number"
                forceCallingCode={true}
              />
            </div>
            <div className="py-2">
              <Autocomplete
                options={allTeams.map((team) => team.school)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Favorite College Football Team"
                    fullWidth
                  />
                )}
                value={favoriteTeam}
                onChange={(event: any, newValue: string | null) => {
                  setFavoriteTeam(newValue || '');
                  if (newValue !== favoriteTeam) {
                    setUserProfileUpdated(true);
                  }
                }}
                sx={{ marginY: '0.5rem' }}
              />
            </div>
          </div>
          {!userDetails?.discordAuthTokens && (
            <div className="flex flex-col items-center sm:flex-row justify-between my-2">
              <DiscordButton disabledWithTooltip={!userDiscordReminder} />
              <FormGroup>
                <Tooltip
                  title={
                    userDiscordReminder
                      ? 'Click here to turn off Discord reminders.'
                      : 'Click here so you can log in to Discord!'
                  }
                  enterTouchDelay={0}
                >
                  <div>
                    <Switch
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleDiscordOpt(event.target.checked)
                      }
                      checked={userDiscordReminder}
                    />
                    <span>{`Discord Reminder ${
                      userDiscordReminder ? 'Enabled' : 'Disabled'
                    }`}</span>
                  </div>
                </Tooltip>
              </FormGroup>
            </div>
          )}
          <TrophyCase userTrophies={currentUsersTrophies} />
          <div className="fixed bottom-0 z-10 my-4 w-full flex justify-center max-w-[560px] mx-auto">
            <div className="w-1/3 mr-2">
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => navigate(-1)}
                size="large"
                sx={{ color: 'black' }}
              >
                Back
              </Button>
            </div>
            <div className="w-2/3 ml-2">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => {
                  if (userProfileUpdated) {
                    await updateUser();
                  }
                }}
                size="large"
                disabled={!userProfileUpdated}
              >
                Update
              </Button>
            </div>
          </div>
          <div>
            <Button
              variant="outlined"
              color="error"
              fullWidth={true}
              onClick={async () => {
                setOpenDeletionDialog(true);
              }}
              style={{ marginBottom: '8px' }}
            >
              Delete Account
            </Button>
          </div>
          <div>
            <Button
              data-e2e="logout-button"
              variant="outlined"
              onClick={async () => {
                await logOut();
                navigate('/');
              }}
              fullWidth
            >
              Logout
            </Button>
          </div>
        </form>
      </div>
      <UserDeletionDialog />
    </div>
  );
};

export default Profile;
