import React, { ReactNode } from 'react';
import { iScoreboardResults } from '@shared/shared-utils/models';
import { ScoreboardService } from '../services/scoreboard-service';

type ScoreboardProviderProps = { children: ReactNode };
type ScoreboardContextProps = {
  getAllScoreboardData: () => Promise<iScoreboardResults[] | []>;
};

export const ScoreboardContext = React.createContext(
  {} as ScoreboardContextProps
);

const ScoreboardProvider = ({ children }: ScoreboardProviderProps) => {
  const _scoreboardService = new ScoreboardService();

  const getAllScoreboardData = async () => {
    const scoreboard = await _scoreboardService.getScoreboardResults();
    if (scoreboard?.results) {
      return scoreboard.results;
    }
    return [];
  };

  return (
    <ScoreboardContext.Provider
      value={{
        getAllScoreboardData,
      }}
    >
      {children}
    </ScoreboardContext.Provider>
  );
};

export default ScoreboardProvider;
