import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Main from './modules/main';
import { SnackbarProvider } from 'notistack';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import { environment } from './environments/environment.local.dev';
import logo from './assets/images/cropped-logo.png';

const rollbarConfig = {
  accessToken: 'fc5bddcfa6be43f084e062175348ca5d',
  environment: environment.hostUrl,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: '3.0.0',
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
  enabled: environment.production ? true : false,
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00BE2A',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    warning: {
      main: '#fb923c',
    },
    error: {
      main: '#CF0000',
    },
  },
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/service-worker.js').then(
      function (registration) {
        // Registration was successful
        console.log(
          'ServiceWorker registration successful with scope: ',
          registration.scope,
        );
      },
      function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      },
    );
  });
}

// if ((module as any).hot) (module as any).hot.accept();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// Check if it is a production build
const isProduction = environment.production;

const FallbackUI = () => (
  <div className="flex flex-col justify-center items-center h-screen text-center p-5">
    <img src={logo} alt="Logo" className="w-1/4 mb-10" />
    <div className="text-lg text-gray-800">
      We apologize, something went wrong 😔. Please refresh the page or contact
      us at
      <br />
      <a href="mailto:app@squadblitz.com" className="text-blue-500">
        app@squadblitz.com
      </a>
      <br />
      or text us at
      <br />
      <a href="tel:+18508160398" className="text-blue-500">
        (850) 816-0398
      </a>
    </div>
  </div>
);

// Render the application
const app = (
  <ThemeProvider theme={darkTheme}>
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <CssBaseline />
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </SnackbarProvider>
  </ThemeProvider>
);

// Render with or without Provider and ErrorBoundary based on the build environment
if (isProduction || true) {
  const appWithProvider = (
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={FallbackUI}>{app}</ErrorBoundary>
    </Provider>
  );

  try {
    root.render(appWithProvider);
  } catch (e) {
    console.error(e);
    root.render(app);
  }
} else {
  root.render(app);
}
