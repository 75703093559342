import ImagePicker from '../../../../components/ui/image-picker/image-picker';
import TextInput from '../../../../components/ui/text-input/text-input';
import { ChangeEvent, useContext, useState } from 'react';
import TextArea from '../../../../components/ui/text-input/text-area';
import { LeagueContext } from '../../../../providers/league-provider';
import { iLeague } from '@shared/shared-utils/models';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  RadioGroup,
  Radio,
  Switch,
  TextField,
} from '@mui/material';
import Loading from '../../../../components/ui/loading-animation';
import { AuthContext } from '../../../../providers/auth-provider';
import { DBUserRoles } from '@shared/shared-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFootball, faUserGroup } from '@fortawesome/free-solid-svg-icons';

type LeagueBasicDetailsType = {
  onFinish: () => void;
  league?: iLeague;
  currentSeason: number;
};

const LeagueBasicDetails = (props: LeagueBasicDetailsType) => {
  const { createLeague } = useContext(LeagueContext);
  const { userDetails } = useContext(AuthContext);
  const [leagueBanner, setLeagueBanner] = useState<File>();
  const [leagueName, setLeagueName] = useState<string>('');
  const [leagueDescription, setLeagueDescription] = useState<string>();
  const [createLeagueLoading, setCreateLeagueLoading] = useState(false);
  const [showCharacterLimitError, setShowCharacterLimitError] = useState(false);
  const [showCharacterLimitHelperText, setShowCharacterLimitHelperText] =
    useState(false);
  const [internalLeagueSwitchState, setInternalLeagueSwitchState] =
    useState(false);
  const [leagueIsInternal, setLeagueIsInternal] = useState<boolean>();
  const [leagueIsOpen, setLeagueIsOpen] = useState<boolean>(false);

  const validateAndCreateLeague = async () => {
    if (leagueBanner && leagueName && leagueDescription) {
      await createLeague(
        leagueName,
        leagueDescription,
        leagueBanner,
        props.currentSeason,
        leagueIsOpen,
        leagueIsInternal,
      );
    }
    setCreateLeagueLoading(false);
    props.onFinish();
  };

  return createLeagueLoading ? (
    <Loading></Loading>
  ) : (
    <form
      className="flex flex-col justify-between flex-1 p-4"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div style={{ marginBottom: 5 }}>
        <FontAwesomeIcon
          icon={faFootball}
          size="lg"
          color="#f5ce62"
          style={{ marginRight: 10 }}
        />
        <strong>League Identity</strong>
      </div>
      <ImagePicker
        fullWidth
        label="Add league banner *"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files?.length) {
            setLeagueBanner(e.target.files[0]);
          }
        }}
        required={true}
        e2eTag="create_league_banner"
      />
      <div style={{ marginBottom: 15, marginTop: 15 }}>
        <TextField
          fullWidth
          value={leagueName}
          label="League Name"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length < 31) {
              setLeagueName(event.target.value);
              setShowCharacterLimitError(false);
            } else {
              setShowCharacterLimitError(true);
              setShowCharacterLimitHelperText(true);
            }
          }}
          required={true}
          //  e2eTag="create_league_title"
          error={showCharacterLimitError}
          helperText={
            showCharacterLimitHelperText
              ? `League name must be 30 characters or less. [${leagueName.length}/30]`
              : undefined
          }
        />
      </div>
      <div>
        <TextField
          fullWidth
          label="League Description"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setLeagueDescription(event.target.value);
          }}
          required={true}
          //   e2eTag="create_league_description"
        />
        <div style={{ marginBottom: 15, marginTop: 25 }}>
          <FontAwesomeIcon
            icon={faUserGroup}
            size="lg"
            color="#f5ce62"
            style={{ marginRight: 10 }}
          />
          <strong>League Type</strong>
        </div>
        {/* <FormControl>
          <RadioGroup
            defaultValue="private"
            onChange={(e) => {
              setLeagueIsOpen(e.target.value === 'open');
            }}
          >
            <FormControlLabel
              value="private"
              control={<Radio />}
              label="Private"
            />
            <FormHelperText>
              Private leagues require invitations to add members
            </FormHelperText>
            <FormControlLabel value="open" control={<Radio />} label="Open" />
            <FormHelperText>
              Open leagues allow anyone to join your league
            </FormHelperText>
          </RadioGroup>
        </FormControl> */}
        <div className="mt-4">
          <em>
            Leagues default to 8 players but this can be changed in "Settings"
            later
          </em>
        </div>

        {/* Only show this section if user is SB Super Admin */}
        {userDetails?.role === DBUserRoles.superAdmin && (
          <FormGroup className="mt-4">
            <FormControlLabel
              control={
                <Switch
                  checked={internalLeagueSwitchState}
                  onChange={(e) => {
                    if (userDetails?.role === DBUserRoles.superAdmin) {
                      setLeagueIsInternal(e.target.checked);
                      setInternalLeagueSwitchState(e.target.checked);
                    }
                  }}
                />
              }
              label="Internal League"
            />
          </FormGroup>
        )}
      </div>
      <div className="fixed bottom-0 z-10 my-4 w-full flex justify-center max-w-[560px] mx-auto">
        {!props.league && (
          <Button
            fullWidth
            variant="contained"
            disabled={!leagueName || !leagueBanner || !leagueDescription}
            onClick={() => {
              setCreateLeagueLoading(true);
              validateAndCreateLeague();
            }}
            data-e2e="create_league_btn"
          >
            {props.league ? 'Update' : 'Create'}
          </Button>
        )}
      </div>
    </form>
  );
};

export default LeagueBasicDetails;
