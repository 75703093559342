import ImagePicker from '../../../../components/ui/image-picker/image-picker';
import TextArea from '../../../../components/ui/text-input/text-area';
import TextInput from '../../../../components/ui/text-input/text-input';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  iLeague,
  iLeagueSettings,
  iLeagueScoring,
} from '@shared/shared-utils/models';
import DateInput from '../../../../components/ui/date-input/date-input';
import { LeagueContext } from './../../../../providers/league-provider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LeagueScoring from './league-scoring/league-scoring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import Loading from '../../../../components/ui/loading-animation';
import { ToastContext } from '../../../../providers/toast-provider';
import { Timestamp } from 'firebase/firestore';
import { isLeagueScoringDifferent } from './league-scoring/league-scoring-helper';

type LeagueSettingsType = {
  league: iLeague;
  createdLeagueId?: string;
  onFinish?: () => void;
  isAdmin: boolean;
  readOnly: boolean;
  readOnlyScoring: boolean;
  reactivateLeagueOption: boolean;
  expectedNumLeagueMembers?: number;
};

const LeagueSettings = (props: LeagueSettingsType) => {
  const { showToast } = useContext(ToastContext);
  const {
    appConfig,
    updateLeague,
    saveLeagueSettings,
    setLeaguePickTimer,
    leaguePickTimer,
    leaguePickTimerUnit,
    setLeaguePickTimerUnit,
  } = useContext(LeagueContext);
  const [leagueBanner, setLeagueBanner] = useState<File>();
  const [leagueName, setLeagueName] = useState<string>(props.league.title);
  const [leagueDescription, setLeagueDescription] = useState<string>(
    props.league.description,
  );
  const [leagueEnableDynasty, setLeagueEnableDynasty] = useState<
    boolean | undefined
  >(props.league.settings.leagueEnableDynasty);
  const [leagueIsOpen, setLeagueIsOpen] = useState<boolean>(
    props.league.isOpen,
  );
  const [numOfTeamsDynasty, setNumOfTeamsDynasty] = useState<number>(2);
  const [leagueDraftDate, setLeagueDraftDate] = useState<Date>();
  const [leagueNumberOfTeamsPerUser, setLeagueNumberOfTeamsPerUser] =
    useState<number>(8);
  const [leagueNumberOfMembers, setLeagueNumberOfMembers] = useState<number>(
    props.league.settings.numOfMembers ? props.league.settings.numOfMembers : 8,
  );
  const [leagueTradeEnabled, setLeagueTradeEnabled] = useState<boolean>(true);
  const [leagueFreeAgentEnabled, setLeagueFreeAgentEnabled] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);
  const [editBasicDetails, setEditBasicDetails] = useState(false);
  const [editGeneralSettings, setEditGeneralSettings] = useState(false);
  const [editLeagueSettings, setEditLeagueSettings] = useState(false);
  const [timerValue, setTimerValue] = useState(
    props.league?.settings?.pickTimer ?? leaguePickTimer,
  );
  const [timerUnit, setTimerUnit] = useState(
    props.league?.settings?.pickTimerUnit ?? leaguePickTimerUnit,
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [leagueScoringUpdated, setLeagueScoringUpdated] = useState(false);
  //Default state for scoring is set during league creation
  const [leagueScoring, setLeagueScoring] = useState<iLeagueScoring>(
    props.league.settings?.scoring,
  );
  const [showCharacterLimitError, setShowCharacterLimitError] = useState(false);
  const [showCharacterLimitHelperText, setShowCharacterLimitHelperText] =
    useState(false);

  const saveLeagueDetails = async () => {
    haveBasicDetailsChanged();

    if (props.league || props.createdLeagueId) {
      setIsLoading(true);
      if (props.league) {
        const leagueDetails: iLeague = {} as iLeague;
        leagueDetails.id = props.league.id;
        leagueDetails.title = leagueName;
        leagueDetails.description = leagueDescription;
        leagueDetails.bannerImage = props.league.bannerImage;
        leagueDetails.isOpen = leagueIsOpen;
        await updateLeague(leagueDetails, leagueBanner);
      }

      setLeaguePickTimer(timerValue);
      setLeaguePickTimerUnit(timerUnit);

      const leagueSettings: iLeagueSettings = {
        numOfMembers: leagueNumberOfMembers,
        numOfTeamsPerMember: leagueNumberOfTeamsPerUser,
        tradeEnabled: leagueTradeEnabled,
        freeAgentEnabled: leagueFreeAgentEnabled,
        scoring: leagueScoring,
        pickTimer: timerValue,
        pickTimerUnit: timerUnit,
        leagueEnableDynasty: leagueEnableDynasty,
        numOfTeamsDynasty: numOfTeamsDynasty,
      };

      if (leagueDraftDate) {
        leagueSettings.draftDate = leagueDraftDate;
      }

      leagueSettings.leagueId = props.league
        ? props.league.id
        : props.createdLeagueId;

      if (leagueSettings.leagueId)
        await saveLeagueSettings(leagueSettings.leagueId, leagueSettings);

      setButtonDisabled(true);
      setEditBasicDetails(false);
      setEditGeneralSettings(false);
      setEditLeagueSettings(false);
      setIsLoading(false);
      props.onFinish && props.onFinish();
    }
  };

  useEffect(() => {
    if (props.league) {
      if (props.league?.settings?.draftDate) {
        setLeagueDraftDate(props.league?.settings?.draftDate.toDate());
      }

      if (props.league?.settings?.numOfTeamsPerMember) {
        setLeagueNumberOfTeamsPerUser(
          props.league?.settings?.numOfTeamsPerMember,
        );
      }

      // Dynasty Settings
      if (props.league?.settings?.leagueEnableDynasty) {
        setLeagueEnableDynasty(props.league?.settings?.leagueEnableDynasty);
      }
      if (props.league?.settings?.numOfTeamsDynasty) {
        setNumOfTeamsDynasty(props.league?.settings?.numOfTeamsDynasty);
      }

      if (props.league?.settings?.numOfMembers) {
        setLeagueNumberOfMembers(props.league?.settings?.numOfMembers);
      }

      if (props.league?.settings?.tradeEnabled) {
        setLeagueTradeEnabled(props.league?.settings?.tradeEnabled);
      }

      if (props.league?.settings?.freeAgentEnabled) {
        setLeagueFreeAgentEnabled(props.league?.settings?.freeAgentEnabled);
      }
    }
  }, []);

  //TODO 2024: When owner changes banner, title, or description, post the change in League Activity
  const haveBasicDetailsChanged = () => {
    const currentLeagueBasicDetails = {
      title: props.league.title,
      description: props.league.description,
    };

    const updatedLeagueBasicDetails = {
      title: leagueName,
      description: leagueDescription,
    };

    if (
      JSON.stringify(currentLeagueBasicDetails) !==
        JSON.stringify(updatedLeagueBasicDetails) ||
      leagueBanner
    ) {
      //TODO: post changes to league activity
    }
  };

  //Handles enabling/disabling the Update/Finish button
  useEffect(() => {
    const isDisabled = disableButton();
    setButtonDisabled(isDisabled);
  }, [
    leagueBanner,
    leagueName,
    leagueDescription,
    leagueScoringUpdated,
    leagueNumberOfMembers,
    leagueNumberOfTeamsPerUser,
    leagueDraftDate,
    timerUnit,
    timerValue,
    numOfTeamsDynasty,
    leagueEnableDynasty,
    leagueIsOpen,
  ]);

  const disableButton = () => {
    //If not creator
    if (!props.isAdmin) {
      return true;

      //If user is creator...
    } else {
      let disableBtn = false;

      // Handle empty text fields first
      if (!leagueName || !leagueDescription || !timerValue) {
        disableBtn = true;

        // Timer value is a text field but should not be 0
      } else if (timerValue === 0) {
        disableBtn = true;

        // Now check for changes
      } else {
        // Set to true and check for changes. If something has changed, set to false
        disableBtn = true;

        // Just checks if the user adds a new banner, we can live with this
        if (leagueBanner) {
          disableBtn = false;
        }

        // If league title has changed
        if (leagueName !== props.league.title) {
          disableBtn = false;
        }

        // If league description has changed
        if (leagueDescription !== props.league.description) {
          disableBtn = false;
        }

        //If num of members has changed
        if (
          props.league.settings.numOfMembers &&
          leagueNumberOfMembers !== props.league.settings.numOfMembers
        ) {
          disableBtn = false;
        }

        //If num of teams per member has changed
        if (
          leagueNumberOfTeamsPerUser !==
          props.league.settings.numOfTeamsPerMember
        ) {
          disableBtn = false;
        }

        //If num of teams per member has changed
        if (
          leagueNumberOfTeamsPerUser !==
          props.league.settings.numOfTeamsPerMember
        ) {
          disableBtn = false;
        }

        //If league scoring has changed
        if (leagueScoringUpdated) {
          disableBtn = false;
        }

        //If timer amount has changed
        if (timerValue !== props.league.settings.pickTimer) {
          disableBtn = false;
        }

        //If timer units has changed
        if (timerUnit !== props.league.settings.pickTimerUnit) {
          disableBtn = false;
        }

        // If dynasty mode has changed
        if (leagueEnableDynasty !== props.league.settings.leagueEnableDynasty) {
          disableBtn = false;
        }

        // If num of teams for dynasty mode has changed
        if (
          leagueEnableDynasty &&
          numOfTeamsDynasty !== props.league.settings.numOfTeamsDynasty
        ) {
          disableBtn = false;
        }

        //If league type has changed
        if (leagueIsOpen != props.league.isOpen) {
          disableBtn = false;
        }

        //If draft date has changed
        if (leagueDraftDate && props.league.settings.draftDate) {
          const draftTimestamp = props.league.settings
            .draftDate as unknown as Timestamp;
          const draftDate = new Date(
            draftTimestamp.seconds * 1000 +
              draftTimestamp.nanoseconds / 1000000,
          );

          if (leagueDraftDate.toISOString() !== draftDate.toISOString()) {
            disableBtn = false;
          }
        }

        // // If this is part of the create league process,
        // // don't require additional changes (user accepts defaults)
        if (props.createdLeagueId) {
          if (leagueName !== '' && leagueDescription !== '') {
            disableBtn = false;
          }
        }
      }

      return disableBtn;
    }
  };

  const onLeagueScoringUpdate = (scoring: iLeagueScoring) => {
    // Check to see if scoring has changed from original value
    if (isLeagueScoringDifferent(scoring, props.league.settings?.scoring)) {
      setLeagueScoring(scoring);
      setLeagueScoringUpdated(true);
    } else {
      setLeagueScoringUpdated(false);
    }
  };

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div
      className="flex flex-col justify-between flex-1 p-4"
      data-e2e="create_league_settings"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          // Submit here as a final check to make sure all required fields are filled
          saveLeagueDetails();
        }}
      >
        {props.league && (
          <div>
            <h3 className="border-b-[1px] border-b-accent pb-2 flex justify-between">
              Basic Details
              {/* shows an edit icon for this section */}
              <Tooltip
                enterTouchDelay={0}
                title={
                  // Shows only if user is not league owner
                  !props.isAdmin && 'Only the league owner can edit settings.'
                }
                data-e2e="league_settings_basic_tooltip"
              >
                <div>
                  {/* "Disabled" if not league owner */}
                  <FontAwesomeIcon
                    className={`${
                      props.isAdmin ? 'cursor-pointer' : 'opacity-30'
                    }`}
                    icon={faPenToSquare}
                    onClick={() =>
                      props.isAdmin && setEditBasicDetails(!editBasicDetails)
                    }
                    data-e2e="league_settings_basic_edit_btn"
                  />
                </div>
              </Tooltip>
            </h3>
            {props.reactivateLeagueOption && (
              <Button
                onClick={() => {
                  //TODO: rebuld the league here
                }}
              >
                Reactivate League
              </Button>
            )}
            <ImagePicker
              imageLink={props.league?.bannerImage}
              label="Add league banner"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.files?.length) {
                  setLeagueBanner(e.target.files[0]);
                }
              }}
              // Disabled until edit button is clicked
              disabled={!editBasicDetails}
              e2eTag="ls_league_banner"
            />
            <div className="mt-2">
              <TextInput
                value={leagueName}
                label="Title"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length < 31) {
                    setLeagueName(event.target.value);
                    setShowCharacterLimitError(false);
                  } else {
                    setShowCharacterLimitError(true);
                    setShowCharacterLimitHelperText(true);
                  }
                }}
                // Disabled until edit button is clicked
                disabled={!editBasicDetails}
                required
                e2eTag="ls_basic_title_input"
                error={showCharacterLimitError}
                helperText={
                  showCharacterLimitHelperText
                    ? `League title must be 30 characters or less. [${leagueName.length}/30]`
                    : undefined
                }
                sxProps={{ marginY: 1 }}
              />
            </div>
            <div className="mt-2">
              <TextInput
                value={leagueDescription}
                label="Description"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setLeagueDescription(event.target.value);
                }}
                // Disabled until edit button is clicked
                disabled={!editBasicDetails}
                e2eTag="ls_basic_description_input"
                required
              />
            </div>
          </div>
        )}

        <div>
          <h3 className="border-b-[1px] border-b-accent pb-2 mt-6 flex justify-between">
            League Settings
            {/* shows an edit icon for this section */}
            <Tooltip
              enterTouchDelay={0}
              title={
                // Show if user is not league owner
                !props.isAdmin && 'Only the league owner can edit settings.'
              }
              data-e2e="league_settings_dynasty_tooltip"
            >
              <span>
                {/* Edit icon is "disabled" if user is not league owner or season is in the past */}
                <FontAwesomeIcon
                  className={`${
                    !props.isAdmin ||
                    props.league.season !== appConfig.currentSeason
                      ? 'opacity-30'
                      : 'cursor-pointer'
                  }`}
                  icon={faPenToSquare}
                  // Only allow click if user is league owner
                  onClick={() =>
                    props.isAdmin && setEditLeagueSettings(!editLeagueSettings)
                  }
                  data-e2e="league_settings_edit_btn"
                />
              </span>
            </Tooltip>
          </h3>

          <div>
            {/* Checkbox to enable/disable dynasties */}
            <FormControl fullWidth>
              <h3 className="pb-2 mt-6">Dynasty Mode</h3>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={leagueEnableDynasty}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setLeagueEnableDynasty(event.target.checked);
                    }}
                    // Disabled until edit button is clicked
                    disabled={!editLeagueSettings}
                  />
                }
                label="Enable Dynasty Mode"
              />
              <FormHelperText>
                Keep your league for years to come with Dynasty Mode!
              </FormHelperText>
            </FormControl>
            {leagueEnableDynasty && (
              <div className="my-4">
                <FormControl fullWidth>
                  <InputLabel id="num-dynasty-teams-label">
                    Num of Teams to Keep
                  </InputLabel>
                  <Select
                    labelId="num-dynasty-teams-label"
                    id="num-dynasty-teams-select"
                    value={numOfTeamsDynasty}
                    label="Num of Teams"
                    onChange={(event: SelectChangeEvent<number>) => {
                      if (event.target.value) {
                        //If the selected number of teams > number of teams per squad, show error
                        if (leagueNumberOfTeamsPerUser > +event.target.value) {
                          setNumOfTeamsDynasty(+event.target.value);
                        } else {
                          showToast({
                            messageType: 'error',
                            message:
                              'You cannot change this to be more than the number of teams per squad.',
                            autoHideDuration: 4000,
                            dataTag: 'num_dynasty_teams',
                          });
                        }
                      }
                    }}
                    // Disabled until edit button is clicked
                    disabled={!editLeagueSettings}
                    data-e2e="ls_draft_num_members"
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            {/* <FormControl>
              <h3 className="pb-2 mt-6">League Type</h3>
              <RadioGroup
                onChange={(e) => {
                  if (e.target.value === 'open') {
                    setLeagueIsOpen(true);
                  } else {
                    setLeagueIsOpen(false);
                  }
                }}
              >
                <FormControlLabel
                  value="private"
                  control={
                    <Radio
                      disabled={!editLeagueSettings}
                      checked={!leagueIsOpen}
                    />
                  }
                  label="Private"
                />
                <FormHelperText>
                  Private leagues require invitations to add members
                </FormHelperText>
                <FormControlLabel
                  value="open"
                  control={
                    <Radio
                      disabled={!editLeagueSettings}
                      checked={leagueIsOpen}
                    />
                  }
                  label="Open"
                />
                <FormHelperText>
                  Open leagues allow anyone to join your league
                </FormHelperText>
              </RadioGroup>
            </FormControl> */}
          </div>
        </div>
        <div>
          <h3 className="border-b-[1px] border-b-accent pb-2 mt-6 flex justify-between">
            Draft Settings
            {/* shows an edit icon for this section */}
            <Tooltip
              enterTouchDelay={0}
              title={
                // Show if user is not league owner
                !props.isAdmin
                  ? 'Only the league owner can edit settings.'
                  : // Show if user is league owner but draft has started
                    props.readOnly &&
                    'These settings cannot be edited once the draft begins.'
              }
              data-e2e="league_settings_draft_tooltip"
            >
              <span>
                {/* Edit icon is "disabled" if user is not league owner or draft has started */}
                <FontAwesomeIcon
                  className={`${
                    !props.isAdmin || props.readOnly
                      ? 'opacity-30'
                      : 'cursor-pointer'
                  }`}
                  icon={faPenToSquare}
                  // Only allow click if user is league owner and draft hasn't started
                  onClick={() =>
                    props.isAdmin &&
                    !props.readOnly &&
                    setEditGeneralSettings(!editGeneralSettings)
                  }
                  data-e2e="league_settings_draft_edit_btn"
                />
              </span>
            </Tooltip>
          </h3>
          <DateInput
            showTime
            initialValue={leagueDraftDate}
            placeholder="Draft Date"
            onChange={(val) => {
              if (val) setLeagueDraftDate(val);
            }}
            // Disabled until edit button is clicked
            disabled={!editGeneralSettings}
            e2eTag="ls_draft_date_input"
          />
          <div className="my-4">
            <FormControl fullWidth>
              <InputLabel id="num-members-label">Num of Members</InputLabel>
              <Select
                labelId="num-members-label"
                id="num-members-select"
                value={leagueNumberOfMembers}
                label="Num of Members"
                onChange={(event: SelectChangeEvent<number>) => {
                  if (event.target.value) {
                    //If the selected number of members < the expected number of members (based on number of invites), show error
                    if (props.expectedNumLeagueMembers) {
                      if (
                        props.expectedNumLeagueMembers <= +event.target.value
                      ) {
                        setLeagueNumberOfMembers(+event.target.value);
                      } else {
                        showToast({
                          messageType: 'error',
                          message:
                            'You cannot change this to be less than the number of people you have invited.',
                          autoHideDuration: 4000,
                          dataTag: 'num_members',
                        });
                      }
                    } else {
                      setLeagueNumberOfMembers(+event.target.value);
                    }
                  }
                }}
                // Disabled until edit button is clicked
                disabled={!editGeneralSettings}
                data-e2e="ls_draft_num_members"
              >
                <MenuItem value={2} data-e2e="ls_draft_num_members_2">
                  2
                </MenuItem>
                <MenuItem value={4} data-e2e="ls_draft_num_members_4">
                  4
                </MenuItem>
                <MenuItem value={6} data-e2e="ls_draft_num_members_6">
                  6
                </MenuItem>
                <MenuItem value={8} data-e2e="ls_draft_num_members_8">
                  8
                </MenuItem>
                <MenuItem value={10} data-e2e="ls_draft_num_members_10">
                  10
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="my-4">
            <FormControl fullWidth>
              <InputLabel id="num-teams-label">Num of Teams</InputLabel>
              <Select
                labelId="num-teams-label"
                id="num-teams-select"
                value={leagueNumberOfTeamsPerUser}
                label="Num of Teams"
                onChange={(event: any) => {
                  if (event.target.value)
                    setLeagueNumberOfTeamsPerUser(event.target.value);
                }}
                // Disabled until edit button is clicked
                disabled={!editGeneralSettings}
                data-e2e="ls_draft_num_teams"
              >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </FormControl>
          </div>
          <InputLabel id="pick-timer">Pick Timer</InputLabel>
          <div className="flex max-w-fit">
            <TextField
              id="timer-value"
              value={timerValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setTimerValue(Number(event.target.value));
              }}
              // Disabled until edit button is clicked
              disabled={!editGeneralSettings}
              data-e2e="ls_draft_timer_number"
              required
            />
            <FormControl fullWidth>
              <Select
                labelId="pick-timer"
                id="timer-unit"
                value={timerUnit}
                onChange={(event: SelectChangeEvent<string>) => {
                  setTimerUnit(event.target.value);
                }}
                // Disabled until edit button is clicked
                disabled={!editGeneralSettings}
                data-e2e="ls_draft_timer_units"
              >
                <MenuItem value={'seconds'}>seconds</MenuItem>
                <MenuItem value={'minutes'}>minutes</MenuItem>
              </Select>
            </FormControl>
          </div>
          <LeagueScoring
            readOnly={props.readOnlyScoring}
            isAdmin={props.isAdmin}
            leagueScoring={leagueScoring}
            setLeagueScoringInSettings={onLeagueScoringUpdate}
          />
        </div>
        <div className="mt-12  sticky bottom-4">
          {/* Disabled for all non-owners and until any changes are made */}
          <Tooltip
            enterTouchDelay={0}
            title={
              leagueName === ''
                ? 'League title cannot be blank.'
                : leagueDescription === ''
                  ? 'League description cannot be blank.'
                  : !props.isAdmin && 'Only the league owner can edit settings'
            }
            data-e2e="league_settings_save_tooltip"
          >
            <div className="w-fit absolute right-0 bottom-[inherit]">
              <Button
                variant="contained"
                disabled={buttonDisabled}
                data-e2e="league_settings_save_btn"
                type="submit"
              >
                {props.league && !props.createdLeagueId ? 'Update' : 'Finish'}
              </Button>
            </div>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};

export default LeagueSettings;
