import { Button, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import {
  iLeagueScoring,
  iCategoryStatsItem,
} from '@shared/shared-utils/models';

type LeagueScoringModalProps = {
  updateLeagueScoring: (newScoring: iLeagueScoring) => void;
  closeModal?: () => void;
  currentScoring: iLeagueScoring;
};

const LeagueScoringModal = (props: LeagueScoringModalProps) => {
  const [passingScoring, setPassingScoring] = useState<iCategoryStatsItem[]>();
  const [rushingScoring, setRushingScoring] = useState<iCategoryStatsItem[]>();
  const [defensiveScoring, setDefensiveScoring] =
    useState<iCategoryStatsItem[]>();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const saveNewScoring = () => {
    const updatedScoring: iLeagueScoring = {
      passingScoreStats:
        passingScoring ?? props.currentScoring.passingScoreStats,
      rushingScoreStats:
        rushingScoring ?? props.currentScoring.rushingScoreStats,
      defensiveScoreStats:
        defensiveScoring ?? props.currentScoring.defensiveScoreStats,
    };
    props.updateLeagueScoring(updatedScoring);
  };

  const addNewPassingScoringStat = (newScoringStat: iCategoryStatsItem) => {
    const statIndex = props.currentScoring.passingScoreStats.findIndex(
      (stat) => stat.key === newScoringStat.key,
    );

    const newPassingScoring = [...props.currentScoring.passingScoreStats];
    if (statIndex !== -1) {
      newPassingScoring[statIndex] = newScoringStat;
    }
    setPassingScoring(newPassingScoring);
  };

  const addNewRushingScoringStat = (newScoringStat: iCategoryStatsItem) => {
    const statIndex = props.currentScoring.rushingScoreStats.findIndex(
      (stat) => stat.key === newScoringStat.key,
    );

    const newRushingScoring = [...props.currentScoring.rushingScoreStats];
    if (statIndex !== -1) {
      newRushingScoring[statIndex] = newScoringStat;
    }

    setRushingScoring(newRushingScoring);
  };

  const addNewDefensiveScoringStat = (newScoringStat: iCategoryStatsItem) => {
    const statIndex = props.currentScoring.defensiveScoreStats.findIndex(
      (stat) => stat.name === newScoringStat.name,
    );

    const newDefensiveScoring = [...props.currentScoring.defensiveScoreStats];
    if (statIndex !== -1) {
      newDefensiveScoring[statIndex] = newScoringStat;
    }

    setDefensiveScoring(newDefensiveScoring);
  };

  return (
    <div
      className="mt-2 overflow-y-scroll max-h-[90vh] bg-db-header-background-color"
      data-e2e="scoring_modal"
    >
      <div className="px-6">
        <div>
          <p className="font-bold">Passing Stats</p>
          <hr className="pb-2"></hr>
          {props.currentScoring.passingScoreStats.map((details) => {
            return (
              <div
                className="flex justify-between items-center pl-4"
                key={details.key}
              >
                {details.name}
                <TextField
                  defaultValue={details.multiplier}
                  inputProps={{
                    style: {
                      paddingTop: '9.5px',
                      paddingBottom: '9.5px',
                      width: '30px',
                      height: '10px',
                      textAlign: 'center',
                    },
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (details.multiplier !== +event.target.value) {
                      addNewPassingScoringStat({
                        ...details,
                        multiplier: +event.target.value,
                      });
                      setSubmitButtonDisabled(false);
                    } else {
                      setSubmitButtonDisabled(true);
                    }
                  }}
                  data-e2e="scoring_input"
                />
              </div>
            );
          })}
        </div>
        <div>
          <p className="font-bold">Rushing Stats</p>
          <hr className="pb-2"></hr>

          {props.currentScoring.rushingScoreStats.map((details) => {
            return (
              <div
                className="flex justify-between items-center pl-4"
                key={details.key}
              >
                {details.name}
                <TextField
                  defaultValue={details.value}
                  inputProps={{
                    style: {
                      paddingTop: '9.5px',
                      paddingBottom: '9.5px',
                      width: '30px',
                      height: '10px',
                      textAlign: 'center',
                    },
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (details.multiplier !== +event.target.value) {
                      addNewRushingScoringStat({
                        ...details,
                        multiplier: +event.target.value,
                      });
                      setSubmitButtonDisabled(false);
                    } else {
                      setSubmitButtonDisabled(true);
                    }
                  }}
                  data-e2e="scoring_input"
                />
              </div>
            );
          })}
        </div>
        <div>
          <p className="font-bold">Defensive Stats</p>
          <hr className="pb-2"></hr>

          {props.currentScoring.defensiveScoreStats.map((details) => {
            return details.scoringBreakdown ? (
              <div key={details.key}>
                {Object.entries(details.scoringBreakdown).map(
                  ([key, value]) => {
                    return (
                      <div
                        className="flex justify-between items-center pl-4"
                        key={key}
                      >
                        {key}
                        <TextField
                          defaultValue={value}
                          inputProps={{
                            style: {
                              paddingTop: '9.5px',
                              paddingBottom: '9.5px',
                              width: '30px',
                              height: '10px',
                              textAlign: 'center',
                            },
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (value !== +event.target.value) {
                              addNewDefensiveScoringStat({
                                ...details,
                                scoringBreakdown: {
                                  ...details.scoringBreakdown,
                                  [key]: +event.target.value,
                                },
                              });
                              setSubmitButtonDisabled(false);
                            } else {
                              setSubmitButtonDisabled(true);
                            }
                          }}
                          data-e2e="scoring_input"
                        />
                      </div>
                    );
                  },
                )}
              </div>
            ) : (
              <div
                className="flex justify-between items-center pl-4"
                key={details.key}
              >
                {details.name}
                <TextField
                  defaultValue={details.value}
                  inputProps={{
                    style: {
                      paddingTop: '9.5px',
                      paddingBottom: '9.5px',
                      width: '30px',
                      height: '10px',
                      textAlign: 'center',
                    },
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (details.multiplier !== +event.target.value) {
                      const newScoringStat: iCategoryStatsItem = {
                        ...details,
                        name: details.name,
                        multiplier: +event.target.value,
                      };
                      addNewDefensiveScoringStat(newScoringStat);
                      setSubmitButtonDisabled(false);
                    } else {
                      setSubmitButtonDisabled(true);
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-right mt-8 mb-4">
        <Button
          variant="contained"
          onClick={() => {
            saveNewScoring();
            props.closeModal && props.closeModal();
          }}
          disabled={submitButtonDisabled}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default LeagueScoringModal;
