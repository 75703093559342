import React, { ReactNode } from 'react';
import logo from '../../../assets/images/cropped-logo.png';

interface AuthWrapperProps {
  children: ReactNode;
  hideLogo?: boolean;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, hideLogo }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col items-center w-10/12 md:w-7/12">
        <div className="p-4 w-full">
          <div className="flex flex-col justify-center mb-4">
            {!hideLogo && (
              <div className="flex justify-center w-full items-center">
                <img src={logo} alt="Logo" className="max-w-full max-h-full mb-10" />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
