import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { iUser } from '@shared/shared-utils/models';
import Image from '../../../components/widgets/image';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Reorder = require('react-reorder');

type DraftOrderProps = {
  leagueMembers: iUser[];
  disabled: boolean;
  onReorder: (users: iUser[]) => void;
};

const DraftOrder = (props: DraftOrderProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [sortedList, setSortedList] = useState<string[]>([]);

  const memberMap = useMemo(() => {
    const map = new Map<string, iUser>();
    props.leagueMembers.forEach((member) => {
      map.set(member.id, member);
    });
    return map;
  }, [props.leagueMembers]);

  useEffect(() => {
    const list: string[] = props.leagueMembers.map((member) => member.id);
    setSortedList(list);
  }, [props.leagueMembers]);

  const exchangeItems = (currPos: number, newPos: number) => {
    const tempArr = [...sortedList];
    if (newPos >= tempArr.length) {
      let k = newPos - tempArr.length + 1;
      while (k--) {
        tempArr.push();
      }
    }
    tempArr.splice(newPos, 0, tempArr.splice(currPos, 1)[0]);
    setSortedList(tempArr);
  };

  useEffect(() => {
    const orderedMembers = sortedList
      .map((userId) => memberMap.get(userId))
      .filter((member) => member !== undefined);
    props.onReorder(orderedMembers as iUser[]);
  }, [sortedList]);

  return (
    <div
      className={`db-list-item-wrapper my-4 ${props.disabled ? 'disabled' : ''}`}
    >
      <div className="space-y-3">
        <h2>
          <FontAwesomeIcon icon={faBarsStaggered} className="mr-2" />
          Drag and Drop Your Members
        </h2>
        <Reorder
          reorderId="autolist-id"
          holdTime={0}
          onReorder={(e: any, currentPos: any, newPos: any) => {
            exchangeItems(currentPos, newPos);
          }}
        >
          {sortedList.map((userId, index) => {
            const member = memberMap.get(userId);
            if (!member) return null;
            return (
              <div
                className="rounded-full flex items-center mb-2"
                key={userId}
                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                onMouseDown={() => setIsDragging(true)}
                onMouseUp={() => setIsDragging(false)}
              >
                <span className="mr-2">{index + 1}.</span>
                <Image
                  key={`image-${userId}`}
                  imageUrl={member.profileImage}
                  title={member.fullName}
                  size={12}
                  specialClass="mr-2"
                />
                <span>{member.fullName}</span>
                <FontAwesomeIcon icon={faBarsStaggered} className="ml-auto" />
              </div>
            );
          })}
        </Reorder>
      </div>
    </div>
  );
};

export default DraftOrder;
