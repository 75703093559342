import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface LeaveLeagueDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const LeaveLeagueDialog: React.FC<LeaveLeagueDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="leave-league-dialog-title"
      aria-describedby="leave-league-dialog-description"
    >
      <DialogTitle id="leave-league-dialog-title">
        {'Are you sure you want to leave the league?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="leave-league-dialog-description">
          Leaving this league will remove you from all related activities.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveLeagueDialog;
