import './league-list.scss';
import LeagueItem from './league-item';
import { iLeague, iUser } from '@shared/shared-utils/models';
import { useHorizontalScroll } from '../../../hooks/use-hoz-scroll';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ChatContext } from '../../../providers/chat-provider';
import {
  faChevronLeft,
  faChevronRight,
  faEarthAmericas,
  faPlus,
  faRepeat,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import NonLeagueItem from './non-league-item';
import { ToastContext } from '../../../providers/toast-provider';
import { DBUserRoles } from '@shared/shared-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
} from '@mui/material';
import { LeagueContext } from '../../../providers/league-provider';
import ReactivateLeagueDialog from './ReactivateLeagueDialog';
import { useNavigate } from 'react-router-dom';

type LeagueListType = {
  leagues: iLeague[];
  invitedLeagues: iLeague[] | [];
  title: string;
  user: iUser;
  season: number;
  includeInternalLeagues: (switchState: boolean) => void;
};

const LeagueList = (props: LeagueListType) => {
  const {
    title,
    leagues,
    user,
    season,
    invitedLeagues,
    includeInternalLeagues,
  } = props;
  const { checkForUnreadMessages } = useContext(ChatContext);
  const { showToast } = useContext(ToastContext);
  const { appConfig, reactivateOldLeague } = useContext(LeagueContext);
  const hozScrollRef = useHorizontalScroll();
  const [selectedSeason, setSelectedSeason] = useState(season);
  const [filteredLeagues, setFilteredLeagues] = useState<iLeague[]>();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [internalLeagueSwitchState, setInternalLeagueSwitchState] =
    useState(false);
  const [showReactivateLeagueDialog, setShowReactivateLeagueDialog] =
    useState(false);
  const [leaguesToReactivate, setLeaguesToReactivate] = useState<iLeague[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  // Defaults the leagues displayed to current season
  useEffect(() => {
    setFilteredLeagues(
      leagues.filter((league) => league.season === selectedSeason),
    );
  }, [leagues]);

  useEffect(() => {
    const potentialLeagues = leagues.filter(
      (league) =>
        league.season === appConfig.currentSeason - 1 &&
        league.memberIds?.includes(user.id),
    );

    setLeaguesToReactivate(potentialLeagues);
  }, []);

  // Shows scroll arrows based on scroll position
  const container = hozScrollRef.current;
  const maxScrollLeft = container?.scrollWidth - container?.clientWidth;
  const handleLeagueScroll = () => {
    // If no leagues and on desktop (maxScrollLeft === 19), don't show arrows (no need to scroll)
    if (maxScrollLeft === 19) {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    } // Not on desktop (doesn't matter if they have leagues or not since there is content to scroll)
    else if (maxScrollLeft > 19) {
      // If scrolled all the way to the left, show right arrow only
      if (container?.scrollLeft === 0) {
        setShowLeftArrow(false);
        setShowRightArrow(true);
      } // If scrolled all the way right, show left arrow only
      else if (container?.scrollLeft > maxScrollLeft - 1) {
        setShowLeftArrow(true);
        setShowRightArrow(false);
      } // If scroll is somewhere in between max left and max right, show both arrows
      else {
        setShowLeftArrow(true);
        setShowRightArrow(true);
      }
    }
  };

  // Run scroll function on render and on scrolling
  useEffect(() => {
    handleLeagueScroll();
  }, [handleLeagueScroll]);

  const filterLeaguesBySeason = (seasonSelection: number) => {
    setSelectedSeason(seasonSelection);

    if (seasonSelection === 1990) {
      setFilteredLeagues(leagues);
    } else {
      const seasonLeagues = leagues.filter(
        (league) => league.season === seasonSelection,
      );
      setFilteredLeagues(seasonLeagues);
    }
  };

  // This is to memoize the content so it isn't rerendered every time the scroll state changes
  const memoizedContent = useMemo(() => {
    return (
      <div
        className="px-6 pt-4 league-item-wrapper"
        ref={hozScrollRef}
        id="leagues_list_container"
        onScroll={() => {
          handleLeagueScroll();
        }}
      >
        {invitedLeagues?.length ? (
          invitedLeagues.map((league) => {
            return (
              <NonLeagueItem
                key={league.id}
                backgroundImage={league.bannerImage}
                to={`/join/${league.id}`}
                icon={faUserPlus}
                showUpperTextOverlay={true}
                iconOnClick={(event) => {}}
                iconDataE2E="join_league"
                upperText={`${league.creator.fullName} has invited you to join ${league.title}`}
                lowerText="Accept this invitation to join this league!"
              />
            );
          })
        ) : (
          <></>
        )}
        {filteredLeagues &&
          filteredLeagues.map((league) => {
            const unreadMessages = checkForUnreadMessages(league);
            return (
              <LeagueItem
                league={league}
                key={league.id}
                userId={user.id}
                leagueActive={league.season === season}
                unreadMessages={unreadMessages}
              />
            );
          })}
        <NonLeagueItem
          to="/league/create"
          icon={faPlus}
          iconOnClick={(event) => {}}
          iconDataE2E="create_league"
          upperText="Create League"
          lowerText="Create your own league and start drafting teams!"
          e2eTag="create-league"
        />
        <NonLeagueItem
          to="/league/selectOpenLeague"
          icon={faEarthAmericas}
          iconOnClick={(event) => {}}
          iconDataE2E="join_random_league"
          upperText="Join Open League"
          lowerText="Join an open league with competitors from around the nation!"
          e2eTag="select-open-league"
        />
        {leaguesToReactivate.length > 0 && (
          <NonLeagueItem
            icon={faRepeat}
            iconOnClick={(event) => {}}
            linkOnClick={() => {
              setShowReactivateLeagueDialog(true);
            }}
            iconDataE2E="reactivate_league"
            upperText="Reactivate League"
            lowerText="Want to reactivate last year's league?"
            e2eTag="reactivate-league"
          />
        )}
      </div>
    );
  }, [
    invitedLeagues,
    filteredLeagues,
    user.id,
    season,
    checkForUnreadMessages,
    hozScrollRef,
    leagues,
  ]);

  const reactivateLeague = async (leagueId: string) => {
    const newLeague = await reactivateOldLeague(leagueId);
    if (newLeague) {
      setShowReactivateLeagueDialog(false);
      showToast({
        messageType: 'success',
        message: 'League successfully reactivated!',
        dataTag: 'reactivate_league',
      });
      navigate(`/draft/${newLeague.id}?reactivated=true`);
    } else {
      setShowReactivateLeagueDialog(false);
      showToast({
        messageType: 'error',
        message: 'Error reactivating league.',
      });
    }
  };

  return (
    <div>
      <div className="flex justify-between mt-4 items-center">
        <h4 className="m-4">{title}</h4>
        <div className="min-w-[105px] max-w-fit mr-4 text-end">
          <FormControl variant="standard">
            <Select
              onChange={(e) => {
                filterLeaguesBySeason(Number(e.target.value));
              }}
              value={selectedSeason ?? props.season}
              sx={{
                borderWidth: 0,
              }}
            >
              {appConfig.allSeasons.map((year) => (
                <MenuItem key={year} value={year}>
                  {year.toString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Only show if user is SB Super Admin.
      Used to toggle the League List to include internal leagues */}
      {user.role === DBUserRoles.superAdmin && (
        <FormGroup className="mt-4">
          <FormControlLabel
            control={
              <Switch
                checked={internalLeagueSwitchState}
                onChange={(e) => {
                  if (user.role === DBUserRoles.superAdmin) {
                    includeInternalLeagues(e.target.checked);
                    setInternalLeagueSwitchState(e.target.checked);
                  }
                }}
              />
            }
            label="Include Internal Leagues"
          />
        </FormGroup>
      )}

      {/* Add left and right scroll arrows */}
      <div className="scroll-container-outer">
        {showLeftArrow && (
          <div className="scroll-arrow left">
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        )}
        {showRightArrow && (
          <div className="scroll-arrow right">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        )}
      </div>

      <div className="scroll-container" ref={containerRef}>
        {memoizedContent}
      </div>

      <ReactivateLeagueDialog
        openReactivateLeagueDialog={showReactivateLeagueDialog}
        onClose={() => setShowReactivateLeagueDialog(false)}
        reactivatableLeagues={leaguesToReactivate}
        handleReactivateLeagueClick={reactivateLeague}
      />
    </div>
  );
};

export default LeagueList;
