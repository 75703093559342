import { useContext, useState, useEffect } from 'react';
import Header from '../../../components/widgets/header/header';
import { LeagueContext } from '../../../providers/league-provider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, margin } from '@mui/system';
import { Button } from '@mui/material';
import Loading from '../../../components/ui/loading-animation';
import { iLeague } from '@shared/shared-utils/models';

export function SelectOpenLeague() {
  const { getOpenLeagues, appConfig } = useContext(LeagueContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [openLeaguesList, setOpenLeaguesList] = useState<iLeague[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setUpOpenLeagueList();
  }, []);

  const setUpOpenLeagueList = async () => {
    try {
      setLoading(true);
      const returnedOpenLeaguesList = await getOpenLeagues(
        appConfig.currentSeason,
      );

      // sort with the highest amount of members first
      returnedOpenLeaguesList.sort((a, b) => {
        return b.memberIds.length - a.memberIds.length;
      });
      setOpenLeaguesList(returnedOpenLeaguesList);
    } catch (error) {
      console.log('Error loading SelectOpenLeague', error);
    }

    setLoading(false);
  };

  const handleRowClick = (leagueId: string) => {
    navigate(`/join/${leagueId}`);
  };
  return loading ? (
    <Loading></Loading>
  ) : (
    <div id="db-select-open-league" className="flex flex-col football-bg">
      <Header title={'Open Leagues'} isHome={false} />
      <div className="pt-[4.8rem]">
        {/* Table of open league */}
        {/* TODO: make the table scrollable */}
        {openLeaguesList?.length ? (
          <div>
            <Container
              sx={{
                marginTop: '1.5rem',
                textAlign: 'right',
              }}
            >
              <FontAwesomeIcon icon={faCrown} color="#46B549" />
              &nbsp;&nbsp;Dynasty
            </Container>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: '1rem',
              }}
            >
              <Table aria-label="simple table">
                <TableBody>
                  {openLeaguesList?.map((league) => (
                    <TableRow
                      hover
                      key={league.title}
                      onClick={() => handleRowClick(league.id)}
                      sx={{
                        cursor: 'pointer',
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell style={{ width: '20%' }}>
                        <img
                          src={league.bannerImage}
                          className="h-auto w-16"
                          alt={league.title}
                        />
                      </TableCell>
                      <TableCell>
                        <strong>{league.title}&nbsp;&nbsp;</strong>
                        <FontAwesomeIcon
                          icon={faCrown}
                          color="#46B549"
                          visibility={
                            league.settings.leagueEnableDynasty
                              ? 'visible'
                              : 'hidden'
                          }
                        />
                        <br />
                        <span style={{ fontSize: '90%' }}>
                          {league.memberIds?.length
                            ? league.memberIds.length
                            : 0}{' '}
                          / {league.settings.numOfMembers} squads filled
                          <br />
                          Draft {league.settings.numOfTeamsPerMember} teams
                        </span>
                      </TableCell>
                      <TableCell>
                        <Button>View League</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className="text-center text-white">
            Sorry! There are no open leagues to join at this time. Consider
            creating your own!
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectOpenLeague;
