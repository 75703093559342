import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  iLeagueMemberSquadDb,
  iLeagueSettings,
  leagueDraftStatus,
  iTeam,
  iUser,
  iAppConfig,
  iLeagueDb,
  iLeagueInvite,
  RequestType,
  iTradeRequest,
  iTransferPortalRequest,
  iChatMessage,
} from '@shared/shared-utils/models';
import { DBCollections } from '@shared/shared-utils';
import { DBFirebaseDB } from '../../services/firebase-core';

export class OperationsService {
  private _DBLeaguesCollection = collection(
    DBFirebaseDB,
    DBCollections.leagues,
  );

  private _DBUsersCollection = collection(DBFirebaseDB, DBCollections.users);

  private _DBLeagueSettingsCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueSettings,
  );

  private _DBSquadsCollection = collection(DBFirebaseDB, DBCollections.squads);

  private _DBInvitesCollection = collection(
    DBFirebaseDB,
    DBCollections.invites,
  );

  private _DBSettingsCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueSettings,
  );

  private _DBDraftsCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueDrafts,
  );

  private _DBLeagueMatchupsCollection = collection(
    DBFirebaseDB,
    DBCollections.matchups,
  );

  private _DBLeagueRequestsCollection = collection(
    DBFirebaseDB,
    DBCollections.requests,
  );

  private _DBTeamsCollection = collection(
    DBFirebaseDB,
    DBCollections.teamsMetaData,
  );

  private _DBAppConfigCollection = collection(
    DBFirebaseDB,
    DBCollections.appConfig,
  );

  private _DBChatsCollection = collection(
    DBFirebaseDB,
    DBCollections.leagueChats,
  );

  // TODO: pull just the current season and create a cache
  public async getAllLeagues(season: number) {
    //console.log('read - getAllLeagues()');
    const q = query(this._DBLeaguesCollection, where('season', '==', season));
    const allLeaguesSnapshot = await getDocs(q);
    const allLeagues = [] as iLeagueDb[];
    allLeaguesSnapshot.forEach((doc) => {
      const league = doc.data() as iLeagueDb;
      if (league.createdAt) {
        // Firestore automatically converts dates to Timestamps. We have to convert it back to a date.
        const timestamp = league.createdAt as unknown as Timestamp;
        const date = new Date(
          timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
        );
        league.createdAt = date;
      }
      allLeagues.push(league);
    });
    return allLeagues;
  }

  public async getAllInvites() {
    //console.log('read - getAllInvites()');
    const allInvitesSnapshot = await getDocs(this._DBInvitesCollection);
    const allInvites = [] as iLeagueInvite[];
    allInvitesSnapshot.forEach(async (doc) => {
      allInvites.push(doc.data() as iLeagueInvite);
    });
    return allInvites;
  }

  public async getAllUsers() {
    //console.log('read - getAllUsers()');
    const allUsersSnapshot = await getDocs(this._DBUsersCollection);
    const allUsers = [] as iUser[];
    allUsersSnapshot.forEach((user) => {
      if (user.exists()) {
        allUsers.push(user.data() as iUser);
      }
    });
    allUsers.forEach((user) => {
      if (user.createdDate) {
        // Firestore automatically converts dates to Timestamps. We have to convert it back to a date.
        const timestamp = user.createdDate as unknown as Timestamp;
        const date = new Date(
          timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
        );
        user.createdDate = date;
      }
    });
    return allUsers;
  }

  public async getAllTeams() {
    //console.log('read - getAllTeams()');
    const allTeamsSnapshot = await getDocs(this._DBTeamsCollection);
    const allTeams = [] as iTeam[];
    allTeamsSnapshot.forEach((team) => {
      if (team.exists()) {
        allTeams.push(team.data() as iTeam);
      }
    });
    return allTeams;
  }

  public async getLeagueMemberSquad(leagueId: string, userId: string) {
    //console.log('read - getLeagueMemberSquad()');
    const memberLeagueSquadRef = doc(
      this._DBSquadsCollection,
      leagueId + userId,
    );

    const memberLeagueSquad = await getDoc(memberLeagueSquadRef);
    return memberLeagueSquad.data() as iLeagueMemberSquadDb;
  }

  public async reDraftLeague(leagueId: string) {
    // reset the draft object
    const leagueDraftRef = doc(this._DBDraftsCollection, leagueId);
    deleteDoc(leagueDraftRef);

    // set the league flag for draft
    const leagueRef = doc(this._DBLeaguesCollection, leagueId);
    await setDoc(
      leagueRef,
      { draftStatus: leagueDraftStatus.pending },
      { merge: true },
    );
  }

  public async deleteLeague(leagueId: string) {
    //console.log('read - deleteLeague()');

    // Delete draft
    const leagueDraftRef = doc(this._DBDraftsCollection, leagueId);

    deleteDoc(leagueDraftRef);

    // Delete league settings
    const leagueSettingsRef = doc(this._DBSettingsCollection, leagueId);

    deleteDoc(leagueSettingsRef);

    // Delete matchups
    const leagueMatchupsRef = doc(this._DBLeagueMatchupsCollection, leagueId);

    deleteDoc(leagueMatchupsRef);
    // Delete squads
    const leagueSquadsQuery = query(
      this._DBInvitesCollection,
      where('leagueId', '==', leagueId),
    );

    const leagueSquadsSnapshotList = await getDocs(leagueSquadsQuery);

    leagueSquadsSnapshotList.forEach((squadDoc) => {
      deleteDoc(squadDoc.ref);
    });

    // Delete requests
    const leagueRequestsQuery = query(
      this._DBLeagueRequestsCollection,
      where('leagueId', '==', leagueId),
    );

    const leagueRequestsSnapshotList = await getDocs(leagueRequestsQuery);

    leagueRequestsSnapshotList.forEach((requestDoc) => {
      deleteDoc(requestDoc.ref);
    });

    // Delete invites
    const leagueInvitesQuery = query(
      this._DBInvitesCollection,
      where('leagueId', '==', leagueId),
    );

    const leagueInvitesSnapshotList = await getDocs(leagueInvitesQuery);

    leagueInvitesSnapshotList.forEach((inviteDoc) => {
      deleteDoc(inviteDoc.ref);
    });

    // Delete the league
    const leagueRef = doc(this._DBLeaguesCollection, leagueId);

    await deleteDoc(leagueRef);
  }

  public overwriteTeamData = async (newTeamData: iTeam) => {
    const teamRef = doc(this._DBTeamsCollection, `team-${newTeamData.id}`);
    await setDoc(teamRef, { ...newTeamData });
  };

  public updateAppConfig = async (newAppConfig: iAppConfig) => {
    const appConfigRef = doc(this._DBAppConfigCollection, 'web');
    await updateDoc(appConfigRef, { ...newAppConfig });
  };

  public overwriteLeagueData = async (newLeagueData: iLeagueDb) => {
    const leagueRef = doc(this._DBLeaguesCollection, newLeagueData.id);
    await updateDoc(leagueRef, { ...newLeagueData });
  };

  public overwriteUserData = async (newUserData: iUser, userId: string) => {
    const leagueRef = doc(this._DBUsersCollection, userId);
    await setDoc(leagueRef, { ...newUserData });
  };

  public getUsersNotInALeague = async () => {
    try {
      // Step 1: Fetch all users from the "users" collection
      const usersSnapshot = await getDocs(this._DBUsersCollection);

      // Step 2: Fetch all leagues from the "leagues" collection
      const leaguesSnapshot = await getDocs(this._DBLeaguesCollection);

      // Step 3: Create a set to store unique user IDs that exist in leagues
      const usersInLeaguesSet = new Set();

      // Step 4: Iterate through leagues to extract user IDs from "memberIds" arrays
      leaguesSnapshot.forEach((league) => {
        const leagueData = league.data() as iLeagueDb;
        const memberIdsArray = leagueData.memberIds;

        if (memberIdsArray) {
          memberIdsArray.forEach((userId: string) =>
            usersInLeaguesSet.add(userId),
          );
        }
      });

      // Step 5: Create an array to store user data for users not in leagues with "playerId" populated
      const usersNotInLeagues: iUser[] = [];

      // Step 6: Iterate through users and check if their ID is in the set and "playerId" is populated
      usersSnapshot.forEach((user) => {
        const userId = user.id;
        const userData = user.data() as iUser;

        if (!usersInLeaguesSet.has(userId)) {
          usersNotInLeagues.push(userData);
        }
      });

      return usersNotInLeagues;
    } catch (error) {
      console.error('Error getting data:', error);
      return [];
    }
  };

  public getLeaguesWithoutInvites = async (season: number) => {
    try {
      const leagueQuery = query(
        this._DBLeaguesCollection,
        where('season', '==', season),
      );
      const leagueSnapshotList = await getDocs(leagueQuery);

      const leaguesWithNoInvites: iLeagueDb[] = [];
      leagueSnapshotList.docs.forEach((league) => {
        const leagueData = league.data() as iLeagueDb;
        if (leagueData.memberIds.length === 1) {
          leaguesWithNoInvites.push(leagueData);
        }
      });

      return leaguesWithNoInvites;
    } catch (error) {
      console.error('Error getting data:', error);
      return [];
    }
  };

  public getLeaguesNotFilled = async (season: number) => {
    try {
      const leagueQuery = query(
        this._DBLeaguesCollection,
        where('season', '==', season),
      );
      const leagueSnapshotList = await getDocs(leagueQuery);

      const leaguesNotFilled: iLeagueDb[] = [];

      // Use a for...of loop to handle asynchronous operations
      for (const league of leagueSnapshotList.docs) {
        const leagueData = league.data() as iLeagueDb;
        const leagueSettingsRef = doc(
          this._DBLeagueSettingsCollection,
          leagueData.id,
        );
        const leagueSettingsSnapshot = await getDoc(leagueSettingsRef);
        const leagueSettings = leagueSettingsSnapshot.data() as iLeagueSettings;

        if (
          leagueSettings?.numOfMembers &&
          leagueData.memberIds.length < leagueSettings.numOfMembers &&
          leagueData.memberIds.length > 1
        ) {
          leaguesNotFilled.push(leagueData);
        }
      }

      return leaguesNotFilled;
    } catch (error) {
      console.error('Error getting data:', error);
      return [];
    }
  };

  public getLeaguesFilledNotDrafted = async (season: number) => {
    try {
      const leagueQuery = query(
        this._DBLeaguesCollection,
        where('season', '==', season),
        where('draftStatus', '==', leagueDraftStatus.pending),
      );
      const leagueSnapshotList = await getDocs(leagueQuery);

      const leaguesFilledNotDrafted: iLeagueDb[] = [];
      for (const league of leagueSnapshotList.docs) {
        const leagueData = league.data() as iLeagueDb;
        const leagueSettingsRef = doc(
          this._DBLeagueSettingsCollection,
          leagueData.id,
        );
        const leagueSettingsSnapshot = await getDoc(leagueSettingsRef);
        const leagueSettings = leagueSettingsSnapshot.data() as iLeagueSettings;

        if (
          leagueSettings?.numOfMembers &&
          leagueData.memberIds.length === leagueSettings.numOfMembers
        ) {
          leaguesFilledNotDrafted.push(leagueData);
        }
      }

      return leaguesFilledNotDrafted;
    } catch (error) {
      console.error('Error getting data:', error);
      return [];
    }
  };

  public getLeaguesDraftCompleted = async (season: number) => {
    try {
      const leagueQuery = query(
        this._DBLeaguesCollection,
        where('season', '==', season),
        where('draftStatus', '==', leagueDraftStatus.done),
      );
      const leagueSnapshotList = await getDocs(leagueQuery);

      const leaguesDraftCompleted: iLeagueDb[] = [];
      leagueSnapshotList.docs.forEach(async (league) => {
        const leagueData = league.data() as iLeagueDb;
        if (!leagueData.internalLeague) {
          leaguesDraftCompleted.push(leagueData);
        }
      });

      return leaguesDraftCompleted;
    } catch (error) {
      console.error('Error getting data:', error);
      return [];
    }
  };

  public getAllSeasonRequests = async () => {
    try {
      const date = new Date('2023-05-01');
      const timestamp = Timestamp.fromDate(date);

      const requestsQuery = query(
        this._DBLeagueRequestsCollection,
        where('createdDate', '>', timestamp),
      );

      const requestSnapshot = await getDocs(requestsQuery);
      const allRequests: any[] = [];
      requestSnapshot.forEach((doc) => {
        if (doc.exists()) {
          allRequests.push(doc.data());
        }
      });
      const transfers: iTransferPortalRequest[] = [];
      const trades: iTradeRequest[] = [];
      allRequests.forEach((request) => {
        if (request.type === RequestType.trade) {
          trades.push(request as iTradeRequest);
        } else {
          transfers.push(request as iTransferPortalRequest);
        }
      });
      return {
        trades: trades,
        transfers: transfers,
      };
    } catch (error) {
      console.log('Trouble getting requests data:', error);
      return null;
    }
  };

  public getAllSeasonChats = async () => {
    const date = new Date('2023-05-01');
    const timestamp = Timestamp.fromDate(date);

    try {
      const chatQuery = query(
        this._DBChatsCollection,
        where('timestamp', '>', timestamp),
      );
      const chatSnapshot = await getDocs(chatQuery);
      const allChats: iChatMessage[] = [];
      chatSnapshot.forEach((doc) => {
        if (doc.exists()) {
          allChats.push(doc.data() as iChatMessage);
        }
      });
      return allChats;
    } catch (error) {
      console.log('Trouble getting chat data:', error);
      return null;
    }
  };

  public getAllChatsForUser = async (userId: string) => {
    const date = new Date('2023-05-01');
    const timestamp = Timestamp.fromDate(date);

    try {
      const chatQuery = query(
        this._DBChatsCollection,
        where('timestamp', '>', timestamp),
        where('userId', '==', userId),
      );
      const chatSnapshot = await getDocs(chatQuery);
      const allChats: iChatMessage[] = [];
      chatSnapshot.forEach((doc) => {
        if (doc.exists()) {
          allChats.push(doc.data() as iChatMessage);
        }
      });
      return allChats;
    } catch (error) {
      console.log("Trouble getting user's chat data:", error);
      return null;
    }
  };

  public getUsersLeagues = async (
    userId: string,
    season: number,
  ): Promise<iLeagueDb[] | null> => {
    const leagueByMemberQuery = query(
      this._DBLeaguesCollection,
      where('memberIds', 'array-contains', userId),
      where('season', '==', season),
    );

    const leaguesSnapshotList = await getDocs(leagueByMemberQuery);

    if (leaguesSnapshotList.empty) {
      return null;
    }

    const leaguesToPopulate: iLeagueDb[] = [];
    for (let i = 0; i < leaguesSnapshotList.size; i++) {
      const doc = leaguesSnapshotList.docs[i];
      if (doc.exists()) {
        const leagueItem = doc.data() as iLeagueDb;

        leaguesToPopulate.push(leagueItem);
      }
    }

    return leaguesToPopulate;
  };
}
