import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/auth-provider';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LineButton from '../../../components/ui/line-button/line-button';
import { FirebaseError } from 'firebase/app';
import ConfirmPasswordInput from '../../../components/ui/password-input/confirm-password-input';
import Loading from '../../../components/ui/loading-animation';
import AuthWrapper from '../../../components/ui/auth-wrapper/auth-wrapper';
import { Button } from '@mui/material';

const ResetPassword = () => {
  const {
    isLoggedIn,
    currentUser,
    verifyResetPasswordCode,
    resetPassword,
    loginWithEmailAndPasssword,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string | undefined>();
  const [codeError, setCodeError] = useState<FirebaseError | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const navigateToCorrectPage = async () => {
    if (isLoggedIn) {
      navigate('/home');
    }
  };

  const resetWithNewPassword = async () => {
    const code = searchParams.get('oobCode');
    if (code && password) {
      await resetPassword(code, password);
      email && (await loginWithEmailAndPasssword(email, password));
      navigate('/home');
    }
  };

  const checkCodeValid = async () => {
    const code = searchParams.get('oobCode');
    if (code) {
      try {
        const vEmail = await verifyResetPasswordCode(code);
        setEmail(vEmail);
      } catch (error) {
        setCodeError(error as FirebaseError);
      }
    }
  };

  const handleResetPassword = (passwordMatch: boolean, password?: string) => {
    if (passwordMatch) {
      setPassword(password);
    } else {
      setPassword(undefined);
    }
  };

  useEffect(() => {
    checkCodeValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    navigateToCorrectPage();
  }, [isLoggedIn, currentUser]);

  return resetPasswordLoading ? (
    <Loading></Loading>
  ) : (
    <AuthWrapper>
      <h2>Reset Password</h2>
      {codeError && (
        <p className="mx-4">
          Invalid code please try again from
          <Link to="/forgot-password"> reset password link</Link>.
        </p>
      )}
      {email && <p className="mx-4">Enter new password for {email}</p>}
      <form
        className="flex flex-col justify-between flex-1"
        onSubmit={(event) => {
          event.preventDefault();
          password && setResetPasswordLoading(true);
        }}
      >
        {email && (
          <>
            <div>
              <ConfirmPasswordInput
                firstPlaceholderText="Enter new password"
                secondPlaceholderText="Confirm password"
                doPasswordsMatch={handleResetPassword}
              />
            </div>
            <div className="flex justify-between mt-4">
              <Button
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                CANCEL
              </Button>
              <Button
                disabled={!password}
                variant="outlined"
                onClick={() => {
                  setResetPasswordLoading(true);
                  resetWithNewPassword();
                }}
              >
                UPDATE
              </Button>
            </div>
          </>
        )}
      </form>
    </AuthWrapper>
  );
};

export default ResetPassword;
