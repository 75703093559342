import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../components/ui/button/button';
import { UpcomingGame } from '../../../../components/ui/UpcomingGame/upcoming-game';
import { iTeam } from '@shared/shared-utils/models';

type FreeAgentTransferPropType = {
  setSlotType: (slot: string) => void;
  freeAgentByTeam: iTeam | undefined;
  freeAgentToTeam: iTeam | undefined;
  onTransferRequestClick: () => void;
};

export const FreeAgentTransfer = ({
  setSlotType,
  freeAgentByTeam,
  freeAgentToTeam,
  onTransferRequestClick,
}: FreeAgentTransferPropType) => {
  return (
    <div className="flex flex-col justify-between flex-1 mt-4">
      <div>
        <div className="my-2 text-sm">
          Transfer one of your teams with a team in the portal.
        </div>
        <div
          className="flex justify-between border-b-[1px] border-b-gray p-4 cursor-pointer"
          onClick={() => {
            setSlotType('freeAgentByTeam');
          }}
          data-e2e="freeAgentByTeam"
        >
          {freeAgentByTeam && (
            <div className="flex items-center justify-between">
              <div
                style={{
                  backgroundImage: `url(${freeAgentByTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={freeAgentByTeam.school}
              />
              <div className="flex flex-col">
                <p className="text-base">{freeAgentByTeam.school}</p>
                <div>
                  <UpcomingGame
                    teamID={freeAgentByTeam.id}
                    game={freeAgentByTeam.nextGame}
                  />
                </div>
              </div>
            </div>
          )}
          {!freeAgentByTeam && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-base">Team you want to transfer</p>
              </div>
            </div>
          )}
          <FontAwesomeIcon icon={faChevronDown} className="text-white fa-lg" />
        </div>
        <div
          className="flex justify-between border-b-[1px] border-b-gray p-4 cursor-pointer mt-4"
          onClick={() => {
            setSlotType('freeAgentToTeam');
          }}
          data-e2e="freeAgentToTeam"
        >
          {freeAgentToTeam && (
            <div className="flex items-center justify-between">
              <div
                style={{
                  backgroundImage: `url(${freeAgentToTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
                title={freeAgentToTeam.school}
              />
              <div className="flex flex-col">
                <p className="text-base">{freeAgentToTeam.school}</p>
                <div>
                  <UpcomingGame
                    teamID={freeAgentToTeam.id}
                    game={freeAgentToTeam.nextGame}
                  />
                </div>
              </div>
            </div>
          )}
          {!freeAgentToTeam && (
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-base">Team you want to add</p>
              </div>
            </div>
          )}
          <FontAwesomeIcon icon={faChevronDown} className="text-white fa-lg" />
        </div>
      </div>
      <div className="flex justify-end mt-12">
        <Button
          disabled={!freeAgentByTeam || !freeAgentToTeam}
          label={'Transfer'}
          onClick={() => {
            onTransferRequestClick();
          }}
          data-e2e="submit_transfer_btn"
        />
      </div>
    </div>
  );
};
