// This type is what is on the actual user type
export interface iUserTrophiesDB {
  [trophyType: string]: {
    [season: number]: iUserTrophy;
    totalProgress?: number;
  };
}

// This type is used for the UI only and is built in the trophy provider.
export interface iUserTrophiesUI {
  [trophyType: string]: iUserTrophy;
}

export interface iUserTrophy {
  trophyType: string;
  awardDate: Date;
  progress?: number;  // Number of trophies user has earned
}

// The type for setting up trophies and icons
export interface iTrophyConst {
  trophyType: string;
  title: string;
  description: string;
  path?: string;
  progress?: {
    [key: number]: {
      path: string;
    };
  };
  pending?: boolean;
  manualTrophyNotification?: boolean;
  archived?: boolean;
}

// The constant type above is built into this for the UI
export interface iTrophy {
  trophyType: string;
  title: string;
  description: string;
  path: string;
  pending?: boolean;
}

export const allTrophies: { [trophyType: string]: iTrophyConst; } = {
  adminSB: {
    trophyType: 'adminSB',
    title: 'SB Admin',
    description: "We're special and we have a badge to prove it!",
    path: '/assets/trophies/SB-Admin.png',
  },
  leagueWinner2023: {
    trophyType: 'leagueWinner2023',
    title: '2023 League Champion',
    description: 'Awarded for league domination!',
    path: '/assets/trophies/league-winners/2023/2023-league-winner.png',
    archived: true
  },
  leagueWinner2024: {
    trophyType: 'leagueWinner2024',
    title: '2024 League Champion',
    description: 'Awarded for league domination!',
    path: '/assets/trophies/league-winners/2024/2024-league-first.png',
  },
  leagueSecond2023: {
    trophyType: 'leagueSecond2023',
    title: '2023 League Champion Second Place',
    description: "If you ain't first, you're last. - Ricky Bobby, Inc.",
    path: '/assets/trophies/league-winners/2023/2023-league-second.png',
    archived: true
  },
  leagueSecond2024: {
    trophyType: 'leagueSecond2024',
    title: '2024 League Champion Second Place',
    description: "If you ain't first, you're last. - Ricky Bobby, Inc.",
    path: '/assets/trophies/league-winners/2024/2024-league-second.png',
  },
  leagueThird2023: {
    trophyType: 'leagueThird2023',
    title: '2023 League Champion Third Place',
    description: "Don't get too excited. This is essentially a participation trophy...",
    path: '/assets/trophies/league-winners/2023/2023-league-third.png',
    archived: true
  },
  leagueThird2024: {
    trophyType: 'leagueThird2024',
    title: '2024 League Champion Third Place',
    description: "Don't get too excited. This is essentially a participation trophy...",
    path: '/assets/trophies/league-winners/2024/2024-league-third.png',
  },
  allConferencePatreon1: {
    trophyType: 'allConferencePatreon1',
    title: 'All Conference Team',
    description: 'Awarded for being a tier 1 patron!',
    path: '/assets/trophies/patreon/All-Conference-Patreon-Tier-1.png',
    manualTrophyNotification: true
  },
  allAmericanPatreon2: {
    trophyType: 'allAmericanPatreon2',
    title: 'All American Team',
    description: 'Awarded for being a tier 2 patron!',
    path: '/assets/trophies/patreon/All-American-Patreon-Tier-2.png',
    manualTrophyNotification: true
  },
  joinedRandomLeague: {
    trophyType: 'joinedRandomLeague',
    title: 'No friends of my own...',
    description: 'Awarded for being brave enough to challenge random strangers and joining a random league! Keep it up and watch this trophy upgrade!',
    progress: {
      50: {
        path: '/assets/trophies/random-league/Random-League-gold.png'
      },
      25: {
        path: '/assets/trophies/random-league/Random-League-silver.png'
      },
      10: {
        path: '/assets/trophies/random-league/Random-League-bronze.png'
      },
      1: {
        path: '/assets/trophies/random-league/Random-League-color.png'
      }
    }
  },
  giveawayWinner: {
    trophyType: 'giveawayWinner',
    title: "Luck o' the Irish",
    description: 'Awarded for winning a giveaway!',
    path: '/assets/trophies/Giveaway-Winner.png',
    manualTrophyNotification: true
  },
  matchupBlowout: {
    trophyType: 'matchupBlowout',
    title: "It's a blowout!",
    description: 'Awarded for getting beat by at least 75 points!',
    path: '/assets/trophies/Matchup-Blowout.png',
  },
  matchupCupcakes: {
    trophyType: 'matchupCupcakes',
    title: "Matchup Cupcake",
    description: 'Awarded for winning a matchup by at least 75 points! Keep it up and watch this trophy upgrade!',
    progress: {
      50: {
        path: '/assets/trophies/cupcakes/Matchup-Cupcake-gold.png'
      },
      25: {
        path: '/assets/trophies/cupcakes/Matchup-Cupcake-silver.png'
      },
      10: {
        path: '/assets/trophies/cupcakes/Matchup-Cupcake-bronze.png'
      },
      1: {
        path: '/assets/trophies/cupcakes/Matchup-Cupcake-color.png'
      }
    },
  },
  gameChanger: {
    trophyType: 'gameChanger',
    title: 'Game Changer',
    description: 'Awarded for suggesting awesome features for Squad Blitz! Your ideas have literally changed the game!',
    path: '/assets/trophies/Game-Changer.png',
    manualTrophyNotification: true
  },
  flagOnThePlay: {
    trophyType: 'flagOnThePlay',
    title: 'Flag on the Play',
    description: 'Awarded to the first user to report a bug. Thanks for keeping an eye on the field and helping us maintain fair play!',
    path: '/assets/trophies/Flag-on-the-Play.png',
    manualTrophyNotification: true
  },
  publicHero: {
    trophyType: 'publicHero',
    title: 'Public Hero',
    description: 'Awarded for creating a public league and completing a draft. Saving the day, one league at a time!',
    progress: {
      50: {
        path: '/assets/trophies/public-hero/Public-Hero-gold.png'
      },
      25: {
        path: '/assets/trophies/public-hero/Public-Hero-silver.png'
      },
      10: {
        path: '/assets/trophies/public-hero/Public-Hero-bronze.png'
      },
      1: {
        path: '/assets/trophies/public-hero/Public-Hero-color.png'
      }
    },
  }
};
