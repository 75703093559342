/**
 * Gets number of seconds until next draft pick
 * @param draftNextPickBy iDraft.nextPickBy
 * @returns secondsLeft as number
 */
export const secondsBetweenPicks = (draftNextPickBy: any) => {
  const secondsLeft = Math.round(
    (new Date(draftNextPickBy.toDate()).getTime() - new Date().getTime()) / 1000
  );

  return secondsLeft;
};

/**
 * Determines what the timer value should be based on the number of seconds
 * @param secondsLeft Seconds until next draft pick
 * @returns The value to display as a string
 */
export const draftDisplayTimer = (secondsLeft: number) => {
  if (secondsLeft > 60) {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft - minutes * 60;
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  } else if (secondsLeft <= 0) {
    return '0:00';
  } else {
    return `0:${secondsLeft < 10 ? '0' + secondsLeft : secondsLeft}`;
  }
};
