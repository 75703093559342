import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, LinearProgress } from '@mui/material';
import { useContext, useState } from 'react';
import { iTradeRequest, RequestType } from '@shared/shared-utils/models';
import { AuthContext } from '../../../providers/auth-provider';
import LineButton from '../../ui/line-button/line-button';
import ModalWrapper from '../modal-wrapper/modal-wrapper';
import './request-details.scss';

type RequestDetailsPropTypes = {
  request: iTradeRequest;
  onCloseClick: () => void;
  onAcceptClick: () => void;
  onRejectClick: () => void;
  onCancelRequestClick: () => void;
};

export const RequestDetails = ({
  request,
  onCloseClick,
  onAcceptClick,
  onRejectClick,
  onCancelRequestClick,
}: RequestDetailsPropTypes) => {
  const { userDetails } = useContext(AuthContext);

  const [requestSent, setRequestSent] = useState<boolean>(false);

  return (
    <ModalWrapper
      title={
        request.type === RequestType.trade
          ? 'Trade request'
          : 'Free Agent request'
      }
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={() => {
        if (onCloseClick) onCloseClick();
      }}
    >
      <div
        className="p-4 bg-db-header-background-color"
        data-e2e="request_details_modal"
        key={request.id}
      >
        <div className="text-lg">{request.leagueTitle}</div>
        <div className="relative flex items-center justify-between mt-4">
          <div className="flex flex-col items-center justify-between min-w-[48%] h-[240px] border-primary border-2 rounded-xl overflow-hidden">
            <div className="flex flex-col items-center justify-center py-8">
              <div
                key={request.tradedTeam.id}
                style={{
                  backgroundImage: `url(${request.tradedTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary mb-2 rounded-full h-16 w-16 min-h-[2rem] team-image"
                title={request.tradedTeam.school}
                data-e2e={`req_det_drop_team_${request.tradedTeam.id}`}
              />
              <div className="mt-2 text-center">
                {request.tradedTeam.school}
              </div>
            </div>
            <div className="flex items-center justify-start min-w-full p-2 text-black bg-primary">
              <div
                key={request.requesterDetails.id}
                style={{
                  backgroundImage: `url(${request.requesterDetails.profileImage})`,
                }}
                className="bg-no-repeat bg-cover bg-primary mr-2 rounded-full h-12 w-12 min-h-[2rem]"
                title={request.requesterDetails.fullName}
                data-e2e={`requestor_${request.requesterDetails.id}`}
              />
              <div className="pl-1 font-semibold">
                {request.requesterDetails.fullName}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between items-center min-w-[48%] h-[240px] border-primary border-2 rounded-xl overflow-hidden">
            <div className="flex flex-col items-center justify-center py-8">
              <div
                key={request.requestedTeam.id}
                style={{
                  backgroundImage: `url(${request.requestedTeam.logos[0].replace(
                    /http:/g,
                    'https:',
                  )})`,
                }}
                className="bg-no-repeat bg-cover bg-primary mb-2 rounded-full h-16 w-16 min-h-[2rem] team-image"
                title={request.requestedTeam.school}
                data-e2e={`req_det_add_team_${request.requestedTeam.id}`}
              />
              <div className="mt-2 text-center">
                {request.requestedTeam.school}
              </div>
            </div>
            {request.type === RequestType.trade &&
              request.requestedTeamOwner && (
                <div className="flex items-center justify-start min-w-full p-2 text-black bg-primary">
                  <div
                    key={request.requestedTeamOwner.id}
                    style={{
                      backgroundImage: `url(${request.requestedTeamOwner.profileImage})`,
                    }}
                    className="bg-no-repeat bg-cover bg-primary mr-2 rounded-full h-12 w-12 min-h-[2rem]"
                    title={request.requestedTeamOwner.fullName}
                    data-e2e={`requestee_${request.requestedTeamOwner.id}`}
                  />
                  {request.requestedTeamOwner.fullName}
                </div>
              )}
          </div>
          <div className="absolute left-0 right-0 flex items-center justify-center w-16 h-16 mx-auto rounded-full ite bg-primary">
            <FontAwesomeIcon
              icon={faArrowsRotate}
              className="text-3xl text-black"
            />
          </div>
        </div>
        <div className="my-4 text-right">
          {request.requesterDetails.id !== userDetails?.id && (
            <>
              <LineButton
                label="Reject"
                onClick={() => {
                  onRejectClick();
                }}
                disabled={requestSent}
                data-e2e="reject_trade_btn"
              />
              <Button
                onClick={() => {
                  setRequestSent(true);
                  onAcceptClick();
                }}
                variant="contained"
                disabled={requestSent}
                data-e2e="accept_trade_btn"
              >
                Accept
              </Button>
            </>
          )}
          {request.requesterDetails.id === userDetails?.id && (
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                onCancelRequestClick();
              }}
            >
              Cancel Request
            </Button>
          )}
        </div>
        {requestSent && <LinearProgress />}
      </div>
    </ModalWrapper>
  );
};
