import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Splash() {
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: Need to remove this
    setTimeout(function () {
      navigate('login');
    }, 0);
  }, [navigate]);

  return (
    <div
      id="db-splash"
      className="flex items-center justify-center football-bg splash-page"
    >
      <img
        src="/assets/images/logo.png"
        className="h-64"
        alt="Squad Blitz Logo"
        onClick={() => {
          navigate('login');
        }}
      />
    </div>
  );
}

export default Splash;
