import { SearchContextManager } from '@giphy/react-components';

type GifSearchProps = {
  children: any;
  defaultSearchTerm?: string;
};

const GiphySearchContextManager = (props: GifSearchProps) => {
  const apiKey = 'o0pHw5LLYcbL7CbfG0fuyBE6nfndQQew';

  return (
    <SearchContextManager
      apiKey={apiKey}
      theme={{
        darkMode: true,
        hideCancelButton: true,
      }}
      shouldFetchChannels={false}
      initialTerm={props.defaultSearchTerm ?? 'college football'}
      shouldDefaultToTrending={false}
      options={{ rating: 'g' }}
    >
      {props.children}
    </SearchContextManager>
  );
};

export default GiphySearchContextManager;
