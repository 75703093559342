import { Button, IconButton, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { iUser } from '@shared/shared-utils/models';

type AddMembersProps = {
  currentMembers: string[];
  creator: iUser;
  updateLeagueMembers: (members: string[]) => void;
};

const AddMembers = (props: AddMembersProps) => {
  const [leagueMembers, setLeagueMembers] = useState<string[]>(
    props.currentMembers.filter((member) => member !== props.creator.email),
  );
  const [newestLeagueMember, setNewestLeagueMember] = useState('');

  const handleLeagueMemberChange = (index: number, email: string) => {
    const updatedMembers = [...leagueMembers];
    updatedMembers[index] = email;
    setLeagueMembers(updatedMembers);
  };

  const addLeagueMember = () => {
    if (newestLeagueMember.trim() !== '') {
      const updatedMembers = [...leagueMembers, newestLeagueMember.trim()];
      setLeagueMembers(updatedMembers);
      setNewestLeagueMember('');
      props.updateLeagueMembers(updatedMembers);
    }
  };

  const deleteLeagueMember = (index: number) => {
    const updatedMembers = leagueMembers.filter((_, i) => i !== index);
    setLeagueMembers(updatedMembers);
    props.updateLeagueMembers(updatedMembers);
  };

  return (
    <div className="space-y-4">
      <TextField
        fullWidth
        value={props.creator.email}
        label="League Creator Email"
        disabled
      />

      {leagueMembers.map((member, index) => (
        <div key={index} className="flex items-center space-x-2">
          <TextField
            fullWidth
            value={member}
            label="League Member Email"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleLeagueMemberChange(index, event.target.value)
            }
            required
            placeholder="Enter New League Member Email"
          />
          <IconButton onClick={() => deleteLeagueMember(index)} color="error">
            <Delete />
          </IconButton>
        </div>
      ))}

      <div className="flex space-x-2">
        <TextField
          fullWidth
          value={newestLeagueMember}
          label="New Member Email"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNewestLeagueMember(event.target.value)
          }
          required
          placeholder="Enter New League Member Email"
        />
        <Button onClick={addLeagueMember} variant="contained">
          Add Member
        </Button>
      </div>
    </div>
  );
};

export default AddMembers;
