import {
  iLeague,
  iMessage,
  iUser,
  leagueDraftStatus,
} from '@shared/shared-utils/models';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../../../providers/auth-provider';
import { ChatService } from '../../../../../services/chat-service';
import Loading from '../../../../../components/ui/loading-animation';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { DBFirebaseDB } from '../../../../../services/firebase-core';
import { DBCollections } from '@shared/shared-utils';
import { NotificationService } from '../../../../../services/notification-service';
import Image from '../../../../../components/widgets/image';
import { LeagueContext } from '../../../../../providers/league-provider';
import { UserService } from '../../../../../services/user-service';
import ChatInput from './chat-input';
import ChatList from './chat-list';

type LeagueChatType = {
  league: iLeague;
};

const LeagueChat = (props: LeagueChatType) => {
  const notificationService = new NotificationService();
  const userService = new UserService();
  const { updateLeague } = useContext(LeagueContext);
  const { userDetails, setUserDetails } = useContext(AuthContext);
  const [chatMessages, setChatMessages] = useState(Array<iMessage>);
  const chatService = new ChatService();
  const [lastMessageTime, setLastMessageTime] = useState<Date>(new Date());
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const memberImages = props.league.members.map((member, index) => {
    return {
      memberId: member.id,
      memberImage: (
        <Image
          key={`${member?.id}`}
          imageUrl={member?.profileImage}
          title={member?.fullName}
          size={12}
          specialClass="mr-2"
        />
      ),
    };
  });

  useEffect(() => {
    if (props.league) {
      setLoading(true);
      getChatData();
    }
  }, [chatMessages]);

  const getChatData = async () => {
    await getMessage(10, false);
    await listenToChatChanges(props.league.id);
    setLoading(false);
  };

  const listenToChatChanges = async (leagueId: string) => {
    const colRef = query(
      collection(DBFirebaseDB, DBCollections.leagueChats),
      where('roomId', '==', leagueId),
      orderBy('timestamp', 'desc'),
      limit(1),
    );

    onSnapshot(colRef, (snapshot) => {
      snapshot.forEach(async (doc) => {
        let groupChatMessages = chatMessages;
        let details = doc.data();
        if (groupChatMessages.length > 0) {
          if (
            groupChatMessages[groupChatMessages.length - 1]['timestamp'] ===
              details['timestamp']['seconds'] &&
            groupChatMessages[groupChatMessages.length - 1]['userId'] ===
              details['userId']
          ) {
          } else {
            // await getMessage(1, true);
            updateChatMessagesState(
              [details],
              chatMessages,
              setLastMessageTime,
              setChatMessages,
              setShowLoadMore,
            );
          }
        } else {
          await getMessage(1, true);
        }
      });
    });
  };

  // Function to fetch messages from the server
  const fetchMessages = async (
    leagueId: string,
    lastMessageTimestamp: Date,
    limit: number,
  ): Promise<any[]> => {
    const dataObj = await chatService.getGroupChats(
      leagueId,
      lastMessageTimestamp,
      limit,
    );
    console.log('fetchMessages', dataObj);
    return dataObj;
  };

  // Function to update the chat messages state
  const updateChatMessagesState = (
    newMessages: any[],
    existingMessages: iMessage[],
    setLastMessageTime: (timestamp: any) => void,
    setChatMessages: (messages: iMessage[]) => void,
    setShowLoadMore: (show: boolean) => void,
  ) => {
    const sortedData = newMessages.sort(
      (a, b) => a.timestamp.seconds.valueOf() - b.timestamp.seconds.valueOf(),
    );

    if (sortedData.length < 10) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }

    sortedData.forEach((details, index) => {
      if (index === 0) {
        setLastMessageTime(details.timestamp);
      }
      existingMessages.push({
        message: details.message,
        datetime:
          new Date(details.timestamp.seconds * 1000).toDateString() +
          ' ' +
          new Date(details.timestamp.seconds * 1000).toLocaleTimeString(),
        timestamp: details.timestamp.seconds,
        userId: details.userId,
        userName: details.userName,
        userProfile: details.profileImage,
      });
    });

    const sortedChatData = existingMessages.sort(
      (a, b) => a.timestamp.valueOf() - b.timestamp.valueOf(),
    );

    setChatMessages(sortedChatData);
  };

  // Function to update the user profile with the latest message history
  const updateUserProfileWithMessageHistory = async (
    userDetails: iUser | null,
    leagueId: string,
    userService: UserService,
    setUserDetails: (userDetails: iUser) => void,
  ) => {
    const now = new Date();
    if (userDetails) {
      const newUserDetails: iUser = {
        ...userDetails,
        messageHistory: {
          ...userDetails.messageHistory,
          [leagueId]: now,
        },
      };
      await userService.updateUserProfile(newUserDetails, userDetails.id);
      setUserDetails(newUserDetails);
    }
  };

  // Main function that coordinates the fetching, processing, and updating
  const getMessage = async (limit: number, newDate: boolean) => {
    let lastMessageTimestamp = new Date();
    if (!newDate) {
      lastMessageTimestamp = lastMessageTime;
    }

    const newMessages = await fetchMessages(
      props.league.id,
      lastMessageTimestamp,
      limit,
    );
    updateChatMessagesState(
      newMessages,
      chatMessages,
      setLastMessageTime,
      setChatMessages,
      setShowLoadMore,
    );
    await updateUserProfileWithMessageHistory(
      userDetails,
      props.league.id,
      userService,
      setUserDetails,
    );
  };

  const sendMessage = async (message: string, isGif?: boolean) => {
    if (message !== '') {
      if (!message.replace(/\s/g, '').length) {
      } else {
        chatService.sendMessage(
          props.league.id,
          props.league.title,
          userDetails?.id as string,
          userDetails?.fullName as string,
          userDetails?.profileImage as string,
          message as string,
        );
        const now = new Date();
        await updateLeague({ ...props.league, lastMessageSent: now });

        if (userDetails)
          await sendNotificationToLeagueMembers(
            isGif ? 'Picture' : message,
            userDetails.fullName,
          );
      }
    }
  };

  const loadMoreMessages = async () => {
    getMessage(10, false);
  };

  const sendNotificationToLeagueMembers = async (
    message: string,
    fullName: string,
  ) => {
    try {
      if (props.league.memberIds) {
        const playerIds: string[] = props.league.members
          .filter((member) => member.playerId)
          .filter((member) => member.id !== userDetails?.id)
          .map((member) => member.playerId) as string[];

        const redirectUrl =
          props.league.draftStatus === leagueDraftStatus.done
            ? `/redirect?to=league/${props.league.id}?tab=chat`
            : `/redirect?to=draft/${props.league.id}?tab=chat`;

        if (playerIds.length) {
          await notificationService.sendChatNotification(
            playerIds,
            props.league.title,
            message,
            fullName,
            redirectUrl,
          );
        }
      }
    } catch (error) {
      console.log('Chat notification error: ', error);
    }
  };

  return loading || !chatMessages ? (
    <Loading></Loading>
  ) : (
    <div
      className="w-full px-5 flex flex-col justify-between"
      data-e2e="chat_container"
    >
      {userDetails && (
        <ChatList
          loadMoreMessages={showLoadMore}
          onLoadMoreClick={loadMoreMessages}
          chatMessages={chatMessages}
          currentUserDetails={userDetails}
          memberImages={memberImages}
        />
      )}
      <ChatInput sendNewMessage={sendMessage} />
    </div>
  );
};

export default LeagueChat;
