import Skeleton from '@mui/material/Skeleton';

const HeaderSkeleton = () => {
  return (
    <div className="flex justify-between w-full mb-3">
      <div className="flex flex-col items-center justify-center">
        <div className="w-14 p-1 border-[1px] rounded-full border-gray h-14">
          <Skeleton variant="circular" width={46} height={46} />
        </div>
        <span className="p-1 text-xs">profile</span>
      </div>
      <div className="flex">
        {
          <div className="flex flex-col items-center justify-center">
            <div className="p-1 w-14 ">
              <Skeleton variant="circular" width={46} height={46} />
            </div>
            <span className="p-1 text-xs">notifications</span>
          </div>
        }
      </div>
    </div>
  );
};

const ActivitySkeleton = () => {
  return (
    <div className="my-4">
      <Skeleton
        variant="rounded"
        width="100%"
        height={180}
        style={{ borderRadius: 14 }}
      />
    </div>
  );
};

const ResultsSkeleton = () => {
  return (
    <div className="flex flex-col w-215 h-300 rounded-2xl bg-db-header-background-color mr-4">
      <Skeleton
        variant="rounded"
        width={300}
        height={60}
        style={{ borderRadius: 14 }}
      />
      <div className="flex justify-between py-2">
        <div className="flex">
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton
            animation="wave"
            height={20}
            width={80}
            style={{ marginLeft: 5, marginBottom: 2 }}
          />
        </div>
        <Skeleton
          animation="wave"
          height={20}
          width={30}
          style={{ marginRight: 10 }}
        />
      </div>
      <div className="flex justify-between py-2">
        <div className="flex">
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton
            animation="wave"
            height={20}
            width={80}
            style={{ marginLeft: 5, marginBottom: 2 }}
          />
        </div>
        <Skeleton
          animation="wave"
          height={20}
          width={30}
          style={{ marginRight: 10 }}
        />
      </div>
    </div>
  );
};

const LeagueSkeleton = () => {
  return (
    <div className="flex flex-col w-255 h-380 rounded-2xl bg-db-header-background-color mr-4">
      <Skeleton
        variant="rounded"
        width={255}
        height={255}
        style={{ borderRadius: 14 }}
      />
      <div className="flex flex-col p-2 w-255">
        <Skeleton
          animation="wave"
          height={30}
          width="80%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={20}
          width="20%"
          style={{ marginBottom: 2 }}
        />
        <Skeleton
          animation="wave"
          height={20}
          width="20%"
          style={{ marginBottom: 2 }}
        />
        <div className="flex">
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="circular" width={30} height={30} />
        </div>
      </div>
    </div>
  );
};

export const HomeLoadingSkeleton = () => {
  return (
    <div>
      <div className="p-4 pb-0 bg-db-header-background-color fixed w-full max-w-[560px] top-0 left-0 right-0 m-auto z-50">
        <HeaderSkeleton />
      </div>
      <ActivitySkeleton />
      <h4 className="m-4">Leagues</h4>
      <div className="flex px-4">
        <LeagueSkeleton />
        <LeagueSkeleton />
      </div>
      <h4 className="m-4">Results</h4>
      <div className="flex  w-full overflow-x-scroll px-4">
        <ResultsSkeleton />
        <ResultsSkeleton />
      </div>
    </div>
  );
};
