import { useEffect, useState } from 'react';

type CountdownTimerProps = {
  futureDate: Date;
  isLeagueOwner: boolean;
};

const DraftCountdownTimer = (props: CountdownTimerProps) => {
  const [timeObject, setTimeObject] = useState({
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timeExpired, setTimeExpired] = useState(false);

  const calculateTimeRemaining = () => {
    const currentDate = new Date();
    const totalSecondsRemaining =
      Math.floor(props.futureDate.getTime() / 1000) -
      Math.floor(currentDate.getTime() / 1000);

    const weeks = Math.floor(totalSecondsRemaining / (60 * 60 * 24 * 7));
    const days = Math.floor(totalSecondsRemaining / (60 * 60 * 24)) % 7;
    const hours = Math.floor(totalSecondsRemaining / (60 * 60)) % 24;
    const minutes = Math.floor(totalSecondsRemaining / 60) % 60;
    const seconds = Math.floor(totalSecondsRemaining) % 60;

    setTimeObject({
      weeks,
      days,
      hours,
      minutes,
      seconds,
    });

    setTimeExpired(totalSecondsRemaining <= 0);
  };

  useEffect(() => {
    const timerId = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const { weeks, days, hours, minutes, seconds } = timeObject;

  return (
    <div className="flex flex-col items-center w-full pt-2">
      <h3><strong>COUNTDOWN TO THE DRAFT</strong></h3>
      <div className="flex flex-row justify-between mt-2">
        <div className="flex flex-col items-center px-2.5">
          <p className="text-2xl">{weeks > 0 ? weeks : '00'}</p>
          <p className="text-xs">WEEKS</p>
        </div>
        <div className="flex flex-col items-center px-2.5">
          <p className="text-2xl">{days > 0 ? days : '00'}</p>
          <p className="text-xs">DAYS</p>
        </div>
        <div className="flex flex-col items-center px-2.5">
          <p className="text-2xl">{hours > 0 ? hours : '00'}</p>
          <p className="text-xs">HOURS</p>
        </div>
        <div className="flex flex-col items-center px-2.5">
          <p className="text-2xl">{minutes > 0 ? minutes : '00'}</p>
          <p className="text-xs">MINUTES</p>
        </div>
        <div className="flex flex-col items-center px-2.5">
          <p className="text-2xl">{seconds > 0 ? seconds : '00'}</p>
          <p className="text-xs">SECONDS</p>
        </div>
      </div>
      {timeExpired &&
        (props.isLeagueOwner ? (
          <p className="pt-4">Everyone is waiting on YOU to start the draft!</p>
        ) : (
          <p className="pl-4 pr-4 pt-4">
            Waiting for your league owner to start the draft!
          </p>
        ))}
    </div>
  );
};

export default DraftCountdownTimer;
