import React, { ReactNode, useContext, useState } from 'react';
import {
  RequestType,
  iLeague,
  iTeam,
  iUser,
} from '@shared/shared-utils/models';
import { TeamsService } from '../services/teams-service';
import { ToastContext } from './toast-provider';
import { getAnalytics, logEvent } from 'firebase/analytics';

type TransfersProviderProps = {
  children: ReactNode;
};

type TransfersContextProps = {
  processingTransferRequest: boolean;
  setProcessingTransferRequest: React.Dispatch<React.SetStateAction<boolean>>;
  freeAgentByTeam: iTeam | undefined;
  setFreeAgentByTeam: React.Dispatch<React.SetStateAction<iTeam | undefined>>;
  freeAgentToTeam: iTeam | undefined;
  setFreeAgentToTeam: React.Dispatch<React.SetStateAction<iTeam | undefined>>;
  sendFreeAgentRequest: (
    props: {
      league: iLeague;
      freeAgentToTeam: iTeam;
      freeAgentByTeam: iTeam;
      userDetails: iUser;
      currentWeek: number;
    },
    successCallback: () => void,
  ) => Promise<void>;
};

export const TransfersContext = React.createContext(
  {} as TransfersContextProps,
);

const TransfersProvider = ({ children }: TransfersProviderProps) => {
  const _teamsService = new TeamsService();
  const { showToast } = useContext(ToastContext);

  const analytics = getAnalytics();

  const [processingTransferRequest, setProcessingTransferRequest] =
    useState(false);
  const [freeAgentByTeam, setFreeAgentByTeam] = useState<iTeam>();
  const [freeAgentToTeam, setFreeAgentToTeam] = useState<iTeam>();

  const sendFreeAgentRequest = async (
    props: {
      league: iLeague;
      freeAgentToTeam: iTeam;
      freeAgentByTeam: iTeam;
      userDetails: iUser;
      currentWeek: number;
    },
    successCallback: () => void,
  ) => {
    try {
      setProcessingTransferRequest(true);
      // TODO Send the year in here
      await _teamsService.processTransferPortalRequest(
        props.league.id,
        props.league.title,
        props.userDetails,
        {
          id: props.freeAgentToTeam.id,
          school: props.freeAgentToTeam.school,
          logos: props.freeAgentToTeam.logos,
          nextGame: props.freeAgentToTeam.nextGame,
        },
        {
          id: props.freeAgentByTeam.id,
          school: props.freeAgentByTeam.school,
          logos: props.freeAgentByTeam.logos,
          nextGame: props.freeAgentByTeam.nextGame,
        },
        RequestType.freeAgent,
        props.currentWeek,
      );
      showToast({
        messageType: 'info',
        message: 'Transfer executed successfully.',
      });
      logEvent(analytics, 'transfer-portal', {
        content_type: 'transfer-successful',
        content_id: props.userDetails?.id,
      });
      setFreeAgentByTeam(undefined);
      setFreeAgentToTeam(undefined);
      successCallback();
    } catch (e: any) {
      showToast({ messageType: 'error', message: e });
    }

    setProcessingTransferRequest(false);
  };

  return (
    <TransfersContext.Provider
      value={{
        processingTransferRequest,
        setProcessingTransferRequest,
        freeAgentByTeam,
        setFreeAgentByTeam,
        freeAgentToTeam,
        setFreeAgentToTeam,
        sendFreeAgentRequest,
      }}
    >
      {children}
    </TransfersContext.Provider>
  );
};

export default TransfersProvider;
