import { useState, useContext, useEffect } from 'react';
import {
  iLeague,
  iUser,
  iLeagueMemberSquadDb,
  iMatchupSquad,
  iMatchupSquadItem,
  iMatchupSquadObj,
  matchupSlotTypes,
  iTeam,
  iTeamOwnerMap,
} from '@shared/shared-utils/models';
import {
  hasEmptyProperty,
  arePropertiesUnique,
  DiscordWebhooks,
} from '@shared/shared-utils';
import Header from '../../../../components/widgets/header/header';
import TabHeader from '../../../../components/ui/tabs/tab-header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LeagueContext } from '../../../../providers/league-provider';
import { AuthContext } from '../../../../providers/auth-provider';
import { TeamsContext } from '../../../../providers/teams-provider';
import SelectTeamModal from '../../../../components/modals/select-team/select-team-modal';
import { hasGameStarted } from '../../../../utils/game-utils';
import { ToastContext } from '../../../../providers/toast-provider';
import { FreeAgentTransfer } from '../widgets/free-agent';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { TradeTeam } from '../widgets/trade-team';
import Loading from '../../../../components/ui/loading-animation';
import { Button, Tooltip } from '@mui/material';
import SquadName from '../squad-name';
import TeamResearch from '../widgets/team-research';
import { UserService } from '../../../../services/user-service';
import UpdateSquadModal from './page-sections/update-squad-modal';
import { TransfersContext } from '../../../../providers/transfer-provider';
import { DiscordService } from '../../../../services/discord-service';

const UpdateSquad = () => {
  const { showToast } = useContext(ToastContext);
  const {
    currentLeague,
    saveMatchupSquad,
    appConfig,
    getMemberSquad,
    getLeagueDetails,
  } = useContext(LeagueContext);
  const { userDetails, setUserDetails } = useContext(AuthContext);
  const {
    sendFreeAgentRequest,
    setFreeAgentByTeam,
    setFreeAgentToTeam,
    setProcessingTransferRequest,
    freeAgentByTeam,
    freeAgentToTeam,
    processingTransferRequest,
  } = useContext(TransfersContext);
  const navigate = useNavigate();
  const [myTeams, setMyTeams] = useState<iTeam[]>();
  const [selectableTeams, setSelectableTeams] = useState<iTeam[]>();
  const [unSelectableTeams, setUnSelectableTeams] = useState<iTeam[]>();
  const [unSelectableTeamIds, setUnSelectableTeamIds] = useState<string[]>();
  const [squadOwnerList, setSquadOwnerList] = useState<iTeamOwnerMap[]>([]);
  const { state }: any = useLocation();
  const { leagueId }: any = useParams();
  const { getTeamById } = useContext(TeamsContext);
  const [league, setLeague] = useState<iLeague>();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [slotType, setSlotType] = useState<string>();
  // TODO figure out if this is needed. It is really just the current week the way its being used now.
  const [currentWeekMatchUpSquad, setCurrentWeekMatchUpSquad] =
    useState<iMatchupSquadItem>();
  const [matchupSquad, setMatchupSquad] = useState<iMatchupSquad>(); // this is the one for the current week
  const [teamSelected, setMatchUpTeam] = useState<iTeam>();
  const [selectedSquad, setSelectedSquad] = useState<iMatchupSquadObj>();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>();
  const [squadName, setSquadName] = useState<string>(state.squadName);
  const [showSelectTeamModal, setShowSelectTeamModal] =
    useState<boolean>(false);
  const analytics = getAnalytics();
  const userService = new UserService();
  const _discordService = new DiscordService();

  const handleFreeAgentRequest = async () => {
    if (
      league?.id &&
      league?.creator &&
      userDetails?.id &&
      freeAgentByTeam &&
      freeAgentToTeam
    ) {
      sendFreeAgentRequest(
        {
          league: league,
          freeAgentToTeam: freeAgentToTeam,
          freeAgentByTeam: freeAgentByTeam,
          userDetails: userDetails,
          currentWeek: currentWeek,
        },
        () => {
          navigate(-1);
        },
      );
    }
  };

  const initLeague = async () => {
    if (
      currentLeague &&
      currentLeague.id === state.leagueId &&
      currentLeague.id === leagueId
    ) {
      setLeague(currentLeague);
    } else {
      const returnedLeague = await getLeagueDetails(leagueId, true);
      setLeague(returnedLeague);
    }

    setFreeAgentByTeam(undefined);
    setFreeAgentToTeam(undefined);
  };

  useEffect(() => {
    initLeague();
  }, [currentLeague]);

  useEffect(() => {
    if (state?.myTeams) {
      // Set if the team is locked for the week or not
      const teams = state?.myTeams.map((team: iTeam) => {
        return {
          ...team,
          teamLocked: hasGameStarted(team?.nextGame?.start_date),
        };
      });

      setMyTeams(teams);
    }
  }, [state]);

  useEffect(() => {
    if (appConfig) {
      setCurrentWeek(appConfig.currentWeek);
    }
  }, [appConfig]);

  useEffect(() => {
    if (state?.myTeams) {
      if (
        state.allMatchupSquads &&
        Object.keys(state.allMatchupSquads).length > 0
      ) {
        const mapSquadNames = async () => {
          // get the data from the mapped matchUps
          const mappedMatchupSquad = (await mapMatchupSquads(
            state.allMatchupSquads,
          )) as iMatchupSquad;

          // Since these came from the server we need to check if the game has been played.
          if (mappedMatchupSquad) {
            Object.keys(mappedMatchupSquad).forEach((key) => {
              const matchupSquad: iTeam = mappedMatchupSquad[
                key as keyof typeof mappedMatchupSquad
              ] as iTeam;

              if (!matchupSquad) return;
              matchupSquad.teamLocked = hasGameStarted(
                matchupSquad?.nextGame?.start_date,
              );
            });

            setMatchupSquad(mappedMatchupSquad);
          }

          // If there is data we need to filter those teams from the current available teams

          if (mappedMatchupSquad) {
            setSelectableTeams(
              state?.myTeams?.filter(
                (team: iTeam) =>
                  !Object?.values(mappedMatchupSquad as iMatchupSquad).find(
                    (sTeam) => {
                      return sTeam?.id === team?.id;
                    },
                  ),
              ),
            );
          } else {
            // have all teams available
            setSelectableTeams(state.myTeams);
          }
        };

        // call the function
        mapSquadNames()
          // make sure to catch any error
          .catch(console.error);
      } else {
        // have all teams available
        setSelectableTeams(state.myTeams);
      }
    }

    handleSaveButtonState();
  }, [currentWeek, selectedTabIndex]);
  // TODO: Might not need this since we aren't changing weeks now
  // Somehow removing this still doesn't work. I think its from the fact that current week defaults to 1

  useEffect(() => {
    !matchupSquad ||
      (Object?.keys(matchupSquad).length !== 6 && setSaveButtonDisabled(true));
  }, [matchupSquad]);

  useEffect(() => {
    if (
      selectedTabIndex !== 3 &&
      (slotType === 'tradeToTeam' ||
        slotType === 'tradeByTeam' ||
        slotType === 'freeAgentByTeam' ||
        slotType === 'freeAgentToTeam')
    ) {
      updateSelectableTeams(slotType);
    }
  }, [slotType]);

  useEffect(() => {
    teamSelected && addCurrentTeamToTable();
  }, [teamSelected]);

  const mapOwnerTeams = async () => {
    if (league?.members) {
      const squadOwnerMap: iTeamOwnerMap[] = [];

      // Get the squad for each member
      await Promise.all(
        league.members.map(async (member) => {
          const squad = await getMemberSquad(league.id, member.id);
          if (squad?.teams) {
            squad.teams.forEach((team) => {
              squadOwnerMap.push({
                teamId: +team.split('-')[1],
                ownerDetails: member,
              });
            });
          }
        }),
      );
      setSquadOwnerList(squadOwnerMap);
      setShowSelectTeamModal(true);
    }
  };

  const mapMatchupSquads = async (matchupItems: iMatchupSquadItem) => {
    if (currentWeek) {
      // TODO since we aren't letting them change the week this needs to be rethought
      const matchupSquadItem = matchupItems[currentWeek];
      if (matchupSquadItem) {
        const mappedMatchupSquad: any = {};
        mappedMatchupSquad['ps1'] = await getTeamById(
          String(matchupSquadItem.ps1),
        );
        mappedMatchupSquad['ps2'] = await getTeamById(
          String(matchupSquadItem.ps2),
        );
        mappedMatchupSquad['rs1'] = await getTeamById(
          String(matchupSquadItem.rs1),
        );
        mappedMatchupSquad['rs2'] = await getTeamById(
          String(matchupSquadItem.rs2),
        );
        mappedMatchupSquad['ds1'] = await getTeamById(
          String(matchupSquadItem.ds1),
        );
        mappedMatchupSquad['ds2'] = await getTeamById(
          String(matchupSquadItem.ds2),
        );

        return mappedMatchupSquad;
      }
    }
  };

  const resetSelection = (slot: string, team: any) => {
    // reset the selection here
    const newMatchupSquad = { ...matchupSquad } as iMatchupSquad;
    delete newMatchupSquad[slot as keyof typeof newMatchupSquad];

    // Remove that property
    setMatchupSquad(newMatchupSquad);
    setCurrentWeekMatchUpSquad({ [currentWeek]: newMatchupSquad });

    // Add the team back to be selected
    let resetTeams: iTeam[] = [];
    if (selectableTeams) {
      resetTeams = [...selectableTeams];
    }
    resetTeams?.push(team);
    setSelectableTeams(resetTeams);
  };

  const updateSelectableTeams = async (teamFilter: string) => {
    setUnSelectableTeamIds([]);
    switch (teamFilter) {
      case 'freeAgentByTeam':
        setSelectableTeams(myTeams);
        setUnSelectableTeams([]);
        break;
      case 'freeAgentToTeam':
        setProcessingTransferRequest(true);
        if (currentLeague?.memberIds && currentLeague?.id) {
          const leagueMemberTeams: any = [];
          for (let i = 0; i < currentLeague.memberIds.length; i++) {
            const memberDetails = await getMemberSquad(
              currentLeague.id,
              currentLeague.memberIds[i],
            );
            if (memberDetails?.teams) {
              memberDetails.teams.forEach((teamId) => {
                leagueMemberTeams.push({ id: teamId.split('-')[1] });
              });
            }
          }
          // TODO: do we need this? I had to use the ids
          setUnSelectableTeams(leagueMemberTeams);
          setUnSelectableTeamIds(
            leagueMemberTeams?.map((team: iTeam) => 'team-' + team.id),
          );

          setSelectableTeams([]);
        }
        setProcessingTransferRequest(false);
        break;
      default:
        if (myTeams) {
          setSelectableTeams(myTeams);
        }
    }
  };

  // Adds a team to SelectTeamModal table
  const addCurrentTeamToTable = (team?: iTeam) => {
    if (team) {
      if (unSelectableTeams && unSelectableTeams?.indexOf(team) >= 0) {
        unSelectableTeams.filter((team) => team !== team);
      }

      if (selectableTeams && selectableTeams.indexOf(team) < 0) {
        selectableTeams.unshift(team);
      }
    }
  };

  const clearTeamSelected = () => {
    if (teamSelected) {
      if (unSelectableTeams && unSelectableTeams.indexOf(teamSelected) < 0) {
        const updatedUnselectable = unSelectableTeams;
        updatedUnselectable.unshift(teamSelected);
        setUnSelectableTeams(updatedUnselectable);
      }

      if (selectableTeams && selectableTeams.indexOf(teamSelected) >= 0) {
        const updatedSelectable = selectableTeams;
        updatedSelectable.shift();
        setSelectableTeams(updatedSelectable);
      }
    }
  };

  const handleResetAll = () => {
    // Reset the lineups
    let remainingMatchupTeams = {};

    if (matchupSquad)
      Object.keys(matchupSquad as iMatchupSquad).forEach((squadKey) => {
        const squad = matchupSquad[
          squadKey as keyof typeof matchupSquad
        ] as iTeam;

        // Don't remove a locked team
        if (squad?.teamLocked) {
          remainingMatchupTeams = {
            ...remainingMatchupTeams,
            [squadKey]: squad,
          };
        }
      });
    setMatchupSquad(remainingMatchupTeams as iMatchupSquad);

    // Set current week squad
    setCurrentWeekMatchUpSquad({
      ...currentWeekMatchUpSquad,
      [currentWeek]: remainingMatchupTeams as iMatchupSquad,
    });

    let teamsToAddBack = myTeams;

    if (remainingMatchupTeams) {
      Object.keys(remainingMatchupTeams).forEach((key) => {
        const squadToRemove = remainingMatchupTeams[
          key as keyof typeof remainingMatchupTeams
        ] as iTeam;
        teamsToAddBack = teamsToAddBack?.filter(
          (team) => team.id !== squadToRemove.id,
        );
      });
    }
    // Add all remaining teams back to selection
    setSelectableTeams(teamsToAddBack);
  };

  const handleSaveSquad = async () => {
    if (league?.id && matchupSquad && userDetails?.id) {
      // confirm the spots are unique and filled and there are only 6
      if (
        !hasEmptyProperty(matchupSquad) &&
        arePropertiesUnique(matchupSquad) &&
        Object.keys(matchupSquad).length === 6
      ) {
        const matchupSquadItems = {
          ...currentWeekMatchUpSquad,
        };

        let badTeamState = false;
        // Make sure these teams are on the squad
        Object.keys(matchupSquad).forEach((key) => {
          const slottedTeam = matchupSquad[
            key as keyof typeof matchupSquad
          ] as iTeam;
          // make sure they exist on state.myTeams
          if (
            !state.myTeams?.find((team: iTeam) => team.id === slottedTeam.id)
          ) {
            console.log('ERROR', slottedTeam, state.myTeams);
            showToast({
              messageType: 'error',
              message: 'Some of your teams are not on your roster!',
            });

            _discordService.postDiscordMessage(
              `User tried to start a team that they didn't own in league: ${league?.id} and user: ${userDetails?.id}`,
              DiscordWebhooks.activityChannel,
            );
            badTeamState = true;
          }
        });

        // Escape if we are in a bad state
        if (badTeamState) return;

        matchupSquadItems[currentWeek] = matchupSquad;

        saveMatchupSquad(
          league.id,
          userDetails.id,
          state.myTeams,
          matchupSquadItems,
        );
        showToast({
          messageType: 'success',
          message: 'Your lineup has been updated!',
        });
        navigate(-1);
      } else {
        showToast({
          messageType: 'error',
          message:
            'Some of your slots are either not filled or duplicates. Fix these and try again!',
        });
      }
    }
  };

  const handleSaveButtonState = (newMatchupSquad?: iMatchupSquad) => {
    const isDifferent =
      JSON.stringify(newMatchupSquad) !== JSON.stringify(matchupSquad);
    if (isDifferent) {
      setSaveButtonDisabled(false);
    } else {
      // If no squad changes
      !matchupSquad || Object?.keys(matchupSquad).length !== 6
        ? setSaveButtonDisabled(true)
        : setSaveButtonDisabled(false);
    }
  };

  const handleUpdateSquadName = async (newName: string) => {
    if (league && userDetails) {
      // Update that it has been shown
      const newSquadNameProperty: Partial<iUser> = {
        squadNames: {
          ...userDetails?.squadNames,
          [league.id]: newName,
        },
      };

      try {
        // Update the squad name on the server
        await userService.updateUserProfile(
          newSquadNameProperty,
          userDetails.id,
        );

        const newUserDetails = {
          ...userDetails,
          ...newSquadNameProperty,
        };

        // Set the new squad name to local state
        setUserDetails(newUserDetails);

        // Update the squad name
        setSquadName(newName);

        // Log the action
        logEvent(analytics, 'squad-name-update', {
          content_type: 'update-successful',
          content_id: userDetails?.id,
        });
      } catch (error) {
        console.log('ERROR updating squad name', error);
        showToast({
          messageType: 'error',
          message: 'Error updating squad name. Please try again.',
        });
      }
    }
  };

  return !league ||
    processingTransferRequest ||
    !matchupSquad ||
    !userDetails ? (
    <Loading></Loading>
  ) : (
    <div id="db-update-squad" className="flex flex-col football-bg">
      <Header
        title="Update Squad"
        isHome={false}
        subContent={
          <TabHeader
            selectedTabIndex={selectedTabIndex}
            tabItems={[
              { key: 'clMatchupSquad', text: 'Matchup', customClass: '' },
              {
                key: 'clTrade',
                text: 'Trade',
                customClass: league.settings?.tradeEnabled ? '' : 'disabled',
              },
              {
                key: 'clFreeAgent',
                text: 'Transfer',
                customClass: league.settings?.freeAgentEnabled
                  ? ''
                  : 'disabled',
              },
              {
                key: 'clResearch',
                text: 'Research',
              },
            ]}
            onTabChange={(selectedIndex) => {
              setSelectableTeams([]);
              setUnSelectableTeams([]);
              setSelectedTabIndex(selectedIndex);
            }}
          />
        }
      />
      <div className="flex flex-1 p-4 flex-col pt-[7.8rem]">
        {selectedTabIndex === 0 && (
          <div>
            <div className="mt-4">
              <SquadName
                initialSquadName={squadName}
                onUpdateSquadName={handleUpdateSquadName}
              ></SquadName>
            </div>

            <div className="mb-12">
              <UpdateSquadModal
                matchupSquad={matchupSquad}
                handleResetSelectionClick={resetSelection}
                handleTeamSlotClick={(squad) => {
                  if (!squad.matchupSquadTeam?.teamLocked) {
                    setMatchUpTeam(squad.matchupSquadTeam);
                    setSelectedSquad(squad);
                    setSlotType(squad.matchupSlotType);
                    setShowSelectTeamModal(true);
                    addCurrentTeamToTable(squad.matchupSquadTeam);
                  } else {
                    showToast({
                      messageType: 'warning',
                      message:
                        'You can not move a team that has already played!',
                      dataTag: 'team_played',
                    });
                  }
                }}
              />
            </div>

            <div className="fixed bottom-0 z-10 my-4 w-full flex justify-center max-w-[560px] mx-auto">
              <div className="w-1/3 mr-2">
                <Button
                  children={'Reset All'}
                  disabled={
                    !matchupSquad || Object?.values(matchupSquad).length < 1
                  }
                  onClick={() => handleResetAll()}
                  size="large"
                  fullWidth
                  color="warning"
                  variant="contained"
                  data-e2e="reset_all_update_squad"
                />
              </div>
              <div className="w-2/3 pr-8">
                <Tooltip
                  title={
                    saveButtonDisabled &&
                    matchupSquad &&
                    Object?.keys(matchupSquad).length !== 6 &&
                    'You need to fill all of your positions!'
                  }
                  enterTouchDelay={0}
                >
                  <div>
                    <Button
                      children={'Save Lineup'}
                      disabled={saveButtonDisabled}
                      data-e2e="save_update_squad"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => handleSaveSquad()}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
        {selectedTabIndex === 1 && (
          <TradeTeam
            myTeams={myTeams || []}
            onTradeRequestClick={() => {
              navigate(-1);
            }}
            usersLeague={league}
            userDetails={userDetails}
          />
        )}
        {selectedTabIndex === 2 && (
          <FreeAgentTransfer
            onTransferRequestClick={() => {
              handleFreeAgentRequest();
            }}
            setSlotType={async (mSlot: string) => {
              setSlotType(mSlot);
              await updateSelectableTeams(mSlot);
              setShowSelectTeamModal(true);
            }}
            freeAgentByTeam={freeAgentByTeam}
            freeAgentToTeam={freeAgentToTeam}
          />
        )}
        {selectedTabIndex === 3 && (
          <TeamResearch
            buttonClick={() => {
              mapOwnerTeams();
            }}
          />
        )}
      </div>
      {showSelectTeamModal && (
        <SelectTeamModal
          title={
            selectedSquad && selectedTabIndex === 0
              ? `Select Team - ${selectedSquad?.posTitle} Team ${selectedSquad?.posTeamNum}`
              : selectedTabIndex === 3
                ? 'Team Research'
                : ''
          }
          league={league}
          isResearch={selectedTabIndex === 3}
          includedTeams={
            selectedTabIndex === 3
              ? []
              : selectableTeams?.map((team: iTeam) => 'team-' + team.id)
          }
          excludedTeams={selectedTabIndex === 3 ? [] : unSelectableTeamIds}
          handleClose={() => {
            setSlotType('');
            clearTeamSelected();
            setMatchUpTeam(undefined);
            setShowSelectTeamModal(false);
          }}
          currentTeam={teamSelected}
          teamOwnerMap={
            slotType === 'tradeToTeam' || selectedTabIndex === 3
              ? squadOwnerList
              : undefined
          }
          isLockedTeamSelectable={selectedTabIndex === 1}
          slotType={slotType}
          defaultSeason={
            appConfig.currentWeek > 1
              ? appConfig.currentSeason
              : appConfig.currentSeason - 1
          } //If current season has stats, show that season. Otherwise, show last season
          onTeamSelect={(selectedTeam) => {
            if (selectedTabIndex === 0) {
              if (selectedTeam) {
                const selectedMatchupSquad: iMatchupSquad =
                  currentWeekMatchUpSquad
                    ? {
                        ...currentWeekMatchUpSquad[currentWeek],
                      }
                    : matchupSquad
                      ? (matchupSquad as iMatchupSquad)
                      : ({} as iMatchupSquad);

                switch (slotType) {
                  case matchupSlotTypes.ps1:
                    selectedMatchupSquad.ps1 = selectedTeam;
                    break;
                  case matchupSlotTypes.ps2:
                    selectedMatchupSquad.ps2 = selectedTeam;
                    break;
                  case matchupSlotTypes.rs1:
                    selectedMatchupSquad.rs1 = selectedTeam;
                    break;
                  case matchupSlotTypes.rs2:
                    selectedMatchupSquad.rs2 = selectedTeam;
                    break;
                  case matchupSlotTypes.ds1:
                    selectedMatchupSquad.ds1 = selectedTeam;
                    break;
                  case matchupSlotTypes.ds2:
                    selectedMatchupSquad.ds2 = selectedTeam;
                    break;
                }
                setMatchupSquad(selectedMatchupSquad);
                handleSaveButtonState(selectedMatchupSquad);
                const matchupSquadItems = { ...currentWeekMatchUpSquad };
                matchupSquadItems[currentWeek] = selectedMatchupSquad;
                setCurrentWeekMatchUpSquad(matchupSquadItems);

                if (myTeams) {
                  const allMyTeams = [...state.myTeams];

                  setSelectableTeams(
                    allMyTeams.filter(
                      (team) =>
                        !Object?.values(selectedMatchupSquad).find(
                          (sTeam) => sTeam?.id === team?.id,
                        ),
                    ),
                  );
                }
              }
            }

            if (selectedTabIndex === 2) {
              if (slotType === 'freeAgentByTeam') {
                setFreeAgentByTeam(selectedTeam);
              }

              if (slotType === 'freeAgentToTeam') {
                setFreeAgentToTeam(selectedTeam);
              }
              setSlotType('');
            }
          }}
        />
      )}
    </div>
  );
};

export default UpdateSquad;
