import { createColumnHelper } from '@tanstack/react-table';
import { iTableData, statsData, statsGroup } from '@shared/shared-utils/models';

/** Column builders **/
const columnHelper = createColumnHelper<iTableData>();

const ownerColumn = [
  columnHelper.accessor((data) => data.teamOwner, {
    id: 'teamOwner',
    header: 'Team Owner',
    cell: (info) => <div>{info.row.original.teamOwner} </div>,
  }),
];

const gamesColumn = [
  columnHelper.accessor(
    (data) => data.team.gameSeasonTotals && data.team.gameSeasonTotals.wins,
    {
      id: 'wins',
      header: 'Win',
      cell: (info) => (
        <div>
          {info.row.original.team.gameSeasonTotals
            ? info.row.original.team.gameSeasonTotals.wins
            : 0}
        </div>
      ),
    },
  ),
  columnHelper.accessor((data) => data.team.gameSeasonTotals?.losses, {
    id: 'loss',
    header: 'Loss',
    cell: (info) => (
      <div>
        {info.row.original.team.gameSeasonTotals
          ? info.row.original.team.gameSeasonTotals.losses
          : 0}
      </div>
    ),
  }),
  columnHelper.accessor(
    (data) => data.team.gameSeasonTotals && data.team.gameSeasonTotals.games,
    {
      id: 'games',
      header: 'Games Played',
      cell: (info) => (
        <div>
          {info.row.original.team.gameSeasonTotals
            ? info.row.original.team.gameSeasonTotals.games
            : 0}
        </div>
      ),
    },
  ),
];

const dynamicColumns = Object.keys(statsData).map((key) => {
  return columnHelper.accessor(
    (data) => data.resultTotals.stats[key] && data.resultTotals.stats[key].stat,
    {
      id: key,
      header: statsData[key as keyof typeof statsData].name,
      cell: (info) => (
        <div>
          {info.row.original.resultTotals &&
          info.row.original.resultTotals.stats[key]
            ? info.row.original.resultTotals.stats[key].stat
            : 0}
        </div>
      ),
    },
  );
});

const groupedDynamicColumns = Object.keys(statsGroup).map((key) => {
  const keyValue = statsGroup[key as keyof typeof statsGroup];

  return columnHelper.accessor(
    (data) =>
      data.cumulativeScores[key as keyof typeof data.cumulativeScores].value,
    {
      id: key,
      header: keyValue,
      cell: (info) => {
        return (
          <div>
            {
              info.row.original.cumulativeScores[
                key as keyof typeof info.row.original.cumulativeScores
              ].value
            }
          </div>
        );
      },
    },
  );
});

export const columns = [
  ...ownerColumn,
  ...groupedDynamicColumns,
  ...dynamicColumns,
  ...gamesColumn,
];
