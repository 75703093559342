import { iTeam } from '@shared/shared-utils/models';
import { useHorizontalScroll } from '../../../../../hooks/use-hoz-scroll';

type MySquadProps = {
  setShowTeamDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTeam: React.Dispatch<React.SetStateAction<iTeam | undefined>>;
  squadList: any[];
  tradedTeams: string[];
};

export const MySquad = (props: MySquadProps) => {
  const hozScrollRef2 = useHorizontalScroll();
  return (
    <>
      {/* <p className="px-4 font-bold">My Squad</p> */}
      <div
        className="flex w-full p-4 pb-1 overflow-x-scroll"
        ref={hozScrollRef2}
      >
        {props.squadList.map((slot: iTeam, index: number) => {
          return (
            slot && (
              <div
                key={index}
                className={`flex flex-col items-center justify-start slot`}
                onClick={() => {
                  props.setShowTeamDetailsModal(true);
                  props.setSelectedTeam(slot);
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${slot.logos[0].replace(
                      /http:/g,
                      'https:',
                    )})`,
                  }}
                  className="bg-no-repeat bg-cover bg-primary mx-4 mt-4 rounded-full h-16 w-16 min-h-[2rem] team-image cursor-pointer"
                  title={slot.school}
                />
                <span
                  className="p-2 break-words max-w-[110px] text-center cursor-pointer"
                  data-e2e="squad_team"
                >
                  {slot.school}
                </span>
                {/* See if the team is traded */}
                {props.tradedTeams.find(
                  (teamId) => teamId === 'team-' + slot.id,
                ) && <span className="text-red-600 text-xs">(traded)</span>}
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default MySquad;
