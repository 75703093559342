import { SxProps, TextField, Theme } from '@mui/material';
import { ChangeEvent } from 'react';
import '../input.scss';

type TextInputProps = {
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  disabled?: boolean;
  required?: boolean;
  e2eTag?: string;
  sxProps?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
};

export function TextInput(props: TextInputProps) {
  return (
    <TextField
      className="mt-4"
      fullWidth
      value={props.value}
      label={props.label}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(event);
      }}
      disabled={props.disabled}
      required={props.required}
      data-e2e={props.e2eTag}
      sx={props.sxProps}
      error={props.error}
      helperText={props.helperText}
      FormHelperTextProps={
        props.error
          ? {
              sx: { color: '#CF0000' },
            }
          : { sx: { color: '#00BE2A' } }
      }
    />
  );
}

export default TextInput;
