import { iTeam } from '@shared/shared-utils/models';
import { UpcomingGame } from '../../ui/UpcomingGame/upcoming-game';

type LDSquadPositionType = {
  rowClicked?: () => any;
  teamNum?: number;
  team?: iTeam;
  slot?: string;
};

export function LDSquadPosition(props: LDSquadPositionType) {
  return (
    <div
      className="flex items-center justify-start cursor-pointer"
      onClick={() => {
        props.rowClicked?.();
      }}
      data-e2e={props.slot ? props.slot : ''}
    >
      {props.team ? (
        <>
          <div
            key={props.team.id}
            style={{
              backgroundImage: `url(${props.team.logos[0].replace(
                /http:/g,
                'https:',
              )})`,
            }}
            className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
            title={props.team.school}
          />
          <div className="flex flex-col">
            <p className="text-base" data-e2e="LD_position_school">
              {props.team.school}
            </p>
            <div>
              <UpcomingGame teamID={props.team.id} game={props.team.nextGame} />
            </div>
          </div>
        </>
      ) : (
        <>
          <img
            src="/assets/images/add-button.png"
            alt="add button"
            className="w-7"
          />
          <p className="ml-4 text-base">Select team {props.teamNum}</p>
        </>
      )}
    </div>
  );
}

export default LDSquadPosition;
