import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateLeagueFAB = () => {
  const navigate = useNavigate();

  return (
    <Box className="fixed bottom-0 right-0 m-4 z-50">
      <Fab
        variant="extended"
        color="primary"
        onClick={() => {
          navigate('/league/create');
        }}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-1" />
        Create League
      </Fab>
    </Box>
  );
};

export default CreateLeagueFAB;
