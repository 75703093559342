import React from 'react';
import { iUser } from '@shared/shared-utils/models';
import Image from '../widgets/image';

interface ScoreCardProps {
  userA: iUser;
  userB: iUser;
  homeScore: number;
  awayScore: number;
  leagueName: string;
  leagueWeek: string;
  ringColor: string;
  backgroundColor: string;
  titleBackgroundColor: string;
}

const ScoreCard: React.FC<ScoreCardProps> = ({
  userA,
  userB,
  homeScore,
  awayScore,
  leagueName,
  leagueWeek,
  ringColor,
  backgroundColor,
  titleBackgroundColor,
}) => {
  return (
    userA &&
    userB && (
      <div className="flex flex-col items-center relative w-full mb-4 text-black">
        <div
          className={`p-4 rounded-lg ring ${ringColor} flex flex-col items-center justify-around w-full h-full`}
          style={{ backgroundColor }}
        >
          <div
            className={`text-base rounded-lg py-1 mb-2 capitalize w-full flex items-center justify-around font-bold`}
            style={{ backgroundColor: titleBackgroundColor }}
          >
            <span className="text-left">{leagueName}</span>
            <span className="text-right">Week {leagueWeek}</span>
          </div>
          <div className="flex flex-col w-full">
            <div className="text-gray-600 pb-2 w-full flex justify-between">
              <div className=" font-semibold flex items-center">
                <Image
                  imageUrl={userA.profileImage}
                  title={userA.fullName || ''}
                  size={12}
                  specialClass="mr-2"
                  clickable={false}
                />
                {userA.fullName}
              </div>
              <span className="">{homeScore.toFixed(1)}</span>
            </div>
            <div className="border-t border-accent text-gray-600 pt-2 w-full flex justify-between">
              <div className=" font-semibold flex items-center">
                <Image
                  imageUrl={userB.profileImage}
                  title={userB.fullName || ''}
                  size={12}
                  specialClass="mr-2"
                  clickable={false}
                />
                {userB.fullName}
              </div>
              <span className="">{awayScore.toFixed(1)}</span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ScoreCard;
