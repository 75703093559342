import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useEffect, useState } from 'react';

const ImagePicker = ({
  label,
  imageLink,
  disabled = false,
  e2eTag,
  ...otherProps
}: any) => {
  const [imagePreview, setImagePreview] = useState(imageLink);

  useEffect(() => {
    setImagePreview(imageLink);
  }, [imageLink]);

  return (
    <div className={`file-input ${disabled && 'opacity-40'}`}>
      <input
        accept="image/*"
        type="file"
        id="file"
        className="file"
        {...otherProps}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (otherProps.onChange) {
            otherProps.onChange(e);
          }
          if (e.target && e.target.files?.length) {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
          }
        }}
        disabled={disabled}
      />
      <label
        htmlFor="file"
        className="relative flex-col items-center justify-between"
      >
        {imagePreview ? (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              className="absolute text-2xl text-db-background-color top-4 right-4"
            />
            <img
              data-e2e={e2eTag}
              src={imagePreview}
              alt="profile pic"
              className="w-full"
            />
          </>
        ) : (
          <>
            <img
              src="/assets/images/add-button.png"
              alt="add button"
              className="w-14"
            />
            <p className="block mt-4">{label}</p>
          </>
        )}
      </label>
    </div>
  );
};

export default ImagePicker;
