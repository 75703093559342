import Button from '@mui/material/Button/Button';
import TeamDetails from '../../../../components/modals/team-details/team-details';
import { useContext, useEffect, useState } from 'react';
import { iTeam } from '@shared/shared-utils/models';
import { ToastContext } from '../../../../providers/toast-provider';
import ModalWrapper from '../../../../components/modals/modal-wrapper/modal-wrapper';
import { Autocomplete, TextField } from '@mui/material';
import { all } from 'axios';
import { TeamsContext } from '../../../../providers/teams-provider';

type TeamResearchProps = {
  buttonClick: () => void;
};

const TeamResearch = (props: TeamResearchProps) => {
  const { showToast } = useContext(ToastContext);
  const { getAllTeamsData } = useContext(TeamsContext);

  const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<iTeam>();
  const [allTeams, setAllTeams] = useState<iTeam[]>();

  useEffect(() => {
    loadTeams();
  }, []);

  const loadTeams = async () => {
    const teams = await getAllTeamsData();
    setAllTeams(teams ? teams : []);
  };

  return (
    <div className="flex flex-col flex-1 mt-4">
      <div>
        <div className="my-2 text-base text-center">
          Research the teams before you set your lineup!
        </div>
        <div className="my-2 text-sm flex flex-col px-8 mt-4">
          <span className="italic">
            Just remember, football is 80 percent mental and 40 percent
            physical.
          </span>
          <span className="font-bold self-end mt-1">
            - Steve Emtman, The Little Giants
          </span>
        </div>
      </div>
      <div className="text-center mt-12">
        <Button
          children="Research Teams"
          variant="contained"
          onClick={() => props.buttonClick()}
          data-e2e="research_teams_btn"
        />
      </div>
      {allTeams && (
        <div className="flex flex-col mt-4">
          Select a team to view details!
          {/* Favorite Team Dropdown */}
          <Autocomplete
            options={allTeams.map((team) => team.school)}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Select Team" fullWidth />
            )}
            value={selectedTeam?.school}
            onChange={(event: any, newValue: string | null) => {
              setSelectedTeam(
                allTeams.find((team) => team.school === newValue),
              );
            }}
          />
          <div className="mt-4">
            {selectedTeam && (
              <Button
                fullWidth
                variant="contained"
                onClick={() => setShowTeamDetailsModal(true)}
              >
                Show Team Details
              </Button>
            )}
          </div>
        </div>
      )}

      {showTeamDetailsModal && selectedTeam && (
        <ModalWrapper
          title={'Team Details'}
          backEnabled={false}
          closeOnTapOutside={false}
          onCloseClicked={() => {
            setShowTeamDetailsModal(false);
          }}
        >
          <TeamDetails
            team={selectedTeam}
            onError={() => {
              setSelectedTeam(undefined);
              setShowTeamDetailsModal(false);
              showToast({
                messageType: 'error',
                message:
                  'Unable to show team details at this time. Please try again later.',
              });
            }}
          />
        </ModalWrapper>
      )}
    </div>
  );
};

export default TeamResearch;
