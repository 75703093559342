import axios from 'axios';

interface iNotification {
  app_id: string;
  include_player_ids: Array<string>;
  headings?: {
    en: string;
  };
  contents: {
    en: string;
  };
  data?: {
    redirectUrl: string;
  };
}

export class NotificationService {
  private oneSignalConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic NzNiN2M2NGEtNmJiYi00NmI0LWJmNmUtNjM2MzIxMWJhMTEx`, // rest api key
    },
  };

  private appId = '47b2d5b6-6fd9-4d14-a964-61bb5b3cc085';

  private oneSignalUrl = 'https://onesignal.com/api/v1/notifications'; // api to send push Notifications

  public draftTurnUpdate = async (playerId: string, leagueName: string) => {
    // set data for sending push notification
    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: [playerId], // add player id or player ids from user doc to send notification to specific user
      contents: { en: `It is your turn to pick in ${leagueName}!` }, // modify with your message
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Notification Error', error);
    }
  };

  public draftHasStarted = async (
    playerIds: Array<string>,
    leagueName: string,
  ) => {
    // set data for sending push notification
    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: playerIds, // add player id or player ids from user doc to send notification to specific user
      contents: { en: `Get ready, your draft has started in ${leagueName}!` }, // modify with your message
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Notification Error', error);
    }
  };

  public tradeRequestSent = async (playerId: string, leagueName: string) => {
    // set data for sending push notification
    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: [playerId], // add player id or player ids from user doc to send notification to specific user
      contents: { en: `You have received a trade request in ${leagueName}` }, // modify with your message
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Notification Error', error);
    }
  };

  public leagueInvite = async (playerId: string, leagueName: string) => {
    // set data for sending push notification
    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: [playerId], // add player id or player ids from user doc to send notification to specific user
      contents: { en: `You have received an invite to join ${leagueName}` }, // modify with your message
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Notification Error', error);
    }
  };

  public sendChatNotification = async (
    playerId: string[],
    leagueName: string,
    message: string,
    name: string,
    redirectUrl: string,
  ) => {
    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: playerId, // add player id or player ids from user doc to send notification to specific user
      headings: { en: `New message from ${name} in ${leagueName} !` }, // modify with your message
      contents: { en: `${message}` }, // modify with your message
      data: {
        redirectUrl: redirectUrl,
      },
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Notification Error', error);
    }
  };

  // Send notification to the league owner when a member has joined the league.
  // If it is the last member, indicate so in the notification.
  public memberJoinedLeagueNotification = async (
    ownerPlayerId: string,
    joinedPlayerName: string,
    leagueTitle: string,
    redirectUrl: string,
    numMembersRemaining: number,
    lastMember?: boolean,
  ) => {
    const heading = lastMember
      ? 'The last member has joined your league!'
      : 'Another member has joined your league!';

    const contentMessage = lastMember
      ? `${joinedPlayerName} has joined ${leagueTitle}. Time to start the draft!`
      : `${joinedPlayerName} has joined ${leagueTitle}. You are still waiting on ${numMembersRemaining} member${
          numMembersRemaining === 1 ? '' : 's'
        }!`;

    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: [ownerPlayerId], // add player id or player ids from user doc to send notification to specific user
      headings: { en: heading }, // modify with your message
      contents: { en: contentMessage }, // modify with your message
      data: {
        redirectUrl: redirectUrl,
      },
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Notification Error', error);
    }
  };

  public sendNewTrophyNotification = async (playerId: string) => {
    const notification: iNotification = {
      app_id: this.appId, // oneSignal app id
      include_player_ids: [playerId], // add player id or player ids from user doc to send notification to specific user
      headings: { en: 'New Trophy Alert!' }, // modify with your message
      contents: { en: 'Congratulations! You have earned a new trophy!' }, // modify with your message
    };

    try {
      await axios.post(this.oneSignalUrl, notification, this.oneSignalConfig);
    } catch (error) {
      console.log('Error sending new trophy notification: ', error);
    }
  };
}
