import { useState, useContext, useEffect } from 'react';
import {
  iLeague,
  iSmallGame,
  iTeam,
  iScoreboardResults,
} from '@shared/shared-utils/models';
import Header from '../../../components/widgets/header/header';
import { LeagueContext } from '../../../providers/league-provider';
import { TeamsContext } from '../../../providers/teams-provider';
import { hasGameStarted } from '../../../utils/game-utils';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { UpcomingGame } from '../../../components/ui/UpcomingGame/upcoming-game';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { ScoreboardContext } from '../../../providers/scoreboard-povider';
import { faFootball, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './gameday-guide.scss';
import Button from '../../../components/ui/button/button';
import Loading from '../../../components/ui/loading-animation';
import ModalWrapper from '../../../components/modals/modal-wrapper/modal-wrapper';
import TeamDetails from '../../../components/modals/team-details/team-details';
import { ToastContext } from '../../../providers/toast-provider';

const GameDayGuide = () => {
  const { getAllScoreboardData } = useContext(ScoreboardContext);
  const { currentLeague, appConfig, getMemberSquad } =
    useContext(LeagueContext);
  const [myTeams, setMyTeams] = useState<iTeam[]>();
  const { state }: any = useLocation();
  const { getTeamById } = useContext(TeamsContext);
  const { showToast } = useContext(ToastContext);

  const [league, setLeague] = useState<iLeague>();
  const [currentWeek, setCurrentWeek] = useState(1);

  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [inActionTeams, setInActionTeams] = useState<iTeam[]>();
  const [byeTeams, setByeTeams] = useState<iTeam[]>([]);
  const [scoreboardMap, setScoreboardMap] =
    useState<Map<number, iScoreboardResults>>();
  const [showTeamDetailsModal, setShowTeamDetailsModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<iTeam>();

  const scoreboardStatus = {
    scheduled: 'scheduled',
    inProgress: 'in_progress',
    completed: 'completed',
  };

  const analytics = getAnalytics();

  useEffect(() => {
    if (currentLeague) setLeague(currentLeague);
  }, [currentLeague]);

  useEffect(() => {
    console.log('state', state);
    if (state?.myTeams) {
      // Set if the team is locked for the week or not
      const teams = state?.myTeams.map((team: iTeam) => {
        return {
          ...team,
          teamLocked: hasGameStarted(team?.nextGame?.start_date),
        };
      });

      setMyTeams(teams);
    }
  }, [state]);

  useEffect(() => {
    if (appConfig) {
      setCurrentWeek(appConfig.currentWeek);
    }
  }, [appConfig]);

  useEffect(() => {
    loadData();
  }, [currentWeek, myTeams]);

  const loadData = async () => {
    setProcessingRequest(true);
    try {
      const scoreboard = await loadScoreboard();
      await filterTeams(scoreboard);
    } catch (error) {
      console.log('Error loading', error);
    }
    setProcessingRequest(false);
  };

  const filterTeams = async (scoreboard: iScoreboardResults[]) => {
    const inActionTeams: iTeam[] = [];
    const byeTeams: iTeam[] = [];

    const teamMap = new Map();
    myTeams?.forEach((team) => {
      if (team.nextGame?.start_date) {
        inActionTeams.push(team);
        teamMap.set(team.id, {});
      } else {
        byeTeams.push(team);
      }
    });

    inActionTeams?.sort((a: iTeam, b: iTeam) => {
      if (
        (a.nextGame as iSmallGame)?.start_date >
        (b.nextGame as iSmallGame)?.start_date
      ) {
        return 1;
      } else if (
        (a.nextGame as iSmallGame)?.start_date <
        (b.nextGame as iSmallGame)?.start_date
      ) {
        return -1;
      } else {
        return 0;
      }
    });

    const scoreboardMap = new Map();
    scoreboard.forEach((game) => {
      if (teamMap.get(game.homeTeam.id) || teamMap.get(game.awayTeam.id)) {
        scoreboardMap.set(game.id, game);
      }
    });

    setInActionTeams(inActionTeams);
    setByeTeams(byeTeams);
    setScoreboardMap(scoreboardMap);
  };

  const loadScoreboard = async () => {
    try {
      return await getAllScoreboardData();
    } catch (error) {
      console.error('Error loading scoreboard', error);
      return [];
    }
  };

  const determineHomePossession = (
    isHome: boolean,
    possession: string | undefined,
  ) => {
    if (isHome && possession === 'home') {
      return true;
    } else {
      return false;
    }
  };

  const borderClass = (status: string | undefined) => {
    if (status === scoreboardStatus.inProgress) {
      return 'border-green';
    } else if (status === scoreboardStatus.completed) {
      return 'border-blue';
    } else {
      return 'border-gray';
    }
  };

  const teamCard = (team: iTeam) => {
    const isTeamHome = team.nextGame?.home_id === team.id ? true : false;
    const scoreboard = scoreboardMap?.get(team.nextGame?.id as number);
    const homeTeamPossession = determineHomePossession(
      isTeamHome,
      scoreboard?.possession,
    );
    return (
      <div
        key={team.id}
        className={`flex flex-col justify-between border-2 rounded-lg mb-2 p-2  ${borderClass(
          scoreboard?.status,
        )}`}
      >
        <div className="flex items-center justify-between mb-1">
          <div className="flex text-sm mb-1.5">
            <div
              key={team.id}
              style={{
                backgroundImage: `url(${team.logos[0].replace(
                  /http:/g,
                  'https:',
                )})`,
              }}
              className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-3 cursor-pointer"
              title={team.school}
              onClick={() => {
                setShowTeamDetailsModal(true);
                setSelectedTeam(team);
              }}
            />
            <div className="flex flex-col">
              <div>
                {team.school}
                {scoreboard?.status === scoreboardStatus.inProgress &&
                  homeTeamPossession && (
                    <FontAwesomeIcon
                      icon={faFootball}
                      className="ml-1 text-xs"
                    />
                  )}
              </div>
              <div>
                <UpcomingGame teamID={team.id} game={team.nextGame} />
                {scoreboard?.status === scoreboardStatus.inProgress &&
                  !homeTeamPossession && (
                    <FontAwesomeIcon
                      icon={faFootball}
                      className="ml-1 text-xs"
                    />
                  )}
              </div>
            </div>
          </div>

          <div className="flex flex-col font-sm">
            <div>
              {isTeamHome
                ? scoreboard?.homeTeam.points
                : scoreboard?.awayTeam.points}
            </div>
            <div>
              {!isTeamHome
                ? scoreboard?.homeTeam.points
                : scoreboard?.awayTeam.points}
            </div>
          </div>
          {scoreboard?.status === scoreboardStatus.inProgress && (
            <div className="flex flex-col text-xs">
              <div>T: {scoreboard?.clock}</div>
              <div>Q: {scoreboard?.period}</div>
              <div>{scoreboard?.situation}</div>
            </div>
          )}
        </div>
        <div className="flex justify-between pt-1 text-xs border-t border-gray">
          <div className="text-xs">
            {team.nextGame?.start_date
              ? DateTime.fromISO(team.nextGame?.start_date).toLocaleString({
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                })
              : ''}{' '}
            | {scoreboard?.tv ? scoreboard?.tv : 'NA'}
          </div>
          {team?.nextBettingLines && team?.nextBettingLines[0] ? (
            <div className="flex">
              <div className="mr-0.5">
                S: {team?.nextBettingLines[0].formattedSpread}
              </div>
              <div>O/U: {team?.nextBettingLines[0].overUnder}</div>
            </div>
          ) : (
            <div>No betting lines available</div>
          )}
        </div>
      </div>
    );
  };

  const byeTeamCard = (team: iTeam) => {
    return (
      <div className="flex border-2 border-solid	border-white rounded-lg mb-1 p-1.5 justify-between">
        <div className="flex text-sm">
          <div
            key={team.id}
            style={{
              backgroundImage: `url(${team.logos[0].replace(
                /http:/g,
                'https:',
              )})`,
            }}
            className="bg-no-repeat bg-cover bg-primary rounded-full h-10 w-10 min-h-[2rem] team-image mr-4"
            title={team.school}
          />
          <div className="flex flex-col">
            <p>{team.school}</p>
            <div>
              <UpcomingGame teamID={team.id} game={team.nextGame} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return !league || processingRequest ? (
    <Loading></Loading>
  ) : (
    <div id="db-gameday-guide" className="flex flex-col football-bg">
      <Header title="Gameday Guide" isHome={false} />
      <div className="flex flex-1 p-4 flex-col pt-[4.8rem]">
        <div className="flex flex-col">
          <div className="flex justify-between mb-2">
            Inaction Teams{' '}
            <Button
              label={
                <FontAwesomeIcon icon={faRefresh} className="ml-1 text-xl" />
              }
              className="px-3 py-1 text-primary"
              onClick={() => {
                loadData();
              }}
            />
          </div>

          {inActionTeams?.map((team) => {
            return teamCard(team);
          })}
        </div>
        {byeTeams?.length !== 0 && (
          <div className="mt-4">
            Bye Teams
            {byeTeams?.map((team) => {
              return byeTeamCard(team);
            })}
          </div>
        )}
      </div>
      {showTeamDetailsModal && selectedTeam && (
        <ModalWrapper
          title={'Team Details'}
          backEnabled={false}
          closeOnTapOutside={false}
          onCloseClicked={() => {
            setShowTeamDetailsModal(false);
          }}
        >
          <TeamDetails
            team={selectedTeam}
            onError={() => {
              setSelectedTeam(undefined);
              setShowTeamDetailsModal(false);
              showToast({
                messageType: 'error',
                message:
                  'Unable to show team details at this time. Please try again later.',
              });
            }}
          />
        </ModalWrapper>
      )}
    </div>
  );
};

export default GameDayGuide;
