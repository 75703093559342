import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSquareCheck } from '@fortawesome/free-solid-svg-icons';

type SquadNameProps = {
  initialSquadName: string;
  onUpdateSquadName: (newName: string) => void;
};

const SquadName: React.FC<SquadNameProps> = ({
  initialSquadName,
  onUpdateSquadName,
}) => {
  const [editingSquadName, setEditingSquadName] = useState(false);
  const [squadName, setSquadName] = useState(initialSquadName);

  // const cancelEdit = () => {
  //   // Restore the initial squad name
  //   setSquadName(initialSquadName);

  //   // Exit the edit mode
  //   setEditingSquadName(false);
  // };

  const startEdit = () => {
    // Enter the edit mode
    setEditingSquadName(true);
  };

  const handleDoneClick = () => {
    // Call the onUpdateSquadName callback function with the updated name if it has been updated
    if(squadName != initialSquadName) {
      onUpdateSquadName(squadName)
    }

    // Exit the edit mode
    setEditingSquadName(false);
  };

  return (
    <div>
      <div className="flex">
        <div>
          {!editingSquadName ? (
               <div><h2>Squad: {squadName}</h2></div>
           ) : (
             <TextField
               className="w-full"
               variant="standard"
               value={squadName}
               onChange={(event) => setSquadName(event.target.value)}
             />
           )}
        </div>
        <div className="ml-1">
          {!editingSquadName ? (
            <Button style={{minWidth:0}} onClick={startEdit}><FontAwesomeIcon icon={faEdit} color="#46B549" /></Button>
          ) : (
           <Button style={{minWidth:0, fontSize: '110%'}} onClick={handleDoneClick}><FontAwesomeIcon icon={faSquareCheck} color="#46B549" /></Button>
          )}        
        </div>
      </div>
    </div>
  );
};

export default SquadName;
