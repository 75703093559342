import { defensiveScoreStats, passingScoreStats, rushingScoreStats } from '../utils/constants';
import { iTeam, iCategoryStatsItem } from './team';
import { iUser } from './user';

// export interface iLeagueMember {
//   id: string;
//   fullName: string;
//   email: string;
//   profileImage: string;
//   playerId?: string;
// }

export enum leagueDraftStatus {
  pending = 'pending',
  inProgress = 'inProgress',
  done = 'done',
}

export enum mailType {
  leagueInvite = 'League Invite',
  newUser = 'New User'
}

export enum emailTemplates {
  leagueInvite = 'league-invite',
  newUser = 'new-user'
}

export interface iLeagueDb {
  id: string;
  title: string;
  description?: string;
  bannerImage?: string;
  creator: string;
  isPrivate?: boolean;
  isActive?: boolean;
  draftStatus?: leagueDraftStatus;
  memberIds: string[];
  createdAt?: Date;
  updatedAt?: Date;
  season: number;
  leaderBoard?: iLeagueMemberRanking;
  expectedNumMembers?: number;
  lastMessageSent?: Date;
  internalLeague?: boolean;
  isOpen: boolean;
}

//TODO: Typings, think about a league that was just created vs one that has finished the draft. should they be different types?
// This interface is for managing league in UI to avoid firestore reads.
export interface iLeague {
  id: string;
  title: string;
  description: string;
  bannerImage?: string;
  creator: iUser;
  isPrivate?: boolean;
  isActive?: boolean;
  draftStatus?: leagueDraftStatus;
  members: iUser[];
  memberIds: string[];
  createdAt?: Date;
  updatedAt?: Date;
  invites?: iLeagueInvite[];
  settings: iLeagueSettings;
  matchups: iMatchup[];
  weeklyReports: {
    [key: number]: iWeeklyReport;
  };
  leaderBoard: iLeagueMemberRanking;
  season: number;
  autopickLists?: iAutoPickList[];
  expectedNumMembers?: number;
  lastMessageSent?: Date;
  unreadMessages?: boolean;
  internalLeague?: boolean;
  isOpen: boolean;
}

export interface iAutoPickList {
  ownerId: string;
  teamOrder: string[];
}

export interface iLeagueSettings {
  leagueId?: string;
  draftDate?: any;
  numOfTeamsPerMember?: number;
  numOfMembers?: number;
  tradeEnabled?: boolean;
  freeAgentEnabled?: boolean;
  scoring: iLeagueScoring;
  pickTimer: number;
  pickTimerUnit: string;
  leagueEnableDynasty?: boolean;
  numOfTeamsDynasty?: number;
}

export const defaultLeagueSettings: iLeagueSettings = {
  draftDate: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)),
  numOfTeamsPerMember: 8,
  numOfMembers: 8,
  pickTimer: 5,
  pickTimerUnit: 'minutes',
  scoring: {
    'passingScoreStats': passingScoreStats,
    'rushingScoreStats': rushingScoreStats,
    'defensiveScoreStats': defensiveScoreStats
  }
};

export interface iLeagueScoring {
  passingScoreStats: iCategoryStatsItem[];
  rushingScoreStats: iCategoryStatsItem[];
  defensiveScoreStats: iCategoryStatsItem[];
}

export interface iLeagueInvite {
  id: string;
  to: string[];
  leagueId: string;
  activityMessage: string;
  template: {
    data: {
      leagueId: string,
      hostUrl: string,
      leagueName: string;
    },
    name: emailTemplates;
  };
  mailType: mailType;
  delivery: any;
}
export interface iNewUserMail {
  id: string;
  to: string[];
  template: {
    data: null,
    name: emailTemplates;
  };
  type: mailType;
  delivery: any;
}

export enum matchupSlotTypes {
  ps1 = 'passingSquad1',
  ps2 = 'PassingSquad2',
  rs1 = 'rushingSquad1',
  rs2 = 'rushingSquad2',
  ds1 = 'defensiveSquad1',
  ds2 = 'defensiveSquad2',
}

export interface iMatchupSquad {
  ps1: iTeam;
  ps2: iTeam;
  rs1: iTeam;
  rs2: iTeam;
  ds1: iTeam;
  ds2: iTeam;
  benchedTeams: string[];
}

export interface iMatchupSquadDB {
  ps1: string;
  ps2: string;
  rs1: string;
  rs2: string;
  ds1: string;
  ds2: string;
  benchedTeams: string[];
}
export interface iLeagueMemberSquadDb {
  leagueId: string;
  memberId: string;
  teams: string[];
  matchupSquad: iMatchupSquadItemDB;
  results: {
    wins: number;
    losses: number;
    ties: number;
  };
  activeLineUp: iMatchupSquadDB;
  tradedTeams?: {
    teamsLeaving: string[];
    teamsComing: string[];
  };
}

export interface iMatchupSquadItemDB {
  [key: number]: iMatchupSquadDB;
}

export interface iMatchupSquadItem {
  [key: number]: iMatchupSquad;
}

export interface iLeagueMemberSquad {
  leagueId: string;
  memberId: string;
  teams: string[];
  matchupSquad: iMatchupSquadItem;
  results: {
    wins: number;
    losses: number;
    ties: number;
  };
  activeLineUp: iMatchupSquadItem;
}

export interface iLeagueMatchups {
  leagueId: string;
  matchups: iMatchup[];
  weeklyReports: {
    [key: number]: iWeeklyReport;
  };
}
export interface iMatchup {
  round: number;
  memberA: string;
  memberB: string;
  winnerId?: string;
  memberAPoints: number;
  memberBPoints: number;
  postSeasonRank?: number;
  memberASeed?: number;
  memberBSeed?: number;
  resultTime?: any;
}

export interface iMyMatchup extends iMatchup {
  leagueId: string;
}

export interface iMatchupSquadObj {
  matchupSquadTeam?: iTeam;
  posTitle?: string;
  posTeamNum?: number;
  matchupSlotType?: matchupSlotTypes;
  slot?: string;
}

// export const userAsLeagueMember = (user: iUser): iLeagueMember => {
//   return {
//     id: user.id,
//     fullName: user.fullName,
//     email: user.email,
//     profileImage: user.profileImage,
//   } as iLeagueMember;
// };

export interface iLeagueMemberRanking {
  [key: string]: iLeagueMemberRankData;
}

export interface iLeagueMemberRankData {
  wins: number;
  losses: number;
  pointsScored: number;
  pointsScoredAgainst: number;
  rank: number;
  winningStreak: boolean;
  lengthOfStreak: number;
}

export interface iOrdinalRankingConst {
  [key: number]: {
    alpha: string;
    numeric: {
      number: number;
      suffix: string;
    };
  };
}

export const lineupSpots = ['ps1', 'ps2', 'rs1', 'rs2', 'ds1', 'ds2'];

export interface iWeeklyReport {
  mostPointsScored: {
    userId: string,
    value: number;
  },
  fewestPointsScored: {
    userId: string,
    value: number;
  },
  mostPointsInLoss: {
    userId: string,
    value: number;
  },
  fewestPointsInWin: {
    userId: string,
    value: number;
  },
  week: number,
  dateCreated: any;
}
