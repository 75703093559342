import { AuthContext } from '../../../providers/auth-provider';
import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type RequireAuthPropsType = {
  children: any;
};

export const RequireAuth = ({ children }: RequireAuthPropsType) => {
  const location = useLocation();
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromDiscordRedirect = queryParams.get('fromDiscordRedirect');

    if (!isLoggedIn) {
      //Check if navigation is from <DiscordRedirectPage/>
      //If so, pass that on as state
      if (fromDiscordRedirect) {
        navigate('/?fromDiscordRedirect=true');
      }
      navigate('/');
    }
  }, [isLoggedIn]);

  return <>{children}</>;
};
