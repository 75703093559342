export interface iTabHeaderItem {
  key: string;
  text: string;
  customClass: string;
}

export const createTabItems = [{ key: 'clDetails', text: 'Details' }];

export const createTabItemsAll = [
  { key: 'clMembers', text: 'Invite Members' },
  { key: 'clConfig', text: 'Settings' },
];

export const tabItemsDetails = [
  { key: 'clDetails', text: 'Details' },
  { key: 'clChat', text: 'Chat' },
  { key: 'clMembers', text: 'Members' },
  { key: 'clSettings', text: 'Settings' },
];

export const updateSquadTabItems = [
  { key: 'clMatchupSquad', text: 'Matchup Squad' },
  { key: 'clTrade', text: 'Trade' },
  { key: 'clFreeAgent', text: 'Transfer Portal' },
];

export const matchupFilterOptions = [
  { label: 'Week 1', value: 1 },
  { label: 'Week 2', value: 2 },
  { label: 'Week 3', value: 3 },
  { label: 'Week 4', value: 4 },
  { label: 'Week 5', value: 5 },
  { label: 'Week 6', value: 6 },
  { label: 'Week 7', value: 7 },
  { label: 'Week 8', value: 8 },
  { label: 'Week 9', value: 9 },
  { label: 'Week 10', value: 10 },
  { label: 'Week 11', value: 11 },
  { label: 'Week 12', value: 12 },
  { label: 'Week 13', value: 13 },
  { label: 'Week 14', value: 14 },
];
