//Prod DB
export const environment = {
  production: true,
  // TODO: move this to .env file while release
  hostUrl: 'https://app.squadblitz.com/',
  hostingProd: true,
  isLocal: false,
  addAnalytics: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDRys1kEYldKeYOPwY-ps-RmDgA-sZ9Jq4',
    authDomain: 'app.squadblitz.com',
    projectId: 'dunderball-bdfa8',
    storageBucket: 'dunderball-bdfa8.appspot.com',
    messagingSenderId: '810139796',
    appId: '1:810139796:web:e203c267b44a02d43b3481',
    measurementId: 'G-JZ64T1FM9H',
  },
};

//Any changes made here also need to be made in the functions directory
//functions\src\utils\discord-config.ts
export const discordConfig = {
  serverId: "1006946760553418872",
  //Application
  clientId: "1021521071444348928",
  clientSecret: "Xu_0gLfSrBauPNdJ2RAVBqGa7fkPm-R3",
  oAuthUrl: 'https://discord.com/api/oauth2/authorize?client_id=1021521071444348928&redirect_uri=http%3A%2F%2Fapp.squadblitz.com%2Fdiscord-redirect&response_type=code&scope=identify%20guilds.join',
  redirectUri: 'http://app.squadblitz.com/discord-redirect',
  scope: 'identify guilds.join',

  //Bot
  botToken: "MTAyMTUyMTA3MTQ0NDM0ODkyOA.GOjP9Z.mE_uIZH6ZrLJkLuAWMHOajZ_V6FVCfjqA3QJaY",
};





// // squadblitz - dev DB
// // local only
// export const environment = {
//   production: false,
//   // TODO: move this to .env file while release
//   hostUrl: 'http://localhost:4200',
//   hostingProd: false,
//   isLocal: true,
//   addAnalytics: false,
//   firebaseConfig: {
//     apiKey: 'AIzaSyBZalNqBBMlceyiKnfR_vgWqZMXX41tZsY',
//     authDomain: 'squadblitz-dev.firebaseapp.com',
//     projectId: 'squadblitz-dev',
//     storageBucket: 'squadblitz-dev.appspot.com',
//     messagingSenderId: '592855814487',
//     appId: '1:592855814487:web:6ef15888796636b6e42e12',
//     measurementId: 'G-Q8RLDN0JF4',
//   },
// };

// //Local server config only
// export const discordConfig = {
//   serverId: "1006946760553418872",
//   //Application
//   clientId: "1021521071444348928",
//   clientSecret: "Xu_0gLfSrBauPNdJ2RAVBqGa7fkPm-R3",
//   oAuthUrl: 'https://discord.com/api/oauth2/authorize?client_id=1021521071444348928&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fdiscord-redirect&response_type=code&scope=identify%20guilds.join',
//   redirectUri: 'http://localhost:4200/discord-redirect',
//   scope: 'identify guilds.join',

//   //Bot
//   botToken: "MTAyMTUyMTA3MTQ0NDM0ODkyOA.GOjP9Z.mE_uIZH6ZrLJkLuAWMHOajZ_V6FVCfjqA3QJaY",
// };
