import './../modal.scss';
import { useContext, useEffect, useState } from 'react';
import ModalWrapper from '../modal-wrapper/modal-wrapper';
import { TeamsContext } from '../../../providers/teams-provider';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import Toggle from 'react-toggle';

import Table from '../../ui/table/table';
import DebouncedInput from '../../ui/debounced-input/debounced-input';
import { hasGameStarted } from '../../../utils/game-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faLock } from '@fortawesome/free-solid-svg-icons';
import MultiSelectDropdown from '../../ui/multiselect/multiselect-dropdown';
import {
  defensiveScoreStats,
  passingScoreStats,
  rushingScoreStats,
} from '@shared/shared-utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Button, Tooltip } from '@mui/material';
import { ToastContext } from '../../../providers/toast-provider';
import { iTableData, SelectTeamModalType } from '@shared/shared-utils/models';
import { columns } from './column.helper';
import { UpcomingGame } from '../../ui/UpcomingGame/upcoming-game';
import { DateTime } from 'luxon';
import { getTeamOwnerName } from './select-team.helper';

//Filter options shown by default every time this modal renders
const statsColumnOptions = [
  { label: 'Passing', value: 'Passing' },
  { label: 'Rushing', value: 'Rushing' },
  { label: 'Defensive', value: 'Defensive' },
  { label: 'Record', value: 'Record' },
  { label: 'Select All', value: 'Select All' },
];

//Filter options only shown only when trading and selecting from other owners' teams
//Added to statsColumnOptions upon render
const tradingColumnOptions = [{ label: 'Team Owner', value: 'Team Owner' }];

//Filter options that will not be included when 'Select All' is selected
const otherColumnOptions = [{ label: 'Hide Byes', value: 'Hide Byes' }];

const SelectTeamModal = (props: SelectTeamModalType) => {
  const {
    title,
    onTeamSelect,
    excludedTeams,
    includedTeams,
    handleClose,
    currentTeam,
    isLockedTeamSelectable,
    teamOwnerMap,
    slotType,
    hideNextGame,
    hideLockedIcon,
    orderedList,
    preSelectedTeam,
    defaultSeason,
    isResearch,
  } = props;

  const { getAllTeamsData, getSeasonResultTotals, getCumulativeScores } =
    useContext(TeamsContext);
  const { showToast } = useContext(ToastContext);

  const [tableDataToUse, setTableDataToUse] = useState<iTableData[]>();
  const [showAverage, setShowAverage] = useState<boolean>(false);
  const [hideBye, setHideBye] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState<iTableData>();
  const [columnsToShow, setColumnsToShow] = useState<string[]>(
    slotType === 'tradeToTeam' || isResearch
      ? ['Passing', 'Rushing', 'Defensive', 'Record', 'Team Owner']
      : ['Passing', 'Rushing', 'Defensive', 'Record'],
  );
  const [tableVisibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [primaryColumnOptions, setPrimaryColumnOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >(statsColumnOptions);
  const [selectAll, setSelectAll] = useState<boolean>(true);

  const analytics = getAnalytics();

  // Initializes the data on render and show average change
  useEffect(() => {
    initAllData();
  }, [showAverage, hideBye, excludedTeams, orderedList]);

  window.onpopstate = () => {
    window.onpopstate = null;
    handleClose();
  };


  useEffect(() => {
    if (slotType === 'tradeToTeam' || isResearch) {
      const allColumns = [
        'Passing',
        'Rushing',
        'Defensive',
        'Record',
        'Team Owner',
      ];

      if (allColumns.every((col) => columnsToShow.includes(col))) {
        setSelectAll(true);
      }
    } else if (slotType !== 'tradeToTeam' && !isResearch) {
      const allColumns = ['Passing', 'Rushing', 'Defensive', 'Record'];

      if (allColumns.every((col) => columnsToShow.includes(col))) {
        setSelectAll(true);
      }
    }

    const visibleColumns = ['team', 'games'];

    columnsToShow.forEach((column) => {
      visibleColumns.push(column);

      switch (column) {
        case 'Passing':
          visibleColumns.push(...passingScoreStats.map((item) => item.key));
          break;
        case 'Rushing':
          visibleColumns.push(...rushingScoreStats.map((item) => item.key));
          break;
        case 'Defensive':
          visibleColumns.push(...defensiveScoreStats.map((item) => item.key));
          break;
        case 'Record':
          visibleColumns.push('wins', 'loss');
          break;
        case 'Team Owner':
          visibleColumns.push('teamOwner');
          break;
      }
    });

    setVisibleColumns(visibleColumns);
  }, [columnsToShow]);

  // If user is picking a team from another owner, show the 'Team Owner' column filter option dropdown.
  // Otherwise, it's not shown in the filter dropdown
  useEffect(() => {
    if (slotType === 'tradeToTeam' || isResearch) {
      tradingColumnOptions.forEach((option) => {
        !statsColumnOptions.includes(option) &&
          statsColumnOptions.splice(statsColumnOptions.length - 1, 0, option);
      });
    } else {
      tradingColumnOptions.forEach((option) => {
        const index = statsColumnOptions.indexOf(option);
        index >= 0 && statsColumnOptions.splice(index, 1);
      });
    }
    setPrimaryColumnOptions(statsColumnOptions);
  }, []);

  /** Column builders **/

  const columnHelper = createColumnHelper<iTableData>();

  const teamColumn = [
    columnHelper.accessor((data) => data.team.school, {
      id: 'team',
      header: 'Team',
      cell: (info) => {
        return (
          <div
            className={`flex items-center justify-start space-x-4 min-w-[200px] ${
              !hideLockedIcon && info.row.original.team.teamLocked
                ? 'grayscale'
                : ''
            }`}
            data-e2e={
              info.row.original.team.id +
              '_' +
              info.row.original.team.teamLocked
            }
          >
            {/* Show tooltip if team is locked */}
            <Tooltip
              enterTouchDelay={0}
              title={
                !isLockedTeamSelectable &&
                !hideLockedIcon &&
                !isResearch &&
                info.row.original.team.teamLocked
                  ? "Can't select a team that has played already"
                  : ''
              }
              placement="top"
            >
              <div className="flex-col min-w-[200px]">
                {info.row.original.team.id === currentTeam?.id && (
                  <div className="pb-2" data-e2e="current_team_heading">
                    -CURRENT TEAM-
                  </div>
                )}
                <div className="flex">
                  <div
                    key={info.row.original.team.id}
                    style={{
                      backgroundImage: `url(${info.row.original.team.logos[0].replace(
                        /http:/g,
                        'https:',
                      )})`,
                    }}
                    className="bg-no-repeat bg-cover bg-primary mr-2 rounded-full h-8 w-8 min-h-[2rem] team-image"
                    title={info.row.original.team.school}
                  />
                  <div className="flex flex-col items-start text-sm">
                    <div
                      className="text-left"
                      data-e2e={
                        info.row.original.team.id === currentTeam?.id
                          ? 'current_team_school'
                          : 'other_team_school'
                      }
                    >
                      {info.getValue()}
                    </div>
                    {/* TODO: Consider making a component with this area. We use it a number of times */}
                    {!hideNextGame && (
                      <div className="flex text-xs">
                        <UpcomingGame
                          teamID={info.row.original.team.id}
                          game={info.row.original.team.nextGame}
                        />
                        <div className="ml-0.5">
                          {info.row.original.team.nextGame?.start_date
                            ? DateTime.fromISO(
                                info.row.original.team.nextGame?.start_date,
                              ).toLocaleString({
                                month: 'numeric',
                                day: 'numeric',
                              })
                            : ''}{' '}
                        </div>
                      </div>
                    )}
                    {!hideNextGame && (
                      <div className="text-xs">
                        {info.row.original?.team.nextBettingLines &&
                        info.row.original?.team.nextBettingLines[0] ? (
                          <div>
                            {
                              info.row.original?.team.nextBettingLines[0]
                                .formattedSpread
                            }
                          </div>
                        ) : (
                          <div>No betting lines available</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  {!hideLockedIcon && info.row.original.team.teamLocked && (
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-white fa-sm"
                    />
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
        );
      },
    }),
  ];

  const displayColumns = [...teamColumn, ...columns];

  //Initialize all data upon render
  const initAllData = async () => {
    const teamsData = await getAllTeamsData();

    // Make sure teamsData is not null
    if (teamsData) {
      const results = await getSeasonResultTotals(defaultSeason);

      // Build table data type
      const tableData: iTableData[] = teamsData.map((team) => {
        const teamIdString = team.id + '';
        const scores = getCumulativeScores(results[teamIdString], props.league);
        return {
          team: team,
          resultTotals: results[teamIdString],
          id: team.id,
          teamOwner: getTeamOwnerName(
            team.id,
            teamOwnerMap ? teamOwnerMap : [],
          ),
          cumulativeScores: scores,
        };
      });

      let displayedData = tableData;

      if (showAverage && displayedData) {
        displayedData = averageData(displayedData);
      }

      // If a team is selected from the autopick list, set that as the selected row
      if (preSelectedTeam) {
        const preselectedTeamWithStats = displayedData.find(
          (data) => data.team.id === preSelectedTeam.id,
        );

        if (preselectedTeamWithStats) {
          setRowSelection(preselectedTeamWithStats);
        }
      }

      if (excludedTeams?.length) {
        const excludedTeamsList = [...displayedData].filter((data) => {
          return !excludedTeams.includes(`team-${data.team.id}`);
        });

        displayedData = excludedTeamsList;
      }


      if (includedTeams?.length) {
        const includedTeamsList = [...displayedData].filter((data) =>
          includedTeams.includes(`team-${data.team.id}`),
        );

        displayedData = includedTeamsList;
      }

      if (hideBye) {
        displayedData = filterOutByeWeek(displayedData);
      }

      if (orderedList) {
        displayedData = sortTableData(displayedData, orderedList);
      }

      displayedData.forEach(
        (data) =>
          (data.team.teamLocked = hasGameStarted(
            data.team.nextGame?.start_date,
          )),
      );

      setTableDataToUse(displayedData);
    }
  };
  // Averages the given data
  const averageData = (dataToAverage: iTableData[]) => {
    const averagedData = dataToAverage.map((data) => {
      if (!data.resultTotals) {
        return data;
      }
      const numberOfGamesPlayed = data.team.gameSeasonTotals
        ? data.team.gameSeasonTotals.games
        : 0;

      const statsToUpdate = { ...data.resultTotals?.stats };
      const cumulativeScoresToAverage = { ...data.cumulativeScores };

      const avgData = (stat: number) => {
        const avgRoundedStat =
          numberOfGamesPlayed === 0
            ? 0
            : Math.round((stat / numberOfGamesPlayed) * 100) / 100;
        return avgRoundedStat;
      };

      Object.keys(statsToUpdate).forEach((category) => {
        const stat = statsToUpdate[category].stat;

        statsToUpdate[category].stat = avgData(stat);
      });

      data.cumulativeScores.Defensive.value = avgData(
        cumulativeScoresToAverage.Defensive.value,
      );
      data.cumulativeScores.Passing.value = avgData(
        cumulativeScoresToAverage.Passing.value,
      );
      data.cumulativeScores.Rushing.value = avgData(
        cumulativeScoresToAverage.Rushing.value,
      );

      data.resultTotals.stats = statsToUpdate;

      return data;
    });
    return averagedData;
  };

  const filterOutByeWeek = (tableData: iTableData[]) => {
    const filteredByBye = tableData.filter((data) => data.team.nextGame);
    return filteredByBye;
  };

  const sortTableData = (
    tableData: iTableData[],
    orderedList: string[], // string of teamIDs
  ): iTableData[] => {
    const orderedIds = orderedList.map((team) => team.split('-')[1]);

    // Create a dictionary to store the index of each school in array orderedList
    const teamIndexObj: { [teamId: number]: number } = {};
    orderedIds.forEach((id, index) => {
      teamIndexObj[+id] = index;
    });

    const sortedData = tableData.sort((a, b) => {
      return teamIndexObj[a.id] - teamIndexObj[b.id];
    });
    return sortedData;
  };

  const onColumnsToShowChange = ({ value }: { value: any }) => {
    if (value === 'Select All') {
      if (slotType !== 'tradeToTeam' && !isResearch) {
        setColumnsToShow(['Passing', 'Rushing', 'Defensive', 'Record']);
      }
      if (slotType === 'tradeToTeam' || isResearch) {
        setColumnsToShow([
          'Passing',
          'Rushing',
          'Defensive',
          'Record',
          'Team Owner',
        ]);
      }
      setSelectAll(!selectAll);
    } else {
      setSelectAll(false);

      setColumnsToShow((prevSelected) => {
        const newArray = [...prevSelected];
        // if it's in, remove
        if (newArray.includes(value)) {
          return newArray.filter((item) => item !== value);
          // else, add
        } else {
          newArray.push(value);
          return newArray;
        }
      });
    }
  };

  // This handles the hide byes select change
  const onByeSelectionChange = ({ value }: { value: any }) => {
    if (value === 'Hide Byes') {
      setHideBye(!hideBye);
    }

    setColumnsToShow((prevSelected) => {
      // if it's in, remove
      const newArray = [...prevSelected];
      if (newArray.includes(value)) {
        return newArray.filter((item) => item !== value);
        // else, add
      } else {
        newArray.push(value);
        return newArray;
      }
    });
  };

  const handleRowSelection = (rowData: iTableData) => {
    if (rowData.team.id !== currentTeam?.id) {
      if (
        !excludedTeams?.includes(`team-${rowData.team.id}`) &&
        (!rowData.team.teamLocked || isLockedTeamSelectable)
      ) {
        setRowSelection(rowData);
      } else {
        showToast({
          messageType: 'warning',
          message: 'You cant select a locked team!',
          autoHideDuration: 2000,
          dataTag: 'team_locked',
        });
      }
    }
  };

  return (
    <ModalWrapper
      title={title}
      backEnabled={false}
      closeOnTapOutside={false}
      onCloseClicked={() => {
        handleClose();
      }}
    >
      <div
        className="min-h-[75vh] flex justify-start flex-col relative"
        data-e2e="selectTeamModal"
      >
        <div className="px-4 mb-4">
          <div className="flex flex-row justify-between">
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              className="p-2 border shadow font-lg border-primary border-b-primary"
              placeholder="Search Team"
            />
            <div className="input-holder">
              <MultiSelectDropdown
                primaryContainerOptions={primaryColumnOptions}
                secondaryContainerOptions={otherColumnOptions}
                onPrimaryOptionChange={onColumnsToShowChange}
                onSecondaryOptionChange={onByeSelectionChange}
                primarySelected={columnsToShow}
                secondarySelected={hideBye ? ['Hide Byes'] : ['']}
                dropDownBtn={
                  <FontAwesomeIcon icon={faFilter} className="fa-lg" />
                }
                selectAllOptions={selectAll}
              />
            </div>
          </div>
          <div className="flex flex-wrap my-2 sm:justify-between">
            <label className="py-2">
              <span>Show Averages</span>
              <Toggle
                defaultChecked={showAverage}
                icons={false}
                onChange={(e) => {
                  setShowAverage(!showAverage);
                  if (showAverage) {
                    logEvent(analytics, 'select_content', {
                      content_type: 'show-averages',
                      content_id: '',
                    });
                  }
                }}
              />
            </label>
          </div>
          <span className="text-sm">Season Data: {defaultSeason}</span>
        </div>
        <div className="flex-1 overflow-scroll max-h-[75vh] h-[75vh] border-t-[1px] border-t-gray ">
          {tableDataToUse && tableDataToUse.length >= 0 ? (
            <Table
              data={tableDataToUse}
              columns={displayColumns}
              canSelect={1}
              columnFilters={columnFilters}
              updateColFilter={setColumnFilters}
              globalFilter={globalFilter}
              updateGlobalFilter={setGlobalFilter}
              rowSelection={rowSelection}
              rowClick={(rowData: iTableData) => {
                !isResearch && handleRowSelection(rowData);
              }}
              selectedRow={rowSelection}
              visibleColumns={tableVisibleColumns}
              noDataMessage="No teams found"
              cellRowsHighlight={currentTeam || preSelectedTeam}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        {rowSelection &&
          Object.keys(rowSelection).length > 0 &&
          !excludedTeams?.includes(`team-${rowSelection.team.id}`) &&
          !isResearch && (
            <div className="fixed bottom-0 z-10 my-4 w-full flex justify-center max-w-[560px] mx-auto">
              <div className="w-1/3 mr-2">
                <Button
                  onClick={() => {
                    if (rowSelection) {
                      setRowSelection({} as iTableData);
                    }
                  }}
                  children="Clear"
                  fullWidth
                  size="large"
                  color="warning"
                  variant="contained"
                />
              </div>
              <div className="w-2/3">
                <Tooltip
                  enterTouchDelay={0}
                  title={props.hideSelect ? 'Not your turn' : null}
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={() => {
                        if (rowSelection) {
                          onTeamSelect(rowSelection.team);
                          handleClose();
                        }
                      }}
                      disabled={props.hideSelect ?? false}
                      children={`Select ${rowSelection.team.school}`}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      data-e2e="select_team_button"
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          )}
      </div>
    </ModalWrapper>
  );
};

export default SelectTeamModal;
